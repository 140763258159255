import { PanZoomHelper } from "common/helpers/panZoomHelper";
import React, { useState, useRef } from "react";
import { ReactSVGPanZoom, TOOL_AUTO } from "react-svg-pan-zoom";

const SvgViewer = ({
	height = 500,
	width = 500,
	children,
	scaleFactor = 1.1,
	...rest
}) => {
	const Viewer = useRef(null);
	const [tool, setTool] = useState(TOOL_AUTO);
	const [value, setValue] = useState(
		PanZoomHelper.getCurrentValue(width, height)
	);
	const scaleFactorMax = 1.25;

	const setPanZoomValue = (newValue) => {
		sessionStorage.setItem("pan_zoom_value", JSON.stringify(newValue));
		setValue(newValue);
	};

	return (
		<ReactSVGPanZoom
			detectAutoPan={false}
			ref={Viewer}
			scaleFactor={scaleFactor}
			scaleFactorMax={scaleFactorMax}
			scaleFactorMin={0.5}
			width={width}
			height={height}
			tool={tool}
			onChangeTool={setTool}
			value={value}
			onChangeValue={setPanZoomValue}
			miniatureProps={{ position: "right" }}
			customToolbar={() => <></>}
			SVGBackground="transparent"
			{...rest}
		>
			{children}
		</ReactSVGPanZoom>
	);
};
export default SvgViewer;
