import LeagueService from "services/LeagueService";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import { LeagueViewType } from "common/constants/LeagueViewType";

export const loadUserLeagueInfo = async () => {
    await Promise.all([
        LeagueService.getUserLeagueLimit(),
        LeagueService.getJoinedLeagues(),
        LeagueService.getNewLeagues(),
        LeagueService.getPopularLeagues(),
    ]).then(([limit, joined, newest, popular]) => {
        const updates = {};
        updates[StoreKeys.LEAGUES.LIMIT] = limit;
        updates[StoreKeys.LEAGUES.JOINED] = joined;
        updates[StoreKeys.LEAGUES.NEW] = newest;
        updates[StoreKeys.LEAGUES.POPULAR] = popular;
        updates[StoreKeys.LEAGUES.NAV_VIEW_TYPE] =
            joined.length > 0 ? LeagueViewType.joined : LeagueViewType.popular;
        Store.setMany(updates);
    });
};

export const loadAnalystLeagueInfo = async () => {
    const analystLeague = await LeagueService.getAnalystLeague();
    const events = await LeagueService.getLeagueEvents(analystLeague.id);
    let rankings = [];
    if (events.length > 0) {
        const lastEvent = events[events.length - 1];
        rankings = await LeagueService.getLeagueRankings(
            analystLeague.id,
            lastEvent.eventId
        );
    } else {
        // Load and show all members 0 pts
    }

    const updates = {};
    updates[StoreKeys.LEAGUES.ANALYST.LEAGUE] = analystLeague;
    updates[StoreKeys.LEAGUES.ANALYST.EVENTS] = events;
    updates[StoreKeys.LEAGUES.ANALYST.RANKINGS] = rankings;
    Store.setMany(updates);
};
