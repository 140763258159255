import axiosWrapper from "common/axiosWrapper";
import Store from "common/store";
import StoreKeys from "../storeKeys";

export default class SettingsHelper {
	/** @type {Map<string, any>} */
	static settings = new Map();
	/** @type {boolean} */
	static loading = false;
	/** @type {Promise<void>} */
	static loadingPromise = null;

	constructor() {
		SettingsHelper.loadingPromise = SettingsHelper.loadSettingsFromApi();
	}

	/** @returns {Promise<any | null>} */
	static async getSetting(settingName) {
		if (SettingsHelper.settings.size === 0) {
			await SettingsHelper.loadSettingsFromApi();
		}

		if (SettingsHelper.settings.has(settingName)) {
			return SettingsHelper.settings.get(settingName);
		}

		return null;
	}

	static async loadSettingsFromApi() {
		if (this.loading) {
			await this.loadingPromise;
			return;
		}

		this.loading = true;
		this.loadingPromise = new Promise(async (resolve) => {
			/** @type {Array<Setting>} */
			const rawSettings = await axiosWrapper.getCached("api/settings/all");

			const setKeys = {};
			for (const setting of rawSettings) {
				const value = SettingsHelper.parseValueToObject(setting.value);
				SettingsHelper.settings.set(setting.name, value);

				if (setting.storeKey) {
					setKeys[setting.storeKey] = value;
				}
			}

			setKeys[StoreKeys.SETTINGS.LOADED] = true;
			Store.setMany(setKeys);

			resolve();
		});
	}

	/**
	 * @param {string} value
	 * @returns {any}
	 */
	static parseValueToObject(value) {
		if (value.length === 0) {
			return value;
		}

		const lowerCase = value.toLowerCase();
		if (lowerCase === "true") {
			return true;
		} else if (lowerCase === "false") {
			return false;
		} else if (lowerCase === "null") {
			return null;
		} else if (lowerCase === "undefined") {
			return undefined;
		} else if (Number.isInteger(value)) {
			return Number(value);
		}
	}
}

// eslint-disable-next-line no-unused-vars
class Setting {
	/** @type {number} */
	id;
	/** @type {string} */
	name;
	/** @type {string} */
	value;
	/** @type {string | null} */
	storeKey;
}
