import * as React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import { ReactComponent as Crown } from "icons/Crown.svg";
import LeagueInfo from "common/components/LeagueInfo/LeagueInfo";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import { SetLoading } from "common/components/Loader/Loader";
import LeagueService from "services/LeagueService";
import { connect } from "common/connect";
import UserManager from "common/userManager";
import ModalHelper from "common/helpers/modalHelper";
import { loadUserLeagueInfo } from "common/helpers/leagueHelper";
import ErrorModal from "common/components/Modals/ErrorModal/ErrorModal";
import CreateEditLeagueModal from "common/components/Modals/Leagues/CreateEditLeagueModal";
import LeaveDeleteLeagueModal from "common/components/Modals/Leagues/LeaveDeleteLeagueModal";
import SuccessModal from "common/components/Modals/SuccessModal/SuccessModal";

const propKeys = {
	events: StoreKeys.LEAGUES.VIEWING.EVENTS,
	league: StoreKeys.LEAGUES.VIEWING.LEAGUE,
	rankings: StoreKeys.LEAGUES.VIEWING.RANKINGS,
	viewType: StoreKeys.LEAGUES.VIEWING.VIEW_TYPE,
};

function LeagueDetailsModalC({ events, league, rankings, viewType }) {
	const isLeagueCreator = UserManager.getUserId() === league.createdBy;
	const onChangeViewType = (id) => {
		Store.set(StoreKeys.LEAGUES.VIEWING.VIEW_TYPE, id);
	};

	const onChangeEvent = async (eventId) => {
		SetLoading(true, "Loading League Rankings");
		const rankings = await LeagueService.getLeagueRankings(league.id, eventId);
		Store.set(StoreKeys.LEAGUES.VIEWING.RANKINGS, rankings);
		SetLoading(false);
	};

	const attemptLeaveLeague = async () => {
		try {
			ModalHelper.closeAllModals();
			SetLoading(true, "Leaving League");
			await LeagueService.leaveLeague(league.id);
			SetLoading(true, "Refreshing Leagues Data");
			await loadUserLeagueInfo();
			SetLoading(false);
			ModalHelper.openModal(<SuccessModal message={"League Left!"} />);
		} catch (error) {
			SetLoading(false);
			if (error["message"]) {
				ModalHelper.openModal(
					<ErrorModal
						message={error["message"]}
						displayContactDiscord={false}
					/>
				);
			}
		}
	};

	const attemptDeleteLeague = async () => {
		try {
			SetLoading(true, "Deleting League");
			await LeagueService.deleteLeague(league.id);
			ModalHelper.closeAllModals();
			await loadUserLeagueInfo();
			SetLoading(false);
			ModalHelper.openModal(<SuccessModal message={"League Deleted!"} />);
		} catch (error) {
			SetLoading(false);
			console.log(error);
		}
	};

	const openLeaveLeague = () => {
		ModalHelper.openModal(
			<LeaveDeleteLeagueModal onConfirm={() => attemptLeaveLeague()} />
		);
	};

	const openDeleteLeague = () => {
		ModalHelper.openModal(
			<LeaveDeleteLeagueModal
				isDelete={true}
				onConfirm={() => attemptDeleteLeague()}
			/>
		);
	};

	const openEditLeague = () => {
		ModalHelper.openModal(
			<CreateEditLeagueModal isEdit={true} league={league} />
		);
	};

	React.useEffect(() => {
		if (!viewType) {
			Store.set(StoreKeys.LEAGUES.VIEWING.VIEW_TYPE, 1);
		}
	});

	const ownerMenuItems = [
		{ onClick: openEditLeague, text: "Edit League" },
		{ onClick: openDeleteLeague, text: "Delete League" },
	];
	const memberMenuItems = [{ onClick: openLeaveLeague, text: "Leave League" }];
	const menuItems = isLeagueCreator ? ownerMenuItems : memberMenuItems;

	return (
		<CustomModal>
			<ModalPanel>
				<ModalBody>
					<ContentHeader
						icon={<Crown />}
						text="League Info"
						menuItems={menuItems}
					/>
					<LeagueInfo
						league={league}
						events={events}
						rankings={rankings}
						viewType={viewType}
						onChangeViewType={onChangeViewType}
						onChangeEvent={onChangeEvent}
						showNameplate={true}
					/>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}

export default function LeagueDetailsModal(props) {
	return connect(<LeagueDetailsModalC />, propKeys, props);
}
