import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import * as React from "react";
import "./SuccessFailModal.scss";
import Discord from "common/components/Discord/Discord";
import { Colors } from "styles/Colors";
import { ReactComponent as DiscordIcon } from "icons/DiscordIcon.svg";
import { ReactComponent as InstagramIcon } from "icons/InstagramIcon.svg";
import { ReactComponent as TwitterIcon } from "icons/TwitterIcon.svg";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
	return {
		icon: {
			cursor: "pointer",
			margin: "2px 4px",
		},
	};
});

export default function SuccessFailModal({
	success,
	hasNextStage,
	currentGame,
}) {
	const styles = useStyles();
	return (
		<>
			<CustomModal>
				{success === true && (
					<ModalPanel className="success-fail-modal success">
						<ModalHeader>Picks Saved!</ModalHeader>
						<ModalBody>
							{hasNextStage && (
								<label
									style={{
										display: "block",
										textAlign: "center",
										width: 214,
										letterSpacing: 1.3,
										fontWeight: "300",
										fontSize: 14,
										color: Colors.primary,
									}}
								>
									Once this stage of the event is complete, be sure to come back
									to make your picks for the next stage.
								</label>
							)}

							<div
								style={{
									display: "flex",
									margin: 8,
									justifyContent: "center",
								}}
							>
								<DiscordIcon
									title="Discord"
									onClick={() => {
										window.open("https://discord.gg/r8ujzkRYeF", "_blank");
									}}
									className={styles.icon}
								/>
								<InstagramIcon
									title="Instagram"
									onClick={() => {
										window.open(currentGame.instagramUrl, "_blank");
									}}
									className={styles.icon}
								/>
								<TwitterIcon
									title="Twitter"
									onClick={() => {
										window.open(currentGame.twitterUrl, "_blank");
									}}
									className={styles.icon}
								/>
							</div>
							<label
								style={{
									display: "block",
									textAlign: "center",
									width: 214,
									letterSpacing: 1.3,
									fontWeight: "300",
									fontSize: 14,
									color: Colors.primary,
								}}
							>
								For updates when pick'em submissions open join us on socials.
							</label>
						</ModalBody>
					</ModalPanel>
				)}
				{success === false && (
					<>
						<ModalPanel className="success-fail-modal fail">
							<ModalHeader>Error Saving Picks</ModalHeader>
							<ModalBody className="success-fail-section">
								<label>An error occured submitting your picks.</label>
								<label>Please refresh and try again.</label>
								<label>
									If problem persists, please contact us on Discord:
								</label>
								<Discord />
							</ModalBody>
						</ModalPanel>
					</>
				)}
			</CustomModal>
		</>
	);
}
