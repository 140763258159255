import { AppBar, Tab, Tabs, Toolbar } from "@material-ui/core";
import React from "react";
import * as Styles from "./styles";
import useTabSelect from "./useTabSelect";
import useTabList from "./useTabList";

function BottomIconMenu({ hasSurvey }) {
	const [tab, setTab] = useTabSelect();
	const classes = Styles.styles();
	const tabList = useTabList("small", hasSurvey && window.location.pathname.startsWith('/rl'));
	const tabClasses = Styles.tab();
	const tabsClasses = Styles.bottomTabs();

	return (
		<AppBar position="fixed" className={classes.bottomBar}>
			<Toolbar className={classes.toolbar} disableGutters>
				<Tabs
					classes={tabsClasses}
					value={tab}
					variant="fullWidth"
					TabIndicatorProps={{ children: <span /> }}
				>
					{tabList.map((item) => (
						<Tab
							key={`bottomIconMenu-${item.value}`}
							classes={tabClasses}
							label={
								<div>
									{item.icon}
									<span>{item.title}</span>
								</div>
							}
							value={item.value}
							title={item.title}
							onClick={() => setTab(item.value)}
						/>
					))}
				</Tabs>
			</Toolbar>
		</AppBar>
	);
}

export default BottomIconMenu;
