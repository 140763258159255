import { makeStyles } from "@material-ui/core";
import EventSelector from "common/components/EventSelector/EventSelector";
import { connect } from "common/connect";
import EventHelper from "common/helpers/eventHelper/eventHelper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import EventRenderer from "components/screens/EventRenderer/EventRenderer";
import * as React from "react";
import EventService from "services/EventService";

const useStyles = makeStyles(() => {
	return {
		container: {
			width: "100%",
		},
	};
});

const propKeys = {
	events: StoreKeys.ADMIN.PICKS.EVENTS,
};

function AdminPickOverrideC(props) {
	const classNames = useStyles();
	const [events, setEvents] = React.useState(null);

	React.useEffect(() => {
		if (events.length === 0) {
			EventService.getCurrentSeasonEvents().then((events) => {
				setEvents(events);
			});
		}
	});

	const onEventChange = async (eventId) => {
		await EventHelper.fullLoadEvent(eventId);
		Store.set(StoreKeys.EVENT.IS_EDITING, false);
	};

	return (
		<div className={classNames.container}>
			<EventSelector
				events={events ?? []}
				onChangeEvent={onEventChange}
				disableChange={false}
			/>
			<EventRenderer />
		</div>
	);
}

export default function AdminPickOverride(props) {
	return connect(<AdminPickOverrideC />, propKeys, props);
}
