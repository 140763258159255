export const LocalStorageKeys = {
	GAME: "GAME",
	POSTLOGINURL: "POSTLOGINURL",
	POSTLINKURL: "POSTLINKURL",
};

export const SessionStorageKeys = {
	GAME: "GAME",
	EVENTID: "EVENTID",
	GROUPPICKS: "GROUPPICKS",
	MATCHPICKS: "MATCHPICKS",
};
