import {
	AppBar,
	Badge,
	Button,
	Chip,
	Hidden,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
} from "@material-ui/core";
import {
	AccountCircle,
	ExpandMore,
	PersonOutline,
	Menu as MenuIcon,
	NotificationsNoneOutlined,
} from "@material-ui/icons";
import { connect } from "common/connect";
import {
	LocalStorageKeys,
	SessionStorageKeys,
} from "common/constants/StorageKeys";
import { changeUrl } from "common/history";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import UserManager from "common/userManager";
import { ReactComponent as PickstopLogo } from "icons/PickstopLogoNew.svg";
import React from "react";
import { withRouter } from "react-router-dom";
import * as Styles from "./styles";
import { useEffect } from "react";
import UserProfileModalHelper from "common/helpers/userProfileModalHelper";
import NotificationService from "services/NotificationService";
// eslint-disable-next-line no-unused-vars
import { NotificationDto } from "common/types/NotificationDto";
import { NotificationClickAction } from "common/constants/NotificationClickAction";
import NameplateService from "services/NameplateService";
import ModalHelper from "common/helpers/modalHelper";
import NameplateSelectorModal from "common/components/Modals/NameplateSelectorModal/NameplateSelectorModal";
import UserService from "services/UserService";
import { SetLoading } from "common/components/Loader/Loader";
import LookupService from "services/LookupService";
import TopIconMenu from "./TopIconMenu";
import { getCurrentTabFromRoute } from "./useTabSelect";
import { useLocation } from "react-router-dom";
import { GetCurrentGameId, SetGameGlobally } from "common/constants/Games";

const propKeys = {
	games: StoreKeys.GAMES,
	roles: StoreKeys.ROLES,
	open: StoreKeys.LeftPane.Open,
	notifications: StoreKeys.NOTIFICATIONS.LIST,
	readNotifications: StoreKeys.NOTIFICATIONS.READ,
	isLoggedIn: StoreKeys.USER.LOGGED_IN,
	username: StoreKeys.USER.USERNAME,
	currentSurvey: StoreKeys.SURVEY.CURRENT,
};

function NavMenuC(props) {
	const classes = Styles.styles();
	const gameMenuClasses = Styles.gameMenu();
	const [mainAnchorEl, setMainAnchorEl] = React.useState(null);
	const [userAnchorEl, setUserAnchorEl] = React.useState(null);
	const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
	const location = useLocation();
	const tab = getCurrentTabFromRoute(location);

	const gameId = GetCurrentGameId();
	const selectedGame = props.games?.find((game) => game.id === gameId);
	const isLoggedIn = props.isLoggedIn;
	const userId = UserManager.getUserId();
	const username = props.username;
	const isAdmin =
		props.roles?.includes("Admin") ||
		props.roles?.includes("EventCreator") ||
		props.roles?.includes("SurveyCreator");
	let unreadNotificationCount = props.notifications?.length;

	if (props.readNotifications && props.notifications) {
		unreadNotificationCount = props.notifications.filter(
			(n) => !props.readNotifications.includes(n.id)
		).length;
	}

	useEffect(() => {
		if (!props.games) {
			LookupService.getGames().then((games) => {
				Store.set(StoreKeys.GAMES, games);
			});
		}
		if (isLoggedIn && !props.notifications) {
			NotificationService.getNotifications().then((notifications) => {
				Store.set(StoreKeys.NOTIFICATIONS.LIST, notifications);
			});
		}
	}, [isLoggedIn, props.games, props.notifications]);

	const handleMainMenuClick = (event) => {
		setMainAnchorEl(event.currentTarget);
	};

	const handleMainMenuClose = () => {
		setMainAnchorEl(null);
	};

	const handleNotificationMenuClick = (event) => {
		setNotificationAnchorEl(event.currentTarget);
	};

	const handleNotificationMenuClose = () => {
		setNotificationAnchorEl(null);
	};

	const handleUserMenuClick = (event) => {
		setUserAnchorEl(event.currentTarget);
	};

	const handleUserMenuClose = () => {
		setUserAnchorEl(null);
	};

	const onSelectPremiumNameplateAction = () => {
		Promise.all([
			UserService.getUserCardInfo(userId),
			NameplateService.getUserNameplateOptions(),
		]).then(([userCard, nameplates]) => {
			const pinnedTrophy = !!userCard ? userCard.pinnedTrophy[0] : null;
			ModalHelper.openModal(<NameplateSelectorModal />, () => {}, {
				header: "Unlock 1 Patron reward nameplate for this month",
				userId,
				username,
				nameplates,
				pinnedEventName: pinnedTrophy?.eventName,
				pinnedTrophyImagePath: pinnedTrophy?.imagePath,
				onSelect: selectNameplate.bind(this),
				hideDefault: true,
			});
		});
	};

	const selectNameplate = (nameplate) => {
		ModalHelper.closeAllModals();
		SetLoading(true, "Saving Nameplate");
		NameplateService.selectNameplate(nameplate.id).then(() => {
			SetLoading(false);
		});
	};

	/**
	 *
	 * @param {NotificationDto} notification
	 */
	const handleNotificationClicked = (notification) => {
		handleNotificationMenuClose();

		NotificationService.readNotification(notification);
		switch (notification.onClickAction) {
			case NotificationClickAction.SelectPremiumNameplate:
				onSelectPremiumNameplateAction();
				break;
			case NotificationClickAction.ViewTrophy:
				// Not used.
				break;
			case NotificationClickAction.OpenProfile:
				const userId = UserManager.getUserId();
				UserProfileModalHelper.openProfile(userId);
				break;
			default:
				console.warn(
					`Failed to implement notification click action, ${notification.onClickAction}, in NavMenu.js`
				);
				break;
		}
	};

	const handleSetGame = (newGameId) => {
		SetGameGlobally(gameId, newGameId, true);
	};

	const handleDrawerToggle = () => {
		Store.set(StoreKeys.LeftPane.Open, !props.open);
	};

	const renderAuthMenuItems = () => {
		return (
			<div className={classes.authButtons}>
				{isLoggedIn && (
					<>
						<Hidden smDown>
							<Chip className={classes.usernameChip} label={username} />
						</Hidden>
						<IconButton
							aria-label="current-user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleNotificationMenuClick}
							disabled={!props.notifications}
						>
							<Badge badgeContent={unreadNotificationCount} color="secondary">
								<NotificationsNoneOutlined color="primary" />
							</Badge>
						</IconButton>
						<Menu
							id="menu-user"
							classes={{ paper: classes.menu }}
							anchorEl={notificationAnchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(notificationAnchorEl)}
							onClose={handleNotificationMenuClose}
						>
							{props.notifications?.length === 0 && (
								<MenuItem
									onClick={() => {
										handleNotificationMenuClose();
									}}
								>
									No notifications
								</MenuItem>
							)}
							{props.notifications?.map((notification, i) => (
								<MenuItem
									className={classes.notification}
									key={i}
									onClick={() => {
										handleNotificationClicked(notification);
										handleNotificationMenuClose();
									}}
								>
									{notification.html && (
										<div
											dangerouslySetInnerHTML={{ __html: notification.html }}
										></div>
									)}
								</MenuItem>
							))}
						</Menu>
						<IconButton
							aria-label="current-user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleUserMenuClick}
						>
							<AccountCircle className={classes.userIcon} />
						</IconButton>
						<Menu
							id="menu-user"
							classes={{ paper: classes.menu }}
							anchorEl={userAnchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(userAnchorEl)}
							onClose={handleUserMenuClose}
						>
							<MenuItem
								onClick={() => {
									const userId = UserManager.getUserId();
									UserProfileModalHelper.openProfile(userId);
									handleUserMenuClose();
								}}
							>
								Profile
							</MenuItem>
							{isAdmin && (
								<MenuItem
									onClick={() => {
										changeUrl("/admin");
										handleUserMenuClose();
									}}
								>
									Admin
								</MenuItem>
							)}
							<MenuItem
								onClick={() => {
									handleUserMenuClose();
									UserManager.logout().then(() => {
										changeUrl("/");
									});
								}}
							>
								Log Out
							</MenuItem>
						</Menu>
					</>
				)}
				{!isLoggedIn && (
					<>
						<Hidden smDown>
							<Button
								onClick={() => {
									UserManager.openLoginModal();
								}}
							>
								Login
							</Button>
							<Button
								onClick={() => {
									UserManager.openRegisterModal();
								}}
							>
								Register
							</Button>
						</Hidden>
						<Hidden mdUp>
							<IconButton
								aria-label="anonymous-user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleUserMenuClick}
							>
								<PersonOutline className={classes.userIcon} />
							</IconButton>
							<Menu
								id="menu-user"
								classes={{ paper: classes.menu }}
								anchorEl={userAnchorEl}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(userAnchorEl)}
								onClose={handleUserMenuClose}
							>
								<MenuItem
									onClick={() => {
										handleUserMenuClose();
										UserManager.openLoginModal();
									}}
								>
									Login
								</MenuItem>
								<MenuItem
									onClick={() => {
										handleUserMenuClose();
										UserManager.openRegisterModal();
									}}
								>
									Register
								</MenuItem>
							</Menu>
						</Hidden>
					</>
				)}
			</div>
		);
	};

	return (
		<AppBar position="static" className={classes.navbar}>
			<Toolbar className={classes.toolbar}>
				{tab === "pickems" && (
					<Hidden mdUp implementation="css">
						<IconButton
							aria-label="open drawer"
							onClick={handleDrawerToggle}
							edge="start"
						>
							<MenuIcon className={classes.userIcon} />
						</IconButton>
					</Hidden>
				)}
				<PickstopLogo className={classes.logo} onClick={() => changeUrl("/")} />
				{props.games && (
					<>
						<Button
							classes={gameMenuClasses}
							style={{ display: "-webkit-box" }}
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={handleMainMenuClick}
							endIcon={<ExpandMore />}
						>
							{selectedGame?.name}
						</Button>
						<Menu
							id="game-menu"
							classes={{ paper: classes.menu }}
							anchorEl={mainAnchorEl}
							open={Boolean(mainAnchorEl)}
							onClose={handleMainMenuClose}
						>
							{props.games?.map((game, i) => {
								return (
									<MenuItem key={i} onClick={() => handleSetGame(game.id)}>
										{game.name}
									</MenuItem>
								);
							})}
						</Menu>
					</>
				)}

				{<TopIconMenu hasSurvey={!!props.currentSurvey} />}

				{renderAuthMenuItems()}
			</Toolbar>
		</AppBar>
	);
}

const NavMenu = withRouter((props) => connect(<NavMenuC />, propKeys, props));

export default NavMenu;
