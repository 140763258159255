import axiosWrapper from "common/axiosWrapper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
// eslint-disable-next-line no-unused-vars
import { NotificationDto } from "common/types/NotificationDto";

const baseUrl = "/api/notification";

export default class NotificationService {
	/**
	 *
	 * @returns {Promise<Array<NotificationDto>>}
	 */
	static async getNotifications() {
		return axiosWrapper.getCached(`${baseUrl}/all`);
	}

	/**
	 *
	 * @param {number} notificationId
	 * @returns {Promise<Array<NotificationDto>>}
	 */
	static async clearNotification(notificationId) {
		return axiosWrapper.post(`${baseUrl}/clear`, { notificationId });
	}

	/**
	 *
	 * @param {NotificationDto} notification
	 * @returns
	 */
	static async readNotification(notification) {
		if (!notification) {
			return;
		}

		/** @type {number[]} */
		const read = Store.get(StoreKeys.NOTIFICATIONS.READ, []);
		if (!read.includes(notification.id)) {
			read.push(notification.id);
			Store.set(StoreKeys.NOTIFICATIONS.READ, read);

			NotificationService.clearNotification(notification.id);
		}
	}
}
