import axios from "axios";
import ErrorModal from "./components/Modals/ErrorModal/ErrorModal";
import ModalHelper from "./helpers/modalHelper";
import Store from "./store";
import StoreKeys from "./storeKeys";
import UserManager from "./userManager";
import * as React from "react";
import { GetCurrentGameId } from "./constants/Games";

const handleError = (error) => {
	const response = error.response;
	if (response.status === 401) {
		throw new Error("Unauthorized");
	} else if (response.status === 400) {
		let message = response.data;
		if (response.data["title"]) {
			message = response.data["title"];
		}
		ModalHelper.openModal(
			<ErrorModal message={message} displayContactDiscord={false} />,
			null
		);
	}

	throw response.data;
};

class AxiosWrapper {
	cache = new Map();

	get = async (url) => {
		const config = await this.getConfig();

		try {
			const result = await axios.get(url, config);
			return result.data;
		} catch (error) {
			handleError(error);
		}
	};

	getCached = async (url) => {
		if (this.cache.has(url)) {
			return Promise.resolve(this.cache.get(url));
		}

		return this.get(url).then((value) => {
			this.cache.set(url, value);

			return value;
		});
	};

	/**
	 * Used to clear a specific url from cache.
	 *
	 * @param {string | null} url
	 */
	clearCache = (url) => {
		if (!url) {
			this.cache = new Map();
			return;
		}

		if (this.cache.has(url)) {
			this.cache.delete(url);
		}
	};

	getConfig = async () => {
		const config = {};
		config.headers = {};

		config.headers.Game = GetCurrentGameId();

		return config;
	};

	post = async (url, data) => {
		const config = await this.getConfig();

		try {
			const result = await axios.post(url, data, config);
			return result.data;
		} catch (error) {
			handleError(error);
		}
	};

	delete = async (url) => {
		const config = await this.getConfig();

		try {
			const result = await axios.delete(url, config);
			return result.data;
		} catch (error) {
			handleError(error);
		}
	};
}

const axiosWrapper = new AxiosWrapper();
export default axiosWrapper;
