import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalPanel,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "common/components/CustomModal/Modal/ModalFrame";
import ModalHelper from "common/helpers/modalHelper";
import LoginModal from "../LoginModal/LoginModal";
import RegisterModal from "../RegisterModal/RegisterModal";
import FatButton from "common/components/FatButton/FatButton";

export default function AccountRequired() {
	const login = () => {
		ModalHelper.openModal(<LoginModal />);
	};

	const register = () => {
		ModalHelper.openModal(<RegisterModal />);
	};

	return (
		<CustomModal>
			<ModalPanel>
				<ModalHeader>Account Required</ModalHeader>
				<ModalBody>
					<p>
						To save picks and get rewarded based on global placements{" "}
						<label className="pickstop-color">Login</label> or{" "}
						<label className="pickstop-color">Register</label>
					</p>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<FatButton
							text="Login"
							onClick={() => {
								login();
							}}
							primary
						/>
						<FatButton
							text="Register"
							onClick={() => {
								register();
							}}
							primary
						/>
					</div>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
