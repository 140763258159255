import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalHeader,
	ModalBody,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import {
	TextField,
	Table,
	TableBody,
	TableCell,
	TableRow,
	InputAdornment,
	Grid,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import LeagueService from "services/LeagueService";
import LeagueTable from "common/components/LeagueTable/LeagueTable";
import UserManager from "common/userManager";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import { ReactComponent as Crown } from "icons/Crown.svg";

let searchTimeoutID = -1;

export default function SearchLeaguesModal() {
	const [state, setState] = React.useState({
		searchTerm: "",
		leagues: [],
	});
	const loggedIn = UserManager.isLoggedIn();

	const onChangeTerm = (searchTerm) => {
		if (searchTerm.length < 3) {
			setState({
				searchTerm,
				leagues: [],
			});
			if (searchTimeoutID) {
				clearTimeout(searchTimeoutID);
			}
			return;
		}

		setState({
			searchTerm,
			leagues: state.leagues,
		});

		if (searchTimeoutID) {
			clearTimeout(searchTimeoutID);
		}

		searchTimeoutID = window.setTimeout(() => {
			LeagueService.searchLeagues(searchTerm).then((leagues) => {
				setState({
					searchTerm,
					leagues,
				});
			});
		}, 250); //run the search code 250 milliseconds after last change
	};

	return (
		<CustomModal>
			<ModalPanel>
				<ModalBody>
					<ContentHeader icon={<Crown />} text="League Search" />
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>League Name:</TableCell>
								<TableCell>
									<TextField
										id="password"
										onChange={(e) => onChangeTerm(e.target.value)}
										defaultValue={""}
										variant="outlined"
										autoComplete="off"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Search />
												</InputAdornment>
											),
										}}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					{state.leagues?.length > 0 && (
						<Grid container xs={12}>
							<LeagueTable loggedIn={loggedIn} leagues={state.leagues} />
						</Grid>
					)}
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
