import { Box, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";
import * as React from "react";

const classStyles = makeStyles((theme) => {
	return {
		instructionsContainer: {
			marginBottom: 20,
			display: "flex",
			justifyContent: "center",
		},
		instructions: {
			padding: 8,
			borderRadius: 12,
			backgroundColor: Colors.panelLighterColor,
			width: "100%",
			textAlign: "center",
		},
	};
});

export default function Instructions(props) {
	const { text, isEditing } = props;
	const classes = classStyles(props);
	return (
		<>
			{isEditing && (
				<Box className={classes.instructionsContainer} mb={2}>
					<label className={classes.instructions}>{text}</label>
				</Box>
			)}
		</>
	);
}
