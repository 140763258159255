import axiosWrapper from "common/axiosWrapper";

const baseUrl = "/api/event";

/** The Event service which calls the API endpoints for the Event Controller */
export default class EventService {
	/** Gets the next event. */
	static async getNextEventId() {
		return await axiosWrapper.get(`${baseUrl}/next`);
	}

	/** Gets the previous event. */
	static async getPreviousEventId() {
		return await axiosWrapper.get(`${baseUrl}/previous`);
	}

	/** Gets the current event for each active game. Events can be in the future or the past. */
	static async getCurrentEvents() {
		return await axiosWrapper.getCached(`${baseUrl}/currentEvents`);
	}

	/** Gets the current season's events for the current game */
	static async getCurrentSeasonEvents() {
		return await axiosWrapper.getCached(`${baseUrl}/getSeasonEvents`);
	}

	/** Gets the event. */
	static async getEvent(eventId) {
		if (!eventId) {
			return null;
		}
		return await axiosWrapper.get(`${baseUrl}/get?eventId=${eventId}`);
	}

	/** Gets events. */
	static async getEvents(gameId) {
		return await axiosWrapper.get(`${baseUrl}/getEvents?gameId=${gameId}`);
	}

	static async getEventsNotRewarded() {
		return await axiosWrapper.get(`${baseUrl}/notRewarded`);
	}

	/** Gets the official match picks. */
	static async getOfficialMatchPicks(eventId) {
		return await axiosWrapper.get(`${baseUrl}/official?eventId=${eventId}`);
	}

	/**
	 * Gets the past event ids.
	 *
	 * @returns {Promise<Array<number>>} The past event ids.
	 */
	static async getPastEventIds() {
		return await axiosWrapper.get(`${baseUrl}/allPastEventIds`);
	}

	static async getEventImages() {
		return await axiosWrapper.getCached(`${baseUrl}/eventImages`);
	}

	static async updateTeams(teamInfo) {
		return await axiosWrapper.post(`${baseUrl}/updateteams`, teamInfo);
	}

	static async getSwissStagePickData(eventId) {
		return await axiosWrapper.get(`${baseUrl}/swissstage?eventId=${eventId}`);
	}
}
