import * as React from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";

const useStyles = makeStyles({
	contentPanel: {
		backgroundColor: Colors.panel,
		borderRadius: 12,
		padding: 10,
		marginBottom: 5,
		minHeight: 100,
	},
});

export default function ContentPanel(props) {
	const styles = useStyles();
	return (
		<Container className={styles.contentPanel}>{props.children}</Container>
	);
}
