import React from "react";
import ModalHelper from "./modalHelper";
import UserProfileModal from "common/components/Modals/UserProfileModal/UserProfileModal";

export default class UserProfileModalHelper {
	/**
	 * Opens the user profile for the user by id.
	 * @param {number} userId
	 */
	static openProfile(userId, onClose) {
		ModalHelper.openModal(<UserProfileModal userId={userId} />, onClose);
	}
}
