import axiosWrapper from "common/axiosWrapper";

/** Gets the discord login url. */
export const getDiscordUrl = async () => {
	return await axiosWrapper.getCached(`api/auth/discord-url`);
};

/** Gets the google url. */
export const getGoogleUrl = async () => {
	return await axiosWrapper.getCached(`api/auth/google-url`);
};

/** Gets the patreon url. */
export const getPatreonUrl = async () => {
	return await axiosWrapper.getCached(`api/auth/patreon-url`);
};

/** Gets the patreon url. */
export const getRedditUrl = async () => {
	return await axiosWrapper.getCached(`api/auth/reddit-url`);
};
