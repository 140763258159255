import moment from "moment";
import * as React from "react";

export default class PicksHelper {
	constructor(eventData) {
		this.event = eventData;
	}

	/**
	 * Gets the string value of the time until picks lock.
	 *
	 * @returns {string}
	 */
	getTimeTillLock() {
		if (!this.event) {
			return "";
		}

		// if (this.event.unlocked) {
		// }

		const now = new Date();
		if (!this.isLocked()) {
			const lockTime = this.getLockTime();
			const diff = lockTime - now;
			const seconds = Math.floor((diff / 1000) % 60);
			const minutes = Math.floor((diff / (1000 * 60)) % 60);
			const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
			const days = Math.floor(diff / (1000 * 60 * 60 * 24));

			let result = "";
			if (days > 0) {
				result += `${days}D `;
			}
			if (hours > 0) {
				result += `${hours}H `;
			}
			if (minutes > 0) {
				result += `${minutes}M `;
			}
			if (seconds > 0) {
				result += `${seconds}S `;
			}

			return result;
		}

		return "";
	}

	getRelockTime() {
		return this.convertUtcToLocalTime(new Date(this.event?.relockTime));
	}

	getStartTime() {
		return this.convertUtcToLocalTime(
			new Date(this.event?.metadata?.startDate)
		);
	}

	getEndTime() {
		return this.convertUtcToLocalTime(new Date(this.event?.metadata?.endDate));
	}

	getLockTime() {
		return this.event.unlocked ? this.getRelockTime() : this.getStartTime();
	}

	/**
	 *
	 * @returns {boolean} True if event has started
	 */
	isStarted() {
		if (!this.event) {
			return true;
		}

		return this.getStartTime() < new Date();
	}

	/**
	 *
	 * @returns {boolean} True if picks are locked
	 */
	isLocked() {
		if (!this.event) {
			return true;
		}

		return this.getLockTime() < new Date();
	}

	/**
	 *
	 * @returns {boolean} True if event has finished
	 */
	isFinished() {
		if (!this.event) {
			return true;
		}

		return this.getEndTime() < new Date();
	}

	/**
	 * @returns {JSX.Element}
	 */
	getEventDateRange() {
		let dateInfo = <></>;

		if (!this.event) {
			return dateInfo;
		}

		const startDate = new Date(this.event.metadata.startDate);
		const endDate = moment(new Date(this.event.metadata.endDate)).toDate();

		if (startDate.getMonth() !== endDate.getMonth()) {
			const startMonth = new Intl.DateTimeFormat("en", {
				month: "long",
			}).format(startDate);
			const startDay = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
				startDate
			);
			const endMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
				endDate
			);
			const endDay = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
				endDate
			);

			dateInfo = (
				<>
					<label>{`${startMonth} ${startDay} - ${endMonth} ${endDay}`}</label>
				</>
			);
		} else {
			const startMonth = new Intl.DateTimeFormat("en", {
				month: "long",
			}).format(startDate);
			const startDay = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
				startDate
			);
			const endDay = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
				endDate
			);

			dateInfo = (
				<>
					<label>{`${startMonth} ${startDay} - ${endDay}`}</label>
				</>
			);
		}

		return dateInfo;
	}

	/**
	 * Converts the backend UTC date to local time.
	 *
	 * @param {Date} date
	 */
	convertUtcToLocalTime(date) {
		const utcDate = new Date(
			Date.UTC(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				date.getHours(),
				date.getMinutes(),
				date.getSeconds()
			)
		);
		return utcDate;
	}
}
