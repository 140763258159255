import React from "react";

export default function FinalRoundHeader({
	x,
	y = 0,
	width,
	roundHeader,
	canvasPadding,
	onClick,
}) {
	return (
		// @ts-ignore
		<g style={{ pointerEvents: "bounding-box" }} onClick={onClick}>
			<rect
				x={x}
				y={y + canvasPadding}
				width={width}
				height={roundHeader.height}
				fill={roundHeader.backgroundColor}
				rx="3"
				ry="3"
			/>
			<text
				fontFamily={roundHeader.fontFamily}
				x={x + width / 2}
				y={y + canvasPadding + roundHeader.height / 2}
				style={{
					fontSize: `${roundHeader.fontSize}px`,
					color: roundHeader.fontColor,
				}}
				fill="currentColor"
				dominantBaseline="middle"
				textAnchor="middle"
			>
				Grand Final
			</text>
		</g>
	);
}
