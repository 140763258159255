import * as React from "react";
import { Colors } from "styles/Colors";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
	return {
		pageIndicators: {
			display: "flex",
			justifyContent: "center",
			marginBottom: 10,
			marginTop: 10,
		},
		filledCircle: {
			backgroundColor: Colors.primary,
			cursor: "default",
		},
		unfilledCircle: {
			backgroundColor: Colors.panel,
		},
		circle: {
			height: 8,
			width: 8,
			borderRadius: "50%",
			marginRight: 5,
			cursor: "pointer",
		},
	};
});

export default function PageIndicators(props) {
	const classNames = useStyles();
	let { step, totalSteps } = props;

	let pageIndicators = [];
	//MLA if time make this it's own component so it can be reused
	for (let i = 0; i < totalSteps; i++) {
		const cls =
			classNames.circle +
			" " +
			(i === step ? classNames.filledCircle : classNames.unfilledCircle);
		pageIndicators.push(<div key={i} className={cls}></div>);
	}

	return <div className={classNames.pageIndicators}>{pageIndicators}</div>;
}
