import { makeStyles, Grid, Box } from "@material-ui/core";
import * as React from "react";
import { Colors } from "styles/Colors";
import { TrophyTypes } from "common/constants/TrophyTypes";

const useStyles = makeStyles((theme) => ({
	trophyList: {
		maxHeight: 460,
		overflowY: "auto",
		borderColor: Colors.body,
	},
	awardContainer: {
		marginTop: "auto",
		marginBottom: "auto",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		borderTopLeftRadius: 12,
		borderTopRightRadius: 12,
		backgroundClip: "content-box",
	},
	awardImage: {
		maxHeight: 150,
		margin: 10,
	},
	award: {
		display: "flex",
		justifyContent: "center",
		maxWidth: 100,
	},
	eventName: {
		height: 54,
		display: "flex",
		justifyContent: "center",
		textAlign: "center",
		fontSize: 12,
	},
}));

export default function TrophyList(props) {
	const awards = props.awards ?? [];
	const styles = useStyles();

	const getGradientColor = (trophyTypeId) => {
		switch (trophyTypeId) {
			case TrophyTypes.Wood:
				return Colors.wood;
			case TrophyTypes.Bronze:
				return Colors.bronze;
			case TrophyTypes.Silver:
				return Colors.silver;
			case TrophyTypes.Gold:
				return Colors.gold;
			case TrophyTypes.Platinum:
				return Colors.platinum;
			case TrophyTypes.Diamond:
				return Colors.diamond;
			default:
				return "transparent";
		}
	};

	if (!awards || awards.length === 0) {
		return <Box>No awards to show.</Box>;
	}

	const customStyle = {
		width: props.width,
	};
	return (
		<Grid
			container
			spacing={3}
			className={styles.trophyList}
			style={customStyle}
		>
			{awards.map((record, index) => {
				const gradientColor = getGradientColor(record.trophyTypeId);
				return (
					<Grid
						item
						xs={4}
						key={index}
						className={styles.awardContainer}
						onClick={() => {
							if (props.onClick) {
								props.onClick(record.id, record.eventId);
							}
						}}
						style={{
							background: `transparent linear-gradient(180deg, ${gradientColor} 0%, transparent 60%) 0% 0% content-box`,
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<div className={styles.award} title={record.eventName}>
								<img
									className={styles.awardImage}
									alt=""
									src={record.imagePath}
								/>
							</div>
							<div className={styles.eventName}>
								<span>{record.eventName}</span>
							</div>
						</div>
					</Grid>
				);
			})}
		</Grid>
	);
}
