import * as React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: Colors.panelLight,
		borderRadius: 12,
		border: "1px solid " + theme.palette.primary.main,
		color: theme.palette.primary.main,
		textAlign: "left",
		alignItems: "center",
		width: 143,
		height: 34,
		whiteSpace: "nowrap",
		display: "inline-flex",
		justifyContent: "center",
		cursor: "pointer",
		margin: 4,
		"&.disabled": {
			opacity: 0.4,
			cursor: "default",
			animation: "none",
		},
	},
	textSpan: {},
}));

export default function NameplateButton({
	buttonText,
	nameplateId,
	disabled,
	onClick,
}) {
	const styles = useStyles();
	return (
		<Box
			className={`${styles.root} ${disabled ? "disabled" : ""} ${
				nameplateId && !disabled ? "nameplate-" + nameplateId : ""
			} nameplate-container`}
			onClick={() => {
				if (!disabled) {
					onClick();
				}
			}}
		>
			{buttonText}
		</Box>
	);
}
