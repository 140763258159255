import * as $ from "jquery";
import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import "./Leaderboard.scss";
import { SetLoading } from "common/components/Loader/Loader";
import UserManager from "common/userManager";
import {
	AiOutlineVerticalAlignTop,
	AiOutlineVerticalAlignBottom,
	AiOutlineSearch,
} from "react-icons/ai";
import { FaCrown, FaUser } from "react-icons/fa";
import { VscGlobe } from "react-icons/vsc";
import RealtimeHelper from "common/helpers/realtime";
import ModalHelper from "common/helpers/modalHelper";
import PicksHelper from "common/picksHelper";

const resultsOnScreen = 9;
const resultsPerLoad = 29;
let allowLoadOnScroll = true;
let isLoading = false;
let lastScrollTop = 0;
let searchTimeoutID = -1;

function getNoPicksJsx() {
	return (
		<label className="shift-left">
			No picks have been scored for this event.
		</label>
	);
}

function Leaderboard(props) {
	//eventData is optional
	const {
		leaderboardClass,
		hasBracket,
		leaderboardHeaders,
		tableData,
		modalTableData,
		loadData,
		loadDataAddtl,
		noDataEl,
		firstRow,
		lastRow,
		totalRecords,
		rowNumberToGoTo,
		userIdToGoTo,
		searchLeaderboard,
		eventData,
		totalEntrants,
		currentUserRow,
	} = props;
	const [leaderboardOpen, setLeaderboardOpen] = React.useState(false);
	const [hasMore, setHasMore] = React.useState(true);

	const [bracketsSubmitted, setBracketsSubmitted] = React.useState(null);
	const [lastBracketsSubmitted, setLastBracketsSubmitted] =
		React.useState(null);
	const [searchTerm, setSearchTerm] = React.useState("");
	const eventStarted = new PicksHelper(eventData).isStarted();

	React.useEffect(() => {
		RealtimeHelper.on(
			"/submissions",
			"SubmissionUpdate",
			onSubmissionCountUpdate
		);
		return function cleanup() {
			RealtimeHelper.off(
				"/submissions",
				"SubmissionUpdate",
				onSubmissionCountUpdate
			);
		};
	});

	React.useEffect(
		(eventId) => {
			const id = !!eventData ? eventData.id : undefined;
			const bs = !!eventData ? eventData.bracketsSubmitted : undefined;
			if (id !== eventId) {
				setBracketsSubmitted(bs);
			}
		},
		[eventData]
	);

	React.useEffect(() => {
		if (leaderboardOpen) {
			const resultRow = getModalTableRowById(userIdToGoTo);
			if (!rowNumberToGoTo || !resultRow) {
				return;
			}

			const $body = getModalTableBody();
			let scrollTop =
				rowNumberToGoTo < resultsOnScreen + 1 ? 0 : resultRow.position().top;

			$body.scrollTop(scrollTop);
			lastScrollTop = scrollTop;
		}
	}, [rowNumberToGoTo, userIdToGoTo, leaderboardOpen]);

	const getModalTableBody = () => {
		return $(`.modal-table .leaderboard-body`);
	};

	const getModalTableRow = () => {
		return $(`.modal-table .leaderboard-row`);
	};

	const getModalTableRowById = (id) => {
		return $(`.modal-table .leaderboard-row[data-id=${id}]`);
	};

	const getRowHeight = () => {
		const $row = getModalTableRow();
		if (!$row) {
			return 0;
		}
		return $row.height();
	};

	const scrollToTop = () => {
		const $body = getModalTableBody();
		isLoading = true;
		loadData(0, true, true).then(() => {
			allowLoadOnScroll = true;
			setHasMore(true);
			$body.animate({ scrollTop: 0 }, "fast");
			isLoading = false;
		});
	};

	const scrollToBottom = () => {
		const $body = getModalTableBody();
		isLoading = true;
		loadData(totalRecords - 29, true, true).then(() => {
			allowLoadOnScroll = true;
			setHasMore(true);
			$body.animate({ scrollTop: $body[0].scrollHeight }, "fast");
			isLoading = false;
		});
	};

	const fetchMoreData = (ev) => {
		if (isLoading || !hasMore || !allowLoadOnScroll) {
			return;
		}
		if (firstRow === 0 && lastRow === totalRecords) {
			setHasMore(false);
			return;
		}
		const evScrollTop = ev.target.scrollTop;
		const evScrollHeight = ev.target.scrollHeight;
		let startRow = 0;
		let isUp = false;
		if (evScrollTop > lastScrollTop) {
			startRow = lastRow;
			if (evScrollTop < evScrollHeight * 0.6 || startRow === totalRecords) {
				return;
			}
		} else {
			if (evScrollTop > evScrollHeight * 0.3) {
				return;
			}
			isUp = true;
			startRow = firstRow - (resultsPerLoad + 1);
			if (firstRow === 1) {
				return;
			}
		}
		isLoading = true;

		loadData(startRow, false, isUp).then((hasData) => {
			isLoading = false;
			if (!hasData) {
				//if user scrolls too fast they might hit a 0 here so
				//set scrolltop off by one so if user scrolls we trigger event
				if (isUp && firstRow) {
					getModalTableBody().scrollTop(lastScrollTop + 1);
				} else if (!isUp && lastRow < totalRecords) {
					getModalTableBody().scrollTop(lastScrollTop - 1);
				}

				isLoading = false;
				return;
			}
			if (isUp) {
				const rowHeight = getRowHeight();
				const newScrollTop = rowHeight * modalTableData.length;
				getModalTableBody().scrollTop(newScrollTop);
			}
		});
	};

	const onSearchChange = (ev) => {
		const value = ev.target.value.trim();

		setSearchTerm(value);

		if (value.length > 0 && value.length < 3) {
			return;
		}
		if (searchTimeoutID) {
			clearTimeout(searchTimeoutID);
		}
		allowLoadOnScroll = !value;
		if (!value) {
			openLeaderboardMe();
			return;
		}
		searchTimeoutID = window.setTimeout(() => {
			searchLeaderboard(value.trim()).then(() => {
				getModalTableBody().scrollTop(0);
				lastScrollTop = 0;
			});
		}, 250); //run the search code 250 milliseconds after last change
	};
	const onSubmissionCountUpdate = (eventId, bracketsSubmitted) => {
		if (!!eventData && eventData.id === eventId) {
			setBracketsSubmitted(bracketsSubmitted);
		}
	};

	const openLeaderboardTop = () => {
		setHasMore(true);
		openLeaderboard(true);
	};

	const openLeaderboardMe = () => {
		openLeaderboard(false);
	};

	const closeLeaderboard = () => {
		loadDataAddtl(true).then(() => {
			setLeaderboardOpen(false);
		});
	};

	//@param fromTop: if true, will load resultsPerLoad records starting at Rank=1, if false,
	//will load resultsPerLoad with the user's rank in the middle (ie if user rank is 100 we'll load (resultsPerLoad - 1) / 2 results before user, user rank, and (resultsPerLoad - 1) / 2 after user
	const openLeaderboard = (fromTop) => {
		SetLoading(true, "Loading");
		loadDataAddtl(fromTop).then(() => {
			setLeaderboardOpen(true);

			ModalHelper.listenForClose(closeLeaderboard.bind(this));
			ModalHelper.listenForOpen(() => {
				setLeaderboardOpen(false);
				ModalHelper.listenForClose(() => {
					setLeaderboardOpen(true);
					setTimeout(() => {
						ModalHelper.listenForClose(closeLeaderboard.bind(this));
					});
				});
			});

			isLoading = false;
			SetLoading(false);
		});
	};

	let bracketCountChanged = lastBracketsSubmitted !== bracketsSubmitted;
	if (bracketCountChanged) {
		setTimeout(() => {
			setLastBracketsSubmitted(bracketsSubmitted);
		}, 500);
	}
	const showYourLeaderboard = UserManager.isLoggedIn() && hasBracket;

	if (!tableData || tableData.length < 1) {
		return (
			<>
				{noDataEl}
				<div className={"right " + leaderboardClass}>
					<div className={"leaderboard " + leaderboardClass}>
						<label
							className={
								"brackets-submitted " +
								(bracketCountChanged ? "changed " : "") +
								leaderboardClass
							}
						>
							<span>{bracketsSubmitted}</span>{" "}
							{"Submission" + (bracketsSubmitted !== 1 ? "s " : " ")}
						</label>
						<label className={"total-entrants " + leaderboardClass}>
							<span>
								{totalEntrants} {"Entrant" + (totalEntrants !== 1 ? "s" : "")}
							</span>
						</label>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="leaderboard-container">
				<div
					className={"leaderboard-heading-container left " + leaderboardClass}
				>
					<div className="leaderboard-heading">
						<FaCrown />
						<h4>{eventStarted ? "GLOBAL TOP 10" : "RECENT ENTRANTS"}</h4>
					</div>
				</div>
				{tableData.length === 0 && getNoPicksJsx()}
				{tableData.length > 0 && (
					<>
						<div className={"right " + leaderboardClass}>
							<div className={"leaderboard " + leaderboardClass}>
								<div className={"leaderboard-headers"}>
									{leaderboardHeaders && leaderboardHeaders.length > 0 && (
										<div className="leaderboard-row">
											{leaderboardHeaders.map((record, index) => {
												return (
													<div key={index} className={"header-" + (index + 1)}>
														{record}
													</div>
												);
											})}
										</div>
									)}
								</div>
								<div className="leaderboard-body">
									{tableData.map((record, index) => {
										if (index < 10) {
											return (
												<div
													key={index}
													className={
														"leaderboard-row " + (index === 0 ? "first" : "")
													}
												>
													{record}
												</div>
											);
										}
										return null;
									})}
									<label
										className={
											"brackets-submitted " +
											(bracketCountChanged ? "changed " : "") +
											leaderboardClass
										}
									>
										<span>{bracketsSubmitted}</span>{" "}
										{"Submission" + (bracketsSubmitted !== 1 ? "s " : " ")}
									</label>
									<label className={"total-entrants " + leaderboardClass}>
										<span>
											{totalEntrants}{" "}
											{"Entrant" + (totalEntrants !== 1 ? "s" : "")}
										</span>
									</label>
									{currentUserRow && (
										<div className="current-user leaderboard-body">
											<div className="leaderboard-row">{currentUserRow}</div>
										</div>
									)}
								</div>
							</div>
						</div>
						{eventStarted && (
							<div className="leaderboard-button-row">
								<div className="leaderboard-button-container">
									<button
										className="leaderboard-details full"
										onClick={openLeaderboardTop.bind(this)}
									>
										<VscGlobe />
										GLOBAL LEADERBOARD
									</button>
								</div>
								{showYourLeaderboard && (
									<div className="leaderboard-button-container">
										<button
											className="leaderboard-details your"
											onClick={openLeaderboardMe.bind(this)}
										>
											<FaUser />
											YOUR LEADERBOARD
										</button>
									</div>
								)}
							</div>
						)}
					</>
				)}
			</div>
			{leaderboardOpen && (
				<CustomModal>
					<ModalPanel>
						<ModalHeader>Global Leaderboard</ModalHeader>
						<ModalBody>
							<div style={{ minHeight: 420 }}>
								<div className={"leaderboard modal-table " + leaderboardClass}>
									<div className={"leaderboard-headers"}>
										{leaderboardHeaders && leaderboardHeaders.length > 0 && (
											<div className="leaderboard-row">
												{leaderboardHeaders.map((record, index) => {
													return (
														<div
															key={index}
															className={"header-" + (index + 1)}
														>
															{record}
														</div>
													);
												})}
											</div>
										)}
										<span
											className="click-target goToTop"
											onClick={scrollToTop.bind(this)}
										>
											<AiOutlineVerticalAlignTop />
										</span>
									</div>
									<div
										className="leaderboard-body"
										onScroll={fetchMoreData.bind(this)}
									>
										{modalTableData.map((record) => {
											return record;
										})}
									</div>
									<div className="footer">
										<span
											className="click-target goToBottom"
											onClick={scrollToBottom.bind(this)}
										>
											<AiOutlineVerticalAlignBottom />
										</span>
									</div>
								</div>
								<div className="leaderboardSearch">
									<AiOutlineSearch />
									<input
										placeholder="User search"
										value={searchTerm}
										onChange={onSearchChange.bind(this)}
									/>
								</div>
							</div>
						</ModalBody>
					</ModalPanel>
				</CustomModal>
			)}
		</>
	);
}

export default Leaderboard;
