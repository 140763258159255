import * as React from "react";
import { BsClock } from "react-icons/bs";
import PicksHelper from "common/picksHelper";
import { useForceUpdate } from "common/helpers/componentHelper";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		fontSize: 12,
		textAlign: "center",

		"& label": {
			display: "block",

			"& svg": {
				marginRight: 4,
				position: "relative",
				bottom: 1,
			},

			"&.clock-label": {
				"& svg": {
					marginRight: 8,
				},
			},
		},
	},
}));

export default function Timer(props) {
	const forceUpdate = useForceUpdate();
	const picksHelper = new PicksHelper(props.eventData);
	const locked = picksHelper.isLocked();
	const finished = picksHelper.isFinished();
	const lockTime = picksHelper.getTimeTillLock();

	/** Used to update state each second to reflect in the lock clock. */
	useEffect(() => {
		if (!locked) {
			const timer = window.setTimeout(() => {
				forceUpdate();
			}, 1000);

			return () => {
				if (timer) {
					window.clearTimeout(timer);
				}
			};
		}
	});
	const classNames = useStyles();

	if (!props.eventData) {
		return <></>;
	}

	return (
		<div className={classNames.container}>
			{finished && <label className="clock-label">Event is complete</label>}
			{!finished && (
				<>
					{!locked && (
						<>
							<label className="clock-label">
								<BsClock />
								Picks lock in:
							</label>
							<label className="lock-time">{lockTime}</label>
						</>
					)}
					{locked && <label className="clock-label">Picks Are Locked</label>}
				</>
			)}
		</div>
	);
}
