import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

/**
 * Changes the url without refreshing the page.
 *
 * @param {string} url
 */
export function changeUrl(url) {
	history.push(url);
}

/**
 * Gets the base url of the website.
 *
 * @returns {string}
 */
export function getBaseUrl() {
	var getUrl = window.location;
	var baseUrl = getUrl.protocol + "//" + getUrl.host;

	return baseUrl;
}
