import { GroupStageTeam } from "common/components/GroupStageTeam/GroupStageTeam";
import { PickTypes } from "common/constants/PickTypes";
import EventHelper from "common/helpers/eventHelper/eventHelper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";
import Instructions from "common/components/Instructions/Instructions";
import _ from "lodash";
// eslint-disable-next-line no-unused-vars
import { EventFormatData } from "common/helpers/eventFormatHelper";
// eslint-disable-next-line no-unused-vars
import { GroupStagePick } from "common/types/GroupStagePick";
import PageIndicators from "common/components/PageIndicators/PageIndicators";
import { connect } from "common/connect";
import UserManager from "common/userManager";
import ModalHelper from "common/helpers/modalHelper";
import AccountRequired from "common/components/Modals/AccountRequired/AccountRequired";
import NameplateButton from "common/components/NameplateButton";
import { GetCurrentGameId } from "common/constants/Games";

const classStyles = makeStyles((theme) => {
	return {
		rounded: {
			borderTopLeftRadius: theme.shape.borderRadius,
			borderTopRightRadius: theme.shape.borderRadius,
			borderBottomLeftRadius: theme.shape.borderRadius,
			borderBottomRightRadius: theme.shape.borderRadius,
		},
		groupSection: {
			width: 96,
			backgroundColor: Colors.panelLight,
			paddingTop: theme.spacing(),
			paddingBottom: theme.spacing(),
			textAlign: "center",

			"& label": {
				paddingBottom: theme.spacing(),
			},
		},
		groupTeam: {
			paddingTop: 2,
			paddingBottom: 2,
		},
		groupStageItem: {
			margin: 4,
		},
		topLeftEl: {
			position: "absolute",
			zIndex: 10,
			color: Colors.white,
			top: -3,
			left: -3,
			width: 0,
			height: 0,
			borderTopLeftRadius: 12,
			borderWidth: 24,
			borderStyle: "solid",
			borderBottomColor: "transparent",
			borderRightColor: "transparent",
			opacity: 1,
		},
		topLeftElTxt: {
			position: "absolute",
			fontSize: 14,
			fontWeight: 500,
			width: 25,
			top: -18,
			left: -20,
		},
		threeWin: {
			borderTopColor: Colors.primary,
			borderLeftColor: Colors.primary,
			color: Colors.greyText,
		},
		buttonRow: {
			display: "flex",
			justifyContent: "center",
			marginBottom: 12,
		},
	};
});

const propKeys = {
	eventData: StoreKeys.EVENT.EVENTDATA,
	format: StoreKeys.EVENT.FORMAT,
	isEditing: StoreKeys.EVENT.IS_EDITING,
	showPlayers: StoreKeys.EVENT.SHOW_PLAYERS,
	userGroupPicks: StoreKeys.EVENT.USER.GROUP_PICKS,
	officialGroupPicks: StoreKeys.EVENT.OFFICIAL.GROUP_PICKS,
	bracketUser: StoreKeys.EVENT.BRACKET_USER,
	stageIndex: StoreKeys.EVENT.STAGE_INDEX,
	step: StoreKeys.EVENT.STEP,
	showOfficial: StoreKeys.EVENT.SHOW_OFFICIAL,
	games: StoreKeys.GAMES,
};

/**
 * @typedef GroupStageRendererCProps
 * @type {object}
 * @property {any} eventData
 * @property {EventFormatData} format
 * @property {boolean} isEditing
 * @property {boolean} showPlayers
 * @property {GroupStagePick[]} userGroupPicks
 * @property {GroupStagePick[]} officialGroupPicks
 * @property {any} bracketUser
 * @property {number} stageIndex
 * @property {number} step
 * @property {bool} showOfficial
 * @property {number} width
 * @property {any[]} games
 */

/**
 * @param {GroupStageRendererCProps} props
 */
function GroupStageRendererC({
	eventData,
	format,
	isEditing,
	showPlayers,
	userGroupPicks,
	officialGroupPicks,
	bracketUser,
	stageIndex,
	step,
	showOfficial,
	width,
	games,
}) {
	const classNames = classStyles();
	const currentStage = format.stages[stageIndex];
	const currentEventStage = eventData.eventStages[stageIndex];
	const groups = _.uniq(
		_.orderBy(
			eventData.teams
				.filter((et) => et.eventStageId === currentEventStage.id)
				.map((team) => team.group)
		)
	);
	const gameId = GetCurrentGameId();
	const currentGame = games.find((g) => g.id === gameId);

	officialGroupPicks = officialGroupPicks.filter(
		(ogp) => ogp.eventStageId === currentEventStage.id
	);
	userGroupPicks = userGroupPicks.filter(
		(ugp) => ugp.eventStageId === currentEventStage.id
	);

	for (const pick of userGroupPicks) {
		pick.group = currentEventStage.eventSubstage.find(
			(ess) => ess.id === pick.eventSubstageId
		)?.title;
	}

	const updateGroupPicks = (groupPicks) => {
		/** @type {GroupStagePick[]} */
		let finalPicks = Store.get(StoreKeys.EVENT.USER.GROUP_PICKS) ?? [];
		finalPicks = finalPicks.filter(
			(p) => p.eventStageId !== currentEventStage.id
		);
		const concatPicks = finalPicks.concat(groupPicks);
		Store.set(StoreKeys.EVENT.USER.GROUP_PICKS, finalPicks.concat(concatPicks));
	};

	/**
	 *
	 * @param {number} teamId
	 * @param {boolean} isAdvancing
	 */
	const toggleAdvancing = (group, teamId, isAdvancing) => {
		if (!UserManager.isLoggedIn()) {
			ModalHelper.openModal(<AccountRequired />);
			return;
		}
		if (!isEditing) {
			return;
		}

		const eventSubstageId = currentEventStage.eventSubstage.find(
			(ess) => ess.title === group
		)?.id;

		/** @type {GroupStagePick[]} */
		let groupStagePicks = userGroupPicks;
		const countAdvancing = groupStagePicks.filter(
			(gsp) => gsp.group === group && gsp.pickTypeId === PickTypes.Advancing
		).length;
		if (isAdvancing) {
			if (
				countAdvancing ===
				Number(
					currentStage.groupStageDetails.advancingCount ??
						currentStage.groupStageDetails.totalAdvancing
				)
			) {
				return;
			} else {
				groupStagePicks.push({
					userId: null,
					eventId: null,
					eventStageId: currentEventStage.id,
					eventSubstageId,
					teamId,
					pickTypeId: PickTypes.Advancing,
					group,
				});
				updateGroupPicks(groupStagePicks);
			}
		} else {
			groupStagePicks = groupStagePicks.filter(
				(gsp) =>
					!(gsp.teamId === teamId && gsp.pickTypeId === PickTypes.Advancing)
			);

			groupStagePicks = groupStagePicks.filter(
				(gsp) =>
					!(gsp.teamId === teamId && gsp.pickTypeId === PickTypes.TopTeam)
			);

			groupStagePicks = groupStagePicks.filter(
				(gsp) =>
					!(gsp.teamId === teamId && gsp.pickTypeId === PickTypes.SecondTeam)
			);

			updateGroupPicks(groupStagePicks);
		}
	};

	/**
	 *
	 * @param {string} group
	 * @param {number} teamId
	 * @param {boolean} isTopTeam
	 */
	const toggleTopTeam = (group, teamId, isTopTeam) => {
		const eventSubstageId = currentEventStage.eventSubstage.find(
			(ess) => ess.title === group
		)?.id;

		if (!isEditing) {
			return;
		}

		/** @type {GroupStagePick[]} */
		const countTopTeam = userGroupPicks.filter(
			(gsp) => gsp.group === group && gsp.pickTypeId === PickTypes.TopTeam
		).length;

		if (isTopTeam) {
			if (countTopTeam === 1) {
				return;
			} else {
				// Add the 1st team pick.
				userGroupPicks.push({
					userId: null,
					eventId: null,
					eventStageId: currentEventStage.id,
					eventSubstageId,
					teamId,
					pickTypeId: PickTypes.TopTeam,
					group,
				});

				// If the team is flagged as second, remove it
				userGroupPicks = userGroupPicks.filter(
					(gsp) =>
						!(gsp.teamId === teamId && gsp.pickTypeId === PickTypes.SecondTeam)
				);

				updateGroupPicks(userGroupPicks);
			}
		} else {
			userGroupPicks = userGroupPicks.filter(
				(gsp) =>
					!(gsp.teamId === teamId && gsp.pickTypeId === PickTypes.TopTeam)
			);
			updateGroupPicks(userGroupPicks);
		}
	};

	/**
	 *
	 * @param {string} group
	 * @param {number} teamId
	 * @param {boolean} isSecondTeam
	 */
	const toggleSecondTeam = (group, teamId, isSecondTeam) => {
		const eventSubstageId = currentEventStage.eventSubstage.find(
			(ess) => ess.title === group
		)?.id;

		if (!isEditing) {
			return;
		}

		/** @type {GroupStagePick[]} */
		const countSecondTeam = userGroupPicks.filter(
			(gsp) => gsp.group === group && gsp.pickTypeId === PickTypes.SecondTeam
		).length;

		const isAlreadyFirstTeam = !!userGroupPicks.find(
			(gsp) => gsp.teamId === teamId && gsp.pickTypeId === PickTypes.TopTeam
		);

		if (isSecondTeam) {
			if (isAlreadyFirstTeam || countSecondTeam === 1) {
				return;
			} else {
				userGroupPicks.push({
					userId: null,
					eventId: null,
					eventStageId: currentEventStage.id,
					eventSubstageId,
					teamId,
					pickTypeId: PickTypes.SecondTeam,
					group,
				});
				updateGroupPicks(userGroupPicks);
			}
		} else {
			userGroupPicks = userGroupPicks.filter(
				(gsp) =>
					!(gsp.teamId === teamId && gsp.pickTypeId === PickTypes.SecondTeam)
			);
			updateGroupPicks(userGroupPicks);
		}
	};

	const renderGroup = (index, group) => {
		return (
			<Grid item key={index}>
				<div className={classNames.groupSection + " " + classNames.rounded}>
					<label className="group-name">{group}</label>
					{eventData.teams
						.filter((team) => team.group === group)
						.map((team, index) => renderTeam(index, group, team))}
				</div>
			</Grid>
		);
	};

	const renderTeam = (index, group, team) => {
		const notAdvancing =
			userGroupPicks.filter(
				(gsp) =>
					!(gsp.teamId === team.id && gsp.pickTypeId === PickTypes.Advancing)
			).length === 0;
		const notVisible = isEditing && step === 1 && notAdvancing;
		let className = [classNames.groupSection, classNames.rounded];
		if (notVisible) {
			className.push(classNames.notVisible);
		}

		const picksToView = showOfficial ? officialGroupPicks : userGroupPicks;

		const isAdvancing =
			picksToView.find(
				(gsp) =>
					gsp.teamId === team.id && gsp.pickTypeId === PickTypes.Advancing
			) != null;
		const isTopTeam =
			picksToView.find(
				(gsp) => gsp.teamId === team.id && gsp.pickTypeId === PickTypes.TopTeam
			) != null;
		const isSecondTeam =
			picksToView.find(
				(gsp) =>
					gsp.teamId === team.id && gsp.pickTypeId === PickTypes.SecondTeam
			) != null;

		const topLeftEl = isTopTeam ? (
			<div className={classNames.topLeftEl + " " + classNames.threeWin}>
				<span className={classNames.topLeftElTxt}>1st</span>
			</div>
		) : isSecondTeam ? (
			<div className={classNames.topLeftEl + " " + classNames.threeWin}>
				<span className={classNames.topLeftElTxt}>2nd</span>
			</div>
		) : (
			<></>
		);
		return (
			<div className={classNames.groupStageItem}>
				<GroupStageTeam
					team={team}
					step={step}
					isAdvancing={isAdvancing}
					isEliminated={!isEditing && !isAdvancing}
					isTopTeam={isTopTeam}
					isSecondTeam={isSecondTeam}
					toggleAdvancing={(teamId) =>
						toggleAdvancing(group, teamId, !isAdvancing)
					}
					toggleTopTeam={(teamId) => toggleTopTeam(group, teamId, !isTopTeam)}
					toggleSecondTeam={(teamId) =>
						toggleSecondTeam(group, teamId, !isSecondTeam)
					}
					topLeftEl={topLeftEl}
					isEditing={isEditing}
					showPlayers={showPlayers}
					officialGroupPicks={officialGroupPicks}
					showOfficial={showOfficial}
				/>
			</div>
		);
	};

	const next = () => {
		let nextStep = step + 1;
		let maxStep = currentStage.groupStageDetails.scoreSecondPlace
			? 3
			: currentStage.groupStageDetails.scoreFirstPlace
			? 2
			: 1;
		if (nextStep === maxStep) {
			EventHelper.submitPicks(currentGame).then(() => {
				const updates = {};
				updates[StoreKeys.EVENT.IS_EDITING] = false;
				updates[StoreKeys.EVENT.STEP] = 0;
				Store.setMany(updates);
			});
		} else {
			if (nextStep > maxStep) {
				nextStep = maxStep;
			}
			Store.set(StoreKeys.EVENT.STEP, nextStep);
		}
	};

	const back = () => {
		let backStep = step - 1;
		if (backStep < 0) {
			backStep = 0;
		}
		Store.set(StoreKeys.EVENT.STEP, backStep);
	};

	const nextIsDisabled = (groups) => {
		if (step === 0) {
			for (let group of groups) {
				const countAdvancing = userGroupPicks.filter(
					(gsp) => gsp.group === group && gsp.pickTypeId === PickTypes.Advancing
				).length;

				if (
					countAdvancing <
					Number(
						currentStage.groupStageDetails.advancingCount ??
							currentStage.groupStageDetails.totalAdvancing
					)
				) {
					return true;
				}
			}
		} else if (step === 1) {
			for (let group of groups) {
				const countTopTeam = userGroupPicks.filter(
					(gsp) => gsp.group === group && gsp.pickTypeId === PickTypes.TopTeam
				).length;

				if (countTopTeam < 1) {
					return true;
				}
			}
		} else if (step === 2) {
			for (let group of groups) {
				const countSecondTeam = userGroupPicks.filter(
					(gsp) =>
						gsp.group === group && gsp.pickTypeId === PickTypes.SecondTeam
				).length;

				if (countSecondTeam < 1) {
					return true;
				}
			}
		}
		return false;
	};

	const getInstructions = () => {
		switch (step) {
			case 0:
				return `PICK THE ${
					currentStage.groupStageDetails.advancingCount ??
					currentStage.groupStageDetails.totalAdvancing
				} TEAMS ADVANCING FROM EACH GROUP`;
			case 1:
				return "PICK THE TOP TEAM FROM EACH GROUP";
			case 2:
				return "PICK THE SECOND PLACE TEAM FROM EACH GROUP";
			default:
				break;
		}

		return "";
	};

	const calculateNextStep = () => {
		let nextStep = step;

		// Moving from Pick Advancing
		if (step === 0) {
			if (currentStage.groupStageDetails.scoreFirstPlace) {
				nextStep = 1;
			} else {
				nextStep = 3;
			}
		}
		// Moving from Pick First Place
		else if (step === 1) {
			if (currentStage.groupStageDetails.scoreSecondPlace) {
				nextStep = 2;
			} else {
				nextStep = 3;
			}
		}

		return nextStep;
	};

	const isSubmit = calculateNextStep() === 3;
	const nextText = isSubmit ? "SUBMIT" : "NEXT";
	const nextDisabled = nextIsDisabled(groups);
	const steps = currentStage.groupStageDetails.scoreSecondPlace
		? 3
		: currentStage.groupStageDetails.scoreFirstPlace
		? 2
		: 1;
	return (
		<>
			<div
				style={{
					width: Math.max(width, 400),
					display: "flex",
					justifyContent: "center",
				}}
			>
				<div style={{ width: 400, marginTop: 8 }}>
					<Instructions isEditing={isEditing} text={getInstructions()} />
					{groups.length > 1 && (
						<Grid
							container
							justify="center"
							className={"group-stage groups-" + groups.length + " ml-2 mr-2"}
							spacing={1}
						>
							{groups.map((group, index) => renderGroup(index, group))}
						</Grid>
					)}
					{isEditing && (
						<>
							<PageIndicators step={step} totalSteps={steps} />
							<div className={classNames.buttonRow}>
								<div className="inner">
									<NameplateButton
										onClick={back}
										buttonText={"BACK"}
										disabled={step === 0}
									/>
									<NameplateButton
										onClick={next}
										buttonText={nextText}
										nameplateId={isSubmit ? 32 : null}
										disabled={nextDisabled}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default function GroupStageRenderer(props) {
	return connect(<GroupStageRendererC />, propKeys, props);
}
