import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import * as React from "react";
import "../SuccessFailModal/SuccessFailModal.scss";

export default function SuccessModal(props) {
	return (
		<>
			<CustomModal>
				<ModalPanel className="success-fail-modal success">
					<ModalHeader>Success</ModalHeader>
					<ModalBody className="success-fail-section">
						<label>{props.message}</label>
						<br />
					</ModalBody>
				</ModalPanel>
			</CustomModal>
		</>
	);
}
