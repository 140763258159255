import axiosWrapper from "common/axiosWrapper";

const baseUrl = "/api/trophy";

/** The trophy service which calls the API endpoints for the Trophy Controller */
export default class TrophyService {
	/**
	 * Pins the trophy by its combo key.
	 * @param {number} trophyId The trophy id.
	 * @param {number} eventId The event id.
	 * @returns {Promise<void>} An async result containing nothing.
	 */
	static async pinTrophy(trophyId, eventId) {
		const route = `/pintrophy/${trophyId}/${eventId}`;
		return await axiosWrapper.get(`${baseUrl}${route}`);
	}

	/**
	 * Unpins the user's trophy.
	 * @returns {Promise<void>} An async result containing nothing.
	 */
	static async unpinTrophy() {
		const route = `/unpintrophy`;
		return await axiosWrapper.get(`${baseUrl}${route}`);
	}

	/**
	 * Fetches all rewards for a user then filter by the gameId if provided.
	 * @param {number} userId The user id.
	 * @param {number | null | undefined} gameId The related game id.
	 * @returns {Promise<Array<any>>} An async result containing all of the user's rewards filtered by game id.
	 */
	static async getYourAwards(userId, gameId) {
		const route = `/usertrophies/${userId}`;
		return await axiosWrapper.getCached(`${baseUrl}${route}`).then((awards) => {
			return awards.filter((award) => !gameId || award.gameId === gameId);
		});
	}

	/**
	 * Get recent winners and the related trophies.
	 * @returns {Promise<Array<any>>}
	 */
	static async getRecentTrophies() {
		const route = "/winnersandtrophies";
		return await axiosWrapper.getCached(`${baseUrl}${route}`);
	}

	static async getTrophyLeaderboard(startRow) {
		let route = `/leaderboard`;
		if (!isNaN(startRow)) {
			route += `/${startRow}`;
		}

		return await axiosWrapper.getCached(`${baseUrl}${route}`);
	}

	static async getTrophyLeaderboardAdditional(fromTop) {
		const route = `/leaderboard/additional/${fromTop}`;
		return await axiosWrapper.getCached(`${baseUrl}${route}`);
	}

	static async searchTrophyLeaderboard(term) {
		const route = `/search/leaderboard/${term}`;
		return await axiosWrapper.getCached(`${baseUrl}${route}`);
	}

	/**
	 * Gets the user's trophy case.
	 * @param {number} userId
	 * @returns
	 */
	static async getUserTrophyCase(userId) {
		const route = `/trophycase/${userId}`;
		return await axiosWrapper.get(`${baseUrl}${route}`);
	}

	/**
	 * Updates the specified slot with the associated trophy/eventid for the current user.
	 * @param {number} slot
	 * @param {number} trophyId
	 * @param {number} eventId
	 * @returns
	 */
	static async updateTrophyCase(slot, trophyId, eventId) {
		const route = `/trophycase/${slot}/${trophyId}/${eventId}`;
		return await axiosWrapper.post(`${baseUrl}${route}`, {});
	}

	static async getTrophyLeaderboardUserRow(userId) {
		if (!userId) {
			return;
		}
		return await axiosWrapper.getCached(`${baseUrl}/leaderboarduser/${userId}`);
	}

	static async giveTrophies(eventId) {
		if (!eventId) {
			return;
		}

		return await axiosWrapper.post(
			`${baseUrl}/giveTrophies?eventId=${eventId}`,
			{}
		);
	}
}
