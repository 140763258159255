import React from "react";

export default class ModalHelper extends React.Component {
	static instance;
	static listenForCloseCallback;
	static listenForOpenCallback;

	static openModal(modal, onClose = null, props = {}) {
		if (ModalHelper.listenForOpenCallback) {
			ModalHelper.listenForOpenCallback();
			ModalHelper.listenForOpenCallback = null;
		}

		const modalStates = ModalHelper.instance.state.modalStates ?? [];

		modalStates.push(new ModalState(modal, props, onClose));

		ModalHelper.instance.setState({
			modalStates: [...modalStates],
		});
	}

	static closeAllModals() {
		if (ModalHelper.listenForCloseCallback) {
			ModalHelper.listenForCloseCallback();
			ModalHelper.listenForCloseCallback = null;
		}

		const modalStates = ModalHelper.instance.state.modalStates ?? [];
		let currentModal = modalStates.pop();
		while (currentModal) {
			if (currentModal?.onClose) {
				currentModal.onClose();
			}

			currentModal = modalStates.pop();
		}

		ModalHelper.instance.setState({
			modalStates: [],
		});
	}

	static closeModal() {
		if (ModalHelper.listenForCloseCallback) {
			ModalHelper.listenForCloseCallback();
			ModalHelper.listenForCloseCallback = null;
		}

		/** @type {Array<ModalState>} */
		const modalStates = ModalHelper.instance.state.modalStates ?? [];
		if (modalStates.length === 0) {
			return;
		}

		const currentModal = modalStates.pop();
		if (currentModal?.onClose) {
			currentModal.onClose();
		}

		ModalHelper.instance.setState({
			modalStates: [...modalStates],
		});
	}

	static removeListenForClose() {
		ModalHelper.listenForCloseCallback = null;
	}

	static listenForOpen(callback) {
		ModalHelper.listenForOpenCallback = callback;
	}

	static listenForClose(callback) {
		ModalHelper.listenForCloseCallback = callback;
	}

	/**
	 * Gets the current modal state being viewed.
	 *
	 * @returns {ModalState} the current modal state.
	 */
	static getCurrentModalState() {
		const last = ModalHelper.instance.state.modalStates.length;
		const open = last > 0;
		if (!open) {
			return null;
		}

		return ModalHelper.instance.state.modalStates[last - 1];
	}

	constructor(props) {
		super(props);

		this.state = {
			modalStates: [],
		};

		ModalHelper.instance = this;
	}

	render() {
		const last = ModalHelper.instance.state.modalStates.length;
		const open = last > 0;

		return (
			<>
				{open &&
					ModalHelper.instance.state.modalStates.map((state, i) => {
						if (!state.modal) {
							return <></>;
						}

						const element = React.cloneElement(state.modal, state.props ?? {});
						return (
							<div key={i} hidden={i !== last - 1}>
								{element}
							</div>
						);
					})}
			</>
		);
	}
}

class ModalState {
	constructor(modal, props, onClose) {
		this.modal = modal;
		this.props = props;
		this.onClose = onClose;
	}
}
