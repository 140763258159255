import { makeStyles, Grid } from "@material-ui/core";
import * as React from "react";
import { Colors } from "styles/Colors";
import { TrophyTypes } from "common/constants/TrophyTypes";
import TrophyService from "services/TrophyService";
import UserManager from "common/userManager";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";
import { EditOutlined } from "@material-ui/icons";
import ModalHelper from "common/helpers/modalHelper";
import TrophySelectorModal from "../Modals/TrophySelectorModal/TrophySelectorModal";
import { SetLoading } from "../Loader/Loader";
import UserProfileModalHelper from "common/helpers/userProfileModalHelper";

const useStyles = makeStyles((theme) => ({
	trophyList: {
		maxHeight: 500,
		overflowY: "auto",
		borderColor: Colors.body,
	},
	awardContainer: {
		marginTop: "auto",
		marginBottom: "auto",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		borderTopLeftRadius: 12,
		borderTopRightRadius: 12,
		backgroundClip: "content-box",
		position: "relative",
	},
	awardImage: {
		maxHeight: 90,
		maxWidth: 90,
		margin: 10,
	},
	award: {
		display: "flex",
		justifyContent: "center",
		maxWidth: 100,
	},
	eventName: {
		height: 54,
		display: "flex",
		justifyContent: "center",
		textAlign: "center",
		fontSize: 12,
	},
	patreonLevel: {
		height: 150,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
	},
	editIcon: {
		position: "absolute",
		right: 8,
		top: 8,
		height: 20,
		width: 20,
		stroke: theme.palette.secondary.main,
		fill: theme.palette.secondary.main,
		zIndex: 1,
		"&:hover": {
			cursor: "pointer",
		},
	},
	editIconInner: {
		height: 20,
		width: 20,
		stroke: theme.palette.secondary.main,
		fill: theme.palette.secondary.main,
	},
}));

export default function TrophyCase(props) {
	const { userId, awards, slots, width } = props;
	const isCurrentUser = userId === UserManager.getUserId();
	const [trophyCase, setTrophyCase] = React.useState(null);

	React.useEffect(() => {
		TrophyService.getUserTrophyCase(userId).then((awardData) => {
			setTrophyCase(awardData);
		});
	}, [userId]);

	const styles = useStyles();
	const getGradientColor = (trophyTypeId) => {
		switch (trophyTypeId) {
			case TrophyTypes.Wood:
				return Colors.wood;
			case TrophyTypes.Bronze:
				return Colors.bronze;
			case TrophyTypes.Silver:
				return Colors.silver;
			case TrophyTypes.Gold:
				return Colors.gold;
			case TrophyTypes.Platinum:
				return Colors.platinum;
			case TrophyTypes.Diamond:
				return Colors.diamond;
			default:
				return Colors.panelLight;
		}
	};

	const selectTrophyCaseSlot = (slotId, trophyId, eventId) => {
		ModalHelper.closeAllModals();
		SetLoading(true, "Updating Trophy Case");
		TrophyService.updateTrophyCase(slotId, trophyId, eventId).then(() => {
			SetLoading(false);
			UserProfileModalHelper.openProfile(props.userId);
		});
	};

	const editTrophySlot = (slotId) => {
		const except = trophyCase.filter(
			(trophyCase) => trophyCase.slot !== slotId
		);
		ModalHelper.openModal(<TrophySelectorModal />, () => {}, {
			header: "Select a trophy to pin",
			awards,
			except,
			onSelect: selectTrophyCaseSlot.bind(this, slotId),
		});
	};

	if (!trophyCase) {
		return <></>;
	}

	const customStyle = {
		width,
	};
	return (
		<Grid
			container
			spacing={3}
			className={styles.trophyList}
			style={customStyle}
		>
			{Array.from({ length: slots }, (x, i) => i).map((index) => {
				const record = trophyCase.find((tc) => tc.slot === index);
				const gradientColor = getGradientColor(record?.trophyTypeId);
				const hasSlotAccess = slots >= index + 1;
				const editClicked = () => {
					editTrophySlot(index);
				};
				return (
					<Grid
						item
						xs={4}
						key={index}
						className={styles.awardContainer}
						style={{
							background: `transparent linear-gradient(217deg, ${gradientColor} 0%, transparent 60%) 0% 0% content-box`,
						}}
					>
						{isCurrentUser && hasSlotAccess && (
							<HtmlTooltip title={<>Edit</>}>
								<span className={styles.editIcon} onClick={editClicked}>
									<EditOutlined className={styles.editIconInner} />
								</span>
							</HtmlTooltip>
						)}
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							{record && (
								<>
									<div className={styles.award} title={record.eventName}>
										<img
											className={styles.awardImage}
											alt=""
											src={record.imagePath}
										/>
									</div>
									<div className={styles.eventName}>
										<span>{record.eventName}</span>
									</div>
								</>
							)}
							{!record && (
								<div className={styles.patreonLevel}>
									<span>
										<span>Open</span>
									</span>
								</div>
							)}
						</div>
					</Grid>
				);
			})}
		</Grid>
	);
}
