import {
	Hidden,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	makeStyles,
} from "@material-ui/core";
import * as React from "react";
import LeagueNameplate from "../LeagueNameplate/LeagueNameplate";
import { ReactComponent as GreenOpenLock } from "icons/Green_Open_Lock.svg";
import { ReactComponent as OrangeLock } from "icons/Orange_Lock.svg";
import JoinLeagueModal from "../Modals/Leagues/JoinLeagueModal";
import ModalHelper from "common/helpers/modalHelper";
import { loadUserLeagueInfo } from "common/helpers/leagueHelper";
import ErrorModal from "../Modals/ErrorModal/ErrorModal";
import { SetLoading } from "../Loader/Loader";
import LeagueService from "services/LeagueService";
import SuccessModal from "../Modals/SuccessModal/SuccessModal";
import StoreKeys from "common/storeKeys";
import { connect } from "common/connect";
import { InfoRounded } from "@material-ui/icons";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";

const useStyles = makeStyles((theme) => ({
	logoImage: {
		width: 24,
	},
	info: {
		fontSize: 16,
		position: "relative",
		top: 3,
		left: 3,
		cursor: "pointer",
	},
}));

const propKeys = {
	joinedLeagues: StoreKeys.LEAGUES.JOINED,
};

function LeagueTableC({ joinedLeagues, leagues, loggedIn }) {
	const styles = useStyles();
	const attemptJoinLeague = async (leagueId, password = null) => {
		try {
			ModalHelper.closeAllModals();
			SetLoading(true, "Joining League");
			await LeagueService.joinLeague(leagueId, password);
			SetLoading(true, "Refreshing Leagues Data");
			await loadUserLeagueInfo();
			SetLoading(false);
			ModalHelper.openModal(<SuccessModal message={"League Joined!"} />);
		} catch (error) {
			SetLoading(false);
			if (error["message"]) {
				ModalHelper.openModal(
					<ErrorModal
						message={error["message"]}
						displayContactDiscord={false}
					/>
				);
			}
		}
	};

	const joinLeague = async (leagueId, hasPassword) => {
		if (joinedLeagues.find((l) => l.leagueId == leagueId)) {
			ModalHelper.openModal(
				<ErrorModal
					message={`You have already joined this league!`}
					displayContactDiscord={false}
				/>
			);
		} else if (!hasPassword) {
			attemptJoinLeague(leagueId);
		} else {
			ModalHelper.openModal(
				<JoinLeagueModal
					onConfirm={(password) => attemptJoinLeague(leagueId, password)}
				/>
			);
		}
	};

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell></TableCell>
					<TableCell>League Name</TableCell>
					<TableCell>
						<Hidden xsDown>Members</Hidden>
						<Hidden smUp>#</Hidden>
					</TableCell>
					<TableCell align="center">
						Required{" "}
						{/* <HtmlTooltip
							title={
								<>
									<span>3rd Party Account Link Required.</span>
									<span>Open your profile to link 3rd party accounts.</span>
								</>
							}
						>
							<InfoRounded className={styles.info} />
						</HtmlTooltip> */}
					</TableCell>
					{loggedIn && <TableCell>Join</TableCell>}
				</TableRow>
			</TableHead>
			<TableBody>
				{leagues.map((l, i) => (
					<TableRow>
						<TableCell>{i + 1}</TableCell>
						<TableCell>
							<LeagueNameplate
								name={l.name}
								nameplateId={l.nameplateId}
								disableClick={true}
							/>
						</TableCell>
						<TableCell>{l.members}</TableCell>
						<TableCell align="center">
							{l.requireDiscord && (
								<img
									className={styles.logoImage}
									src="/images/DiscordLogo.png"
									title="Discord"
									alt="Discord Logo"
								/>
							)}
							{l.requireReddit && (
								<img
									className={styles.logoImage}
									src="/images/RedditLogo.png"
									title="Reddit"
									alt="RedditLogo Logo"
								/>
							)}
						</TableCell>
						{loggedIn && (
							<TableCell>
								<div>
									{l.hasPassword ? (
										<OrangeLock
											style={{
												cursor: "pointer",
											}}
											onClick={() => {
												joinLeague(l.id, l.hasPassword);
											}}
										/>
									) : (
										<GreenOpenLock
											style={{
												cursor: "pointer",
											}}
											onClick={() => {
												joinLeague(l.id, l.hasPassword);
											}}
										/>
									)}
								</div>
							</TableCell>
						)}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}

export default function LeagueTable(props) {
	return connect(<LeagueTableC />, propKeys, props);
}
