import { connect } from "common/connect";
import ModalHelper from "common/helpers/modalHelper";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import ScorePillModal from "../Modals/ScorePillModal/ScorePillModal";
import "./ScorePill.scss";

const propKeys = {
	formatData: StoreKeys.EVENT.FORMAT,
	bracketScore: StoreKeys.EVENT.SCORE,
};

function ScorePillC({ formatData, bracketScore }) {
	const openScoreBreakdown = () => {
		ModalHelper.openModal(<ScorePillModal />, null, {
			groups: formatData?.scoringBreakdown?.groups,
			bracketScore,
		});
	};

	return (
		<>
			<div className="score-pill" onClick={openScoreBreakdown}>
				<div className="inner">
					<div className="score-label">
						<span>Score</span>
						<AiFillInfoCircle />
					</div>
					<div className="corner">
						<div className="points">{bracketScore?.totalScore ?? 0} pts</div>
						<div className="top">
							Top{" "}
							{bracketScore
								? Math.max(Math.ceil(bracketScore?.percentile ?? 100), 1)
								: 100}
							%
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default function ScorePill(props) {
	return connect(<ScorePillC />, propKeys, props);
}
