import { Button, makeStyles } from "@material-ui/core";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import EventFormatService from "services/EventFormatService";
import EventFormatBracketBuilder from "./EventFormatBracketBuilder";
import EventFormatDetails from "./EventFormatDetails";
import EventFormatHelper, {
	buildScoringDetails,
} from "common/helpers/eventFormatHelper";
import { SetLoading } from "common/components/Loader/Loader";

const useStyles = makeStyles((theme) => {
	return {
		buttonRow: {
			marginTop: theme.spacing() * 2,
			marginBottom: 12,
		},
	};
});

export default function EventFormatEditor() {
	const classNames = useStyles();

	const save = async () => {
		/** @type {EventFormatBuilder} */
		const formatBuilder = Store.get(
			StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT
		);

		// Update Scoring incase it wasn't updated previously
		buildScoringDetails(formatBuilder);

		const eventFormat = { ...formatBuilder.eventFormat };
		eventFormat.json = JSON.stringify(formatBuilder.data);

		SetLoading(true, "Saving Format");
		try {
			const updatedFormat = await EventFormatService.saveFormat(eventFormat);
			const formatBuilder =
				EventFormatHelper.getEventFormatBuilder(updatedFormat);
			Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, formatBuilder);
			Store.set(StoreKeys.EVENTFORMATBUILDER.EVENT_FORMATS, null);
		} catch (error) {
			console.error(error);
		}
		SetLoading(false);
	};

	const saveAndClose = async () => {
		await save();
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, "");
	};

	const cancel = () => {
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, "");
	};

	return (
		<>
			<EventFormatDetails />
			<EventFormatBracketBuilder />

			<div className={classNames.buttonRow}>
				<Button color="primary" onClick={save}>
					SAVE
				</Button>
				<Button color="primary" onClick={saveAndClose}>
					SAVE AND CLOSE
				</Button>
				<Button color="secondary" onClick={cancel}>
					CANCEL
				</Button>
			</div>
		</>
	);
}
