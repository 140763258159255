import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalPanelTransparent,
	ModalHeader,
	ModalBody,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
// eslint-disable-next-line no-unused-vars
import { NameplateStyle } from "common/types/NameplateStyle";
import { Grid, makeStyles, Box } from "@material-ui/core";
import LeagueNameplate from "common/components/LeagueNameplate/LeagueNameplate";

const useStyles = makeStyles((theme) => ({
	pointer: {
		cursor: "pointer",
	},
	name: {
		textDecoration: "underline",
		display: "block",
		marginTop: theme.spacing(),
		cursor: "pointer",
	},
	descriptionContainer: {
		textAlign: "center",
		fontSize: 14,
	},
	description: {
		cursor: "pointer",
	},
	maxHeight300: {
		maxHeight: 300,
		overflowY: "auto",
	},
	extraItems: {
		display: "flex",
		flexDirection: "column",
		alignContent: "space-around",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 8,
	},
}));

export default function LeagueNameplateSelectorModal(props) {
	const { leagueName, hideDefault } = props;
	/** @type {Array<NameplateStyle>} */
	const nameplates = props.nameplates;
	/** @type {(value: NameplateStyle) => void} */
	const onSelect = props.onSelect;
	const styles = useStyles();

	/** @type {NameplateStyle} */
	const defaultNameplate = new NameplateStyle(
		null,
		"Classic",
		"The default nameplate",
		""
	);

	if (!onSelect) {
		console.error(
			"You are using a Nameplate Selector Modal without an onSelect callback."
		);
	}

	/**
	 *
	 * @param {NameplateStyle} nameplate
	 * @returns {JSX.Element}
	 */
	const renderNameplateChoice = (nameplate, i) => {
		return (
			<Grid
				container
				item
				xs={12}
				sm={6}
				key={i}
				onClick={() => onSelect(nameplate)}
				justify="center"
			>
				<Box mb={6} ml={2} mr={2}>
					<Grid
						container
						className={styles.pointer}
						direction="column"
						alignItems="center"
						wrap="nowrap"
					>
						<Grid item xs={12}>
							<LeagueNameplate
								name={leagueName}
								nameplateId={nameplate.id}
								disableClick={true}
							/>
						</Grid>
						<Grid item xs={12}>
							<label className={styles.name}>{nameplate.name}</label>
						</Grid>
						<Grid item xs={12} className={styles.descriptionContainer}>
							<label className={styles.description}>
								{nameplate.description}
							</label>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		);
	};

	return (
		<CustomModal>
			<ModalPanelTransparent>
				<ModalPanel>
					<ModalHeader>{props.header ?? "Select a Nameplate"}</ModalHeader>
					<ModalBody>
						<Grid
							container
							justify="space-around"
							className={styles.maxHeight300}
						>
							{!hideDefault && renderNameplateChoice(defaultNameplate)}
							{nameplates.map((nameplate, i) =>
								renderNameplateChoice(nameplate, i)
							)}
							{hideDefault && nameplates.length === 0 && (
								<div>
									<h4>There are no nameplates to select.</h4>
								</div>
							)}
						</Grid>
					</ModalBody>
				</ModalPanel>
			</ModalPanelTransparent>
		</CustomModal>
	);
}
