import * as React from "react";
import { connect } from "common/connect";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
// eslint-disable-next-line no-unused-vars
import {
	EventFormatBracket,
	EventFormatBracketPart,
	EventFormatBracketColumn,
	EventFormatBracketColumnMatch,
	EventFormatBracketColumnMatchTarget,
	EventFormatBuilder,
	EventFormatStage,
	getHeaderNamesFromBracket,
	EventFormatBracketView,
	EventFormatScoringBreakdown,
	EventFormatScoringBreakdownGroup,
	EventFormatScoringBreakdownRecord,
	EventFormatScoringGroup,
	buildScoringDetails,
	EventFormatBracketScoringGroup,
} from "common/helpers/eventFormatHelper";
import {
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Tab,
	Tabs,
	TextField,
} from "@material-ui/core";
import { Colors } from "styles/Colors";
import DoubleEliminationBracket from "bracket-system/bracket-double/double-elim-bracket";
import Match from "bracket-system/components/match";
import { EventStageTypes } from "common/constants/EventStageTypes";
import * as GroupStageBracket from "bracket-system/mock-data/double-elim-group-stage";
import * as DoubleElimination from "bracket-system/mock-data/simple-double-full";
import * as Playoffs from "bracket-system/mock-data/playoffs";
import Collapse from "common/components/Collapse/Collapse";
import { GetUpperBracketHeaderName } from "bracket-system/components/upper-round-header";
import { GetLowerBracketHeaderName } from "bracket-system/components/lower-round-header";
import { Match as MatchType, Theme } from "bracket-system/types";
import { Subject } from "rxjs";
import {
	ChevronLeft,
	ChevronRight,
	Add,
	Edit,
	Delete,
	Launch,
} from "@material-ui/icons";
import { flattenArrayList } from "common/helpers/arrayHelper";
import ModalHelper from "common/helpers/modalHelper";
import ScorePillModal from "common/components/Modals/ScorePillModal/ScorePillModal";
import SvgViewer from "bracket-system/svg-viewer";
import { useForceUpdate } from "common/helpers/componentHelper";
import {
	findBracketMatchById,
	findColumnMatchById,
	getBracketMatchIdByTarget,
	getBracketMatchIdsByTargetColumn,
	getMatchCount,
	getPartName,
} from "common/helpers/bracketHelper";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => {
	return {
		bracketBuilderContainer: {
			display: "flex",
			flexDirection: "row",
			position: "relative",
		},
		bracketRender: {
			border: `2px solid ${Colors.panel}`,
			display: "inline-flex",
			flex: "0 0 75%",
			maxHeight: 800,
			overflow: "auto",
		},
		bracketEditor: {
			border: `2px solid ${Colors.panel}`,
			display: "inline-flex",
			flex: "0 0 25%",
			flexDirection: "column",
			maxHeight: 800,
			backgroundColor: Colors.panel,
		},
		bracketEditorPhaseSelector: {
			display: "flex",
			alignContent: "flex-start",
			justifyContent: "space-between",
			alignItems: "center",
			borderBottom: `1px solid ${Colors.body}`,
		},
		invisibleOnDisable: {
			"&:disabled": {
				color: "transparent",
				backgroundColor: "transparent",
				border: 0,
				"& .MuiSvgIcon-root": {
					color: "transparent !important",
				},
			},
		},
		subEditor: {
			marginLeft: 24,
		},
		subEditorPlus: {
			marginLeft: 48,
		},
		scroller: {
			maxHeight: 800,
			overflowY: "auto",
		},
		editorField: {
			marginBottom: 8,
			"& .MuiInputBase-root": {
				overflow: "hidden",
			},
		},
		bannerContainer: {
			position: "absolute",
			width: "75%",
			textAlign: "center",
		},
		bannerInner: {
			margin: 4,
			padding: "8px 16px",
			display: "inline-block",
			backgroundColor: Colors.panel,
			borderRadius: theme.shape.borderRadius,
		},
		viewHeader: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			flexGrow: 1,
			marginLeft: 12,
			marginRight: 24,
		},
		textField: {
			display: "block",
			margin: "20px 0",
		},
	};
});

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {EventFormatBuilder} currentFormat
 * @property {EventFormatBracketView} currentView
 * @property {EventFormatBracketScoringGroup} currentScoringGroup
 * @property {EventFormatBracketColumnMatch} bindingMatch
 * @property {number} bracketEditorView
 */

const propKeys = {
	currentFormat: StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT,
	currentView: StoreKeys.EVENTFORMATBUILDER.CURRENT_VIEW,
	currentScoringGroup: StoreKeys.EVENTFORMATBUILDER.CURRENT_SCORING_GROUP,
	bindingMatch: StoreKeys.EVENTFORMATBUILDER.BINDING_MATCH,
};

/**
 * @param {ComponentProps} props
 */
function EventFormatBracketBuilderC({
	currentFormat,
	currentView,
	currentScoringGroup,
	bindingMatch,
}) {
	const classNames = useStyles();
	const [tabValue, setTabValue] = React.useState(0);
	const [editorStage, setEditorStage] = React.useState(0);
	const forceUpdate = useForceUpdate();
	const focusSubject = new Subject();
	const focusObservable = focusSubject.asObservable();

	const focus = (id) => {
		if (bindingSomething()) {
			return;
		}

		focusSubject.next(id);

		setTimeout(() => {
			const element = document.getElementById(id);
			if (!element) {
				return;
			}

			element.scrollIntoView(true);

			var focusable = element.querySelectorAll(
				'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
			);
			var firstFocusable = focusable[0];
			firstFocusable.focus();
		});
	};

	React.useEffect(() => {
		const callback = () => {
			forceUpdate();
		};
		window.addEventListener("resize", callback);

		return () => {
			window.removeEventListener("resize", callback);
		};
	});

	const onTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const selectedPart = (identifier) => {
		switch (editorStage) {
			case 0:
				focus(`part_${identifier}`);
				return;
			default:
				return;
		}
	};

	const selectedColumn = (identifier, columnIndex) => {
		switch (editorStage) {
			case 0:
				focus(`${identifier}_columns_${columnIndex}`);
				return;
			case 2:
				if (!currentScoringGroup) {
					return;
				}

				// Scoring Group Add Column
				const matchingIndex = currentScoringGroup.columns.findIndex(
					(c) => c.part === identifier && c.col === columnIndex
				);
				if (matchingIndex === -1) {
					currentScoringGroup.columns.push({
						part: identifier,
						col: columnIndex,
					});
				} else {
					currentScoringGroup.columns.splice(matchingIndex, 1);
				}

				const storeUpdate = {};
				storeUpdate[StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT] =
					currentFormat;
				storeUpdate[StoreKeys.EVENTFORMATBUILDER.CURRENT_SCORING_GROUP] =
					currentScoringGroup;
				Store.setMany(storeUpdate);

				return;
			default:
				return;
		}
	};

	const selectedParty = (event, match, isTop) => {
		switch (editorStage) {
			case 0:
				handleBracketMatchClick(event, match, isTop);
				return;
			case 1:
				handleViewMatchClick(event, match, isTop);
				return;
			default:
				return;
		}
	};

	const handleBracketMatchClick = (event, match, isTop) => {
		// If we are binding a match...
		if (bindingMatch) {
			const targetColumnMatch = findColumnMatchById(
				currentStage.bracket,
				bindingMatch.bracketMatchId
			);
			const columnMatch = findColumnMatchById(currentStage.bracket, match.id);
			targetColumnMatch.loserPath = {
				part: columnMatch.part,
				col: columnMatch.col,
				colIndex: columnMatch.colIndex,
				isTop,
			};
			Store.cacheChanges();
			applyBracketUpdates();
			Store.set(StoreKeys.EVENTFORMATBUILDER.BINDING_MATCH, null);
			Store.applyChanges();
			return;
		}
		// We want to map this match dropdown to another match
		else if (event.shiftKey) {
			// Focus on the match we are updating.
			const matchId = match.id;
			const columnMatch = findColumnMatchById(currentStage.bracket, matchId);
			focus(
				`${columnMatch.part}_columns_${columnMatch.col}_matches_${columnMatch.colIndex}`
			);
			// Select it as the match we are mapping.
			mapLoserMatch(match);
			return;
		} else {
			const matchId = match.id;
			const columnMatch = findColumnMatchById(currentStage.bracket, matchId);
			focus(
				`${columnMatch.part}_columns_${columnMatch.col}_matches_${columnMatch.colIndex}`
			);
		}
	};

	const handleViewMatchClick = (event, match, isTop) => {
		if (!currentView) {
			return;
		}

		const columnMatch = findColumnMatchById(currentStage.bracket, match.id);
		/** @type {EventFormatBracketColumnMatchTarget} */
		const matchPath = {
			part: columnMatch.part,
			col: columnMatch.col,
			colIndex: columnMatch.colIndex,
		};

		const targetMatchIndex = currentView.matches.findIndex(
			(m) =>
				m.part === matchPath.part &&
				m.col === matchPath.col &&
				m.colIndex === matchPath.colIndex
		);

		if (targetMatchIndex >= 0) {
			currentView.matches.splice(targetMatchIndex, 1);
		} else {
			currentView.matches.push(matchPath);
		}

		const storeUpdate = {};
		storeUpdate[StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT] = currentFormat;
		storeUpdate[StoreKeys.EVENTFORMATBUILDER.CURRENT_VIEW] = currentView;
		Store.setMany(storeUpdate);
	};

	const bindingSomething = () => {
		return (
			Store.get(StoreKeys.EVENTFORMATBUILDER.BINDING_MATCH) ||
			Store.get(StoreKeys.EVENTFORMATBUILDER.CURRENT_VIEW) ||
			Store.get(StoreKeys.EVENTFORMATBUILDER.CURRENT_SCORING_GROUP)
		);
	};

	const handleChangeStage = (stage) => {
		setEditorStage(stage);
		const storeUpdate = {};
		storeUpdate[StoreKeys.EVENTFORMATBUILDER.BINDING_MATCH] = null;
		storeUpdate[StoreKeys.EVENTFORMATBUILDER.CURRENT_VIEW] = null;
		storeUpdate[StoreKeys.EVENTFORMATBUILDER.CURRENT_SCORING_GROUP] = null;
		Store.setMany(storeUpdate);
	};

	/**
	 *
	 * @param {EventFormatBracketColumnMatch} match
	 */
	const mapLoserMatch = (match) => {
		match.loserPath = null;
		Store.set(StoreKeys.EVENTFORMATBUILDER.BINDING_MATCH, match);
	};

	React.useEffect(() => {
		populateDefaultBracket(currentFormat);
	});

	const bracketStages =
		currentFormat?.data?.stages?.filter(
			(s) =>
				(s.type === EventStageTypes.Group &&
					s.groupStageDetails.hasGroupBrackets) ||
				s.type === EventStageTypes.Playoffs
		) ?? [];

	if (bracketStages.length === 0) {
		return <></>;
	}

	const currentStage = bracketStages[tabValue];

	const applyBracketUpdates = () => {
		applyChangesToBracket(currentStage?.bracket);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	const getEditorPhaseName = () => {
		switch (editorStage) {
			case 0:
				return "Bracket Details";
			case 1:
				return "View Definition";
			case 2:
				return "Scoring Breakdown";
			default:
				return "";
		}
	};

	const highlightedMatchIds =
		currentView?.matches?.map((m) =>
			getBracketMatchIdByTarget(currentStage?.bracket, m)
		) ??
		flattenArrayList(
			currentScoringGroup?.columns?.map((c) =>
				getBracketMatchIdsByTargetColumn(currentStage?.bracket, c)
			)
		);

	const headerNames = getHeaderNamesFromBracket(currentStage.bracket);
	const useSeriesScore =
		currentStage?.type === EventStageTypes.Playoffs
			? currentStage?.playoffDetails?.useSeriesScore
			: currentStage?.groupStageDetails?.useSeriesScore;

	const bracketWidth = (window.innerWidth - 32) * 0.75;

	return (
		<>
			<h1>Bracket Builder</h1>
			<Tabs value={tabValue} onChange={onTabChange}>
				{bracketStages.map((s, index) => {
					return <Tab label={s.name} value={index} />;
				})}
			</Tabs>
			<div className={classNames.bracketBuilderContainer}>
				{bindingMatch && (
					<div className={classNames.bannerContainer}>
						<div className={classNames.bannerInner}>
							Select the slot the loser will move to
						</div>
					</div>
				)}
				{currentView && (
					<div className={classNames.bannerContainer}>
						<div className={classNames.bannerInner}>
							Select the matches the view contains
						</div>
					</div>
				)}
				{currentScoringGroup && (
					<div className={classNames.bannerContainer}>
						<div className={classNames.bannerInner}>
							Select the columns in the scoring group
						</div>
					</div>
				)}
				<div className={classNames.bracketRender}>
					{currentStage?.bracket?.matches && (
						<DoubleEliminationBracket
							matches={currentStage?.bracket?.matches}
							matchComponent={Match}
							options={{
								style: {
									roundHeader: {
										fontSize: 20,
										backgroundColor: Colors.panel,
										fontColor: Colors.primary,
									},
									connectorColor: Colors.panel,
									connectorColorHighlight: Colors.pickstop,
								},
							}}
							svgWrapper={({ children, ...props }) => (
								<SvgViewer width={bracketWidth} height={800} {...props}>
									{children}
								</SvgViewer>
							)}
							onUpperClick={() => {
								editorStage === 0 && selectedPart("UB");
							}}
							onLowerClick={() => {
								editorStage === 0 && selectedPart("LB");
							}}
							onFinalsClick={() => {
								editorStage === 0 && selectedPart("GF");
							}}
							onColumnClick={selectedColumn}
							onPartyClick={selectedParty}
							customHeaderNames={headerNames}
							hideSeriesScore={!useSeriesScore}
							highlightMatchIds={highlightedMatchIds}
						/>
					)}
				</div>
				<div className={classNames.bracketEditor}>
					<div className={classNames.bracketEditorPhaseSelector}>
						<Button
							className={classNames.invisibleOnDisable}
							disabled={editorStage === 0}
							onClick={() => {
								handleChangeStage(editorStage - 2);
							}}
						>
							<ChevronLeft color="primary" />
						</Button>
						<label>{getEditorPhaseName()}</label>
						<Button
							className={classNames.invisibleOnDisable}
							disabled={editorStage === 2}
							onClick={() => {
								handleChangeStage(editorStage + 2);
							}}
						>
							<ChevronRight color="primary" />
						</Button>
					</div>
					{editorStage === 0 && (
						<BracketDetailsEditor
							classNames={classNames}
							currentFormat={currentFormat}
							currentStage={currentStage}
							focusObservable={focusObservable}
							mapLoserMatch={mapLoserMatch}
							onApply={applyBracketUpdates}
						/>
					)}
					{editorStage === 1 && (
						<ViewDefinitionEditor
							classNames={classNames}
							currentFormat={currentFormat}
							currentStage={currentStage}
						/>
					)}
					{editorStage === 2 && (
						<ScoringBreakdownEditor
							classNames={classNames}
							currentFormat={currentFormat}
							currentStage={currentStage}
						/>
					)}
				</div>
			</div>
		</>
	);
}

/**
 * @typedef BracketDetailsEditorProps
 * @type {object}
 * @property {any} classNames
 * @property {EventFormatBuilder} currentFormat
 * @property {EventFormatStage} currentStage
 * @property {Observable<any>} focusObservable
 * @property {(match: EventFormatBracketColumnMatch) => void} mapLoserMatch
 * @property {() => void} onApply
 */

/**
 * @param {BracketDetailsEditorProps} props
 */
function BracketDetailsEditor({
	classNames,
	currentFormat,
	currentStage,
	focusObservable,
	mapLoserMatch,
	onApply,
}) {
	return (
		<>
			<Button onClick={onApply}>Apply Bracket Changes</Button>
			<div className={classNames.scroller}>
				{currentStage?.bracket?.parts?.map((part) => {
					return (
						<Collapse
							id={`part_${part.identifier}`}
							focusObservable={focusObservable}
							startOpen={true}
							headerElement={<>{getPartName(part?.identifier)}</>}
							childClassName={classNames.subEditor}
						>
							<TextField
								label="# of Columns"
								value={part?.columnCount}
								className={classNames.editorField}
								variant="outlined"
								type="number"
								onChange={(e) => {
									part.columnCount = Number(e.target.value);
									if (part.columnCount > 9) {
										part.columnCount = 9;
									}
									modifyPartColumns(part);
									Store.set(
										StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT,
										currentFormat
									);
								}}
							/>
						</Collapse>
					);
				})}
				{currentStage?.bracket?.parts?.map((part) => {
					return (
						<Collapse
							id={`${part.identifier}`}
							focusObservable={focusObservable}
							headerElement={<>{getPartName(part?.identifier)} Details</>}
							childClassName={classNames.subEditor}
						>
							<Collapse
								id={`${part.identifier}_columns`}
								focusObservable={focusObservable}
								headerElement={<>Columns</>}
								childClassName={classNames.subEditor}
							>
								{part?.columns?.map((column, index) => {
									return (
										<Collapse
											id={`${part.identifier}_columns_${index}`}
											focusObservable={focusObservable}
											headerElement={<>Column {index + 1}</>}
											childClassName={classNames.subEditor}
										>
											<TextField
												label="Header Name Override"
												defaultValue={column?.name}
												variant="outlined"
												className={classNames.editorField}
												onChange={(e) => {
													column.name = e.target.value;
												}}
											/>
											<TextField
												label="# of Matches"
												type="number"
												defaultValue={column?.matchCount}
												variant="outlined"
												className={classNames.editorField}
												onChange={(e) => {
													column.matchCount = Number(e.target.value);
												}}
											/>
											<TextField
												label="Best Of"
												type="number"
												defaultValue={column?.bestOf}
												variant="outlined"
												className={classNames.editorField}
												onChange={(e) => {
													column.bestOf = Number(e.target.value);
												}}
											/>
											<div>
												<FormControlLabel
													label="Both Teams Advance?"
													control={
														<Checkbox
															checked={column.bothTeamsAdvance ?? false}
															onChange={(e) => {
																column.bothTeamsAdvance = e.target.checked;
																onApply();
															}}
														/>
													}
												/>
											</div>
											<div>
												<FormControlLabel
													label="Lock Picks?"
													control={
														<Checkbox
															checked={column.lockPicks ?? false}
															onChange={(e) => {
																column.lockPicks = e.target.checked;
																onApply();
															}}
														/>
													}
												/>
											</div>
											<Collapse
												id={`${part.identifier}_columns_${index}_matches`}
												focusObservable={focusObservable}
												headerElement={<>Matches</>}
												childClassName={classNames.subEditor}
											>
												{column?.matches?.map((match, matchIndex) => {
													return (
														<Collapse
															id={`${part.identifier}_columns_${index}_matches_${matchIndex}`}
															focusObservable={focusObservable}
															headerElement={<>Match {matchIndex + 1}</>}
															childClassName={classNames.subEditor}
														>
															<Button
																color="secondary"
																onClick={() => mapLoserMatch(match)}
															>
																{match.loserPath
																	? "Remap Loser Path"
																	: "Map Loser Path"}
															</Button>
															{match.loserPath && (
																<div>
																	<div>Part: {match.loserPath.part}</div>
																	<div>Col: {match.loserPath.col}</div>
																	<div>
																		Col Index: {match.loserPath.colIndex}
																	</div>
																	<div>
																		Is Top:{" "}
																		{match.loserPath.isTop ? "Yes" : "No"}
																	</div>
																</div>
															)}
														</Collapse>
													);
												})}
											</Collapse>
										</Collapse>
									);
								})}
							</Collapse>
						</Collapse>
					);
				})}
			</div>
		</>
	);
}

function EventFormatBracketBuilder(props) {
	return connect(<EventFormatBracketBuilderC />, propKeys, props);
}

/**
 * @typedef ViewDefinitionEditorProps
 * @type {object}
 * @property {any} classNames
 * @property {EventFormatBuilder} currentFormat
 * @property {EventFormatStage} currentStage
 */

/**
 * @param {ViewDefinitionEditorProps} props
 */
function ViewDefinitionEditor({ classNames, currentFormat, currentStage }) {
	const [editingView, setEditingView] = React.useState(null);
	const [editingViewMatchIndex, setEditingViewMatchIndex] =
		React.useState(null);

	React.useEffect(() => {
		if (currentStage.bracket && !currentStage.bracket.views) {
			currentStage.bracket.views = [];
			Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
		}
	}, [currentFormat, currentStage]);

	const addView = (index) => {
		currentStage.bracket.views.push(
			new EventFormatBracketView(`New View ${index + 1}`, true, [])
		);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	const modifyView = (view, index) => {
		setEditingViewMatchIndex(index);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_VIEW, view);
	};

	const finishModifying = () => {
		setEditingViewMatchIndex(null);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_VIEW, null);
	};

	const deleteView = (index) => {
		currentStage.bracket.views.splice(index, 1);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	return (
		<div className={classNames.scroller}>
			{currentStage.bracket?.views?.map((view, index) => {
				return (
					<Collapse
						id={`view_${index}`}
						key={`view_${index}`}
						startOpen={true}
						childClassName={classNames.subEditor}
						headerElement={
							<div
								className={classNames.viewHeader}
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								{editingView === index && (
									<TextField
										label="View Name"
										defaultValue={view?.name}
										variant="outlined"
										onBlur={() => {
											setEditingView(null);
											Store.set(
												StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT,
												currentFormat
											);
										}}
										inputRef={(input) => input && input.focus()}
										className={classNames.editorField}
										onChange={(e) => {
											view.name = e.target.value;
										}}
									/>
								)}
								{editingView !== index && (
									<label
										title="Click to Edit"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setEditingView(index);
										}}
									>
										{view.name}
									</label>
								)}
								<IconButton
									edge="end"
									aria-label="delete"
									onClick={() => deleteView(index)}
								>
									<Delete color="primary" />
								</IconButton>
							</div>
						}
					>
						{editingViewMatchIndex !== index && (
							<Button
								onClick={() => {
									modifyView(view, index);
								}}
								color="primary"
							>
								<Edit color="primary" />
								Modify View
							</Button>
						)}
						{editingViewMatchIndex === index && (
							<Button onClick={finishModifying} color="secondary">
								<Edit color="primary" />
								Finish Editing
							</Button>
						)}
					</Collapse>
				);
			})}
			<div style={{ marginTop: 16 }}>
				<Button
					onClick={() => {
						addView(currentStage.bracket.views.length);
					}}
				>
					<Add color="primary" />
					Add View
				</Button>
			</div>
		</div>
	);
}

/**
 * @typedef ScoringBreakdownEditorProps
 * @type {object}
 * @property {any} classNames
 * @property {EventFormatBuilder} currentFormat
 * @property {EventFormatStage} currentStage
 */

/**
 * @param {ScoringBreakdownEditorProps} props
 */
function ScoringBreakdownEditor({ classNames, currentFormat, currentStage }) {
	const [editingGroup, setEditingGroup] = React.useState(null);
	const [editingScoringGroupIndex, setEditingScoringGroupIndex] =
		React.useState(null);

	React.useEffect(() => {
		if (!currentStage.bracket.scoringGroups) {
			currentStage.bracket.scoringGroups = [];
			Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
		}
	}, [currentFormat, currentStage]);

	const addGroup = (index) => {
		currentStage.bracket.scoringGroups.push(
			new EventFormatBracketScoringGroup(`New Scoring Group ${index + 1}`)
		);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	const modifyGroup = (sGroup, index) => {
		setEditingScoringGroupIndex(index);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_SCORING_GROUP, sGroup);
	};

	const finishModifying = () => {
		setEditingScoringGroupIndex(null);
		buildScoringDetails(currentFormat);

		const storeUpdate = {};
		storeUpdate[StoreKeys.EVENTFORMATBUILDER.CURRENT_SCORING_GROUP] = null;
		storeUpdate[StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT] = currentFormat;
		Store.setMany(storeUpdate);
	};

	/**
	 *
	 * @param {EventFormatBracketScoringGroup} sGroup
	 * @param {boolean} value
	 */
	const toggleScoringGroupScoreParticipants = (sGroup, value) => {
		sGroup.scoreParticipants = value;
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	/**
	 *
	 * @param {EventFormatBracketScoringGroup} sGroup
	 * @param {boolean} value
	 */
	const toggleScoringGroupScoreWinnersOnly = (sGroup, value) => {
		sGroup.scoreWinnersOnly = value;
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	/**
	 *
	 * @param {EventFormatBracketScoringGroup} sGroup
	 * @param {boolean} value
	 */
	const toggleScoringGroupScorePlacement = (sGroup, value) => {
		sGroup.scorePlacement = value;
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	const deleteGroup = (index) => {
		currentStage.bracket.scoringGroups.splice(index, 1);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	const previewScoringBreakdown = () => {
		buildScoringDetails(currentFormat);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);

		ModalHelper.openModal(<ScorePillModal />, null, {
			groups: currentFormat.data.scoringBreakdown.groups,
		});
	};

	return (
		<div className={classNames.scroller}>
			{currentStage.bracket.scoringGroups?.map((sGroup, index) => {
				return (
					<Collapse
						id={`sgroup_${index}`}
						key={`sgroup_${index}`}
						startOpen={false}
						childClassName={classNames.subEditor}
						headerElement={
							<div
								className={classNames.viewHeader}
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								{editingGroup === index && (
									<TextField
										label="Scoring Group Name"
										defaultValue={sGroup?.name}
										variant="outlined"
										onBlur={() => {
											setEditingGroup(null);
											Store.set(
												StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT,
												currentFormat
											);
										}}
										inputRef={(input) => input && input.focus()}
										className={classNames.editorField}
										onChange={(e) => {
											sGroup.name = e.target.value;
										}}
									/>
								)}
								{editingGroup !== index && (
									<label
										title="Click to Edit"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setEditingGroup(index);
										}}
									>
										{sGroup.name}
									</label>
								)}
								<IconButton
									edge="end"
									aria-label="delete"
									onClick={() => deleteGroup(index)}
								>
									<Delete color="primary" />
								</IconButton>
							</div>
						}
					>
						{editingScoringGroupIndex !== index && (
							<Button
								onClick={() => {
									modifyGroup(sGroup, index);
								}}
								color="secondary"
							>
								<Edit color="secondary" />
								&nbsp; Modify Group
							</Button>
						)}
						{editingScoringGroupIndex === index && (
							<Button onClick={finishModifying} color="secondary">
								<Edit color="secondary" />
								&nbsp; Finish Editing
							</Button>
						)}

						<div>
							<FormControlLabel
								label="Score Participants?"
								control={
									<Checkbox
										checked={sGroup.scoreParticipants ?? false}
										onChange={(event) =>
											toggleScoringGroupScoreParticipants(
												sGroup,
												event.target.checked
											)
										}
									/>
								}
							/>
						</div>

						{sGroup.scoreParticipants && (
							<>
								<div>
									<FormControlLabel
										label="Score Winners Only?"
										control={
											<Checkbox
												checked={sGroup.scoreWinnersOnly ?? false}
												onChange={(event) =>
													toggleScoringGroupScoreWinnersOnly(
														sGroup,
														event.target.checked
													)
												}
											/>
										}
									/>
								</div>
								<div>
									<TextField
										className={classNames.textField}
										label="Points Per Correct Participant"
										defaultValue={sGroup.pointsPerCorrectParticipant}
										onBlur={() => {
											Store.set(
												StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT,
												currentFormat
											);
										}}
										onChange={(event) => {
											sGroup.pointsPerCorrectParticipant = Number(
												event.target.value
											);
										}}
										variant="outlined"
										type="number"
										required
									/>
								</div>
							</>
						)}

						<div>
							<FormControlLabel
								label="Score Placement?"
								control={
									<Checkbox
										checked={sGroup.scorePlacement ?? false}
										onChange={(event) =>
											toggleScoringGroupScorePlacement(
												sGroup,
												event.target.checked
											)
										}
									/>
								}
							/>
						</div>
					</Collapse>
				);
			})}
			<div style={{ marginTop: 16 }}>
				<div>
					<Button
						onClick={() => {
							addGroup(currentStage.bracket.scoringGroups.length);
						}}
					>
						<Add color="primary" />
						&nbsp; Add Scoring Group
					</Button>
				</div>
				<div>
					<Button onClick={previewScoringBreakdown}>
						<Launch color="primary" />
						&nbsp; Preview Scoring Breakdown
					</Button>
				</div>
			</div>
		</div>
	);
}

/**
 *
 * @param {EventFormatBuilder} currentFormat
 */
export function populateDefaultBracket(currentFormat) {
	let didUpdate = false;
	if (
		currentFormat &&
		currentFormat.data &&
		!currentFormat.data.scoringBreakdown
	) {
		// Build from current data.
		buildScoringDetails(currentFormat);
		didUpdate = true;
	}

	for (const stage of currentFormat?.data?.stages) {
		if (stage.type === EventStageTypes.Group && !stage.bracket) {
			stage.bracket = {
				views: [],
				parts: [
					{
						identifier: "UB",
						columnCount: 2,
						columns: [],
					},
					{
						identifier: "LB",
						columnCount: 2,
						columns: [],
					},
				],
			};
			initializeBracket(stage.bracket);
			didUpdate = true;
		} else if (stage.type === EventStageTypes.Playoffs && !stage.bracket) {
			stage.bracket = {
				views: [],
				parts: [
					{
						identifier: "UB",
						columns: [],
					},
					{
						identifier: "LB",
						columns: [],
					},
					{
						identifier: "GF",
						columns: [],
					},
				],
			};
			initializeBracket(stage.bracket);
			didUpdate = true;
		}
	}

	if (didUpdate) {
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	}
}

export default EventFormatBracketBuilder;
/**
 *
 * @param {EventFormatBracket} bracket
 */
export function initializeBracket(bracket) {
	for (const part of bracket.parts) {
		modifyPartColumns(part);
	}
}

/**
 *
 * @param {EventFormatBracketPart} part
 */
export function modifyPartColumns(part) {
	for (let i = 0; i < part.columnCount; i++) {
		/** @type {EventFormatBracketColumn} */
		let column = null;
		const isColumnAdd = i >= part.columns.length;
		if (isColumnAdd) {
			column = {
				//name: getColumnName(part.identifier, i, part.columnCount),
				index: i,
				matches: [],
				matchCount: getMatchCount(part.identifier, i, part.columnCount),
				bestOf: 5,
			};

			part.columns.push(column);
		} else {
			column = part.columns[i];
		}

		if (column.matchCount < column.matches.length) {
			column.matches = column.matches.slice(0, column.matchCount);
		} else {
			for (let j = column.matches.length; j < column.matchCount; j++) {
				column.matches.push({
					part: part.identifier,
					col: i,
					colIndex: j,
					loserPath: null,
				});
			}
		}
	}
}

/**
 *
 * @param {EventFormatBracket} bracket
 */
export function applyChangesToBracket(bracket) {
	for (const part of bracket.parts) {
		if (part.columns.length > part.columnCount) {
			part.columns = part.columns.slice(0, part.columnCount);
		}

		modifyPartColumns(part);
	}

	generateBracketMatchesFromBracket(bracket);
}

/**
 * Build matches from bracket data.
 * @param {EventFormatBracket} bracket
 */
export function generateBracketMatchesFromBracket(bracket) {
	bracket.matches = {
		upper: [],
		lower: [],
	};

	for (const part of bracket.parts) {
		/** @type {MatchType[]} */
		const target =
			part.identifier === "LB" ? bracket.matches.lower : bracket.matches.upper;
		for (let i = 0; i < part.columnCount; i++) {
			const column = part.columns[i];

			for (let j = 0; j < column.matchCount; j++) {
				const columnMatch = column.matches[j];
				const bracketMatch = {
					id: uuidv4(),
					nextMatchId: null,
					nextLoserMatchId: null,
					state: "PLAYED",
					participants: [],
					startTime: "",
					bothTeamsAdvance: column.bothTeamsAdvance,
				};
				columnMatch.bracketMatchId = bracketMatch.id;
				target.push(bracketMatch);
			}
		}
	}

	mapNextMatches(bracket);
}

/**
 * Map match connections from bracket data.
 * @param {EventFormatBracket} bracket
 */
export function mapNextMatches(bracket) {
	mapUpperBracketMatches(bracket);
	mapLowerBracketMatches(bracket);
}

/**
 * Map match connections from bracket data.
 * @param {EventFormatBracket} bracket
 */
export function mapUpperBracketMatches(bracket) {
	const part = bracket.parts[0];
	const finalsPart = bracket.parts[2];
	for (let i = 0; i < part.columnCount; i++) {
		const column = part.columns[i];
		const nextColumnSameCount =
			i + 1 < part.columnCount &&
			column.matchCount === part.columns[i + 1].matchCount;

		for (let j = 0; j < column.matchCount; j++) {
			const columnMatch = column.matches[j];
			/** @type {MatchType} */
			const bracketMatch = findBracketMatchById(
				bracket,
				columnMatch.bracketMatchId
			);

			if (nextColumnSameCount) {
				const nextColumnMatch = part.columns[i + 1].matches[j];
				bracketMatch.nextMatchId = nextColumnMatch?.bracketMatchId;
			} else if (i < part.columnCount - 1) {
				const nextColumnMatch = part.columns[i + 1].matches[Math.floor(j / 2)];
				bracketMatch.nextMatchId = nextColumnMatch?.bracketMatchId;
			} else if (i === part.columnCount - 1 && finalsPart?.columnCount > 0) {
				const nextColumnMatch =
					finalsPart.columns[0].matches[Math.floor(j / 2)];
				bracketMatch.nextMatchId = nextColumnMatch?.bracketMatchId;
			}

			if (columnMatch.loserPath) {
				const loserPath = columnMatch.loserPath;
				const loserPart = bracket.parts.find(
					(p) => p.identifier === loserPath.part
				);
				if (loserPart.columnCount > loserPath.col) {
					const loserColumn = loserPart.columns[loserPath.col];
					if (loserColumn.matchCount > loserPath.colIndex) {
						const loserColumnMatch = loserColumn.matches[loserPath.colIndex];
						bracketMatch.nextLooserMatchId = loserColumnMatch.bracketMatchId;
					}
				}
			}
		}
	}
}

/**
 * Map match connections from bracket data.
 * @param {EventFormatBracket} bracket
 */
export function mapLowerBracketMatches(bracket) {
	const part = bracket.parts[1];
	const finalsPart = bracket.parts[2];

	if (part.columnCount === 0) {
		return;
	}

	let nextColumn = null;
	for (let i = 0; i < part.columnCount; i++) {
		const column = part.columns[i];

		if (i + 1 < part.columns.length) {
			nextColumn = part.columns[i + 1];
		} else {
			nextColumn = null;
		}

		for (let j = 0; j < column.matchCount; j++) {
			const columnMatch = column.matches[j];
			/** @type {MatchType} */
			const bracketMatch = findBracketMatchById(
				bracket,
				columnMatch.bracketMatchId
			);

			const isLastcolumn = i + 1 === part.columnCount;
			if (isLastcolumn) {
				if (finalsPart?.columnCount > 0) {
					const nextColumnMatch =
						finalsPart.columns[0].matches[Math.floor(j / 2)];
					bracketMatch.nextMatchId = nextColumnMatch?.bracketMatchId;
				}
			} else {
				const directFeed =
					column.matches.length === nextColumn?.matches?.length;
				if (directFeed) {
					const nextColumnMatch = part.columns[i + 1].matches[j];
					bracketMatch.nextMatchId = nextColumnMatch?.bracketMatchId;
				} else {
					const nextColumnMatch =
						part.columns[i + 1].matches[Math.floor(j / 2)];
					bracketMatch.nextMatchId = nextColumnMatch?.bracketMatchId;
				}
			}
		}
	}
}
