import * as React from "react";
import Store from "./store";

class StateWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.keys = [];
		for (let k in props) {
			if (k === "props") continue;
			if (k === "component") continue;
			this.keys.push(props[k]);
		}
		this.onStateChange = this.onStateChange.bind(this);
		Store.subscribe(this.onStateChange, this.keys);
	}

	render() {
		let props = {};
		for (let k in this.props) {
			if (k === "props") continue;
			if (k === "component") continue;
			props[k] = Store.get(this.props[k]);
		}
		for (let k in this.props.props) {
			props[k] = this.props.props[k];
		}
		return React.cloneElement(this.props.component, props);
	}

	componentWillUnmount() {
		this.unmounted = true;
		Store.unsubscribe(this.onStateChange, this.keys);
	}

	onStateChange = () => {
		if (this.unmounted) {
			return;
		}
		this.forceUpdate();
	};
}

export function connect(component, keys, props) {
	return <StateWrapper component={component} {...keys} props={props} />;
}
