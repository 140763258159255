import * as React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";
import UserProfileModalHelper from "common/helpers/userProfileModalHelper";
import UserService from "services/UserService";
import UserManager from "common/userManager";

/**
 * @type {Array<(nameplateId: number) => void>}
 */
let updateCardCallbacks = [];

export function updateUserCardsWithNameplateId(nameplateId) {
	for (let callback of updateCardCallbacks) {
		callback(nameplateId);
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: Colors.panelLight,
		borderRadius: 12,
		border: "1px solid " + theme.palette.primary.main,
		color: theme.palette.primary.main,
		textAlign: "left",
		display: "inline-flex",
		alignItems: "center",
		width: 143,
		height: 34,
		boxSizing: "border-box",
		whiteSpace: "nowrap",
	},
	name: {
		marginLeft: 5,
		flex: "0 0 105px",
		font: "normal normal bold 14px/24px Arial",
		maxWidth: 105,
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	pinned: {
		maxHeight: 30,
		marginRight: 3,
		width: "unset",
	},
	pinnedWrapper: {
		display: "flex",
	},
}));

export default function UserCard(props) {
	//either takes a userid and loads data, or takes the rest of the information to populate the view.
	const {
		userId,
		nameplateId,
		dontLoadData,
		userName,
		pinnedEventName,
		pinnedTrophyImagePath,
	} = props;

	const styles = useStyles();
	const [overrideNameplateId, setOverrideNameplateId] = React.useState(null);
	const [userCardInfo, setUserCardInfo] = React.useState(null);

	const updateNameplateId = (nameplateId) => {
		if (userCardInfo) {
			userCardInfo.nameplateId = nameplateId;
			setUserCardInfo({
				...userCardInfo,
			});
		} else {
			setOverrideNameplateId(nameplateId);
		}
	};

	React.useEffect(() => {
		// Add callback
		if (userId === UserManager.getUserId()) {
			updateCardCallbacks.push(updateNameplateId);

			return () => {
				// Remove callback
				updateCardCallbacks = updateCardCallbacks.filter(
					(callback) => callback !== updateNameplateId
				);
			};
		}
	});

	React.useEffect(() => {
		if (!dontLoadData) {
			UserService.getUserCardInfo(userId).then((userInfo) => {
				setUserCardInfo(userInfo);
			});
		}
	}, [userId, dontLoadData]);

	const pinnedTrophy = !!userCardInfo ? userCardInfo.pinnedTrophy[0] : null;
	const pinnedTrophyName = !!pinnedTrophy ? pinnedTrophy.name : ``;
	const pinnedTrophyImg = !!pinnedTrophy
		? pinnedTrophy.imagePath
		: pinnedTrophyImagePath;
	const pinnedTrophyEvent = !!pinnedTrophy
		? pinnedTrophy.eventName
		: pinnedEventName;
	const uName = !!userCardInfo ? userCardInfo?.username : userName;
	const hasPinnedTrophy = !!pinnedTrophy || pinnedTrophyImagePath;
	const userNameplateId =
		overrideNameplateId ?? nameplateId ?? userCardInfo?.nameplateId;
	const nameplateIdClassName = `nameplate-${userNameplateId}`;

	return (
		<Box
			className={`${styles.root} ${
				userNameplateId ? nameplateIdClassName : ""
			} nameplate-container`}
			style={{ marginBottom: props.marginBottom }}
			onClick={() => {
				UserProfileModalHelper.openProfile(userId);
			}}
		>
			<span className={`${styles.name} nameplate-name`}>{uName}</span>
			{hasPinnedTrophy && (
				<span
					title={`${pinnedTrophyEvent}`}
					className={`${styles.pinnedWrapper} nameplate-trophy`}
				>
					<img
						className={styles.pinned}
						alt={`${pinnedTrophyName}`}
						src={pinnedTrophyImg}
					/>
				</span>
			)}
		</Box>
	);
}
