import { Container, Hidden, withWidth } from "@material-ui/core";
import UserManager from "common/userManager";
import React, { useEffect } from "react";
import NavMenu from "./Layout/NavMenu";
import BottomIconMenu from "./Layout/BottomIconMenu";
import { useForceUpdate } from "common/helpers/componentHelper";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";

const propKeys = {
	currentSurvey: StoreKeys.SURVEY.CURRENT,
};

function LayoutC({ currentSurvey, showMenuTabs, width, children }) {
	const forceUpdate = useForceUpdate();
	const refresh = () => {
		forceUpdate();
	};

	useEffect(() => {
		UserManager.onLogin(() => refresh());
	});

	return (
		<div>
			{showMenuTabs && <NavMenu />}
			<Container
				disableGutters
				maxWidth={false}
				className={
					"body " +
					(!showMenuTabs ? "notopbar " : " ") +
					(width === "sm" || width === "xs" ? "sm" : "")
				}
			>
				{children}
			</Container>
			<Hidden mdUp>
				{showMenuTabs && <BottomIconMenu hasSurvey={!!currentSurvey} />}
			</Hidden>
		</div>
	);
}

const LayoutWidthC = withWidth()(LayoutC); 

export default function Layout(props) {
	return connect((<LayoutWidthC />), propKeys, props);
};
