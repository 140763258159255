import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalPanel,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "common/components/CustomModal/Modal/ModalFrame";
import ModalHelper from "common/helpers/modalHelper";
import { changeUrl } from "common/history";

export default class EmailUsernameChangePrompt extends React.Component {
	render() {
		return (
			<CustomModal>
				<ModalPanel>
					<ModalHeader>Username is email address</ModalHeader>
					<ModalBody>
						<p>
							It looks like your username is email address. <br />
							Would you like to change it?
						</p>
						<p>Keep in mind that your username can be seen by everyone.</p>
					</ModalBody>
					<ModalFooter>
						<button onClick={this.handleYes.bind(this)}>Yes</button>
						<button onClick={this.handleNo.bind(this)}>No</button>
					</ModalFooter>
				</ModalPanel>
			</CustomModal>
		);
	}

	handleYes() {
		ModalHelper.closeModal();
		changeUrl("/pick-username");
	}

	handleNo() {
		ModalHelper.closeModal();
	}
}
