import { Container } from "@material-ui/core";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
// eslint-disable-next-line no-unused-vars
import { EventFormat } from "common/types/EventFormat";
import * as React from "react";
import { Helmet } from "react-helmet";
import EventFormatEditor from "./EventFormatEditor/EventFormatEditor";
import EventFormatSelector from "./EventFormatSelector";

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {EventFormat} currentFormat
 */

const propKeys = {
	currentFormat: StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT,
};

/**
 * @param {ComponentProps} props
 */
function EventFormatBuilderC(props) {
	const { currentFormat } = props;

	return (
		<Container maxWidth={false}>
			<Helmet>
				<title>Pickstop - Event Format Builder</title>
			</Helmet>
			<h1>Event Format Builder</h1>
			{!currentFormat && <EventFormatSelector />}
			{currentFormat && <EventFormatEditor />}
		</Container>
	);
}

function EventFormatBuilder(props) {
	return connect(<EventFormatBuilderC />, propKeys, props);
}

export default EventFormatBuilder;
