import { Colors } from "styles/Colors";
import {
	Box,
	Button,
	Grid,
	InputAdornment,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core";
import { Email, Person, VpnKey } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { getDiscordUrl, getGoogleUrl, getRedditUrl } from "./api";
import { SetLoading } from "common/components/Loader/Loader";
import UserManager from "common/userManager";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalDivider,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import { changeUrl } from "common/history";

const useStyles = makeStyles({
	provider: {
		cursor: "pointer",
		marginLeft: "auto",
		marginRight: "auto",
		display: "block",
		paddingTop: 8,
		paddingBottom: 8,
	},
	centered: {
		textAlign: "center",
	},
	errorMessage: {
		color: Colors.error,
	},
});

export default function RegisterModal() {
	const styles = useStyles();
	const [username, setUsername] = useState("");
	const [usernameError, setUsernameError] = useState(null);
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(null);
	const [discordUrl, setDiscordUrl] = useState("");
	const [googleUrl, setGoogleUrl] = useState("");
	const [redditUrl, setRedditUrl] = useState("");

	useEffect(() => {
		if (discordUrl === "") {
			getDiscordUrl().then((url) => {
				setDiscordUrl(url);
			});
		}
		if (googleUrl === "") {
			getGoogleUrl().then((url) => {
				setGoogleUrl(url);
			});
		}
		if (redditUrl === "") {
			getRedditUrl().then((url) => {
				setRedditUrl(url);
			});
		}
	});

	const validateForm = (username, email, password, confirmPassword) => {
		if (
			username.length === 0 ||
			email.length === 0 ||
			password.length === 0 ||
			confirmPassword.length === 0
		) {
			// Error
			return false;
		}

		return true;
	};

	const onSubmitClicked = (event) => {
		if (event) {
			event.preventDefault();
		}

		if (
			validateForm(
				username.trim(),
				email.trim(),
				password.trim(),
				confirmPassword.trim()
			)
		) {
			SetLoading(true, "Creating Account...");

			UserManager.register(username, email, password, confirmPassword).then(
				(result) => {
					SetLoading(false);
					if (
						result.usernameError ||
						result.emailError ||
						result.passwordError ||
						result.confirmPasswordError
					) {
						setUsernameError(result.usernameError);
						setEmailError(result.emailError);
						setPasswordError(result.passwordError);
						setConfirmPasswordError(result.confirmPasswordError);
					} else {
						UserManager.login(username, password)
							.then(() => {
								changeUrl("/");
							})
							.catch((error) => {
								// Something went wrong, try to login manually.
							});
					}
				}
			);
		}
	};

	const onDiscordLogin = () => {
		window.location.replace(discordUrl);
	};

	const onGoogleLogin = () => {
		window.location.replace(googleUrl);
	};

	const onRedditLogin = () => {
		window.location.replace(redditUrl);
	};

	return (
		<CustomModal>
			<ModalPanel>
				<ModalHeader>Register</ModalHeader>
				<ModalBody>
					<form
						className={styles.centered}
						onSubmit={(e) => onSubmitClicked(e)}
					>
						<Box mb={2}>
							<TextField
								id="username"
								label="Username"
								onChange={(e) => setUsername(e.target.value)}
								variant="outlined"
								autoComplete="username"
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Person />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{usernameError && (
							<Box mb={2}>
								<label className="error">{usernameError}</label>
							</Box>
						)}
						<Box mb={2}>
							<TextField
								id="email"
								label="Email"
								onChange={(e) => setEmail(e.target.value)}
								variant="outlined"
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Email />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{emailError && (
							<Box mb={2}>
								<label className="error">{emailError}</label>
							</Box>
						)}
						<Box mb={2}>
							<TextField
								id="password"
								label="Password"
								type="password"
								autoComplete="new-password"
								onChange={(e) => setPassword(e.target.value)}
								variant="outlined"
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<VpnKey />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{passwordError && (
							<Box mb={2}>
								<label className="error">{passwordError}</label>
							</Box>
						)}
						<Box mb={2}>
							<TextField
								id="confirmpassword"
								label="Confirm Password"
								type="password"
								onChange={(e) => setConfirmPassword(e.target.value)}
								variant="outlined"
								required
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<VpnKey />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						{confirmPasswordError && (
							<Box mb={2}>
								<label className="error">{confirmPasswordError}</label>
							</Box>
						)}
						<Button variant="contained" color="primary" type="submit">
							Submit
						</Button>
						<ModalDivider />
						<Typography variant="body1" style={{ width: 300 }}>
							Or create a Pickstop account linked to your Google or Discord
							account
						</Typography>
						<Grid
							container
							direction="row"
							alignItems="center"
							justify="center"
							alignContent="center"
							spacing={3}
						>
							{discordUrl !== "" && (
								<Grid item xs>
									<img
										className={styles.provider}
										src="/images/DiscordLogo.png"
										title="Discord"
										alt="Discord Logo"
										onClick={() => onDiscordLogin()}
									/>
								</Grid>
							)}
							{googleUrl !== "" && (
								<Grid item xs>
									<img
										className={styles.provider}
										src="/images/GoogleLogo.png"
										title="Google"
										alt="Google Logo"
										onClick={() => onGoogleLogin()}
									/>
								</Grid>
							)}
							{redditUrl !== "" && (
								<Grid item xs>
									<img
										className={styles.provider}
										src="/images/RedditLogo.png"
										title="Reddit"
										alt="Reddit Logo"
										onClick={() => onRedditLogin()}
									/>
								</Grid>
							)}
						</Grid>
					</form>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
