import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import UserCard from "common/components/UserCard";
import UserManager from "common/userManager";
import * as React from "react";

export default function RedeemCodeModal({ promo }) {
	const userId = UserManager.getUserId();
	const username = UserManager.getUsername();
	return (
		<CustomModal>
			<ModalPanel className="success-fail-modal success">
				<ModalHeader>Promo Code Claimed!</ModalHeader>
				<ModalBody className="success-fail-section">
					<label>
						Congratulations, you have successfully claimed a promo code for the{" "}
						{promo.description}
					</label>
					<br />
					<br />
					<label>You have unlocked the following rewards:</label>
					<br />
					<br />
					{promo.rewardNamePlateStyleId && (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<label style={{ fontWeight: 500, fontSize: 24, marginBottom: 8 }}>
								New Nameplate
							</label>
							<br />
							<UserCard
								userId={userId}
								dontLoadData={true}
								userName={username}
								nameplateId={promo.rewardNamePlateStyleId}
							/>
						</div>
					)}
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
