export const Colors = {
	primary: "#ECECEC",
	primaryDark: "#B8B8B8",
	primaryDarker: "#8c8c8c",
	secondary: "#ffb300",
	secondaryDark: "#bf8600",
	pickstop: "#ffb300",
	pickstopGreen: "#5cdc63",
	panelDark: "#272727",
	panel: "#373737",
	panelLight: "#474747",
	panelLighterColor: "#727272",
	greyText: "#4B4B4B",
	body: "#575757",
	error: "#ff4444",
	green: "#78DE7E",
	greenPanel: "#4A674B",
	red: "#FF5151",

	lightGray: "#B9B8B8",
	white: "#FFFFFF",
	softWhite: "#F8F8F8",
	diamond: "#666E82",
	diamond_light: "#E1E9FF",
	platinum: "#587485",
	platinum_light: "#8BCAFF",
	gold: "#85745B",
	gold_light: "#EFCF66",
	silver: "#676767",
	silver_light: "#B9B8B8",
	bronze: "#786057",
	bronze_light: "#E6A780",
	wood: "#806346",
	wood_light: "#DE9343",

	patreon: "#FF424D",
};
