import merge from "deepmerge";
import { Colors } from "styles/Colors";
import { Theme } from "../types";

const defaultTheme: Theme = {
	fontFamily: '"Roboto", "Arial", "Helvetica", "sans-serif"',
	transitionTimingFunction: "cubic-bezier(0, 0.92, 0.77, 0.99)",

	disabledColor: "#5D6371",
	matchBackground: {
		wonColor: Colors.primary,
		lostColor: Colors.panelLighterColor,
	},
	border: {
		color: Colors.primary,
		highlightedColor: Colors.pickstop,
	},
	textColor: {
		highlighted: Colors.pickstop,
		winner: Colors.panel,
		main: Colors.primary,
		dark: Colors.primaryDark,
		disabled: "#5D6371",
	},
	score: {
		text: {
			winner: Colors.primary,
			highlightedWonColor: Colors.green,
			highlightedLostColor: Colors.red,
		},
		background: {
			wonColor: Colors.panel,
			lostColor: Colors.panel,
		},
	},
	canvasBackground: Colors.panelLighterColor,
};

export function createTheme<T>(customTheme?: Theme | T): Theme {
	return merge(defaultTheme, customTheme || {});
}

export default defaultTheme;
