import * as React from "react";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import ContentPanel from "common/components/ContentPanel/ContentPanel";
import {
	Grid,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { ReactComponent as Crown } from "icons/Crown.svg";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { Colors } from "styles/Colors";
import FatButton from "common/components/FatButton/FatButton";
import TabToggle from "common/components/TabToggle/TabToggle";
import { Search, Warning } from "@material-ui/icons";
import { League } from "common/types/League";
import ModalHelper from "common/helpers/modalHelper";
import LeagueNameplate from "common/components/LeagueNameplate/LeagueNameplate";
import { toMonthNameAndYear } from "common/helpers/dateHelper";
import Store from "common/store";
import { LeagueViewType } from "common/constants/LeagueViewType";
import CreateEditLeagueModal from "common/components/Modals/Leagues/CreateEditLeagueModal";
import UserManager from "common/userManager";
import LeagueTable from "common/components/LeagueTable/LeagueTable";
import SearchLeaguesModal from "common/components/Modals/Leagues/SearchLeaguesModal";

const propKeys = {
	leagueLimit: StoreKeys.LEAGUES.LIMIT,
	joinedLeagues: StoreKeys.LEAGUES.JOINED,
	newLeagues: StoreKeys.LEAGUES.NEW,
	popularLeagues: StoreKeys.LEAGUES.POPULAR,
	viewType: StoreKeys.LEAGUES.NAV_VIEW_TYPE,
};

const useStyles = makeStyles((theme) => ({
	message: {
		display: "block",
		width: "100%",
		textAlign: "center",
		textTransform: "uppercase",
		font: "normal normal normal 14px/27px Arial",
		letterSpacing: 1.2,

		"& svg": {
			height: 16,
			width: 16,
			position: "relative",
			top: 2,
			marginRight: 4,
			fill: Colors.pickstop,
		},
	},
	subtext: {
		color: Colors.primaryDark,
		textAlign: "center",
		fontSize: 14,
		width: 200,
	},
	dateSubtext: {
		font: "normal normal normal 11px/24px Roboto",
	},
	searchContainer: {
		marginTop: 8,
		position: "relative",
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
	},
	searchFullWidth: {
		display: "flex",
		justifyContent: "center",
		position: "absolute",
		left: 0,
		right: 0,
	},
	search: {
		display: "flex",
		width: 42,
		height: 42,
		border: `1px solid ${Colors.primary}`,
		background: Colors.body,
		borderRadius: 4,
		alignContent: "center",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		zIndex: 1,
		position: "relative",
	},
	leagueSlotLabel: {
		padding: "24px 0px",
		color: Colors.panelLighterColor,
		font: "normal normal 18px/14px Roboto",
	},
	rank: {
		font: "normal normal 22px/8px Roboto;",
	},
}));

function LeagueNavigatorC({
	leagueLimit,
	joinedLeagues,
	newLeagues,
	popularLeagues,
	viewType,
}) {
	const styles = useStyles();
	const joinedAny = joinedLeagues?.length > 0;
	const loggedIn = UserManager.isLoggedIn();

	const onChange = (id) => {
		Store.set(StoreKeys.LEAGUES.NAV_VIEW_TYPE, id);
	};

	const onSearch = () => {
		ModalHelper.openModal(<SearchLeaguesModal />);
	};

	const joinedOrCreate = loggedIn && (
		<Grid
			container
			item
			direction="row"
			justifyContent="space-around"
			style={{ flexWrap: "nowrap" }}
		>
			<Grid container item direction="column" alignItems="center" xs={6}>
				<FatButton text="Join League" onClick={onSearch} primary />
				<label className={styles.subtext}>
					Compete with friends in a separate pick'em leaderboard
				</label>
			</Grid>
			<Grid container item direction="column" alignItems="center" xs={6}>
				<FatButton
					text="Create League"
					onClick={() => {
						ModalHelper.openModal(
							<CreateEditLeagueModal isEdit={false} league={null} />
						);
					}}
					primary
				/>
				<label className={styles.subtext}>
					Start a separate pick'em leaderboard
				</label>
			</Grid>
		</Grid>
	);

	const navItems = [
		{
			id: LeagueViewType.popular,
			text: "Popular",
		},
		{
			id: LeagueViewType.new,
			text: "New",
		},
	];

	if (joinedAny) {
		navItems.unshift({
			id: LeagueViewType.joined,
			text: "Joined",
		});
	}

	const navTabs = (
		<TabToggle items={navItems} onChange={onChange} value={viewType} />
	);

	const search = (
		<div className={styles.search} onClick={onSearch}>
			<Search />
		</div>
	);

	/** @type{ League[] } */
	let leagues = [];
	switch (viewType) {
		case LeagueViewType.joined:
			leagues = joinedLeagues ?? [];
			break;
		case LeagueViewType.popular:
			leagues = popularLeagues ?? [];
			break;
		case LeagueViewType.new:
			leagues = newLeagues ?? [];
			break;
		default:
			leagues = popularLeagues ?? [];
			break;
	}

	const leagueSlotsRemaining =
		(leagueLimit ?? 0) - (joinedLeagues?.length ?? 0);
	const joinedLeaguesTable = (
		<Table style={{ borderColor: Colors.body }}>
			<TableHead>
				<TableRow>
					<TableCell>League Name</TableCell>
					<TableCell>Your Rank</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{leagues.map((l, i) => (
					<TableRow key={i}>
						<TableCell>
							<div>
								<div>
									<LeagueNameplate
										id={l.leagueId}
										name={l.leagueName}
										nameplateId={l.nameplateId}
									/>
								</div>
								<div className={styles.dateSubtext}>
									You joined {toMonthNameAndYear(l.joinDate)}
								</div>
							</div>
						</TableCell>
						<TableCell>
							<label className={styles.rank}>
								{l.eventRank ? `${l.eventRank} / ${l.memberCount}` : "UNRANKED"}
							</label>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);

	return (
		<ContentPanel>
			<ContentHeader icon={<Crown />} text="League Navigator" />
			<Grid container direction="column">
				{!joinedAny && !loggedIn && (
					<Grid container item>
						<label className={styles.message}>
							<Warning />
							CREATE AN ACCOUNT OR LOGIN TO PARTICIPATE
						</label>
					</Grid>
				)}
				{!joinedAny && loggedIn && (
					<Grid container item>
						<label className={styles.message}>
							<AddCircleRoundedIcon />
							YOU ARE CURRENTLY NOT IN ANY LEAGUES
						</label>
						{joinedOrCreate}
					</Grid>
				)}
				<div className={styles.searchContainer}>
					<div className={styles.searchFullWidth}>{navTabs}</div>
					{search}
				</div>
				<Grid container item>
					{viewType === LeagueViewType.joined && joinedLeaguesTable}
					{viewType !== LeagueViewType.joined && (
						<LeagueTable loggedIn={loggedIn} leagues={leagues} />
					)}
				</Grid>
				<Grid
					container
					item
					alignContent="center"
					justifyContent="center"
					alignItems="center"
				>
					{leagueSlotsRemaining > 0 && viewType === LeagueViewType.joined && (
						<label className={styles.leagueSlotLabel}>
							You can join {leagueSlotsRemaining} more leagues
						</label>
					)}
				</Grid>
				{joinedAny && (
					<Grid container item>
						{joinedOrCreate}
					</Grid>
				)}
			</Grid>
		</ContentPanel>
	);
}

export default function LeagueNavigator(props) {
	return connect(<LeagueNavigatorC />, propKeys, props);
}
