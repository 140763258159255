import { Button, Container, Grid } from "@material-ui/core";
import { SetLoading } from "common/components/Loader/Loader";
import UserManager from "common/userManager";
import * as queryString from "query-string";
import * as React from "react";
import { BiKey } from "react-icons/bi";
import { MdPerson } from "react-icons/md";
import "./ResetPasswordScreen.scss";
import { changeUrl } from "common/history";

export default class ResetPasswordScreen extends React.Component {
	constructor() {
		super();

		this.state = {
			resetId: null,
			username: "",
			password: "",
			confirmPassword: "",
			usernameError: null,
			passwordError: null,
			confirmPasswordError: null,
		};
	}

	componentDidMount() {
		const searchParams = queryString.parse(window.location.search);
		this.setState({
			resetId: searchParams.resetId,
		});
	}

	render() {
		return (
			<Container maxWidth={false} className="reset-password-screen">
				<Grid container style={{ paddingTop: 24 }} justify="center" spacing={2}>
					<Grid item>
						<div className="reset-password-form">
							<form onSubmit={this.onSubmitClicked.bind(this)}>
								<h4 className="create-an-account">Change Your Password</h4>
								<hr />
								<table>
									<tbody>
										<tr>
											<td>
												<label>
													Enter the username of the account <br />
													being changed.
												</label>
											</td>
											<td>
												<MdPerson size={18} className="input-icon" />
												<input
													placeholder="Username"
													value={this.state.username}
													onChange={this.setUsername.bind(this)}
												/>
											</td>
										</tr>
										{this.state.usernameError && (
											<tr>
												<td colSpan={2}>
													<label className="error">
														{this.state.usernameError}
													</label>
												</td>
											</tr>
										)}
										<tr>
											<td>
												<label>Create a new password</label>
											</td>
											<td>
												<BiKey size={20} className="input-icon" />
												<input
													type="password"
													placeholder="Password"
													value={this.state.password}
													onChange={this.setPassword.bind(this)}
												/>
											</td>
										</tr>
										{this.state.passwordError && (
											<tr>
												<td colSpan={2}>
													<label className="error">
														{this.state.passwordError}
													</label>
												</td>
											</tr>
										)}
										<tr>
											<td>
												<label>Re-type password</label>
											</td>
											<td>
												<BiKey size={20} className="input-icon" />
												<input
													type="password"
													placeholder="Password"
													value={this.state.confirmPassword}
													onChange={this.setConfirmPassword.bind(this)}
												/>
											</td>
										</tr>
										{this.state.confirmPasswordError && (
											<tr>
												<td colSpan={2}>
													<label className="error">
														{this.state.confirmPasswordError}
													</label>
												</td>
											</tr>
										)}
										<tr>
											<td></td>
											<td style={{ paddingLeft: 22 }}>
												<Button type="submit">
													Submit
													<img
														alt="Log in"
														src="/images/CarotButton.png"
														className="submit-button"
														style={{ cursor: "pointer" }}
														title="Submit"
													/>
												</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</form>
						</div>
					</Grid>
				</Grid>
			</Container>
		);
	}

	/**
	 * Called when username is changed.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 */
	setUsername(event) {
		const value = event.target.value;
		this.setState({
			username: value,
		});
	}

	/**
	 * Called when password field is changed.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 */
	setPassword(event) {
		const value = event.target.value;
		this.setState({
			password: value,
		});
	}

	/**
	 * Called when password field is changed.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 */
	setConfirmPassword(event) {
		const value = event.target.value;
		this.setState({
			confirmPassword: value,
		});
	}

	/**
	 *
	 * @param {Event} event
	 */
	onSubmitClicked(event) {
		event.preventDefault();
		const resetId = this.state.resetId?.trim();
		const username = this.state.username?.trim();
		const password = this.state.password?.trim();
		const confirmPassword = this.state.confirmPassword?.trim();

		if (this.validateUsernamePassword(username, password, confirmPassword)) {
			SetLoading(true, "Changing Password...");

			UserManager.changePassword(
				resetId,
				username,
				password,
				confirmPassword
			).then((result) => {
				if (
					result.usernameError ||
					result.passwordError ||
					result.confirmPasswordError
				) {
					SetLoading(false);
					this.setState(result);
				} else {
					UserManager.login(username, password)
						.then(() => {
							changeUrl("/");
						})
						.catch((error) => {
							// Something went wrong, try to login manually.
						});
				}
			});
		}
	}

	/**
	 * Validate the username and password.
	 *
	 * @param {string} username
	 * @param {string} password
	 * @param {string} confirmPassword
	 */
	validateUsernamePassword(username, password, confirmPassword) {
		if (
			username.length === 0 ||
			password.length === 0 ||
			confirmPassword.length === 0
		) {
			// Error
			return false;
		}

		return true;
	}
}
