import {
	Button,
	Container,
	List,
	ListItem,
	TextField,
} from "@material-ui/core";
import AdminUserModal from "common/components/Modals/AdminUserModal/AdminUserModal";
import ModalHelper from "common/helpers/modalHelper";
import * as React from "react";
import { Helmet } from "react-helmet";
import LookupService from "services/LookupService";
import UserService from "services/UserService";

export default class UserManagerScreen extends React.Component {
	constructor() {
		super();

		this.state = {
			userSearchValue: "",
			users: [],
		};

		this.onUserSearchChange = this.onUserSearchChange.bind(this);
		this.onUserSearch = this.onUserSearch.bind(this);
	}

	render() {
		const users = this.getUserList();
		return (
			<Container maxWidth={false}>
				<Helmet>
					<title>Pickstop - User Manager</title>
				</Helmet>
				<div>
					<h1>User Manager</h1>
					<label>Search for User by Name or Email</label>
					<br />
					<TextField
						label="Name or Email"
						onChange={this.onUserSearchChange}
					></TextField>
					<Button onClick={this.onUserSearch}>Search</Button>
					<List>{users}</List>
				</div>
			</Container>
		);
	}

	getUserList() {
		if (this.state.users && this.state.users.map) {
			return this.state.users.map((user) => (
				<ListItem
					key={user.id}
					onClick={() => {
						this.onUserSelect(user);
					}}
				>
					{user.username}
				</ListItem>
			));
		}

		return <></>;
	}

	onUserSelect = async (user) => {
		await Promise.all([
			UserService.getRoles(),
			UserService.getUserRoles(user.id),
			UserService.getParticipatingAnalystGame(user.id),
			LookupService.getGames(),
		]).then(([roles, userRoleIds, analystGameId, games]) => {
			ModalHelper.openModal(<AdminUserModal />, () => {}, {
				user,
				roles,
				userRoleIds,
				resetLink: null,
				isAnalyst: userRoleIds.includes(5),
				analystGameId,
				games,
				createAnalystLeague: false,
			});
		});
	};

	/**
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event
	 */
	onUserSearchChange(event) {
		const userSearchValue = event.target.value;

		this.setState({
			userSearchValue,
		});
	}

	onUserSearch = async () => {
		const users =
			(await UserService.searchUsers(this.state.userSearchValue)) ?? [];

		this.setState({
			users,
		});
	};
}
