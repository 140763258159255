import { SetLoading } from "common/components/Loader/Loader";
import { GetCurrentGamePrefix } from "common/constants/Games";
import { LocalStorageKeys } from "common/constants/StorageKeys";
import { changeUrl } from "common/history";
import React from "react";
import { linkDiscord } from "./api";

export default class LinkDiscord extends React.Component {
	async componentDidMount() {
		SetLoading(true, "Linking Discord account");

		const code = this.props.location.search.substring(6);
		try {
			await linkDiscord(code);
		} catch (error) {}

		SetLoading(false);
		const redirect = localStorage.getItem(LocalStorageKeys.POSTLINKURL);
		if (redirect) {
			localStorage.removeItem(LocalStorageKeys.POSTLINKURL);
			changeUrl(redirect);
		} else {
			const route = GetCurrentGamePrefix();
			changeUrl(route + "/event");
		}
	}

	render() {
		return <></>;
	}
}
