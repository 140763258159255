import { Box, Container, Grid } from "@material-ui/core";
import NavCard from "common/components/NavCard/NavCard";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import { Helmet } from "react-helmet";

const propKeys = {
	roles: StoreKeys.ROLES,
};

class AdminScreenC extends React.Component {
	render() {
		const cards = this.getCards();
		return (
			<Container maxWidth={false}>
				<Helmet>
					<title>Pickstop - Admin</title>
				</Helmet>
				<Box mt={4}>
					<Grid container direction="row" justify="space-evenly" spacing={1}>
						{cards.map((card) => {
							return <>{card}</>;
						})}
					</Grid>
				</Box>
			</Container>
		);
	}

	getCards() {
		const isAdmin = this.props.roles?.includes("Admin");
		const isEventCreator = this.props.roles?.includes("EventCreator");
		const isSurveyCreator = this.props.roles?.includes("SurveyCreator");

		let key = 0;
		const cards = [];
		if (!this.props.roles) {
			return cards;
		}

		if (isAdmin) {
			cards.push(
				<NavCard
					key={key++}
					xs={12}
					sm={6}
					md={4}
					path="/admin/user"
					title="User Manager"
					description="Manage user roles and access"
					buttonLabel="Manages Users"
				/>
			);
		}

		if (isAdmin || isEventCreator) {
			cards.push(
				<NavCard
					key={key++}
					xs={12}
					sm={6}
					md={4}
					path="/admin/event/create"
					title="Event Creator"
					description="Create events using an easy interface."
					buttonLabel="Create Event"
				/>
			);
			// cards.push(
			// 	<NavCard
			// 		key={key++}
			// 		xs={12}
			// 		sm={6}
			// 		md={4}
			// 		path="/admin/event/analytics"
			// 		title="Event Analytics"
			// 		description="View detailed analytics for any event."
			// 		buttonLabel="View Event Analytics"
			// 	/>
			// );
		}

		if (isAdmin) {
			cards.push(
				<NavCard
					key={key++}
					xs={12}
					sm={6}
					md={4}
					path="/admin/nameplate"
					title="Nameplate Editor"
					description="Create and edit nameplates"
					buttonLabel="Create/Edit Nameplates"
				/>
			);
		}

		if (isAdmin) {
			cards.push(
				<NavCard
					key={key++}
					xs={12}
					sm={6}
					md={4}
					path="/admin/pickdata"
					title="Pick Data"
					description="View pick data for events"
					buttonLabel="View pick data"
				/>
			);
		}

		if (isAdmin) {
			cards.push(
				<NavCard
					key={key++}
					xs={12}
					sm={6}
					md={4}
					path="/admin/eventFormatBuilder"
					title="Event Format Builder"
					description="Create and edit event formats"
					buttonLabel="Create/Edit event formats"
				/>
			);
		}

		if (isAdmin || isEventCreator) {
			cards.push(
				<NavCard
					key={key++}
					xs={12}
					sm={6}
					md={4}
					path="/admin/giveTrophy"
					title="Reward Trophies"
					description="Reward users with their trophies for an event."
					buttonLabel="Give Trophies"
				/>
			);
			// cards.push(
			// 	<NavCard
			// 		key={key++}
			// 		xs={12}
			// 		sm={6}
			// 		md={4}
			// 		path="/admin/event/analytics"
			// 		title="Event Analytics"
			// 		description="View detailed analytics for any event."
			// 		buttonLabel="View Event Analytics"
			// 	/>
			// );
		}

		if (isAdmin || isSurveyCreator) {
			cards.push(
				<NavCard
					key={key++}
					xs={12}
					sm={6}
					md={4}
					path="/admin/survey"
					title="Create Survey's"
					description="Add/Edit Survey's and view results."
					buttonLabel="Create Survey's"
				/>
			);
		}

		// if (isAdmin) {
		// 	cards.push(
		// 		<NavCard
		// 			key={key++}
		// 			xs={12}
		// 			sm={6}
		// 			md={4}
		// 			path="/admin/pickOverride"
		// 			title="Picks Override"
		// 			description="Override official picks"
		// 			buttonLabel="Override Picks"
		// 		/>
		// 	);
		// }

		return cards;
	}
}

function AdminScreen(props) {
	return connect(<AdminScreenC />, propKeys, props);
}

export default AdminScreen;
