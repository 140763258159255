import * as React from "react";
import Store from "../../store";
import StoreKeys from "../../storeKeys";
import { ReactComponent as TrafficLight } from "icons/TrafficLight.svg";
import { connect } from "common/connect";
import { makeStyles } from "@material-ui/core";

const propKeys = {
	isLoading: StoreKeys.IS_LOADING,
	loadingText: StoreKeys.LOADING_TEXT,
};

const useStyles = makeStyles((theme) => ({
	loadingContainer: {
		display: "flex",
		position: "fixed",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0,
		zIndex: 1750,
		backgroundColor: "rgba(51, 51, 51, 0.5)",
		backdropFilter: "blur(5px)",
	},
	header: {
		color: theme.palette.secondary.main,
		textAlign: "center",
	},
	center: {
		margin: "auto",
	},
	spinnerIcon: {
		width: 100,
		height: 100,
		display: "block",
		margin: "auto",
	},
	rotate: {
		animation: "$rotation 1s infinite linear",
	},
	"@keyframes rotation": {
		"0%": {
			transform: "rotate(0deg)",
		},
		"100%": {
			transform: "rotate(359deg)",
		},
	},
}));

function LoadingComponentC(props) {
	const classNames = useStyles();

	return (
		<>
			{props.isLoading && (
				<div className={classNames.loadingContainer}>
					<div className={classNames.center}>
						<span className={classNames.rotate}>
							<TrafficLight
								className={`${classNames.spinnerIcon} ${classNames.rotate}`}
							/>
						</span>
						<h2 className={classNames.header}>{props.loadingText}</h2>
					</div>
				</div>
			)}
		</>
	);
}

export function SetLoading(isLoading, text = "Loading") {
	const obj = {};
	obj[StoreKeys.IS_LOADING] = isLoading;
	obj[StoreKeys.LOADING_TEXT] = text;

	Store.setMany(obj);
}

export default function LoadingComponent() {
	return connect(<LoadingComponentC />, propKeys);
}
