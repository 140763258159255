export class NameplateStyle {
	/** @type {number} */
	id = null;
	/** @type {string} */
	name = "";
	/** @type {string} */
	description = "";
	/** @type {string} */
	style = "";
	/** @type {number | null} */
	namePlateTypeId = null;

	constructor(id, name, description, style, namePlateTypeId) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.style = style;
		this.namePlateTypeId = namePlateTypeId;
	}
}
