import * as React from "react";
import {
	Divider,
	Grid,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import TabToggle from "common/components/TabToggle/TabToggle";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import EventSelector from "common/components/EventSelector/EventSelector";
import LeagueNameplate from "../LeagueNameplate/LeagueNameplate";
import UserCard from "../UserCard";
import { Colors } from "styles/Colors";
import { changeUrl } from "common/history";
import ModalHelper from "common/helpers/modalHelper";
import { GetCurrentGamePrefix } from "common/constants/Games";

const useStyles = makeStyles((theme) => ({
	tableContainer: {
		marginTop: 12,
	},
	points: {
		backgroundColor: Colors.body,
		padding: "8px 12px",
		borderRadius: 12,
		cursor: "pointer",
		textWrap: "no-wrap",
	},
}));

export default function LeagueInfo({
	league,
	events,
	rankings,
	viewType,
	showNameplate,
	onChangeViewType,
	onChangeEvent,
}) {
	const styles = useStyles();
	const navItems = [
		{
			id: 1,
			text: "Event",
		},
		{
			id: 2,
			text: "Season",
			disabled: true,
		},
	];

	const maxTableHeight = window.innerHeight - 250;
	const openPicks = (eventId, userId) => {
		ModalHelper.closeAllModals();
		const route = GetCurrentGamePrefix();
		changeUrl(`${route}/event/${eventId}/${userId}`);
	};

	React.useEffect(() => {
		if (!viewType) {
			Store.set(StoreKeys.LEAGUES.ANALYST.VIEW_TYPE, 1);
		}
	});

	return (
		<Grid container direction="column" alignItems="center">
			{showNameplate && (
				<>
					<LeagueNameplate
						name={league.name}
						nameplateId={league.nameplateId}
						disableClick={true}
					/>
					<Divider style={{ marginTop: 16 }} />
				</>
			)}
			<Grid>
				<TabToggle
					items={navItems}
					onChange={onChangeViewType}
					value={viewType}
				/>
			</Grid>
			<Divider style={{ marginTop: 16 }} />
			<EventSelector
				events={events}
				onChangeEvent={onChangeEvent}
				disableChange={false}
			/>
			{rankings && (
				<div>
					<TableContainer
						className={styles.tableContainer}
						style={{ maxHeight: maxTableHeight }}
					>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Score</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rankings.map((r, i) => (
									<TableRow key={i}>
										<TableCell>{r.rank}</TableCell>
										<TableCell>
											<UserCard
												userId={r.userId}
												dontLoadData={true}
												userName={r.username}
												pinnedEventName={r.pinnedEventName}
												pinnedTrophyImagePath={r.pinnedTrophyImagePath}
												nameplateId={r.nameplateId}
											/>
										</TableCell>
										<TableCell>
											<div
												className={styles.points}
												onClick={() => openPicks(r.eventId, r.userId)}
											>
												{r.totalScore ?? 0} pts.
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			)}
		</Grid>
	);
}
