import React from "react";
import MatchWrapper from "bracket-system/core/match-wrapper";
import { calculatePositionOfFinalGame } from "./calculate-match-position";
import Connectors from "./final-connectors";
import FinalRoundHeader from "bracket-system/components/final-round-header";

const FinalGame = ({
	upperColumns,
	lowerColumns,
	match,
	rowIndex,
	columnIndex,
	gameHeight,
	gameWidth,
	calculatedStyles,
	onMatchClick,
	onPartyClick,
	matchComponent,
	bracketSnippet,
	numOfUpperRounds,
	numOfLowerRounds,
	upperBracketHeight,
	lowerBracketHeight,
	onFinalsClick,
	onColumnClick,
	hideSeriesScore,
}) => {
	const { canvasPadding, columnWidth, rowHeight, roundHeader } =
		calculatedStyles;
	const { x, y } = calculatePositionOfFinalGame(rowIndex, columnIndex, {
		canvasPadding,
		columnWidth,
		rowHeight,
		gameHeight,
		upperBracketHeight,
		lowerBracketHeight,
	});

	return (
		// @ts-ignore
		<g style={{ pointerEvents: "bounding-box" }} onClick={onFinalsClick}>
			{roundHeader.isShown && (
				<FinalRoundHeader
					x={x}
					y={y - 25}
					roundHeader={roundHeader}
					canvasPadding={canvasPadding}
					width={calculatedStyles.width}
					onClick={(event) => {
						if (onColumnClick) {
							onColumnClick("GF", 0);
							event.stopPropagation();
						}
					}}
				/>
			)}
			{columnIndex !== 0 && (
				<Connectors
					{...{
						upperColumns,
						lowerColumns,
						numOfUpperRounds,
						numOfLowerRounds,
						rowIndex,
						columnIndex,
						gameWidth,
						gameHeight,
						lowerBracketHeight,
						upperBracketHeight,
						style: calculatedStyles,
						bracketSnippet,
					}}
				/>
			)}
			<g>
				<MatchWrapper
					x={x}
					y={
						y +
						(roundHeader.isShown
							? roundHeader.height + roundHeader.marginBottom
							: 0)
					}
					rowIndex={rowIndex}
					columnIndex={columnIndex}
					match={match}
					previousBottomMatch={bracketSnippet.previousBottomMatch}
					topText={match.startTime}
					bottomText={match.name}
					teams={match.participants}
					onMatchClick={onMatchClick}
					onPartyClick={onPartyClick}
					style={calculatedStyles}
					matchComponent={matchComponent}
					hideSeriesScore={hideSeriesScore}
					isQualifierColumn={false}
				/>
			</g>
		</g>
	);
};

export default FinalGame;
