import _ from "lodash";

export class ApplicationStore {
	constructor() {
		this.data = {};
		this.promises = {};
		this.onChangeSubscriptions = {};
		this.isCaching = false;
		this.cacheKeys = [];
		this.changed = [];
	}

	subscribe = (callback, keys) => {
		for (var k of keys) {
			if (!this.onChangeSubscriptions[k]) {
				this.onChangeSubscriptions[k] = [];
			}

			this.onChangeSubscriptions[k].push(callback);
		}
	};

	unsubscribe = (callback, keys) => {
		for (var k of keys) {
			if (!this.onChangeSubscriptions[k]) {
				continue;
			}

			this.onChangeSubscriptions[k] = this.onChangeSubscriptions[k].filter(
				(cb) => cb !== callback
			);
		}
	};

	get = (key, defaultValue) => {
		if (this.data[key] == null) {
			this.data[key] = defaultValue;
			return defaultValue;
		}
		return this.data[key];
	};

	set = (key, value) => {
		if (isPrimitive(value)) {
			this.data[key] = value;
		} else if (value instanceof Date) {
			this.data[key] = new Date(value);
		} else {
			if (Array.isArray(value)) {
				this.data[key] = [...value];
			} else {
				this.data[key] = { ...value };
			}
		}
		this.notify(key);
	};

	remove = (key) => {
		delete this.data[key];
		this.notify(key);
	};

	notify = (key) => {
		// If we are caching, don't notify components until we applyChanges.
		if (this.isCaching) {
			this.changed.push(key);
			return;
		}

		if (!this.onChangeSubscriptions[key]) {
			return;
		}
		for (let callback of this.onChangeSubscriptions[key]) {
			callback();
		}
	};

	cacheChanges(key) {
		this.cacheKeys.push(key);
		this.isCaching = true;
	}

	applyChanges(key) {
		this.cacheKeys = this.cacheKeys.filter((k) => k !== key);
		this.isCaching = this.cacheKeys.length !== 0;

		if (this.isCaching) {
			return;
		}

		const callbacks = [];
		const changed = _.uniq(this.changed);
		for (const key of changed) {
			if (!this.onChangeSubscriptions[key]) {
				continue;
			}
			for (let callback of this.onChangeSubscriptions[key]) {
				if (callbacks.includes(callback)) {
					continue;
				}

				callbacks.push(callback);
			}
		}

		for (const callback of callbacks) {
			callback();
		}
		this.changed = [];
	}

	setMany = (obj) => {
		const objKey = JSON.stringify(obj);
		this.cacheChanges(objKey);
		for (var key in obj) {
			this.set(key, obj[key]);
		}

		this.applyChanges(objKey);
	};
}

function isPrimitive(test) {
	return test !== Object(test);
}

const Store = new ApplicationStore();
//window.ApplicationStore = Store;

export default Store;
