import {
	Box,
	Button,
	Grid,
	InputAdornment,
	makeStyles,
	TextField,
} from "@material-ui/core";
import { Person, VpnKey } from "@material-ui/icons";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalDivider,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import { SetLoading } from "common/components/Loader/Loader";
import { GetCurrentGamePrefix } from "common/constants/Games";
import { LocalStorageKeys } from "common/constants/StorageKeys";
import ModalHelper from "common/helpers/modalHelper";
import { changeUrl } from "common/history";
import UserManager from "common/userManager";
import React, { useEffect, useState } from "react";
import { Colors } from "styles/Colors";
import RegisterModal from "../RegisterModal/RegisterModal";
import { getDiscordUrl, getGoogleUrl, getRedditUrl } from "./api";

const useStyles = makeStyles({
	provider: {
		cursor: "pointer",
		marginLeft: "auto",
		marginRight: "auto",
		display: "block",
		paddingTop: 8,
	},
	centered: {
		textAlign: "center",
	},
	errorMessage: {
		color: Colors.error,
	},
});

export default function LoginModal() {
	const styles = useStyles();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [discordUrl, setDiscordUrl] = useState("");
	const [googleUrl, setGoogleUrl] = useState("");
	const [redditUrl, setRedditUrl] = useState("");
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		if (discordUrl === "") {
			getDiscordUrl().then((url) => {
				setDiscordUrl(url);
			});
		}
		if (googleUrl === "") {
			getGoogleUrl().then((url) => {
				setGoogleUrl(url);
			});
		}
		if (redditUrl === "") {
			getRedditUrl().then((url) => {
				setRedditUrl(url);
			});
		}
	}, [discordUrl, googleUrl, redditUrl]);

	const validateUsernamePassword = () => {
		if (username.length === 0 || password.length === 0) {
			// Error
			return false;
		}

		return true;
	};

	const onLoginClicked = (event) => {
		if (event) {
			event.preventDefault();
		}
		if (validateUsernamePassword()) {
			SetLoading(true, "Attempting Login...");
			UserManager.login(username, password)
				.then(() => {
					SetLoading(false);
					ModalHelper.closeModal();

					const redirect = localStorage.getItem(LocalStorageKeys.POSTLOGINURL);
					if (redirect) {
						localStorage.removeItem(LocalStorageKeys.POSTLOGINURL);
						changeUrl(redirect);
					} else {
						const route = GetCurrentGamePrefix();
						changeUrl(route + "/event");
						window.location.reload();
					}
				})
				.catch((err) => {
					SetLoading(false);

					if (err.response.status === 401) {
						setErrorMessage("Invalid Username or Password provided");
					}
				});
		}
	};

	const onCreateAccount = () => {
		ModalHelper.closeModal();
		ModalHelper.openModal(<RegisterModal />);
	};

	const onDiscordLogin = () => {
		window.location.replace(discordUrl);
	};

	const onGoogleLogin = () => {
		window.location.replace(googleUrl);
	};

	const onRedditUrl = () => {
		window.location.replace(redditUrl);
	};

	return (
		<CustomModal>
			<ModalPanel>
				<ModalHeader>Log In</ModalHeader>
				<ModalBody>
					<form className={styles.centered} onSubmit={(e) => onLoginClicked(e)}>
						<Box mb={2}>
							<TextField
								id="username"
								label="Username"
								onChange={(e) => setUsername(e.target.value)}
								variant="outlined"
								autoFocus
								type="search"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Person />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Box mb={2}>
							<TextField
								id="password"
								autoComplete="current-password"
								label="Password"
								type="password"
								onChange={(e) => setPassword(e.target.value)}
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<VpnKey />
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Box className={styles.errorMessage}>{errorMessage}</Box>
						<Button variant="contained" color="primary" type="submit">
							Submit
						</Button>
						<ModalDivider />
						<label>Log in with Discord, Google or Reddit</label>
						<Grid
							container
							direction="row"
							alignItems="center"
							justify="center"
							alignContent="center"
							spacing={3}
						>
							{discordUrl !== "" && (
								<Grid item xs>
									<img
										className={styles.provider}
										src="/images/DiscordLogo.png"
										title="Discord"
										alt="Discord Logo"
										onClick={() => onDiscordLogin()}
									/>
								</Grid>
							)}
							{googleUrl !== "" && (
								<Grid item xs>
									<img
										className={styles.provider}
										src="/images/GoogleLogo.png"
										title="Google"
										alt="Google Logo"
										onClick={() => onGoogleLogin()}
									/>
								</Grid>
							)}
							{redditUrl !== "" && (
								<Grid item xs>
									<img
										className={styles.provider}
										src="/images/RedditLogo.png"
										title="Reddit"
										alt="Reddit Logo"
										onClick={() => onRedditUrl()}
									/>
								</Grid>
							)}
						</Grid>
						<ModalDivider />
						<Box mb={0}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => onCreateAccount()}
							>
								Create Account
							</Button>
						</Box>
						{/* <a href="/forgot-password">Forgot password</a> */}
					</form>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
