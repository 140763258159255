import {
	Box,
	Button,
	Container,
	Grid,
	InputAdornment,
	TextField,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { SetLoading } from "common/components/Loader/Loader";
import { connect } from "common/connect";
import { GetCurrentGamePrefix } from "common/constants/Games";
import { changeUrl, history } from "common/history";
import StoreKeys from "common/storeKeys";
import UserManager from "common/userManager";
import React, { useState } from "react";
import "./PickUsernameScreen.scss";

const propKeys = {
	provider: StoreKeys.LOGIN_PROVIDER,
};

function PickUsernameScreenC(props) {
	const uName = UserManager.getUsername();
	const [username, setUsername] = useState(uName);
	const [error, setError] = useState(null);

	const onSubmitClicked = (event) => {
		if (event) {
			event.preventDefault();
		}

		SetLoading(true, "Changing Username...");

		UserManager.changeUsername(username.trim()).then((result) => {
			if (result.error) {
				SetLoading(false);
				setError(result.error);
			} else {
				UserManager.setUsername(username.trim());
				const prefix = GetCurrentGamePrefix();
				changeUrl(`${prefix}/event`);
			}
		});
	};

	return (
		<Container maxWidth={false} className="pickusername-screen">
			<Grid container style={{ paddingTop: 24 }} justify="center" spacing={2}>
				<Grid item>
					<form onSubmit={(e) => onSubmitClicked(e)}>
						<div className="pickusername-form">
							<h4>
								<span style={{ textTransform: "capitalize" }}>
									{props.provider}
								</span>{" "}
								Create a new Pickstop username
							</h4>
							<h3>Note:</h3>
							<p style={{ marginLeft: 56, marginRight: 24 }}>
								This username will appear on the public leaderboard and your
								profile that other users can see.
							</p>
							<Box ml={8}>
								<Box mb={2}>
									<TextField
										id="username"
										label="Username"
										value={username}
										onChange={(e) => setUsername(e.target.value)}
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Person />
												</InputAdornment>
											),
										}}
									/>
								</Box>
								{error && (
									<Box mb={2}>
										<p
											className="error"
											style={{ marginLeft: 8, marginRight: 24 }}
										>
											{error}
										</p>
										<label className="error"></label>
									</Box>
								)}
								<Button variant="contained" color="primary" type="submit">
									Submit
								</Button>
							</Box>
							{props.provider && (
								<>
									<hr />
									<h3>Note:</h3>
									<p style={{ marginLeft: 56, marginRight: 24 }}>
										From now on, log in to Pickstop by clicking the{" "}
										{props.provider} icon:
									</p>
									<div className="provider-example">
										{props.provider === "discord" && (
											<img
												src="/images/DiscordLogo.png"
												title="Discord"
												alt="Discord Logo"
											/>
										)}
										{props.provider === "google" && (
											<img
												src="/images/GoogleLogo.png"
												title="Google"
												alt="Google Logo"
											/>
										)}
										{props.provider === "reddit" && (
											<img
												src="/images/RedditLogo.png"
												title="Reddit"
												alt="Reddit Logo"
											/>
										)}
									</div>
								</>
							)}
						</div>
					</form>
				</Grid>
			</Grid>
		</Container>
	);
}

export const PickUsernameScreen = (props) => {
	return connect(<PickUsernameScreenC />, propKeys, props);
};
