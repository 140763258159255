import { INITIAL_VALUE } from "react-svg-pan-zoom";

export class PanZoomHelper {
	static getCurrentValue(width, height) {
		let currentValue = INITIAL_VALUE;
		if (sessionStorage.getItem("pan_zoom_value")) {
			currentValue = JSON.parse(sessionStorage.getItem("pan_zoom_value"));

			let changed = false;
			if (width) {
				currentValue.viewerWidth = width;
				changed = true;
			}
			if (height) {
				currentValue.viewerHeight = height;
				changed = true;
			}

			if (changed) {
				sessionStorage.setItem("pan_zoom_value", JSON.stringify(currentValue));
			}
		}

		return currentValue;
	}

	static resetView(topLeftX = 0, topLeftY = 0, zoom = 1) {
		const currentValue = PanZoomHelper.getCurrentValue();

		currentValue.a = zoom;
		currentValue.d = zoom;
		currentValue.e = topLeftX;
		currentValue.f = topLeftY;

		sessionStorage.setItem("pan_zoom_value", JSON.stringify(currentValue));
	}
}
