import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
	ModalFooter,
} from "common/components/CustomModal/Modal/ModalFrame";
import * as React from "react";
import { getBaseUrl } from "common/history";
import {
	Button,
	FormControl,
	InputLabel,
	List,
	ListItem,
	MenuItem,
	Select,
} from "@material-ui/core";
import ModalHelper from "common/helpers/modalHelper";
import UserService from "services/UserService";
import { toast } from "react-toastify";

const AnalystRoleId = 5;

export default class AdminUserModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = props;
	}

	render() {
		const userRoles = this.getUserRolesList();
		return (
			<>
				<CustomModal>
					<ModalPanel>
						<ModalHeader>{this.props.user?.username}</ModalHeader>
						<ModalBody>
							<label>User Id: {this.props.user?.id}</label>
							{
								this.props.user?.userExternalLogins?.length > 0 &&
								<fieldset>
									<legend>External IDs</legend>
										{this.props.user?.userExternalLogins?.map((item) => {
											if (!item.externalUsername) {
												return <></>;
											}

										return <div>
											<label>{item.name}</label>: {item.externalUsername}
										</div>
									})}
								</fieldset>
							}
							<fieldset>
								<legend>Change Username</legend>
								<input
									style={{ width: "80%" }}
									maxLength={20}
									value={this.state.user?.username}
									onChange={this.changeUsername.bind(this)}
								/>
							</fieldset>
							<fieldset>
								<legend>Flag For Rename</legend>
								{(this.state.user?.usernameChangeAvailable ||
									this.state.flagUsernameChange) && (
									<>
										<label>Flagged to Change</label>
										<br />
									</>
								)}
								<button
									onClick={this.flagUsernameChange.bind(this)}
									disabled={
										this.state.user?.usernameChangeAvailable ||
										this.state.flagUsernameChange
									}
								>
									Flag Username Change
								</button>
							</fieldset>
							<fieldset>
								<legend>Reset Password</legend>
								<button onClick={this.resetPassword.bind(this)}>Reset</button>
								<br />
								{this.state.resetLink && (
									<input
										style={{ width: "100%" }}
										value={this.state.resetLink}
									/>
								)}
							</fieldset>
							<fieldset>
								<legend>User Roles</legend>
								<List>{userRoles}</List>
							</fieldset>
							{this.state?.isAnalyst && (
								<div style={{ margin: 8, marginLeft: 28 }}>
									<input
										type="checkbox"
										checked={this.state.createAnalystLeague ?? false}
										onChange={(e) => {
											this.setState({
												createAnalystLeague: e.currentTarget.checked,
											});
										}}
									/>
									<label>Create Analyst League</label>
								</div>
							)}
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.saveUser.bind(this)}>
								Save
							</Button>
							<Button color="secondary" onClick={this.cancel.bind(this)}>
								Cancel
							</Button>
						</ModalFooter>
					</ModalPanel>
				</CustomModal>
			</>
		);
	}

	onGameSelect(e) {
		const value = e.target.value;
		const gameId = Number(value);
		this.setState({
			analystGameId: gameId,
		});
	}

	getUserRolesList() {
		if (
			this.state &&
			this.state.user &&
			this.state.roles &&
			this.state.userRoleIds
		) {
			return this.state.roles.map((role) => (
				<ListItem key={role.id}>
					<input
						type="checkbox"
						checked={this.state.userRoleIds.includes(role.id)}
						onChange={(e) => this.changeRole(e, role)}
					/>
					<label>{role.name}</label>
					{role.id === AnalystRoleId &&
						this.state.userRoleIds.includes(role.id) && (
							<FormControl
								style={{
									margin: 4,
									minWidth: 120,
								}}
							>
								<InputLabel id="game-label">Game</InputLabel>
								<select
									value={this.state.analystGameId ?? 0}
									onChange={this.onGameSelect.bind(this)}
								>
									<option value={0} key={0}>
										None
									</option>
									{this.state.games?.map((game) => (
										<option value={game.id} key={game.id}>
											{game.name}
										</option>
									))}
								</select>
							</FormControl>
						)}
				</ListItem>
			));
		}

		return <></>;
	}

	changeRole(event, role) {
		const checked = event.currentTarget.checked;
		const userRoleIds = this.state.userRoleIds;
		if (checked) {
			userRoleIds.push(role.id);
		} else {
			const index = userRoleIds.findIndex((r) => r === role.id);
			userRoleIds.splice(index, 1);
		}

		this.setState({
			userRoleIds,
			isAnalyst: userRoleIds.includes(AnalystRoleId),
		});
	}

	changeUsername(event) {
		const value = event.currentTarget.value;
		const user = { ...this.state.user };
		user.username = value;
		this.setState({
			user,
		});
	}

	flagUsernameChange() {
		this.setState({
			flagUsernameChange: true,
		});
	}

	resetPassword = async () => {
		await UserService.resetPassword(this.state.user.id).then((resetId) => {
			const resetLink = `${getBaseUrl()}/reset-password?resetId=${resetId}`;
			this.setState({
				resetLink,
			});
		});
	};

	cancel() {
		ModalHelper.closeModal();
	}

	saveUser = async () => {
		await UserService.updateUser(
			this.state.user.id,
			this.state.user.username,
			this.state.userRoleIds,
			this.state.flagUsernameChange,
			this.state.analystGameId,
			this.state.createAnalystLeague ?? false
		);
		ModalHelper.closeModal();
		toast("User Updated!", { type: "success", toastId: "user-updated" });
	};
}
