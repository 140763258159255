import axiosWrapper from "common/axiosWrapper";
import { EventFormat } from "common/types/EventFormat";

const baseUrl = "/api/eventFormat";

/** The Event service which calls the API endpoints for the Event Controller */
export default class EventFormatService {
	/**
	 * Gets all event formats.
	 *
	 * @returns {Promise<Array<EventFormat>>}
	 */
	static async getAllFormats() {
		return await axiosWrapper.get(`${baseUrl}`);
	}

	/**
	 * Saves event format.
	 *
	 * @param {EventFormat} eventFormat
	 * @returns {Promise<EventFormat>}
	 */
	static async saveFormat(eventFormat) {
		return await axiosWrapper.post(`${baseUrl}`, eventFormat);
	}

	/**
	 * Tries to delete the format by id, if in use it will fail.
	 *
	 * @param {number} eventFormatId
	 * @returns
	 */
	static async deleteFormat(eventFormatId) {
		return await axiosWrapper.delete(`${baseUrl}/${eventFormatId}`);
	}
}
