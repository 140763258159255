import * as React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";

const useStyles = makeStyles((theme) => ({
	button: {
		borderRadius: 24,
		color: Colors.panel,
		padding: "8px 16px",
		margin: 8,
		fontWeight: 600,
		textTransform: "uppercase",

		"&:hover": {
			backgroundColor: Colors.primary,
			boxShadow: "0px 0px 8px black",
		},
	},
	// Primary buttons should have color, our primary color is white. So we use the secondary color as our primary button color
	buttonPrimary: {
		backgroundColor: Colors.secondary,

		"&:hover": {
			backgroundColor: Colors.secondaryDark,
		},
	},
	buttonSecondary: {
		backgroundColor: Colors.primary,

		"&:hover": {
			backgroundColor: Colors.primaryDark,
		},
	},
	disabled: {
		backgroundColor: Colors.body,
		cursor: "default",

		"&:hover": {
			backgroundColor: Colors.body,
			boxShadow: "0px 0px 0px black",
		},
	},
}));

export default function FatButton({ text, onClick, primary, disabled }) {
	const styles = useStyles();

	return (
		<Button
			className={`${styles.button} ${
				primary ? styles.buttonPrimary : styles.buttonSecondary
			} ${disabled ? styles.disabled : ""}`}
			title={text}
			onClick={onClick}
		>
			{text}
		</Button>
	);
}
