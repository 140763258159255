const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export function toMonthNameAndYear(value) {
	if (!value) {
		return "";
	}

	let date = value;
	if (value === value?.toString()) {
		date = new Date(date);
	}

	const month = monthNames[date.getMonth()];

	return `${month} ${date.getFullYear()}`;
}

export function toMonthNameDayAndYear(value) {
	if (!value) {
		return "";
	}

	let date = value;
	if (value === value?.toString()) {
		date = new Date(date);
	}

	const month = monthNames[date.getMonth()];
	const day = date.getDate();

	return `${month} ${day}, ${date.getFullYear()}`;
}

export function toMMDDYYYY(value) {
	if (typeof value === "string") {
		value = new Date(value);
	}
	let monthInt = value.getMonth() + 1;
	const month = monthInt < 10 ? "0" + monthInt : monthInt;
	const day = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
	return `${value.getFullYear()}-${month}-${day}`;
}

export function toMMDDYYYYWithHourMintues(value) {
	if (typeof value === "string") {
		value = new Date(value);
	}
	let monthInt = value.getMonth() + 1;
	const month = monthInt < 10 ? "0" + monthInt : monthInt;
	const day = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
	const hour =
		value.getHours() < 10 ? "0" + value.getHours() : value.getHours();
	const minute =
		value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes();
	return `${value.getFullYear()}-${month}-${day}T${hour}:${minute}`;
}

export function toFriendlyDateTime(value) {
	if (typeof value === "string") {
		value = new Date(value);
	}
	let monthInt = value.getMonth() + 1;
	const month = monthInt < 10 ? "0" + monthInt : monthInt;
	const day = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
	let hour = value.getHours();
	const minute =
		value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes();

	let amPm = "am";
	if (hour >= 12) {
		hour -= 12;
		amPm = "pm";
	}

	hour = hour < 10 ? "0" + hour : hour;

	return `${month}/${day}/${value.getFullYear()} ${hour}:${minute} ${amPm}`;
}
