import * as React from "react";
import { GroupStageTeam } from "common/components/GroupStageTeam/GroupStageTeam";
import { PickTypes } from "common/constants/PickTypes";
import EventHelper from "common/helpers/eventHelper/eventHelper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import { ImageList, ImageListItem, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";
import Instructions from "common/components/Instructions/Instructions";
import PageIndicators from "common/components/PageIndicators/PageIndicators";
import { connect } from "common/connect";
// eslint-disable-next-line no-unused-vars
import { EventFormatData } from "common/helpers/eventFormatHelper";
// eslint-disable-next-line no-unused-vars
import { GroupStagePick } from "common/types/GroupStagePick";
import UserManager from "common/userManager";
import ModalHelper from "common/helpers/modalHelper";
import AccountRequired from "common/components/Modals/AccountRequired/AccountRequired";
import NameplateButton from "common/components/NameplateButton";
import { GetCurrentGameId } from "common/constants/Games";

const classStyles = makeStyles((theme) => {
	return {
		rounded: {
			borderTopLeftRadius: theme.shape.borderRadius,
			borderTopRightRadius: theme.shape.borderRadius,
			borderBottomLeftRadius: theme.shape.borderRadius,
			borderBottomRightRadius: theme.shape.borderRadius,
		},
		groupSection: {
			paddingTop: theme.spacing() * 2,
			paddingBottom: theme.spacing() * 2,
		},
		gridList: {},
		topRow: {
			textAlign: "center",
			fontSize: 12,
			marginTop: 10,
			marginBottom: 15,
		},
		picks: {
			float: "left",
			color: Colors.primary,
			position: "absolute",
		},
		backgroundPanel: {
			padding: 3,
			borderRadius: theme.shape.borderRadius,
			backgroundColor: Colors.panel,
		},
		selected: {
			color: Colors.primary,
		},
		unselected: {
			color: Colors.panel,
		},
		stepLabel: {
			fontWeight: "bold",
			marginRight: 10,
			cursor: "pointer",
		},
		buttonRow: {
			display: "flex",
			justifyContent: "center",
			marginBottom: 12,
		},
		nextButton: {
			color: Colors.panel,
			backgroundColor: Colors.primary,
			borderColor: Colors.white,
			borderWidth: 1,
			borderStyle: "solid",
		},
		backButton: {
			marginRight: 5,
			color: Colors.white,
			backgroundColor: Colors.panel,
			borderColor: Colors.white,
			borderWidth: 1,
			borderStyle: "solid",
		},
		pageIndicators: {
			display: "flex",
			justifyContent: "center",
			marginBottom: 10,
			marginTop: 10,
		},
		filledCircle: {
			backgroundColor: Colors.primary,
			cursor: "default",
		},
		unfilledCircle: {
			backgroundColor: Colors.panel,
		},
		circle: {
			height: 8,
			width: 8,
			borderRadius: "50%",
			marginRight: 5,
			cursor: "pointer",
		},
		notVisible: {
			visibility: "hidden",
		},
		topLeftEl: {
			position: "absolute",
			zIndex: 10,
			color: Colors.white,
			top: -3,
			left: -3,
			width: 0,
			height: 0,
			borderTopLeftRadius: 12,
			borderWidth: 24,
			borderStyle: "solid",
			borderBottomColor: "transparent",
			borderRightColor: "transparent",
			opacity: 1,
		},
		topLeftElTxt: {
			position: "absolute",
			width: 25,
			top: -19,
			left: -19,
		},
		threeWin: {
			borderTopColor: Colors.primary,
			borderLeftColor: Colors.primary,
			color: Colors.greyText,
		},
		correctThreeWins: {
			borderTopColor: Colors.green,
			borderLeftColor: Colors.green,
			color: Colors.panel,
		},
		noWin: {
			borderTopColor: Colors.panel,
			borderLeftColor: Colors.panel,
			color: Colors.primary,
		},
		correctNoWins: {
			borderTopColor: Colors.panel,
			borderLeftColor: Colors.panel,
			color: Colors.green,
		},
	};
});

const propKeys = {
	eventData: StoreKeys.EVENT.EVENTDATA,
	format: StoreKeys.EVENT.FORMAT,
	isEditing: StoreKeys.EVENT.IS_EDITING,
	showPlayers: StoreKeys.EVENT.SHOW_PLAYERS,
	userGroupPicks: StoreKeys.EVENT.USER.GROUP_PICKS,
	officialGroupPicks: StoreKeys.EVENT.OFFICIAL.GROUP_PICKS,
	bracketUser: StoreKeys.EVENT.BRACKET_USER,
	stageIndex: StoreKeys.EVENT.STAGE_INDEX,
	step: StoreKeys.EVENT.STEP,
	showOfficial: StoreKeys.EVENT.SHOW_OFFICIAL,
	games: StoreKeys.GAMES,
};

/**
 * @typedef SwissStageRendererCProps
 * @type {object}
 * @property {any} eventData
 * @property {EventFormatData} format
 * @property {boolean} isEditing
 * @property {boolean} showPlayers
 * @property {GroupStagePick[]} userGroupPicks
 * @property {GroupStagePick[]} officialGroupPicks
 * @property {any} bracketUser
 * @property {number} stageIndex
 * @property {number} step
 * @property {boolean} showOfficial
 * @property {number} width
 * @property {any[]} games
 */

/**
 * @param {SwissStageRendererCProps} props
 */
function SwissStageRendererC({
	eventData,
	format,
	isEditing,
	showPlayers,
	userGroupPicks,
	officialGroupPicks,
	bracketUser,
	stageIndex,
	step,
	showOfficial,
	width,
	games,
}) {
	const classNames = classStyles();
	const [showingOfficial, setShowingOfficial] = React.useState(undefined);
	const [bracketUserId, setBracketUserId] = React.useState(undefined);
	const [currentStageId, setCurrentStageId] = React.useState(0);
	const [teamsSelected, setTeamsSelected] = React.useState({
		advancing: [],
		threeWins: [],
		noWins: [],
	});
	const currentStage = format.stages[stageIndex];
	const currentEventStage = eventData.eventStages[stageIndex];
	const currentSubstage = eventData.eventStages[stageIndex].eventSubstage[0];
	const gameId = GetCurrentGameId();
	const currentGame = games.find((g) => g.id === gameId);

	officialGroupPicks = officialGroupPicks.filter(
		(ogp) => ogp.eventStageId === currentEventStage.id
	);
	userGroupPicks = userGroupPicks.filter(
		(ugp) => ugp.eventStageId === currentEventStage.id
	);

	React.useEffect(() => {
		let differentUser = bracketUserId !== bracketUser?.userId;
		let differentStage = currentStageId !== currentEventStage?.id;
		if (differentStage) {
			setCurrentStageId(currentEventStage.id);
		}

		if (showOfficial !== showingOfficial) {
			setShowingOfficial(showOfficial);

			const picksToShow = showOfficial ? officialGroupPicks : userGroupPicks;
			setTeamsSelected({
				advancing: picksToShow
					.filter((p) => p.pickTypeId === PickTypes.Advancing)
					.map((p) => p.teamId),
				threeWins: picksToShow
					.filter((p) => p.pickTypeId === PickTypes.ThreeWins)
					.map((p) => p.teamId),
				noWins: picksToShow
					.filter((p) => p.pickTypeId === PickTypes.NoWins)
					.map((p) => p.teamId),
			});
		} else if (
			differentUser ||
			differentStage ||
			(userGroupPicks?.length &&
				!teamsSelected?.advancing?.length &&
				!teamsSelected?.threeWins?.length &&
				!teamsSelected?.noWins?.length)
		) {
			setBracketUserId(bracketUser?.userId);
			setTeamsSelected({
				advancing: userGroupPicks
					.filter((p) => p.pickTypeId === PickTypes.Advancing)
					.map((p) => p.teamId),
				threeWins: userGroupPicks
					.filter((p) => p.pickTypeId === PickTypes.ThreeWins)
					.map((p) => p.teamId),
				noWins: userGroupPicks
					.filter((p) => p.pickTypeId === PickTypes.NoWins)
					.map((p) => p.teamId),
			});
		}
	}, [
		isEditing,
		userGroupPicks,
		bracketUserId,
		bracketUser?.id,
		teamsSelected?.noWins?.length,
		teamsSelected?.threeWins?.length,
		teamsSelected?.advancing?.length,
		showOfficial,
		stageIndex,
		currentStageId,
	]);

	/**
	 *
	 * @param {number} teamId
	 * @param {boolean} selected
	 */
	const toggleNoWin = (teamId, selected) => {
		let newAry = [...teamsSelected.noWins];
		const countAdvancing = newAry.length;
		if (selected) {
			if (
				countAdvancing ===
				Number(currentStage.swissStageDetails.cleanSweepLoserCount)
			) {
				return;
			} else {
				newAry.push(teamId);
				setTeamsSelected({
					...teamsSelected,
					noWins: newAry,
				});
			}
		} else {
			const idx = newAry.indexOf(teamId);
			newAry.splice(idx, 1);
			setTeamsSelected({
				...teamsSelected,
				noWins: newAry,
			});
		}
	};
	/**
	 *
	 * @param {number} teamId
	 * @param {boolean} selected
	 */
	const toggleThreeWin = (teamId, selected) => {
		let newAry = [...teamsSelected.threeWins];
		const countAdvancing = newAry.length;
		if (selected) {
			if (
				countAdvancing ===
				Number(currentStage.swissStageDetails.cleanSweepWinnerCount)
			) {
				return;
			} else {
				newAry.push(teamId);
				setTeamsSelected({
					...teamsSelected,
					threeWins: newAry,
				});
			}
		} else {
			const idx = newAry.indexOf(teamId);
			newAry.splice(idx, 1);
			setTeamsSelected({
				...teamsSelected,
				threeWins: newAry,
			});
		}
	};
	/**
	 *
	 * @param {number} teamId
	 * @param {boolean} isAdvancing
	 */
	const toggleAdvancing = (teamId, isAdvancing) => {
		if (!UserManager.isLoggedIn()) {
			ModalHelper.openModal(<AccountRequired />);
			return;
		}

		let newAry = [...teamsSelected.advancing];
		const countAdvancing = newAry.length;
		if (isAdvancing) {
			if (
				countAdvancing >= Number(currentStage.swissStageDetails.advancingCount)
			) {
				return;
			} else {
				const noWinTeams = teamsSelected.noWins;
				const noneIdx = noWinTeams.indexOf(teamId);
				if (noneIdx > -1) {
					noWinTeams.splice(noneIdx, 1);
				}
				newAry.push(teamId);
				setTeamsSelected({
					...teamsSelected,
					noWins: noWinTeams,
					advancing: newAry,
				});
			}
		} else {
			const threeWinTeams = teamsSelected.threeWins;
			const threeIdx = threeWinTeams.indexOf(teamId);
			if (threeIdx > -1) {
				threeWinTeams.splice(threeIdx, 1);
			}
			const idx = newAry.indexOf(teamId);
			newAry.splice(idx, 1);
			setTeamsSelected({
				...teamsSelected,
				threeWins: threeWinTeams,
				advancing: newAry,
			});
		}
	};

	const teamClicked = (teamId, selected) => {
		if (step === 0) {
			toggleAdvancing(teamId, selected);
		}
		if (step === 1) {
			toggleThreeWin(teamId, selected);
		}
		if (step === 2) {
			toggleNoWin(teamId, selected);
		}
	};

	const renderTeam = (index, team) => {
		const isAdvancing = teamsSelected.advancing.indexOf(team.id) > -1;
		const notVisible =
			isEditing &&
			((step === 1 && teamsSelected.advancing.indexOf(team.id) < 0) ||
				(step === 2 && teamsSelected.advancing.indexOf(team.id) > -1));
		let className = [classNames.groupSection, classNames.rounded];
		if (notVisible) {
			className.push(classNames.notVisible);
		}

		let selected = false;
		let topLeftEl = null;
		const inThreeWin = teamsSelected.threeWins.indexOf(team.id) > -1;
		const inNoWin = teamsSelected.noWins.indexOf(team.id) > -1;

		if (!isEditing) {
			if (inThreeWin) {
				const correct =
					!showOfficial &&
					officialGroupPicks.filter(
						(pick) =>
							pick.pickTypeId === PickTypes.ThreeWins && pick.teamId === team.id
					).length > 0;
				topLeftEl = (
					<div
						className={
							classNames.topLeftEl +
							" " +
							(correct ? classNames.correctThreeWins : classNames.threeWin)
						}
					>
						<span className={classNames.topLeftElTxt}>3-0</span>
					</div>
				);
			} else if (inNoWin) {
				const correct =
					!showOfficial &&
					officialGroupPicks.filter(
						(pick) =>
							pick.pickTypeId === PickTypes.NoWins && pick.teamId === team.id
					).length > 0;
				topLeftEl = (
					<div
						className={
							classNames.topLeftEl +
							" " +
							(correct ? classNames.correctNoWins : classNames.noWin)
						}
					>
						<span className={classNames.topLeftElTxt}>0-3</span>
					</div>
				);
			}
		} else {
			switch (step) {
				case 0:
					selected = isAdvancing;
					break;
				case 1:
					const inThreeWin = teamsSelected.threeWins.indexOf(team.id) > -1;
					selected = inThreeWin;
					if (inThreeWin) {
						topLeftEl = (
							<div className={classNames.topLeftEl + " " + classNames.threeWin}>
								<span className={classNames.topLeftElTxt}>3-0</span>
							</div>
						);
					}
					break;
				case 2:
					const inNoWin = teamsSelected.noWins.indexOf(team.id) > -1;
					selected = inNoWin;
					if (inNoWin) {
						topLeftEl = (
							<div className={classNames.topLeftEl + " " + classNames.noWin}>
								<span className={classNames.topLeftElTxt}>0-3</span>
							</div>
						);
					}
					break;
				default:
					break;
			}
		}

		return (
			<ImageListItem className={className.join(" ")} key={index}>
				<GroupStageTeam
					team={team}
					step={step}
					isAdvancing={isAdvancing}
					isEliminated={!isEditing && !isAdvancing}
					isNoWin={inNoWin}
					enableAdvancingStepOne={true}
					toggleAdvancing={(teamId) => {
						if (!isEditing) {
							return;
						}

						teamClicked(teamId, !selected);
					}}
					toggleEliminated={(teamId) => {
						if (!isEditing) {
							return;
						}

						teamClicked(teamId, !selected);
					}}
					topLeftEl={topLeftEl}
					isEditing={isEditing}
					showPlayers={showPlayers}
					officialGroupPicks={officialGroupPicks}
					showOfficial={showOfficial}
				/>
			</ImageListItem>
		);
	};

	const getInstructions = () => {
		switch (step) {
			case 0:
				return `PICK THE ${currentStage.swissStageDetails.advancingCount} ${
					currentStage.swissStageDetails.advancingCount > 1 ? "TEAMS" : "TEAM"
				} ADVANCING TO NEXT STAGE`;
			case 1:
				return `PICK THE ${
					currentStage.swissStageDetails.cleanSweepWinnerCount
				} ${
					currentStage.swissStageDetails.cleanSweepWinnerCount > 1
						? "TEAMS"
						: "TEAM"
				} ADVANCING UNDEFEATED 3-0`;
			case 2:
				return `PICK THE ${
					currentStage.swissStageDetails.cleanSweepLoserCount
				} ${
					currentStage.swissStageDetails.cleanSweepLoserCount > 1
						? "TEAMS"
						: "TEAM"
				} THAT WILL BE ELIMINATED 0-3`;
			default:
				break;
		}
	};

	const persistStateToStore = async () => {
		// Get prexisting picks.
		/** @type {GroupStagePick[]} */
		const finalPicks = Store.get(StoreKeys.EVENT.USER.GROUP_PICKS).filter(
			(ugp) => ugp.eventStageId !== currentEventStage.id
		);

		for (const advancingTeamId of teamsSelected.advancing) {
			finalPicks.push({
				teamId: advancingTeamId,
				pickTypeId: PickTypes.Advancing,
				eventStageId: currentEventStage.id,
				eventSubstageId: currentSubstage.id,
			});
		}
		for (const threeWinTeamId of teamsSelected.threeWins) {
			finalPicks.push({
				teamId: threeWinTeamId,
				pickTypeId: PickTypes.ThreeWins,
				eventStageId: currentEventStage.id,
				eventSubstageId: currentSubstage.id,
			});
		}
		for (const noWinTeamId of teamsSelected.noWins) {
			finalPicks.push({
				teamId: noWinTeamId,
				pickTypeId: PickTypes.NoWins,
				eventStageId: currentEventStage.id,
				eventSubstageId: currentSubstage.id,
			});
		}

		Store.set(StoreKeys.EVENT.USER.GROUP_PICKS, finalPicks);
	};

	const calculateNextStep = () => {
		let nextStep = step;

		// Moving from Pick Advancing
		if (step === 0) {
			if (Number(currentStage.swissStageDetails.cleanSweepWinnerCount) > 0) {
				nextStep = 1;
			} else if (
				Number(currentStage.swissStageDetails.cleanSweepLoserCount) > 0
			) {
				nextStep = 2;
			} else {
				nextStep = 3;
			}
		} else if (step === 1) {
			if (Number(currentStage.swissStageDetails.cleanSweepLoserCount) > 0) {
				nextStep = 2;
			} else {
				nextStep = 3;
			}
		} else if (step === 2) {
			nextStep = 3;
		}

		return nextStep;
	};

	const next = () => {
		let nextStep = calculateNextStep();

		let maxStep = 3;
		if (nextStep === maxStep) {
			persistStateToStore();
			EventHelper.submitPicks(currentGame).then(() => {
				const updates = {};
				updates[StoreKeys.EVENT.IS_EDITING] = false;
				updates[StoreKeys.EVENT.STEP] = 0;
				Store.setMany(updates);
			});
		} else {
			if (nextStep > maxStep) {
				nextStep = maxStep;
			}
			Store.set(StoreKeys.EVENT.STEP, nextStep);
		}
	};

	const back = () => {
		let backStep = step - 1;
		if (backStep < 0) {
			backStep = 0;
		}
		Store.set(StoreKeys.EVENT.STEP, backStep);
	};

	const nextIsDisabled = () => {
		if (step === 0) {
			return (
				teamsSelected.advancing.length <
				Number(currentStage.swissStageDetails.advancingCount)
			);
		} else if (step === 1) {
			return (
				teamsSelected.threeWins.length <
				Number(currentStage.swissStageDetails.cleanSweepWinnerCount)
			);
		} else if (step === 2) {
			return (
				teamsSelected.noWins.length <
				Number(currentStage.swissStageDetails.cleanSweepLoserCount)
			);
		}
		return false;
	};

	const nextDisabled = nextIsDisabled();
	const isSubmit = calculateNextStep() === 3;
	const nextText = calculateNextStep() === 3 ? "SUBMIT" : "NEXT";
	const teams = eventData.teams.filter(
		(t) => t.eventSubstageId === currentSubstage.id
	);
	return (
		<>
			<div
				style={{
					width: Math.max(width, 390),
					display: "flex",
					justifyContent: "center",
				}}
			>
				<div style={{ width: 390, marginTop: 8 }}>
					<Instructions isEditing={isEditing} text={getInstructions()} />
					<ImageList
						className={
							classNames.gridList +
							" " +
							(!isEditing ? classNames.backgroundPanel : "")
						}
						rowHeight={90}
						cols={4}
					>
						{teams.map((team, index) => renderTeam(index, team))}
					</ImageList>
					{isEditing && (
						<>
							<PageIndicators step={step} totalSteps={3} />
							<div className={classNames.buttonRow}>
								<div className="inner">
									<NameplateButton
										onClick={back}
										buttonText={"BACK"}
										disabled={step === 0}
									/>
									<NameplateButton
										onClick={next}
										buttonText={nextText}
										nameplateId={isSubmit ? 32 : null}
										disabled={nextDisabled}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default function SwissStageRenderer(props) {
	return connect(<SwissStageRendererC />, propKeys, props);
}
