import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";
import { TrophyTypes } from "common/constants/TrophyTypes";

const useStyles = makeStyles((theme) => ({
	tableContainer: {
		display: "flex",
		justifyContent: "center",
	},
	tableRow: {
		textAlign: "center",
	},
	miniAward: {
		display: "block",
		borderRadius: "50%",
		overflow: "hidden",
		height: 20,
	},
	materialBlock: {
		height: 20,
	},
	gameIcon: {
		height: 20,
		width: 20,
	},
}));

export default function AwardSummary({
	awards,
	games,
	showEmptyRows,
	hideIcons,
}) {
	const styles = useStyles();

	if (!awards) {
		return <></>;
	}

	return (
		<div className={styles.tableContainer}>
			<table>
				<tbody>
					<tr>
						{!hideIcons && <td></td>}
						<td>
							<span className={styles.miniAward}>
								<img
									className={styles.materialBlock}
									title="Diamond Award Count"
									alt=""
									src="/images/DiamondBlock.svg"
								/>
							</span>
						</td>
						<td>
							<span className={styles.miniAward}>
								<img
									className={styles.materialBlock}
									title="Platinum Award Count"
									alt=""
									src="/images/PlatinumBlock.svg"
								/>
							</span>
						</td>
						<td>
							<span className={styles.miniAward}>
								<img
									className={styles.materialBlock}
									title="Gold Award Count"
									alt=""
									src="/images/GoldBlock.svg"
								/>
							</span>
						</td>
						<td>
							<span className={styles.miniAward}>
								<img
									className={styles.materialBlock}
									title="Silver Award Count"
									alt=""
									src="/images/SilverBlock.svg"
								/>
							</span>
						</td>
						<td>
							<span className={styles.miniAward}>
								<img
									className={styles.materialBlock}
									title="Bronze Award Count"
									alt=""
									src="/images/BronzeBlock.svg"
								/>
							</span>
						</td>
						<td>
							<span className={styles.miniAward}>
								<img
									className={styles.materialBlock}
									title="Wood Award Count"
									alt=""
									src="/images/WoodBlock.svg"
								/>
							</span>
						</td>
					</tr>
					{games.map((g) => {
						const gameAwards = awards.filter((a) => a.gameId === g.id);

						const show = gameAwards.length || showEmptyRows;
						if (!show) {
							return <></>;
						}

						const diamondCount = gameAwards.filter(
							(a) => a.trophyTypeId === TrophyTypes.Diamond
						).length;
						const platCount = gameAwards.filter(
							(a) => a.trophyTypeId === TrophyTypes.Platinum
						).length;
						const goldCount = gameAwards.filter(
							(a) => a.trophyTypeId === TrophyTypes.Gold
						).length;
						const silverCount = gameAwards.filter(
							(a) => a.trophyTypeId === TrophyTypes.Silver
						).length;
						const bronzeCount = gameAwards.filter(
							(a) => a.trophyTypeId === TrophyTypes.Bronze
						).length;
						const woodCount = gameAwards.filter(
							(a) => a.trophyTypeId === TrophyTypes.Wood
						).length;
						return (
							<tr className={styles.tableRow}>
								{!hideIcons && (
									<td>
										<span className={styles.gameIcon}>
											<img
												className={styles.gameIcon}
												title={g.name}
												alt=""
												src={g.iconUrl}
											/>
										</span>
									</td>
								)}
								<td style={{ color: Colors.diamond_light }}>{diamondCount}</td>
								<td style={{ color: Colors.platinum_light }}>{platCount}</td>
								<td style={{ color: Colors.gold_light }}>{goldCount}</td>
								<td style={{ color: Colors.silver_light }}>{silverCount}</td>
								<td style={{ color: Colors.bronze_light }}>{bronzeCount}</td>
								<td style={{ color: Colors.wood_light }}>{woodCount}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
