export default class StyleHelper {
	/**
	 * Utility function to add replaceable CSS.
	 * @param {string} styleString
	 * @returns {HTMLStyleElement} The style element.
	 */
	static addStyle(styleString) {
		const style = document.createElement("style");
		document.head.append(style);
		style.textContent = styleString;
		return style;
	}
}
