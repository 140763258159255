import { Hidden, Tab, Tabs } from "@material-ui/core";
import React from "react";
import * as Styles from "./styles";
import useTabList from "./useTabList";
import useTabSelect from "./useTabSelect";

function TopIconMenu({ hasSurvey }) {
  const [tab, setTab] = useTabSelect();
  const tabList = useTabList(
    "large",
    hasSurvey && window.location.pathname.startsWith("/rl")
  );
  const tabClasses = Styles.tab();
  const tabsClasses = Styles.tabs();

  return (
    <>
      <Hidden lgUp smDown>
        <Tabs
          classes={tabsClasses}
          value={tab}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ children: <span /> }}
        >
          {tabList.map((item) => (
            <Tab
              key={`topIconMenuUp-${item.value}`}
              classes={tabClasses}
              label={
                <div>
                  {item.icon}
                  <span>{item.title}</span>
                </div>
              }
              value={item.value}
              title={item.title}
              onClick={() => {
                setTab(item.value);
              }}
            />
          ))}
        </Tabs>
      </Hidden>
      <Hidden mdDown>
        <Tabs
          classes={tabsClasses}
          value={tab}
          centered
          TabIndicatorProps={{ children: <span /> }}
        >
          {tabList.map((item) => (
            <Tab
              key={`topIconMenuDown-${item.value}`}
              classes={tabClasses}
              label={
                <div>
                  {item.icon}
                  <span>{item.title}</span>
                </div>
              }
              value={item.value}
              title={item.title}
              onClick={() => {
                setTab(item.value);
              }}
              style={{ visibility: item.visible ? "visible" : "hidden" }}
            />
          ))}
        </Tabs>
      </Hidden>
    </>
  );
}

export default TopIconMenu;
