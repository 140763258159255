export const Routes = {
  COMMUNITY: "/community",
  LEAGUES: "/leagues",
  PICKEMS: "/pickems",
  RLAWARDS: "/survey",
  TROPHYROOM: "/trophy-room",
};

export const Tabs = {
  COMMUNITY: "community",
  LEAGUES: "leagues",
  PICKEMS: "pickems",
  RLAWARDS: "survey",
  TROPHYROOM: "trophy-room",
};
