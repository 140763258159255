import {
	EventFormatScoringBreakdownGroup,
	EventFormatScoringBreakdownRecord,
} from "common/helpers/eventFormatHelper";
import * as React from "react";
import "./ScorePillDetails.scss";

/**
 * @typedef ScorePillDetailsProps
 * @type {object}
 * @property {EventFormatScoringBreakdownGroup[]} groups
 */

/**
 * @param {ScorePillDetailsProps} props
 * @param {any} bracketScore
 */
export default function ScorePillDetails({ groups, bracketScore }) {
	bracketScore = bracketScore ?? {};
	const scoreBreakdown = bracketScore?.data
		? JSON.parse(bracketScore?.data)
		: [];

	let league = "";
	let summaryClass = "";
	const rank = bracketScore?.rank ?? 1;
	const percent = bracketScore?.percentile ?? 100;
	const totalScore = bracketScore?.totalScore ?? 0;
	if (rank <= 1) {
		summaryClass = "diamond";
		league = "diamond";
	} else if (rank <= 10) {
		summaryClass = "platinum";
		league = "platinum";
	} else if (percent <= 10) {
		summaryClass = "gold";
		league = "gold";
	} else if (percent <= 30) {
		summaryClass = "silver";
		league = "silver";
	} else if (percent <= 50) {
		summaryClass = "bronze";
		league = "bronze";
	} else {
		summaryClass = "wood";
		league = "wood";
	}

	/**
	 *
	 * @param {EventFormatScoringBreakdownRecord} r
	 * @param {number} i
	 * @returns
	 */
	const row = (r, i, index) => {
		const scoreRecord = scoreBreakdown?.find(
			(s) =>
				s.Name === r.name &&
				(s.StageIndex === undefined || s.StageIndex === index)
		);
		return (
			<tr key={i} className="points">
				<td>
					<label>{r.name}</label>
					<span>{`(+${r.pointsPer} per correct pick)`}</span>
				</td>
				<td>{(scoreRecord ? scoreRecord.Score : 0) ?? 0}</td>
			</tr>
		);
	};

	/**
	 *
	 * @param {EventFormatScoringBreakdownGroup} scoreGroup
	 * @param {number} index
	 * @returns
	 */
	const table = (scoreGroup, index) => {
		return (
			<table key={index}>
				<thead>
					<tr className="stage-divider">
						<th>{scoreGroup.name}</th>
					</tr>
				</thead>
				<tbody>
					{scoreGroup.records.map((r, i) => {
						return row(r, i, index);
					})}
				</tbody>
			</table>
		);
	};

	const tables = groups.map((group, i) => {
		return table(group, i);
	});

	return (
		<div className="score-breakdown">
			<div className="inner">
				<label className="pickstop-color score-header">
					<span>Scoring Breakdown</span>
				</label>
				{tables}
				<div className={"score-summary " + summaryClass}>
					<label className="total-points">Total {totalScore} pts</label>
					<label className="top-100">
						Top {bracketScore ? Math.max(Math.ceil(percent), 1) : 0}%
						{` (${league})`}
					</label>
				</div>
			</div>
		</div>
	);
}
