import { Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import AwardInfo from "./AwardInfo";
import AwardLeaders from "./AwardLeaders";
import RecentAwards from "./RecentAwards";
import YourAwards from "./YourAwards";
import StoreKeys from "common/storeKeys";
import { connect } from "common/connect";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing() * 2,
		width: "100%",
	},
}));

const propKeys = {
	loggedIn: StoreKeys.USER.LOGGED_IN,
};

function TrophyRoomScreenC({ loggedIn }) {
	const styles = useStyles();
	return (
		<Grid className={styles.root} container spacing={3} justifyContent="center">
			{loggedIn && (
				<>
					<Grid container item direction="column" xl={4} lg={6} md={6} sm={12}>
						<YourAwards />
						<AwardInfo />
					</Grid>
					<Grid container item direction="column" xl={4} lg={6} md={6} sm={12}>
						<RecentAwards />
						<AwardLeaders />
					</Grid>
				</>
			)}
			{!loggedIn && (
				<>
					<Grid container item direction="column" xl={4} lg={6} md={6} sm={12}>
						<RecentAwards />
						<AwardInfo />
					</Grid>
					<Grid container item direction="column" xl={4} lg={6} md={6} sm={12}>
						<AwardLeaders />
					</Grid>
				</>
			)}
		</Grid>
	);
}

export default function TrophyRoomScreen(props) {
	return connect(<TrophyRoomScreenC />, propKeys, props);
}
