import ModalHelper from "common/helpers/modalHelper";
import * as React from "react";

import "./CustomModal.scss";

export default function CustomModal(props) {
	React.useEffect(() => {
		const callback = (e) => {
			if (e.key === "Escape") {
				ModalHelper.closeModal();
			}
		};
		document.addEventListener("keydown", callback);

		return () => {
			document.removeEventListener("keydown", callback);
		};
	});

	const clickBackground = (e) => {
		if (
			e.target.className === "overlay" ||
			e.target.className === "overlay-inner"
		) {
			ModalHelper.closeModal();
		}
	};

	return (
		<div className="custom-modal">
			<div className="overlay" onClick={clickBackground}>
				<div className="overlay-inner">{props.children}</div>
			</div>
		</div>
	);
}
