import { Card, Grid, useMediaQuery } from "@material-ui/core";
import * as React from "react";
import "./GamePickemScreen.scss";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ReactComponent as PickstopLogo } from "icons/PickstopLogoGGNew.svg";
import { ReactComponent as BallotBox } from "icons/ballot-box.svg";
import { ReactComponent as MedalWinner } from "icons/medal-winner.svg";
import { ReactComponent as Polygon } from "icons/polygon.svg";
import { ReactComponent as TwitterIcon } from "icons/TwitterIcon.svg";
import { ReactComponent as QuoteStart } from "icons/quote-start.svg";
import { ReactComponent as QuoteEnd } from "icons/quote-end.svg";
import { ReactComponent as DiscordIcon } from "icons/DiscordIcon.svg";
import { ReactComponent as RedditIcon } from "icons/RedditIcon.svg";
import { ReactComponent as InstagramIcon } from "icons/InstagramIcon.svg";
import { changeUrl } from "common/history";
import { Colors } from "styles/Colors";

const useStyles = makeStyles((theme) => ({
	backgroundFill: {
		backgroundColor: "#373737",
	},
	card: {
		backgroundColor: "#4A4A4A",
	},
	lightCard: {
		backgroundColor: "#D9D9D9",
		color: "rgba(0, 0, 0, 0.9)",
	},
	whiteCard: {
		backgroundColor: Colors.softWhite,
		color: Colors.panel,
	},
	welcomePickstopHeaderContainer: {
		marginBottom: 24,
	},
	welcomePickstop: {
		color: "#FFFFFF",
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal",
		letterSpacing: 2.56,
		alignSelf: "stretch",
	},
	welcomePickstopText: {
		color: "#FBFBFB",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "177%",
	},
	shapeContainer: {
		display: "flex",
		marginTop: 32,
		marginBottom: 32,
		alignItems: "center",
	},
	orangeLine: {
		height: 6,
		backgroundColor: "#FFB300",
		borderRadius: 3,
		marginRight: 24,
	},
	orangeLineGrow: {
		display: "flex",
		flexGrow: 1,
		height: 6,
		backgroundColor: "#FFB300",
		borderRadius: 3,
		marginLeft: 24,
		marginRight: 108,
		marginBottom: 24,
	},
	quoteText: {
		marginLeft: 40,
		marginRight: 40,
		fontFamily: "Poppins",
	},
	aspectRatio: {
		aspectRatio: 1.77,
	},
	socialLink: {
		color: Colors.pickstop,
		fontFamily: "Poppins",

		"& a": {
			display: "block",
		},
	},
	mugShot: {
		width: "50%",
		borderRadius: "50%",
		minWidth: 350,
		maxWidth: 500,
	},
}));

export default function AboutUsScreenWidth() {
	const classes = useStyles();

	const logoWidth = Math.max(Math.min(window.innerWidth / 2, 920), 300);
	const logoHeight = logoWidth * 0.134;

	const theme = useTheme();
	const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedium = useMediaQuery(theme.breakpoints.down("md"));
	// const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
	const isXL = useMediaQuery(theme.breakpoints.up("xl"));
	const fontUnit = isSmall ? 5 : isMedium ? 6 : isXL ? 8 : 7;

	const cardMargin = isSmall ? 6 : isMedium ? 10 : isXL ? 40 : 20;
	const cardPadding = isSmall ? 16 : isMedium ? 16 : isXL ? 60 : 32;
	const cardRadius = isSmall ? 24 : isMedium ? 24 : isXL ? 56 : 32;
	const orangeLineWidth = isSmall ? 200 : isMedium ? 350 : isXL ? 400 : 400;
	const pentagonIconWidth = isSmall ? 28 : isMedium ? 28 : isXL ? 38 : 32;
	const pentagonIconHeight = pentagonIconWidth * 1.11;

	const detailCardMargin = isSmall ? 8 : isMedium ? 16 : isXL ? 50 : 25;
	const detailCardPadding = isSmall ? 16 : isMedium ? 16 : isXL ? 32 : 16;
	const detailCardRadius = isSmall ? 24 : isMedium ? 24 : isXL ? 48 : 36;
	const detailCardWidth = isSmall ? 360 : isMedium ? 432 : isXL ? 432 : 432;
	const detailCardIconWidth = isSmall ? 64 : isMedium ? 64 : isXL ? 87 : 87;

	const quoteCardMargin = isSmall ? 8 : isMedium ? 16 : isXL ? 50 : 25;
	const quoteCardPadding = isSmall ? 16 : isMedium ? 16 : isXL ? 32 : 16;
	const quoteCardRadius = isSmall ? 24 : isMedium ? 24 : isXL ? 48 : 36;
	const quoteCardWidth = isSmall ? 360 : isMedium ? 432 : isXL ? 540 : 540;

	const socialIconWidth = isSmall ? 32 : isMedium ? 48 : isXL ? 64 : 64;

	const socialLinks = isSmall
		? [
				{
					name: "Discord Server",
					icon: <DiscordIcon />,
					link: "https://discord.gg/fQSP624Mqf",
				},
				{
					name: "Reddit Account",
					icon: <RedditIcon />,
					link: "https://www.reddit.com/user/pickstopgg/",
				},
				{
					name: "RL Twitter",
					icon: <TwitterIcon />,
					link: "https://twitter.com/pickstoprl",
				},
				{
					name: "RL Instagram",
					icon: <InstagramIcon />,
					link: "https://www.instagram.com/pickstoprl/",
				},
				{
					name: "CS Twitter",
					icon: <TwitterIcon />,
					link: "https://twitter.com/PickstopCS",
				},
				{
					name: "CS Instagram",
					icon: <InstagramIcon />,
					link: "https://www.instagram.com/pickstopcs/",
				},
		  ]
		: [
				{
					name: "Discord Server",
					icon: <DiscordIcon />,
					link: "https://discord.gg/fQSP624Mqf",
				},
				{
					name: "RL Twitter",
					icon: <TwitterIcon />,
					link: "https://twitter.com/pickstoprl",
				},
				{
					name: "RL Instagram",
					icon: <InstagramIcon />,
					link: "https://www.instagram.com/pickstoprl/",
				},
				{
					name: "Reddit Account",
					icon: <RedditIcon />,
					link: "https://www.reddit.com/user/pickstopgg/",
				},
				{
					name: "CS Twitter",
					icon: <TwitterIcon />,
					link: "https://twitter.com/PickstopCS",
				},
				{
					name: "CS Instagram",
					icon: <InstagramIcon />,
					link: "https://www.instagram.com/pickstopcs/",
				},
		  ];

	const quoteCardContainerStyle = {
		position: "relative",
		flexDirection: isSmall ? "row-reverse" : "row",
	};

	const moveToHome = () => {
		changeUrl("/");
	};

	return (
		<div className={classes.backgroundFill}>
			<Grid alignItems="center" direction="column" container>
				<Grid container item xs={12} justifyContent="center">
					<Grid item xs={12} xl={10}>
						<Card
							className={classes.card}
							style={{
								padding: cardPadding,
								margin: cardMargin,
								borderRadius: cardRadius,
							}}
						>
							<div onClick={moveToHome} style={{ cursor: "pointer" }}>
								<PickstopLogo width={logoWidth} height={logoHeight} />
							</div>
							<div className={classes.shapeContainer}>
								<div
									className={classes.orangeLine}
									style={{ width: orangeLineWidth }}
								></div>{" "}
								<Polygon
									width={pentagonIconWidth}
									height={pentagonIconHeight}
								/>
							</div>
							<div className={classes.welcomePickstopHeaderContainer}>
								<label
									className={classes.welcomePickstop}
									style={{ fontSize: fontUnit * 8 }}
								>
									Welcome to Pickstop
								</label>
							</div>
							<div
								className={classes.welcomePickstopText}
								style={{ fontSize: fontUnit * 3 }}
							>
								Since 2019, we’ve been building a pick’em platform for esports
								fans to predict the outcomes of their favorite events, using
								competition with fellow fans to make the viewing experience more
								engaging.
							</div>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={isSmall ? "center" : "flex-end"}
					>
						<Card
							className={classes.lightCard}
							style={{
								padding: detailCardPadding,
								margin: detailCardMargin,
								borderRadius: detailCardRadius,

								width: detailCardWidth,
							}}
						>
							<Grid container alignItems="center">
								<Grid
									item
									container
									xs={3}
									justifyContent="center"
									height="100%"
								>
									<BallotBox width={detailCardIconWidth} />
								</Grid>
								<Grid item xs={9}>
									<div
										style={{
											fontSize: fontUnit * 3,
											marginBottom: 8,
											fontWeight: 500,
										}}
									>
										Pick’ems.
									</div>
									<div
										style={{ fontSize: fontUnit * 2.5, fontFamily: "Poppins" }}
									>
										Before and during a tournament, fill out your brackets for
										each stage of the event as soon as the matchups are
										announced.
									</div>
								</Grid>
							</Grid>
						</Card>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						container
						justifyContent={isSmall ? "center" : "flex-start"}
					>
						<Card
							className={classes.lightCard}
							style={{
								padding: detailCardPadding,
								margin: detailCardMargin,
								borderRadius: detailCardRadius,
								fontSize: fontUnit * 2.5,
								width: detailCardWidth,
							}}
						>
							<Grid container alignItems="center" style={{ height: "100%" }}>
								<Grid
									item
									xs={3}
									container
									justifyContent="center"
									alignItems="center"
									style={{ height: "100%" }}
								>
									<MedalWinner width={detailCardIconWidth} />
								</Grid>
								<Grid item xs={9} style={{ height: "100%" }}>
									<div
										style={{
											fontSize: fontUnit * 3,
											marginBottom: 8,
											fontWeight: 500,
										}}
									>
										Ranked.
									</div>
									<div
										style={{ fontSize: fontUnit * 2.5, fontFamily: "Poppins" }}
									>
										Earn points for correct match results, rank on leaderboards,
										and win awards in pick'em leagues.
									</div>
								</Grid>
							</Grid>
						</Card>
					</Grid>
					<Grid item xs={10} container alignItems="center">
						<label
							style={{
								color: Colors.pickstop,
								fontSize: fontUnit * 4,
								fontWeight: 600,
								fontFamily: "Poppins",
								marginBottom: 24,
							}}
						>
							Our Founders
						</label>
						<div className={classes.orangeLineGrow}></div>
					</Grid>

					<Grid item xs={12} container justifyContent="center">
						<Grid item xs={12} container justifyContent="center">
							<Grid
								container
								justifyContent="space-evenly"
								alignContent="center"
								style={quoteCardContainerStyle}
							>
								<Grid container item xs={12} lg={5} justifyContent="center">
									<img
										src="/images/DanAwesomeMug.png"
										alt="Dan Sellers CEO"
										className={classes.mugShot}
									/>
									<Card
										className={classes.whiteCard}
										style={{
											padding: quoteCardPadding,
											margin: quoteCardMargin,
											borderRadius: quoteCardRadius,
											width: quoteCardWidth,
											position: "relative",
											top: -64,
										}}
									>
										<Grid container style={{ marginBottom: 16 }}>
											<Grid item style={{ flexGrow: 1 }}>
												<div style={{ fontSize: fontUnit * 3 }}>
													Dan Sellers
												</div>
												<div style={{ fontSize: fontUnit * 2 }}>
													Co-Founder & CEO
												</div>
											</Grid>
											<Grid item>
												<a
													href="https://twitter.com/WorldCarWow"
													target="_blank"
													rel="noreferrer"
												>
													<TwitterIcon
														width={socialIconWidth}
														height={socialIconWidth}
													/>
												</a>
											</Grid>
										</Grid>
										<Grid>
											<div>
												<QuoteStart />
											</div>
											<div
												className={classes.quoteText}
												style={{ fontSize: fontUnit * 2.5 }}
											>
												Ex-Competitive Rocket League player and tournament organizer
												since 2015. Has been hosting esports LAN and pick’em
												events since 2018.
											</div>
											<div style={{ float: "right" }}>
												<QuoteEnd />
											</div>
										</Grid>
									</Card>
								</Grid>
								<Grid container item xs={12} lg={5} justifyContent="center">
									<img
										src="/images/BenBetterMug.png"
										alt="Dan Sellers CEO"
										className={classes.mugShot}
									/>
									<Card
										className={classes.whiteCard}
										style={{
											padding: quoteCardPadding,
											margin: quoteCardMargin,
											borderRadius: quoteCardRadius,
											width: quoteCardWidth,
											position: "relative",
											top: -64,
										}}
									>
										<Grid container style={{ marginBottom: 16 }}>
											<Grid item>
												<div style={{ fontSize: fontUnit * 3 }}>
													Ben Randall
												</div>
												<div style={{ fontSize: fontUnit * 2 }}>
													Co-Founder & Lead Developer
												</div>
											</Grid>
											<Grid item>
												<svg
													width={socialIconWidth}
													height={socialIconWidth}
												></svg>
											</Grid>
										</Grid>
										<Grid>
											<div>
												<QuoteStart />
											</div>
											<div
												className={classes.quoteText}
												style={{ fontSize: fontUnit * 2.5 }}
											>
												Veteran Rocket League player with over a decade of
												high-level full stack coding experience building web
												platforms.
											</div>
											<div style={{ float: "right" }}>
												<QuoteEnd />
											</div>
										</Grid>
									</Card>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={10} container alignItems="center">
							<label
								style={{
									color: Colors.pickstop,
									fontSize: fontUnit * 4,
									fontWeight: 600,
									fontFamily: "Poppins",
									marginBottom: 24,
								}}
							>
								CEO Interview (Full Story of Pickstop)
							</label>
							{!isExtraSmall && <div className={classes.orangeLineGrow}></div>}
						</Grid>
						<Grid item xs={7} container justifyContent="center">
							<iframe
								src="https://www.youtube.com/embed/_C5vm-GNQPY?si=DoGIZMSTG1yQ-tP2"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowfullscreen
								className={classes.aspectRatio}
								style={{
									borderRadius: detailCardRadius,
									width: "100%",
									height: "100%",
									maxWidth: 1024,
								}}
							></iframe>
						</Grid>
						<Grid item xs={10} container alignItems="center">
							<label
								style={{
									color: Colors.pickstop,
									fontSize: fontUnit * 4,
									fontWeight: 600,
									fontFamily: "Poppins",
									marginBottom: 24,
								}}
							>
								Socials
							</label>
							<div className={classes.orangeLineGrow}></div>
						</Grid>
						<Grid
							item
							xs={10}
							container
							alignItems="center"
							style={{ marginBottom: 24 }}
						>
							{socialLinks.map((link) => (
								<Grid
									item
									container
									md={4}
									xs={6}
									className={classes.socialLink}
									alignItems="center"
									justifyContent="center"
								>
									<Grid
										item
										xs={8}
										style={{ fontSize: fontUnit * 3, textAlign: "center" }}
									>
										{link.name}
									</Grid>
									<Grid item xs={4}>
										<a href={link.link} target="_blank"
											rel="noreferrer">
											{React.cloneElement(link.icon, {
												width: socialIconWidth,
												height: socialIconWidth,
											})}
										</a>
									</Grid>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
