import { SetLoading } from "common/components/Loader/Loader";
import { GetCurrentGamePrefix } from "common/constants/Games";
import { LocalStorageKeys } from "common/constants/StorageKeys";
import { changeUrl } from "common/history";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import UserManager from "common/userManager";
import React from "react";
import { googleSignIn } from "./api";

export default class SignInGoogle extends React.Component {
	async componentDidMount() {
		SetLoading(true, "Signing in with Google");

		const code = this.props.location.search.substring(6);
		try {
			const result = await googleSignIn(code);
			UserManager.handlePostLogin(result);
			const route = GetCurrentGamePrefix();

			SetLoading(false);
			if (result.usernameIsEmail) {
				changeUrl(route + "/event");
			} else if (result.created) {
				Store.set(StoreKeys.LOGIN_PROVIDER, "google");
				changeUrl("/pick-username");
			} else {
				const redirect = localStorage.getItem(LocalStorageKeys.POSTLOGINURL);
				if (redirect) {
					localStorage.removeItem(LocalStorageKeys.POSTLOGINURL);
					changeUrl(redirect);
				} else {
					changeUrl(route + "/event");
				}
			}
		} catch (error) {
			SetLoading(false);
			const route = GetCurrentGamePrefix();
			changeUrl(route + "/event");
		}
	}

	render() {
		return <></>;
	}
}
