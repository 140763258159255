import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import {
	TextField,
	Table,
	TableBody,
	TableCell,
	TableRow,
	InputAdornment,
	Grid,
	Checkbox,
} from "@material-ui/core";
import { LockOpen, Search, VpnKey } from "@material-ui/icons";
import LeagueService from "services/LeagueService";
import FatButton from "common/components/FatButton/FatButton";
import NameplateService from "services/NameplateService";
import { SetLoading } from "common/components/Loader/Loader";
import ModalHelper from "common/helpers/modalHelper";
import { loadUserLeagueInfo } from "common/helpers/leagueHelper";
import LeagueNameplateSelectorModal from "./LeagueNameplateSelectorModal";
import LeagueNameplate from "common/components/LeagueNameplate/LeagueNameplate";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import { ReactComponent as Crown } from "icons/Crown.svg";
import SuccessModal from "common/components/Modals/SuccessModal/SuccessModal";

export default function CreateEditLeagueModal({ isEdit, league }) {
	const [name, setName] = React.useState(league?.name ?? "");
	const [password, setPassword] = React.useState(
		league?.hasPassword ? "********" : ""
	);
	const [confirmPassword, setConfirmPassword] = React.useState(
		league?.hasPassword ? "********" : ""
	);
	const [requireDiscord, setRequireDiscord] = React.useState(
		league?.requireDiscord ?? false
	);
	const [requireReddit, setRequireReddit] = React.useState(
		league?.requireReddit ?? false
	);
	const [nameplateId, setNameplateId] = React.useState(league?.nameplateId);

	const setNameplate = (nameplate) => {
		ModalHelper.closeModal();
		setNameplateId(nameplate.id);
	};

	const changeNameplate = async () => {
		SetLoading(true, "Loading Nameplates");
		const nameplates = await NameplateService.getUserNameplates();
		SetLoading(false);
		ModalHelper.openModal(
			<LeagueNameplateSelectorModal
				leagueName={name}
				nameplates={nameplates}
				onSelect={setNameplate.bind(this)}
			/>
		);
	};

	const save = async () => {
		SetLoading(true, "Saving League");
		try {
			await LeagueService.createOrUpdateLeague(
				isEdit ? league.id : null,
				name,
				password,
				confirmPassword,
				nameplateId,
				requireDiscord,
				requireReddit
			);
			ModalHelper.closeAllModals();
			await loadUserLeagueInfo();
			SetLoading(false);
			ModalHelper.openModal(<SuccessModal message={"League Saved!"} />);
		} catch (error) {
			SetLoading(false);
			console.log(error);
		}
	};

	return (
		<CustomModal>
			<ModalPanel>
				<ModalBody>
					<ContentHeader
						icon={<Crown />}
						text={`${isEdit ? "Edit" : "Create"} League`}
					/>
					<Grid
						container
						justifyContent="space-evenly"
						style={{ margin: "10px 0" }}
					>
						<LeagueNameplate
							name={name}
							nameplateId={nameplateId}
							disableClick={true}
						/>
					</Grid>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>League Name:</TableCell>
								<TableCell>
									<TextField
										id="name"
										onChange={(e) => setName(e.target.value)}
										defaultValue={league?.name ?? ""}
										variant="outlined"
										required
										autoComplete="off"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Search />
												</InputAdornment>
											),
										}}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Password (optional):</TableCell>
								<TableCell>
									<TextField
										id="password"
										onChange={(e) => setPassword(e.target.value)}
										defaultValue={league?.hasPassword ? "********" : ""}
										variant="outlined"
										autoComplete="off"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<LockOpen />
												</InputAdornment>
											),
										}}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Confirm Password:</TableCell>
								<TableCell>
									<TextField
										id="password"
										onChange={(e) => setConfirmPassword(e.target.value)}
										defaultValue={league?.hasPassword ? "********" : ""}
										variant="outlined"
										autoComplete="off"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<VpnKey />
												</InputAdornment>
											),
										}}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Require Discord Login:</TableCell>
								<TableCell>
									<Checkbox
										checked={requireDiscord}
										onChange={(e) => {
											setRequireDiscord(e.target.checked);
										}}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Require Reddit Login:</TableCell>
								<TableCell>
									<Checkbox
										checked={requireReddit}
										onChange={(e) => {
											setRequireReddit(e.target.checked);
										}}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Grid
						container
						justifyContent="space-evenly"
						style={{ marginTop: 8 }}
					>
						<FatButton text="Change Nameplate" onClick={changeNameplate} />
						<FatButton
							text={isEdit ? "Save League" : "Create League"}
							onClick={save}
							primary
						/>
					</Grid>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
