import axiosWrapper from "common/axiosWrapper";

const baseUrl = "/api/lookup";

export default class LookupService {
	/**
	 * @returns {Promise<Array<any>>}
	 */
	static async getGames() {
		return axiosWrapper.getCached(`${baseUrl}/games`);
	}
	/**
	 * @returns {Promise<Array<any>>}
	 */
	static getPatreonLevels = async () => {
		return await axiosWrapper.getCached(`${baseUrl}/patreonlevels`);
	};
}
