import * as React from "react";
import * as $ from "jquery";
import axiosWrapper from "common/axiosWrapper";
import Timer from "common/components/Timer/Timer";
import { connect } from "common/connect";
import EventHelper from "common/helpers/eventHelper/eventHelper";
import { changeUrl } from "common/history";
import PicksHelper from "common/picksHelper";
import StoreKeys from "common/storeKeys";
import { ReactComponent as CarotLeft } from "icons/Carot_Left.svg";
import { ReactComponent as CarotRight } from "icons/Carot_Right.svg";
import Store from "common/store";
import Leaderboard from "common/components/Leaderboard/Leaderboard";
import { withRouter } from "react-router-dom";
import UserManager from "common/userManager";
import UserCard from "common/components/UserCard";
import ModalHelper from "common/helpers/modalHelper";
import { GetCurrentGamePrefix } from "common/constants/Games";

const propKeys = {
	leaderboard: StoreKeys.EVENT.LEADERBOARD,
	hasBracket: StoreKeys.EVENT.HAS_BRACKET,
	eventData: StoreKeys.EVENT.EVENTDATA,
	pastEventIds: StoreKeys.LeftPane.PastEventIds,
	pastEventIndex: StoreKeys.LeftPane.PastEventIndex,
	viewingPastEvents: StoreKeys.LeftPane.ViewingPastEvents,
};

function PickemLeftPaneC(props) {
	const {
		leaderboard,
		hasBracket,
		eventData,
		pastEventIds,
		viewingPastEvents,
	} = props;
	let { pastEventIndex } = props;
	const picksHelper = new PicksHelper(eventData);
	const eventStarted = picksHelper.isStarted();
	const dateInfo = picksHelper.getEventDateRange();
	const [stateObj, setStateObj] = React.useState({
		items: [],
		totalRecords: 0,
		rowNumberToGoTo: 1,
		userIdToGoTo: null,
	});
	const leaderboardHeaders = eventStarted
		? [``, `Name`, `Score`]
		: [``, `Name`];

	if (!!pastEventIds && !!eventData && eventData.id > 0) {
		const actualIdx = pastEventIds.indexOf(eventData.id);
		if (actualIdx !== pastEventIndex && actualIdx > 0) {
			pastEventIndex = actualIdx;
		}
	}

	const viewPastEvents = async () => {
		if (viewingPastEvents) {
			return;
		}

		const pastEventIds = await EventHelper.getListOfPastEventIds();
		if (pastEventIds.length === 0) {
			return;
		}

		const pastEventId = pastEventIds[0];
		const route = GetCurrentGamePrefix();
		changeUrl(`${route}/event/${pastEventId}`);

		Store.cacheChanges("viewPastEvents");
		Store.set(StoreKeys.LeftPane.PastEventIds, pastEventIds);
		Store.set(StoreKeys.LeftPane.PastEventIndex, 0);
		Store.set(StoreKeys.LeftPane.ViewingPastEvents, true);
		Store.applyChanges("viewPastEvents");
	};

	const viewNextEvent = async (offset) => {
		const peIdx = pastEventIndex + offset;

		if (peIdx < 0 || peIdx >= pastEventIds.length) {
			return;
		}

		const pastEventId = pastEventIds[peIdx];
		const route = GetCurrentGamePrefix();
		changeUrl(`${route}/event/${pastEventId}`);

		Store.cacheChanges("viewNextEvent");
		Store.set(StoreKeys.LeftPane.PastEventIds, pastEventIds);
		Store.set(StoreKeys.LeftPane.PastEventIndex, peIdx);
		Store.set(StoreKeys.LeftPane.ViewingPastEvents, true);
		Store.applyChanges("viewNextEvent");
	};

	const viewLiveEvent = async () => {
		if (!viewingPastEvents) {
			return;
		}

		const liveEventId = await EventHelper.getLiveEventId();
		const route = GetCurrentGamePrefix();
		changeUrl(`${route}/event/${liveEventId}`);

		Store.cacheChanges("viewLiveEvent");
		Store.set(StoreKeys.LeftPane.PastEventIndex, 0);
		Store.set(StoreKeys.LeftPane.ViewingPastEvents, false);
		Store.applyChanges("viewLiveEvent");
	};

	const getLeaderboardData = (startRow, overwriteData, isUp) => {
		if (!eventData) {
			return;
		}
		const def = $.Deferred();
		getLeaderboard(eventData.id, startRow).then((leaderboard) => {
			let items = [...stateObj.items];
			let newItems = null;
			if (overwriteData) {
				newItems = leaderboard;
			} else {
				if (isUp) {
					newItems = leaderboard.concat(items);
				} else {
					newItems = items.concat(leaderboard);
				}
			}
			let copy = { ...stateObj };
			copy.items = newItems;
			copy.rowNumberToGoTo = 1;
			copy.userIdToGoTo = null;
			setStateObj(copy);
			def.resolve(newItems.length > 0);
		});
		return def.promise();
	};

	const getLeaderboardAdditionalData = (fromTop) => {
		if (!eventData) {
			return;
		}
		return getLeaderboardAdditional(eventData.id, fromTop).then((info) => {
			let itemIdx = getItemIdx(UserManager.getUserId(), info.records);
			let copy = { ...stateObj };
			copy.items = info.records;
			copy.totalRecords = info.totalRecords;
			copy.rowNumberToGoTo = fromTop || !itemIdx ? 1 : itemIdx;
			copy.userIdToGoTo = fromTop ? null : UserManager.getUserId();
			setStateObj(copy);
		});
	};

	const searchLeaderboardData = (term) => {
		if (!eventData) {
			return;
		}
		return searchLeaderboard(eventData.id, term).then((data) => {
			let copy = { ...stateObj };
			copy.items = data;
			copy.totalRecords = data.length;
			copy.rowNumberToGoTo = 1;
			copy.userIdToGoTo = null;
			setStateObj(copy);
		});
	};

	const getItemIdx = (id, items) => {
		if (!id || !items) {
			return null;
		}
		return items.findIndex((r) => {
			return r.userId === id;
		});
	};

	const leaderboardNoDataEl = (
		<label>No picks have been scored for this event.</label>
	);
	const preFirstStageAndUnlocked = !picksHelper.isStarted();

	const openBracket = (userId) => {
		if (preFirstStageAndUnlocked) {
			return;
		}
		const event = eventData;
		const route = GetCurrentGamePrefix();
		changeUrl(`${route}/event/${event.id}/${userId}`);
		ModalHelper.closeAllModals();
	};

	const tableDataAry = !leaderboard
		? []
		: leaderboard.map((record) => {
				let scoreClass = record.rank === 1 ? ` first` : ``;
				return (
					<>
						<div className="position">{record.rank}</div>
						<div className="name" title={record.username}>
							<UserCard
								userId={record.userId}
								dontLoadData={true}
								userName={record.username}
								pinnedEventName={record.pinnedEventName}
								pinnedTrophyImagePath={record.pinnedTrophyImagePath}
								nameplateId={record.nameplateId}
							/>
						</div>
						{eventStarted && (
							<div
								className={`score${scoreClass} ${
									preFirstStageAndUnlocked ? "noclick" : ""
								}`}
								onClick={() => openBracket(record.userId)}
							>
								{record.totalScore}
								<img alt="Current Trophy Reward" src={record.trophyUrl}></img>
							</div>
						)}
					</>
				);
		  });
	const modalDataAry = stateObj.items.map((record, index) => {
		let scoreClass = record.rank === 1 ? ` first` : ``;
		return (
			<div
				data-id={record.row}
				key={index}
				className={
					UserManager.getUsername() === record.username
						? "highlight me leaderboard-row"
						: "leaderboard-row"
				}
				style={{ cursor: "pointer" }}
				title={"Click to view " + record.username + "'s picks!"}
			>
				<span className="position">{record.rank}</span>
				<span className="name" title={record.username}>
					<UserCard
						userId={record.userId}
						dontLoadData={true}
						userName={record.username}
						pinnedEventName={record.pinnedEventName}
						pinnedTrophyImagePath={record.pinnedTrophyImagePath}
						nameplateId={record.nameplateId}
					/>
				</span>
				{eventStarted && (
					<span
						className={`score${scoreClass}`}
						onClick={() => openBracket(record.userId)}
					>
						{record.totalScore}
					</span>
				)}
			</div>
		);
	});

	const hasItems = !!stateObj && stateObj.items && stateObj.items.length > 0;
	const leaderboardHiddenClass = preFirstStageAndUnlocked ? `hidden` : ``;
	const imageUrl = eventData?.image?.startsWith("http")
		? eventData?.image
		: `/images/events/${eventData?.image ?? "RLCSX.png"}`;
	const eventUrl =
		!!eventData && !!eventData.url ? eventData.url : "https://liquipedia.net/";
	return (
		<>
			{eventData && (
				<>
					<h4>
						EVENT{" "}
						<label className="selection-pill">
							<span
								className={viewingPastEvents ? "active" : ""}
								onClick={viewPastEvents.bind(this)}
							>
								Past
							</span>
							<span
								className={viewingPastEvents ? "" : "active"}
								onClick={viewLiveEvent.bind(this)}
							>
								Live
							</span>
						</label>
					</h4>
					<h3>{eventData.nameOverride ?? eventData.name}</h3>
					<label>{dateInfo}</label>
					<div className="event-selector">
						{viewingPastEvents && (
							<div
								className="event-toggle"
								title="previous"
								onClick={viewNextEvent.bind(this, 1)}
								style={{ float: "left" }}
							>
								<span>
									<CarotLeft />
								</span>
							</div>
						)}
						<div className="event-image-container">
							<img
								src={imageUrl}
								alt="rocket league"
								onClick={() => window.open(eventUrl)}
							/>
						</div>
						{viewingPastEvents && (
							<div
								className="event-toggle"
								title="next"
								onClick={viewNextEvent.bind(this, -1)}
							>
								<span>
									<CarotRight />
								</span>
							</div>
						)}
					</div>
					<Timer eventData={eventData} />

					<hr />
				</>
			)}
			{!eventData && (
				<>
					<h1>No Current Event</h1>
					<label>Please come back soon for the next event!</label>
				</>
			)}

			<Leaderboard
				leaderboardClass={`events ${leaderboardHiddenClass}`}
				loadData={getLeaderboardData}
				loadDataAddtl={getLeaderboardAdditionalData}
				totalRecords={stateObj.totalRecords}
				leaderboardHeaders={leaderboardHeaders}
				tableData={tableDataAry}
				modalTableData={modalDataAry}
				eventData={eventData}
				hasBracket={hasBracket}
				noDataEl={leaderboardNoDataEl}
				firstRow={hasItems ? stateObj.items[0].row : 0}
				lastRow={hasItems ? stateObj.items[stateObj.items.length - 1].row : 0}
				rowNumberToGoTo={stateObj.rowNumberToGoTo}
				userIdToGoTo={stateObj.userIdToGoTo}
				searchLeaderboard={searchLeaderboardData}
			/>
		</>
	);
}

const getLeaderboard = async (eventId, startRow) => {
	let route = `/get/leaderboard/${eventId}`;
	if (!isNaN(startRow)) {
		route += `/${startRow}`;
	}

	return await axiosWrapper.getCached(`/api/picks${route}`);
};

const getLeaderboardAdditional = async (eventId, fromTop) => {
	return await axiosWrapper.getCached(
		`/api/picks/get/leaderboard/additional/${eventId}/${fromTop}`
	);
};

const searchLeaderboard = async (eventId, term) => {
	let route = `/search/leaderboard/${eventId}/${term}`;

	return await axiosWrapper.getCached(`/api/picks${route}`);
};

const PickemLeftPane = withRouter((props) =>
	connect(<PickemLeftPaneC />, propKeys, props)
);

export default PickemLeftPane;
