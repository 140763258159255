import { Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import LeagueNavigator from "./LeagueNavigator";
import AnalystLeague from "./AnalystLeague";
import { SetLoading } from "common/components/Loader/Loader";
import { loadUserLeagueInfo, loadAnalystLeagueInfo } from "common/helpers/leagueHelper";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing() * 2,
		width: "100%",
	},
}));

export default function LeagueScreen(props) {
	const styles = useStyles();

	React.useEffect(() => {
		SetLoading(true, "Loading League Info");
		Promise.all([loadUserLeagueInfo(), loadAnalystLeagueInfo()]).then(() => {
			SetLoading(false);
		});
	});

	return (
		<Grid className={styles.root} container spacing={3} justifyContent="center">
			<Grid container item direction="column" xl={4} md={6} xs={12}>
				<LeagueNavigator />
			</Grid>
			<Grid container item direction="column" xl={4} md={6} xs={12}>
				<AnalystLeague />
			</Grid>
		</Grid>
	);
}
