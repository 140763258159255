import UserCard from "common/components/UserCard";
import { Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import { Colors } from "styles/Colors";
import TrophyService from "services/TrophyService";
import ContentPanel from "common/components/ContentPanel/ContentPanel";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import _ from "lodash";

const useStyles = makeStyles({
	container: {
		width: "50%",
	},
	heading: {
		color: Colors.pickstop,
		marginTop: 5,
		marginBottom: 5,
	},
	awardContainer: {
		display: "flex",
		flexDirection: "column",
		borderRadius: 12,
		margin: 4,
		textAlign: "center",
		maxWidth: 200,
	},
	awardContainerInner: {
		borderRadius: 12,
		background: `transparent linear-gradient(180deg, ${Colors.diamond} 0%, transparent 50%) 0% 0% content-box`,
	},
	eventName: {
		fontSize: 14,
		height: 50,
		display: "flex",
		justifyContent: "center",
		marginTop: 10,
		maxWidth: 150,
	},
	eventAward: {
		marginTop: 10,
		width: 150,
	},
	awardImage: {
		maxHeight: 150,
	},
});

export default function RecentAwards(props) {
	const styles = useStyles();

	const [recentAwards, setRecentAwards] = React.useState(null);

	React.useEffect(() => {
		TrophyService.getRecentTrophies().then((recentData) => {
			setRecentAwards(recentData);
		});
	}, []);

	const headerJsx = <ContentHeader text="Recent Winners" />;

	if (!recentAwards || recentAwards.length < 1) {
		return (
			<ContentPanel>
				{headerJsx}
				No recent data to show.
			</ContentPanel>
		);
	}

	const distinctEventIds = _.uniqBy(recentAwards, (a) => a.trophy.eventId).map(
		(a) => a.trophy.eventId
	);

	return (
		<ContentPanel>
			{headerJsx}

			<Grid container justify="space-around">
				{distinctEventIds.map((eventId, index) => {
					const userIds = recentAwards
						.filter((a) => a.trophy.eventId === eventId)
						.map((a) => a.user.userId);
					const trophy = recentAwards.find(
						(a) => a.trophy.eventId === eventId
					)?.trophy;

					return (
						<Grid item className={styles.awardContainer} key={index}>
							<div className={styles.awardContainerInner}>
								<div className={styles.eventAward}>
									<img
										className={styles.awardImage}
										src={trophy?.imagePath}
										alt={""}
									/>
								</div>
								<div className={styles.eventName}>{trophy?.eventName}</div>
							</div>
							{userIds.map((userId, index) => {
								return (
									<UserCard userId={userId} key={index} marginBottom={4} />
								);
							})}
						</Grid>
					);
				})}
			</Grid>
		</ContentPanel>
	);
}
