import {
	Box,
	Button,
	Grid,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	MenuItem,
	Select,
} from "@material-ui/core";
import { SetLoading } from "common/components/Loader/Loader";
import UserCard from "common/components/UserCard";
import StyleHelper from "common/helpers/styleHelper";
import { NameplateStyle } from "common/types/NameplateStyle";
import UserManager from "common/userManager";
import * as React from "react";
import NameplateService from "services/NameplateService";
import { Colors } from "styles/Colors";

const useStyles = makeStyles((theme) => ({
	cssEditor: {
		backgroundColor: Colors.panelLight,
		color: Colors.primary,
		width: 600,
		height: 312,
		font: "normal normal 16px/24px Arial",
	},
	nameplateDrawer: {},
	menu: {
		color: Colors.primary,
		backgroundColor: Colors.panel,
	},
}));

const nameplateTemplate = `{id}.nameplate-container {
    border: 1px solid #ECECEC;
    background-color: #474747;
    color: #ECECEC;
}

{id} .nameplate-name {

}

{id} .nameplate-trophy {

}`;

export default function NameplateEditor() {
	const styles = useStyles();
	const userId = UserManager.getUserId();
	/** @type {[NameplateStyle, any]} */
	const [nameplate, setNameplate] = React.useState(null);
	const [nameplateList, setNameplateList] = React.useState([]);
	const [typeList, setTypeList] = React.useState([]);
	const [name, setName] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [type, setType] = React.useState(null);
	const [template, setTemplate] = React.useState(nameplateTemplate);
	/** @type {[HTMLStyleElement, any]} */
	const [styleRef, setStyleRef] = React.useState(null);

	React.useEffect(() => {
		SetLoading(true);
		Promise.all([
			NameplateService.loadNameplates().then((nameplates) => {
				setNameplateList(nameplates);
			}),
			NameplateService.getTypes().then((types) => {
				setTypeList(types);
			}),
		]).then(() => {
			SetLoading(false);
		});
	});

	React.useEffect(() => {});

	const updateStyle = (updatedTemplate) => {
		updatedTemplate = updatedTemplate || template;

		// @ts-ignore
		let testTemplate = updatedTemplate.replaceAll("{id}", ".nameplate-test");
		if (styleRef) {
			styleRef.textContent = testTemplate;
			return;
		}

		const newStyleRef = StyleHelper.addStyle(testTemplate);
		setStyleRef(newStyleRef);
	};

	const onCreateNameplate = () => {
		setNameplate(new NameplateStyle());
		setName("");
		setDescription("");
		setType(null);
		setTemplate(nameplateTemplate);
		updateStyle(nameplateTemplate);
	};

	/**
	 *
	 * @param {NameplateStyle} nameplate
	 */
	const onSelectNameplate = (nameplate) => {
		setNameplate(nameplate);
		setName(nameplate.name);
		setDescription(nameplate.description);
		setType(nameplate.namePlateTypeId);
		setTemplate(nameplate.style);
		updateStyle(nameplate.style);
	};

	const onApply = () => {
		updateStyle();
	};

	const onSave = () => {
		nameplate.name = name;
		nameplate.description = description;
		nameplate.style = template;
		nameplate.namePlateTypeId = type;

		SetLoading(true, "Saving Nameplate");
		NameplateService.saveNameplate(nameplate).then((nameplate) => {
			SetLoading(false);
			onSelectNameplate(nameplate);
		});
	};

	const onChangeName = (event) => {
		const value = event.currentTarget.value;
		setName(value);
	};

	const onChangeDescription = (event) => {
		const value = event.currentTarget.value;
		setDescription(value);
	};

	const onChangeTemplate = (event) => {
		const value = event.currentTarget.value;
		setTemplate(value);
	};

	const onChangeType = (event) => {
		setType(event.target.value);
	};

	return (
		<Grid container lg={12}>
			<Grid item lg={10}>
				<Grid container direction="column" alignItems="center">
					{!nameplate && <h1>MAKE A NAMEPLATE DAMNIT</h1>}
					{nameplate && (
						<>
							<Grid item sm={12} style={{ display: "inline-block" }}>
								<Box mb={12} mt={12}>
									<UserCard userId={userId} nameplateId={"test"} />
								</Box>
							</Grid>
							<Grid item sm={12}>
								<Box>
									<table>
										<tbody>
											<tr>
												<td>
													<label>Name</label>
												</td>
												<td>
													<input value={name} onChange={onChangeName} />
												</td>
											</tr>
											<tr>
												<td>
													<label>Description</label>
												</td>
												<td>
													<input
														value={description}
														onChange={onChangeDescription}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<label>Type</label>
												</td>
												<td>
													<Select
														value={type}
														onChange={onChangeType}
														displayEmpty
														MenuProps={{
															classes: {
																paper: styles.menu,
															},
														}}
													>
														{typeList.map((type) => (
															<MenuItem value={type.id}>
																<em>{type.name}</em>
															</MenuItem>
														))}
													</Select>
												</td>
											</tr>
										</tbody>
									</table>
								</Box>
							</Grid>
							<Grid item sm={12}>
								<Box>
									<label>Style</label>
								</Box>
								<Box>
									<textarea
										value={template}
										className={styles.cssEditor}
										onChange={onChangeTemplate}
									/>
								</Box>
							</Grid>
							<Grid item sm={12}>
								<Button variant="contained" color="primary" onClick={onApply}>
									Apply
								</Button>
								<Button variant="contained" color="primary" onClick={onSave}>
									Save
								</Button>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<Grid item lg={2}>
				<Box className={styles.nameplateDrawer}>
					<List>
						<ListItem
							key={"create"}
							style={{ cursor: "pointer" }}
							onClick={onCreateNameplate}
						>
							<ListItemText
								primary="Create New Nameplate"
								onClick={onCreateNameplate}
							/>
						</ListItem>

						{nameplateList.map((nameplate) => {
							return (
								<ListItem
									key={nameplate.id}
									style={{ cursor: "pointer" }}
									onClick={() => {
										onSelectNameplate(nameplate);
									}}
								>
									<ListItemText primary={nameplate.name} />
								</ListItem>
							);
						})}
					</List>
				</Box>
			</Grid>
		</Grid>
	);
}
