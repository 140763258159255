import { changeUrl } from "common/history";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import { LocalStorageKeys, SessionStorageKeys } from "./StorageKeys";

export const GamesEnum = {
	RocketLeague: 1,
	CounterStrike: 2,
};

export const games = [
	{
		id: GamesEnum.RocketLeague,
		prefix: "rl",
		trophyImagePrefix: "rl",
		active: true,
	},
	{
		id: GamesEnum.CounterStrike,
		prefix: "cs",
		trophyImagePrefix: "cs",
		active: true,
	},
];

export function GetCurrentGameId() {
	return Number(
		sessionStorage.getItem(SessionStorageKeys.GAME) ??
			localStorage.getItem(LocalStorageKeys.GAME) ??
			1
	);
}

export function GetCurrentGamePrefix() {
	const currentGameId = GetCurrentGameId();
	return `/${games.find((g) => g.id === currentGameId)?.prefix}`;
}

export function GetCurrentGameTrophyPath() {
	const currentGameId = GetCurrentGameId();
	return `/images/${
		games.find((g) => g.id === currentGameId)?.trophyImagePrefix
	}`;
}

export function SetGameInStore(newGameId) {
	// Update path in session/local storage.
	sessionStorage.setItem(SessionStorageKeys.GAME, newGameId);
	localStorage.setItem(LocalStorageKeys.GAME, newGameId);
}

export function SetGameGlobally(oldGameId, newGameId, reload) {
	if (newGameId === oldGameId) {
		return;
	}

	SetGameInStore(newGameId);

	// Update pathname
	let pathname = window.location.pathname;
	if (oldGameId) {
		const oldGame = games.find((g) => g.id === oldGameId);
		if (pathname.startsWith(`/${oldGame.prefix}`)) {
			pathname = pathname.substring(oldGame.prefix.length + 1);
		}
	}

	if (pathname.startsWith("/event")) {
		pathname = "/event";
	}

	const route = GetCurrentGamePrefix();
	changeUrl(`${route}${pathname}`);

	if (reload) {
		window.location.reload();
	}
}
