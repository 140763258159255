import { Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import { ReactComponent as CarotLeft } from "icons/Carot_Left.svg";
import { ReactComponent as CarotRight } from "icons/Carot_Right.svg";

const useStyles = makeStyles((theme) => ({
	eventSelector: {
		display: "inline-block",
		margin: "0 10px",
	},
	eventToggle: {
		cursor: "pointer",
		height: "12px",
		width: "12px",
		fill: theme.palette.primary.main,

		"&.disabled": {
			opacity: 0.3,
			cursor: "default",
		},
	},
}));

export default function EventSelector({
	events,
	onChangeEvent,
	disableChange,
}) {
	const eventLength = events?.length ?? 0;
	const styles = useStyles();
	const [state, setState] = React.useState({
		eventIndex: events?.length ? events?.length - 1 : 0,
		eventCount: eventLength,
	});

	// Events initially comes in as 0, we need to check if we changed the event list.
	if (eventLength !== state.eventCount) {
		setState({
			...state,
			eventIndex: eventLength - 1,
			eventCount: eventLength,
		});
	}

	if (!events?.length) {
		return <></>;
	}

	// for now, show at most current event and one previous
	const furthestEventIndexAllowed =
		events.length - 2 >= 0 ? events.length - 2 : 0;

	const event = events[state.eventIndex];
	const viewNextEvent = (offset) => {
		if (
			disableChange ||
			(offset === -1 && state.eventIndex === furthestEventIndexAllowed)
		) {
			return;
		}

		const updatedEventIndex = state.eventIndex + offset;

		if (updatedEventIndex < 0 || updatedEventIndex >= events.length) {
			return;
		}

		setState({
			...state,
			eventIndex: updatedEventIndex,
		});

		const event = events[updatedEventIndex];
		onChangeEvent(event.eventId);
	};

	return (
		<Grid container justifyContent="center">
			<Grid item>
				<div
					className={styles.eventSelector}
					title="Previous"
					onClick={viewNextEvent.bind(this, -1)}
				>
					<span>
						<CarotLeft
							className={
								styles.eventToggle +
								(disableChange ||
								state.eventIndex === 0 ||
								state.eventIndex === furthestEventIndexAllowed
									? " disabled"
									: "")
							}
						/>
					</span>
				</div>
				<div className={styles.eventSelector}>{event.eventName}</div>
				<div
					className={styles.eventSelector}
					title="Next"
					onClick={viewNextEvent.bind(this, 1)}
				>
					<span>
						<CarotRight
							className={
								styles.eventToggle +
								(state.eventIndex === events.length - 1 ? " disabled" : "")
							}
						/>
					</span>
				</div>
			</Grid>
		</Grid>
	);
}
