// eslint-disable-next-line no-unused-vars
import { EventStageTypes } from "common/constants/EventStageTypes";
import { EventFormat } from "../types/EventFormat";
import { Match } from "bracket-system/types";

export default class EventFormatHelper {
	/**
	 * Gets the format builder for the provided format.
	 *
	 * @param {EventFormat} eventFormat
	 * @returns {EventFormatBuilder} The builder object.
	 */
	static getEventFormatBuilder(eventFormat) {
		return new EventFormatBuilder(eventFormat);
	}
}

export class EventFormatBuilder {
	/** @type {EventFormat} */
	eventFormat;

	/** @type {EventFormatData} */
	data;

	/**
	 *
	 * @param {EventFormat} eventFormat
	 */
	constructor(eventFormat) {
		this.eventFormat = eventFormat ?? {};

		// Convert from strings to dates, and parse json to object.
		this.eventFormat.createdDate = this.eventFormat.created
			? new Date(this.eventFormat.created)
			: null;
		this.eventFormat.modifiedDate = this.eventFormat.modified
			? new Date(this.eventFormat.modified)
			: null;
		this.data = this.eventFormat?.json
			? JSON.parse(this.eventFormat.json)
			: {
					stages: [
						{
							name: "Group Stage",
							type: EventStageTypes.Group,
							groupStageDetails: new EventFormatGroupStageDetails(),
						},
						{
							name: "Playoffs",
							type: EventStageTypes.Playoffs,
						},
					],
			  };
	}
}

export class EventFormatData {
	/** @type {EventFormatStage[]} */
	stages;

	/** @type {EventFormatScoringBreakdown} */
	scoringBreakdown;
}

export class EventFormatScoringBreakdown {
	/** @type {EventFormatScoringBreakdownGroup} */
	groups = [];
}

export class EventFormatScoringBreakdownGroup {
	/** @type {string} */
	name;

	/** @type {EventFormatScoringBreakdownRecord[]} */
	records = [];
}

export class EventFormatScoringBreakdownRecord {
	/** @type {string} */
	name;

	/** @type {number} */
	pointsPer;

	/** @type {string} */
	dbColumnName;
}

export class EventFormatStage {
	/**
	 * @type {string}
	 */
	name;

	/**
	 * What type of stage this is.
	 * Playoffs, Swiss, Group.
	 * @type {number}
	 */
	type = EventStageTypes.Playoffs;

	/**
	 * What are the group stage details.
	 * @type {EventFormatGroupStageDetails}
	 */
	groupStageDetails;

	/**
	 * What are the swiss stage details.
	 * @type {EventFormatSwissStageDetails}
	 */
	swissStageDetails;

	/**
	 * What are the playoff details.
	 * @type {EventFormatPlayoffsDetails}
	 */
	playoffDetails;

	/**
	 * # of teams to open picks
	 * @type {number}
	 */
	teamsToOpenPicks;

	/**
	 * What is the bracket data?
	 * Applies in playoffs only.
	 * @type {EventFormatBracket}
	 */
	bracket;
}

export class EventFormatGroupStageDetails {
	/**
	 * If true, then we are using brackets for group stage.
	 * If false, then we are using round robin for group stage.
	 * @type {boolean}
	 */
	hasGroupBrackets = false;

	/** @type {boolean} */
	useSeriesScore = false;

	/** @type {number} */
	groupCount = 4;

	/** @type {number} */
	advancingCount = 3;

	/** @type {number} */
	totalAdvancing = 3;

	/** @type {number} */
	pointsPerAdvancing = 2;

	/** @type {boolean} */
	scoreFirstPlace = true;

	/** @type {number} */
	pointsPerFirstPlace = 1;

	/** @type {boolean} */
	scoreSecondPlace = true;

	/** @type {number} */
	pointsPerSecondPlace = 1;
}

export class EventFormatSwissStageDetails {
	/** @type {number} */
	advancingCount = 8;

	/** @type {number} */
	pointsPerAdvancing = 3;

	/** @type {number} */
	cleanSweepWinnerCount = 2;

	/** @type {number} */
	cleanSweepLoserCount = 2;

	/** @type {number} */
	pointsPerCorrectWinner = 1;

	/** @type {number} */
	pointsPerCorrectLoser = 1;
}

export class EventFormatPlayoffsDetails {
	/** @type {boolean} */
	useSeriesScore = true;

	/** @type {boolean} */
	hasBracketReset = false;

	/** @type {number} */
	pointsForCorrectBracketReset = 1;
}

/** A bracket instance within a stage. */
export class EventFormatBracket {
	/** @type {EventFormatBracketView[]} */
	views = [];

	/** @type {EventFormatBracketPart[]} */
	parts = [];

	/** @type {Array<{upper: Match[], lower: Match[]}>} */
	matches;

	/** @type {EventFormatBracketScoringGroup[]} */
	scoringGroups = [];
}

export class EventFormatBracketView {
	/** @type {string} */
	name;

	/** @type {EventFormatBracketColumnMatchTarget[]} */
	matches;

	/**
	 *
	 * @param {string} name
	 * @param {boolean} showSeriesScore
	 * @param {EventFormatBracketColumnMatchTarget[]} matches
	 */
	constructor(name, showSeriesScore, matches) {
		this.name = name;
		this.showSeriesScore = showSeriesScore;
		this.matches = matches;
	}
}

/** A portion of the bracket. */
export class EventFormatBracketPart {
	/** @type {string} */
	identifier;
	/** @type {number} */
	columnCount;
	/** @type {EventFormatBracketColumn[]} */
	columns;
}

export class EventFormatBracketColumn {
	/** @type {string} */
	name;
	/** @type {number} */
	index;
	/** @type {number} */
	matchCount;
	/** @type {EventFormatBracketColumnMatch[]} */
	matches;
	/** @type {number} */
	bestOf = 5;
	/** @type {boolean} */
	lockPicks;
	/** @type {boolean} */
	bothTeamsAdvance;
}

export class EventFormatBracketColumnMatch {
	/**
	 * Set the bracket part this match is in.
	 * @type {string}
	 */
	part;

	/**
	 * Set the column this match is in.
	 * @type {number}
	 */
	col;

	/**
	 * Set the index within this column.
	 * @type {number}
	 */
	colIndex;

	/**
	 * Where the loser will go (if applicable)
	 * @type {EventFormatBracketColumnMatchTarget}
	 */
	loserPath;

	/**
	 * @type {string}
	 */
	bracketMatchId;
}

export class EventFormatBracketColumnTarget {
	/**
	 * Set the bracket part this match is in.
	 * @type {string}
	 */
	part;

	/**
	 * The bracket column we are in.
	 * @type {number}
	 */
	col;
}

export class EventFormatBracketColumnMatchTarget extends EventFormatBracketColumnTarget {
	/**
	 * The index within the column we are at.
	 * @type {number}
	 */
	colIndex;

	/**
	 * The slot the team drops into.
	 * @type {boolean | undefined | null}
	 */
	isTop = true;
}

export class EventFormatBracketScoringGroup {
	/**
	 * Set the name of this scoring group.
	 * @type {string}
	 */
	name;

	/**
	 * Set the columns included in this scoring group
	 * @type {EventFormatBracketColumnTarget[]} */
	columns = [];

	/** @type {boolean} */
	scoreParticipants = true;

	/** @type {boolean} */
	scoreWinnersOnly = false;

	/** @type {number} */
	pointsPerCorrectParticipant = 1;

	/** @type {boolean} */
	scorePlacement = true;

	/** @type {number} */
	pointsPerCorrectPlacement = 1;

	constructor(name) {
		this.name = name;
	}
}

/**
 *
 * @param {EventFormatBracket} bracket
 * @param {EventFormatBracketColumnMatchTarget[]} viewTargets
 * @returns {{upper: string[], lower: string[]}}
 */
export function getHeaderNamesFromBracket(bracket, viewTargets) {
	const headerNames = {
		upper: [],
		lower: [],
	};

	if (!bracket) {
		return headerNames;
	}

	for (const part of bracket.parts) {
		if (part.identifier === "UB") {
			headerNames.upper = part.columns
				.filter((c) =>
					viewTargets
						? viewTargets.some((t) => t.part == "UB" && t.col == c.index)
						: true
				)
				.map((c) => c.name);
		} else if (part.identifier === "LB") {
			headerNames.lower = part.columns
				.filter((c) =>
					viewTargets
						? viewTargets.some((t) => t.part == "LB" && t.col == c.index)
						: true
				)
				.map((c) => c.name);
		} else if (part.identifier === "GF") {
			headerNames.upper = headerNames.upper.concat(
				part.columns
					.filter((c) =>
						viewTargets ? viewTargets.some((t) => t.part == "GF") : true
					)
					.map((c) => c.name)
			);
		}
	}

	return headerNames;
}

/**
 *
 * @param {EventFormatBracket} bracket
 * @param {EventFormatBracketColumnMatchTarget[]} viewTargets
 * @returns {{upper: number[], lower: number[]}}
 */
export function getLockedColumns(bracket, viewTargets) {
	const lockedColumns = {
		upper: [],
		lower: [],
	};

	if (!bracket) {
		return lockedColumns;
	}

	for (const part of bracket.parts) {
		if (part.identifier === "UB") {
			lockedColumns.upper = part.columns
				.filter((c) => c.lockPicks)
				.map((c) => c.index);
		} else if (part.identifier === "LB") {
			lockedColumns.lower = part.columns
				.filter((c) => c.lockPicks)
				.map((c) => c.index);
		} else if (part.identifier === "GF") {
			lockedColumns.upper = lockedColumns.upper.concat(
				part.columns
					.filter((c) =>
						viewTargets ? viewTargets.some((t) => t.part == "GF") : true
					)
					.map((c) => c.name)
			);
		}
	}

	return lockedColumns;
}

/**
 *
 * @param {EventFormatStage} currentStage
 */
export function stageUsesBracket(currentStage) {
	return (
		(currentStage.type === EventStageTypes.Group &&
			currentStage.groupStageDetails.hasGroupBrackets) ||
		currentStage.type === EventStageTypes.Playoffs
	);
}

/**
 *
 * @param {EventFormatBuilder} currentFormat
 */
export function buildScoringDetails(currentFormat) {
	const scoringBreakdown = new EventFormatScoringBreakdown();
	for (const stage of currentFormat?.data?.stages) {
		const scoringGroup = buildScoringGroup(stage);
		scoringBreakdown.groups.push(scoringGroup);
	}
	currentFormat.data.scoringBreakdown = scoringBreakdown;
}

/**
 *
 * @param {EventFormatStage} stage
 * @returns {EventFormatScoringBreakdownGroup}
 */
function buildScoringGroup(stage) {
	const scoringGroup = new EventFormatScoringBreakdownGroup();

	switch (stage.type) {
		case EventStageTypes.Group:
			buildGroupStageScoringGroup(stage, scoringGroup);
			break;
		case EventStageTypes.Swiss:
			buildSwissStageScoringGroup(stage, scoringGroup);
			break;
		case EventStageTypes.Playoffs:
			buildPlayoffsScoringGroup(stage, scoringGroup);
			break;
		default:
			break;
	}

	return scoringGroup;
}

/**
 *
 * @param {EventFormatStage} stage
 */
function buildGroupStageScoringGroup(stage, scoringGroup) {
	if (stage.groupStageDetails) {
		if (stage?.groupStageDetails.hasGroupBrackets) {
			return buildPlayoffsScoringGroup(stage, scoringGroup);
		}

		scoringGroup.name = stage.name;
		// Advancing
		const advancingToPlayoffs = new EventFormatScoringBreakdownRecord();
		advancingToPlayoffs.name = "Advancing to Next Stage";
		advancingToPlayoffs.pointsPer = stage.groupStageDetails.pointsPerAdvancing;
		scoringGroup.records.push(advancingToPlayoffs);

		if (stage.groupStageDetails.scoreFirstPlace) {
			const firstPlace = new EventFormatScoringBreakdownRecord();
			firstPlace.name = "1st Place Bonus";
			firstPlace.pointsPer = stage.groupStageDetails.pointsPerFirstPlace;
			scoringGroup.records.push(firstPlace);

			if (stage.groupStageDetails.scoreSecondPlace) {
				const secondPlace = new EventFormatScoringBreakdownRecord();
				secondPlace.name = "2nd Place Bonus";
				secondPlace.pointsPer = stage.groupStageDetails.pointsPerSecondPlace;
				scoringGroup.records.push(secondPlace);
			}
		}
	}
}

/**
 *
 * @param {EventFormatStage} stage
 */
function buildSwissStageScoringGroup(stage, scoringGroup) {
	if (stage.swissStageDetails) {
		scoringGroup.name = stage.name;

		const advancingToPlayoffs = new EventFormatScoringBreakdownRecord();
		advancingToPlayoffs.name = "Advancing to Next Stage";
		advancingToPlayoffs.pointsPer = stage.swissStageDetails.pointsPerAdvancing;
		scoringGroup.records.push(advancingToPlayoffs);

		if (stage.swissStageDetails.pointsPerCorrectWinner > 0) {
			const cleanSweepWinner = new EventFormatScoringBreakdownRecord();
			cleanSweepWinner.name = "Clean Sweep Bonus (3-0)";
			cleanSweepWinner.pointsPer =
				stage.swissStageDetails.pointsPerCorrectWinner;
			scoringGroup.records.push(cleanSweepWinner);
		}

		if (stage.swissStageDetails.pointsPerCorrectLoser > 0) {
			const cleanSweepLoser = new EventFormatScoringBreakdownRecord();
			cleanSweepLoser.name = "Clean Sweep Bonus (0-3)";
			cleanSweepLoser.pointsPer = stage.swissStageDetails.pointsPerCorrectLoser;
			scoringGroup.records.push(cleanSweepLoser);
		}
	}
}

/**
 *
 * @param {EventFormatStage} stage
 */
function buildPlayoffsScoringGroup(stage, scoringGroup) {
	scoringGroup.name = stage.name;
	if (!stage?.bracket?.scoringGroups) {
		return;
	}

	for (const bracketGroup of stage?.bracket?.scoringGroups) {
		if (bracketGroup.scoreParticipants) {
			let breakdownRecord = new EventFormatScoringBreakdownRecord();
			breakdownRecord.name = bracketGroup.name;
			breakdownRecord.pointsPer = bracketGroup.pointsPerCorrectParticipant;
			scoringGroup.records.push(breakdownRecord);
		}
	}

	const hasPlacement = stage.bracket?.scoringGroups?.some(
		(g) => g.scorePlacement
	);

	if (hasPlacement) {
		let placementBonus = new EventFormatScoringBreakdownRecord();
		placementBonus.name = "Placement Bonus";
		placementBonus.pointsPer = 1;
		scoringGroup.records.push(placementBonus);
	}

	const hasSeries =
		stage.groupStageDetails?.useSeriesScore ||
		stage.playoffDetails?.useSeriesScore;
	if (hasSeries) {
		let seriesBonus = new EventFormatScoringBreakdownRecord();
		seriesBonus.name = "Series Score Bonus";
		seriesBonus.pointsPer = 1;
		scoringGroup.records.push(seriesBonus);
	}

	// const hasBracketReset = stage.playoffDetails?.hasBracketReset;
	// if (hasBracketReset) {
	// 	let seriesBonus = new EventFormatScoringBreakdownRecord();
	// 	seriesBonus.name = "Bracket Reset Bonus";
	// 	seriesBonus.pointsPer = 1;
	// 	scoringGroup.records.push(seriesBonus);
	// }
}
