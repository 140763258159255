import { Container, Typography } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

export default function EventAnalytics(props) {
	return (
		<Container maxWidth={false}>
			<Helmet>
				<title>Pickstop - Event Analytics</title>
			</Helmet>

			<Typography>Coming Soon</Typography>
		</Container>
	);
}
