import * as React from "react";

// eslint-disable-next-line no-unused-vars
import { SurveyPromptAnswerDto } from "common/types/Survey/SurveyPromptAnswerDto";
import { Box, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";

const fillerImage =
  "https://img.freepik.com/free-vector/square-gray-frame-light-gray-background-vector_53876-167871.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1699833600&semt=ais";

const useStyles = makeStyles((theme) => {
  return {
    selected: {},
    default: {
      position: "relative",
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
      userSelect: "none",
      "& img": {
        margin: "auto",
      },
      "& label": {
        textAlign: "center",
        display: "block",
        userSelect: "none",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  };
});

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {SurveyPromptAnswerDto} answer
 * @property {boolean} selected
 */

/**
 * @param {ComponentProps} props
 */
export default function SurveyAnswerTile({ answer, selected }) {
  const classNames = useStyles();
  return (
    <Box
      width="100%"
      height="100%"
      className={`${classNames.default} ${selected ? classNames.selected : ""}`}
    >
      <img src={answer.imageUrl || fillerImage} alt={answer.text} />
      <label>{answer.text}</label>
    </Box>
  );
}
