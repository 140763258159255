import axiosWrapper from "common/axiosWrapper";
// eslint-disable-next-line no-unused-vars
import AuthenticationResultDto from "common/types/AuthenticationResultDto";

/**
 * Attempts discord sign in with the provided code.
 *
 * @param {string} code The discord access code.
 * @returns {Promise<AuthenticationResultDto>}
 * */
export const discordSignIn = async (code) => {
	return await axiosWrapper.get(`api/auth/signin-discord?code=${code}`);
};

/**
 * Attempts google sign in with the provided code.
 *
 * @param {string} code The google access code.
 * @returns {Promise<AuthenticationResultDto>}
 * */
export const googleSignIn = async (code) => {
	return await axiosWrapper.get(`api/auth/signin-google?code=${code}`);
};

/**
 * Attempts reddit sign in with the provided code.
 *
 * @param {string} code The reddit access code.
 * @returns {Promise<AuthenticationResultDto>}
 * */
export const redditSignIn = async (code) => {
	return await axiosWrapper.get(`api/auth/signin-reddit?code=${code}`);
};

/**
 * Attempts patreon sign in with the provided code.
 *
 * @param {string} code The patreon access code.
 * @returns {Promise<AuthenticationResultDto>}
 * */
export const patreonLink = async (state, code) => {
	return await axiosWrapper.get(
		`api/auth/link-patreon?state=${state}&code=${code}`
	);
};
