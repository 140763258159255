import * as React from "react";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import ContentPanel from "common/components/ContentPanel/ContentPanel";
import { ReactComponent as Analyst } from "icons/Analyst_2.svg";
import { connect } from "common/connect";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import LeagueInfo from "common/components/LeagueInfo/LeagueInfo";
import LeagueService from "services/LeagueService";
import { SetLoading } from "common/components/Loader/Loader";

const propKeys = {
	events: StoreKeys.LEAGUES.ANALYST.EVENTS,
	league: StoreKeys.LEAGUES.ANALYST.LEAGUE,
	rankings: StoreKeys.LEAGUES.ANALYST.RANKINGS,
	viewType: StoreKeys.LEAGUES.ANALYST.VIEW_TYPE,
};

function AnalystLeagueC({ events, league, rankings, viewType }) {
	const onChangeViewType = (id) => {
		Store.set(StoreKeys.LEAGUES.ANALYST.VIEW_TYPE, id);
	};

	const onChangeEvent = async (eventId) => {
		SetLoading(true, "Loading League Rankings");
		const rankings = await LeagueService.getLeagueRankings(league.id, eventId);
		Store.set(StoreKeys.LEAGUES.ANALYST.RANKINGS, rankings);
		SetLoading(false);
	};

	React.useEffect(() => {
		if (!viewType) {
			Store.set(StoreKeys.LEAGUES.ANALYST.VIEW_TYPE, 1);
		}
	});

	return (
		<ContentPanel>
			<ContentHeader icon={<Analyst />} text="Analyst League" />
			<LeagueInfo
				league={league}
				events={events}
				rankings={rankings}
				viewType={viewType}
				onChangeViewType={onChangeViewType}
				onChangeEvent={onChangeEvent}
				showNameplate={false}
			/>
		</ContentPanel>
	);
}

export default function AnalystLeague(props) {
	return connect(<AnalystLeagueC />, propKeys, props);
}
