export const calculateVerticalStartingPoint = (
	columnIndex: number,
	height: number
) => 2 ** columnIndex * (height / 2) - height / 2;

export const columnIncrement = (columnIndex: number, height: number) =>
	2 ** columnIndex * height;

export const calculateHeightIncrease = (
	columnIndex: number,
	rowIndex: number,
	height: number
) => columnIncrement(columnIndex, height) * rowIndex;

export const calculateVerticalPositioning = ({
	rowIndex,
	columnIndex,
	rowHeight,
}) => {
	return (
		calculateHeightIncrease(columnIndex, rowIndex, rowHeight) +
		calculateVerticalStartingPoint(columnIndex, rowHeight)
	);
};

export const calculatePositionOfFinalGame = (
	rowIndex,
	columnIndex,
	{
		canvasPadding,
		rowHeight,
		columnWidth,
		gameHeight,
		upperBracketHeight,
		lowerBracketHeight,

		offsetX = 0,
		offsetY = 0,
	}
) => {
	const yResult =
		gameHeight * (lowerBracketHeight / upperBracketHeight) - rowHeight;

	return {
		x: columnIndex * columnWidth + canvasPadding + offsetX,
		y: yResult + canvasPadding + offsetY,
	};
};

export const calculatePositionOfMatchUpperBracket = (
	columns,
	rowIndex,
	columnIndex,
	{ canvasPadding, rowHeight, columnWidth, offsetX = 0, offsetY = 0 },
	lowerColumns,
	isQualifierRound
) => {
	const noLower = (lowerColumns?.length ?? 0) === 0;
	const columnArray = getColumnSizeArray(columns);

	const yResult = calculateVerticalPositioning({
		rowHeight,
		rowIndex,
		columnIndex: columnArray.indexOf(columns[columnIndex].length),
	});

	const isQualifierColumn =
		isQualifierRound && columns.length - 1 === columnIndex;

	let xResult;
	if (noLower) {
		xResult = columnIndex * columnWidth;
	} else {
		const skipStep = (index) => Math.floor((index + 1) * 2) - 3;

		if (isQualifierColumn) {
			const previousColumn = calculatePositionOfMatchUpperBracket(
				columns,
				rowIndex,
				columnIndex - 1,
				{ canvasPadding, rowHeight, columnWidth, offsetX, offsetY },
				lowerColumns,
				isQualifierRound
			);

			xResult = previousColumn.x + columnWidth - canvasPadding;
		} else {
			const shouldSkip = lowerColumns?.length > columns.length;
			xResult =
				!shouldSkip || columnIndex === 0 || columnIndex === 1
					? columnIndex * columnWidth
					: skipStep(columnIndex) * columnWidth;
		}
	}

	if (columnIndex < columns.length - 1) {
		let nextColumnIndex = columnIndex + 1;

		// make sure there is a next column
		while (columns.length > nextColumnIndex) {
			const nextColumn = columns[nextColumnIndex];
			const nextColumnIsQualifier =
				isQualifierRound && columnIndex === columns.length - 2;
			if (!nextColumnIsQualifier) {
				const nextColumnMatches =
					columnArray.indexOf(columns[columnIndex].length) ===
					columnArray.indexOf(nextColumn.length);
				if (nextColumnMatches) {
					offsetY += 19;
					nextColumnIndex++;
				} else {
					break;
				}
			} else {
				break;
			}
		}
	}

	return {
		x: xResult + canvasPadding + offsetX,
		y: yResult + canvasPadding + offsetY,
	};
};

export const getColumnSizeArray = (columns) => {
	const columnSizeMap = new Map();
	const columnSizesArray = [];

	for (let i = 0; i < columns.length; i++) {
		const columnSize = columns[i].length;
		if (!columnSizeMap.has(columnSize)) {
			columnSizeMap.set(columnSize, i);
			columnSizesArray.push(columnSize);
		}
	}

	return columnSizesArray;
};

export const returnLowerBracketColumnIndex = (columnIndex) =>
	Math.ceil((columnIndex + 1) / 2) - 1;

export const calculatePositionOfMatchLowerBracket = (
	columns,
	rowIndex,
	columnIndex,
	{ canvasPadding, rowHeight, columnWidth, offsetX = 0, offsetY = 0 },
	isQualifierRound
) => {
	const columnArray = getColumnSizeArray(columns);
	const result = calculateVerticalPositioning({
		rowHeight,
		rowIndex,
		columnIndex: columnArray.indexOf(columns[columnIndex].length),
	});

	if (columnIndex < columns.length - 1) {
		const nextColumn = columns[columnIndex + 1];
		const nextColumnIsQualifier =
			isQualifierRound && columnIndex === columns.length - 2;
		if (!nextColumnIsQualifier) {
			const nextColumnMatches =
				columnArray.indexOf(columns[columnIndex].length) ===
				columnArray.indexOf(nextColumn.length);
			if (nextColumnMatches) {
				offsetY += 19;
			}
		}
	}

	return {
		x: columnIndex * columnWidth + canvasPadding + offsetX,
		y: result + canvasPadding + offsetY,
	};
};
