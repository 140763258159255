import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";

const useStyles = makeStyles((theme) => ({
	container: {
		border: `1px solid ${Colors.primary}`,
		display: "inline-flex",
		padding: 0,
		borderRadius: 4,
		overflow: "hidden",
	},
	item: {
		position: "relative",
		backgroundColor: Colors.body,
		color: Colors.primary,
		padding: "12px 16px",
		display: "inline-block",
		border: 0,
		borderRight: `1px solid ${Colors.primary}`,
		cursor: "pointer",

		"&.selected": {
			backgroundColor: Colors.primary,
			color: Colors.panel,
		},

		"&.disabled": {
			color: Colors.body,
			backgroundColor: Colors.panel,
			cursor: "default",
		},
	},
}));

export default function TabToggle({ value, items, onChange }) {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			{items.map((i, index) => (
				<TabItem
					{...i}
					key={index}
					selected={value === i.id}
					disabled={i.disabled}
					setSelectedId={(id) => {
						if (i.disabled) {
							return;
						}
						onChange(id);
					}}
				/>
			))}
		</div>
	);
}

function TabItem({ id, text, selected, setSelectedId, disabled }) {
	const styles = useStyles();
	const rippleRef = React.useRef(null);
	const onRippleStart = (e) => {
		if (disabled) {
			return;
		}
		rippleRef.current.start(e);
	};
	const onRippleStop = (e) => {
		if (disabled) {
			return;
		}
		rippleRef.current.stop(e);
	};

	return (
		<div
			onMouseDown={onRippleStart}
			onMouseUp={onRippleStop}
			className={
				styles.item +
				(selected ? " selected" : "") +
				(disabled ? " disabled" : "")
			}
			onClick={() => {
				setSelectedId(id);
			}}
			key={id}
			disabled={disabled}
		>
			{text}
			{!disabled && <TouchRipple ref={rippleRef} center={false} />}
		</div>
	);
}
