import { SetLoading } from "common/components/Loader/Loader";
import { GetCurrentGamePrefix } from "common/constants/Games";
import { LocalStorageKeys } from "common/constants/StorageKeys";
import { changeUrl } from "common/history";
import React from "react";
import { linkReddit } from "./api";
import * as queryString from "query-string";

export default class LinkReddit extends React.Component {
	async componentDidMount() {
		SetLoading(true, "Linking Reddit Account");

		const searchParams = queryString.parse(window.location.search);
		const code = searchParams.code;
		try {
			await linkReddit(code);
		} catch (error) {}

		SetLoading(false);
		const redirect = localStorage.getItem(LocalStorageKeys.POSTLINKURL);
		if (redirect) {
			localStorage.removeItem(LocalStorageKeys.POSTLINKURL);
			changeUrl(redirect);
		} else {
			const route = GetCurrentGamePrefix();
			changeUrl(route + "/event");
		}
	}

	render() {
		return <></>;
	}
}
