// eslint-disable-next-line no-unused-vars
import { Match } from "bracket-system/types";
import { EventStageTypes } from "common/constants/EventStageTypes";
import {
	// eslint-disable-next-line no-unused-vars
	EventFormatStage,
	// eslint-disable-next-line no-unused-vars
	EventFormatBracket,
	// eslint-disable-next-line no-unused-vars
	EventFormatBracketColumn,
	// eslint-disable-next-line no-unused-vars
	EventFormatBracketColumnMatch,
	// eslint-disable-next-line no-unused-vars
	EventFormatBracketColumnMatchTarget,
	// eslint-disable-next-line no-unused-vars
	EventFormatBracketColumnTarget,
	// eslint-disable-next-line no-unused-vars
	EventFormatBracketScoringGroup,
} from "./eventFormatHelper";
// eslint-disable-next-line no-unused-vars
import { MatchPick } from "common/types/MatchPick";
import _ from "lodash";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
// eslint-disable-next-line no-unused-vars
import { EventStage, EventSubstage } from "common/types/EventStage";

export function getMatchCount(part, column, totalColumns) {
	if (part === "UB") {
		const inverse = Math.abs(column - totalColumns);
		return Math.pow(2, inverse - 1);
	}
	if (part === "LB") {
		const inverse = Math.abs(column - totalColumns);
		const grouping = Math.ceil(inverse / 2);
		return Math.pow(2, grouping - 1);
	}
	if (part === "GF") {
		return 1;
	}
}

export function getPartName(part) {
	if (part === "UB") {
		return "Upper Bracket";
	}
	if (part === "LB") {
		return "Lower Bracket";
	}
	if (part === "GF") {
		return "Grand Final";
	}
}

/**
 * @param {EventFormatBracket} bracket
 * @param {string} bracketMatchId
 * @returns {Match}
 */
export function findBracketMatchById(bracket, bracketMatchId) {
	return (
		bracket.matches.upper?.find((m) => m.id === bracketMatchId) ??
		bracket.matches.lower?.find((m) => m.id === bracketMatchId)
	);
}

/**
 * @param {EventFormatBracket} bracket
 * @param {string} bracketMatchId
 */
export function findColumnMatchById(bracket, bracketMatchId) {
	for (const part of bracket.parts) {
		for (const column of part.columns) {
			const columnMatch = column.matches.find(
				(m) => m.bracketMatchId === bracketMatchId
			);
			if (columnMatch) {
				return columnMatch;
			}
		}
	}
}

/**
 * @param {EventFormatBracket} bracket
 * @param {EventFormatBracketColumnTarget} target
 * @returns {EventFormatBracketColumn}
 */
export function getBracketColumnByTarget(bracket, target) {
	return bracket?.parts
		?.find((p) => p.identifier === target.part)
		?.columns?.find((c) => c.index === target.col);
}

/**
 *
 * @param {EventFormatBracket} bracket
 * @param {EventFormatBracketColumnMatchTarget} target
 * @returns {string}
 */
export function getBracketMatchIdByTarget(bracket, target) {
	return bracket?.parts
		?.find((p) => p.identifier === target.part)
		?.columns?.find((c) => c.index === target.col)
		?.matches?.find((m) => m.colIndex === target.colIndex)?.bracketMatchId;
}

/**
 *
 * @param {EventFormatBracket} bracket
 * @param {EventFormatBracketColumnMatchTarget} target
 * @returns {EventFormatBracketColumnMatch}
 */
export function getBracketMatchByTarget(bracket, target) {
	return bracket?.parts
		?.find((p) => p.identifier === target.part)
		?.columns?.find((c) => c.index === target.col)
		?.matches?.find((m) => m.colIndex === target.colIndex);
}

/**
 *
 * @param {EventFormatBracket} bracket
 * @param {EventFormatBracketColumnTarget} target
 * @returns {string[]}
 */
export function getBracketMatchIdsByTargetColumn(bracket, target) {
	return bracket?.parts
		?.find((p) => p.identifier === target.part)
		?.columns?.find((c) => c.index === target.col)
		?.matches.map((m) => m.bracketMatchId);
}

/**
 *
 * @param {any} eventData
 * @param {EventStage} currentEventStage
 * @param {EventSubstage} currentEventSubstage
 * @param {EventFormatStage} currentStage
 * @param {EventFormatBracket} bracket
 * @param {MatchPick[]} userPicks
 * @param {MatchPick[]} officialPicks
 */
export function parsePicksIntoBracketMatches(
	eventData,
	currentEventStage,
	currentEventSubstage,
	currentStage,
	bracket,
	userPicks,
	officialPicks,
	mapOfficialToUserWhenEmpty = false
) {
	// If Swiss Stage or Group stage with no brackets, skip this.
	if (currentStage.type === EventStageTypes.Swiss) {
		return;
	}

	if (
		currentStage?.type === EventStageTypes.Group &&
		!currentStage.groupStageDetails?.hasGroupBrackets
	) {
		return;
	}

	const teams = eventData.teams;
	userPicks = userPicks?.filter(
		(p) =>
			p.eventStageId === currentEventStage.id &&
			p.eventSubstageId === currentEventSubstage.id
	);

	officialPicks = officialPicks.filter(
		(p) =>
			p.eventStageId === currentEventStage.id &&
			p.eventSubstageId === currentEventSubstage.id
	);

	if (
		mapOfficialToUserWhenEmpty &&
		userPicks?.length == 0 &&
		officialPicks.length > 0
	) {
		/** @type {any[]} */
		let allUserPicks = Store.get(StoreKeys.EVENT.USER.BRACKET_PICKS) ?? [];
		allUserPicks = allUserPicks.concat(_.cloneDeep(officialPicks));
		allUserPicks = allUserPicks.filter((pick) => {
			return !!getBracketMatchIdByTarget(bracket, {
				part: pick.bracketPart,
				col: pick.bracketCol,
				colIndex: pick.bracketColIndex,
			});
		});

		Store.set(StoreKeys.EVENT.USER.BRACKET_PICKS, allUserPicks);
	}

	const sourcePicks = userPicks?.length == 0 ? officialPicks : userPicks;
	bracket = _.cloneDeep(bracket);

	for (let pick of sourcePicks) {
		const bracketMatchId = getBracketMatchIdByTarget(bracket, {
			part: pick.bracketPart,
			col: pick.bracketCol,
			colIndex: pick.bracketColIndex,
		});

		// Abios gives us crap matches sometimes, don't let it ruin our site.
		if (!bracketMatchId) {
			continue;
		}

		const bracketMatch = findBracketMatchById(bracket, bracketMatchId);

		const topTeam = teams.find((t) => t.id === pick.topTeamId);
		const botTeam = teams.find((t) => t.id === pick.bottomTeamId);

		const started = pick.topTeamScore || pick.bottomTeamScore;
		const topTeamScore = started ? (pick.topTeamScore ?? 0).toString() : "";
		const bottomTeamScore = started
			? (pick.bottomTeamScore ?? 0).toString()
			: "";

		bracketMatch.participants = [
			{
				id: topTeam?.id ?? "",
				resultText: topTeamScore,
				isWinner: pick.winnerTeamId === topTeam?.id,
				status: "PLAYED",
				name: topTeam?.abbreviation ?? "",
				logoUrl: topTeam?.logoUrl,
				correctParticipant:
					userPicks.length === 0
						? false
						: correctParticipant(
								{
									part: pick.bracketPart,
									col: pick.bracketCol,
								},
								topTeam?.id,
								officialPicks,
								bracket.scoringGroups
						  ),
				correctPlacement: userPicks.length === 0 ? false : correctPlacement(),
			},
			{
				id: botTeam?.id ?? "",
				resultText: bottomTeamScore,
				isWinner: pick.winnerTeamId === botTeam?.id,
				status: "PLAYED",
				name: botTeam?.abbreviation ?? "",
				logoUrl: botTeam?.logoUrl,
				correctParticipant:
					userPicks.length === 0
						? false
						: correctParticipant(
								{
									part: pick.bracketPart,
									col: pick.bracketCol,
								},
								botTeam?.id,
								officialPicks,
								bracket.scoringGroups
						  ),
				correctPlacement: userPicks.length === 0 ? false : correctPlacement(),
			},
		];
	}

	return bracket.matches;
}

/**
 *
 * @param {EventFormatBracketColumnTarget} column
 * @param {number} teamId
 * @param {MatchPick[]} officialPicks
 * @param {EventFormatBracketScoringGroup[]} scoringGroups
 * @returns {boolean}
 */
function correctParticipant(column, teamId, officialPicks, scoringGroups) {
	if (!teamId) {
		return false;
	}

	// get scoring group that contains current column
	const sg = scoringGroups?.find(
		(sg) =>
			sg.columns.find((c) => c.col === column.col && c.part === column.part) !=
			null
	);
	if (!sg || !sg.scoreParticipants || sg.scoreWinnersOnly) {
		return false;
	}

	// get all team ids from official picks for the scoring group.
	for (const col of sg.columns) {
		const picks = officialPicks.filter(
			(op) => op.bracketPart === col.part && op.bracketCol === col.col
		);
		if (
			picks.find((p) => p.topTeamId === teamId || p.bottomTeamId === teamId)
		) {
			return true;
		}
	}

	return false;
}

function correctPlacement() {
	return false;
}
