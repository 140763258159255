import * as React from "react";
import { Box, Paper as MuiPaper, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";

const useStyles = makeStyles((theme) => {
	return {
		paper: {
			color: Colors.primary,
			backgroundColor: Colors.panel,
			overflow: "clip",
			height: "100%",
			width: "100%",
		},
		paperInner: {
			"& .MuiFormControl-root:not(:first-child)": {
				marginTop: 12,
			},
			boxSizing: "border-box",
		},
	};
});

export class PaperComponentProps {
	/** @type {any} */
	children;

	/** @type {number} */
	padding;

	/** @type {number} */
	margin;

	/** @type {number} */
	width;

	/** @type {number} */
	height;
}

/**
 * @param {PaperComponentProps} props
 */
export default function Paper({
	children,
	padding,
	margin,
	width,
	height,
	onClick,
	rootClassName,
}) {
	padding = padding === undefined || padding === null ? 8 : padding;
	margin = margin === undefined || margin === null ? 8 : margin;
	const classNames = useStyles();
	return (
		<Box width={width} height={height} onClick={onClick}>
			<MuiPaper
				className={classNames.paper}
				elevation={4}
				classes={{ root: rootClassName }}
			>
				<div
					className={classNames.paperInner}
					style={{ padding, margin, height }}
				>
					{children}
				</div>
			</MuiPaper>
		</Box>
	);
}
