import axiosWrapper from "common/axiosWrapper";

const baseUrl = "/api/promo";

export default class PromoService {
	/**
	 * Attempt to redeem a promo code for any current promos.
	 *
	 * @returns {Promise<boolean>} Returns if the code is valid and claimed.
	 */
	static async redeemCode(code) {
		return axiosWrapper.get(`${baseUrl}/redeem?code=${code}`);
	}
}
