import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalPanel,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "common/components/CustomModal/Modal/ModalFrame";
import ModalHelper from "common/helpers/modalHelper";
import { changeUrl } from "common/history";

export default class UsernameChangePrompt extends React.Component {
	render() {
		return (
			<CustomModal>
				<ModalPanel>
					<ModalHeader>Username Change</ModalHeader>
					<ModalBody>
						<p>
							Your account was flagged for a username change by an admin or
							automated process. <br />
							Please change your username now.
						</p>
						<p>Keep in mind that your username can be seen by everyone.</p>
					</ModalBody>
					<ModalFooter>
						<button onClick={this.handleOk.bind(this)}>Ok</button>
					</ModalFooter>
				</ModalPanel>
			</CustomModal>
		);
	}

	handleOk() {
		ModalHelper.closeModal();
		changeUrl("/pick-username");
	}
}
