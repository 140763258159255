import {
	games,
	GetCurrentGameId,
	GetCurrentGamePrefix,
} from "common/constants/Games";
import { Routes, Tabs } from "common/constants/Routes";
import { SessionStorageKeys } from "common/constants/StorageKeys";
import { changeUrl } from "common/history";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export const getCurrentTabFromRoute = (loc) => {
	let pathname = loc.pathname.split("?")[0];
	for (const game of games) {
		if (pathname.startsWith(`/${game.prefix}`)) {
			pathname = pathname.substring(game.prefix.length + 1);
			break;
		}
	}

	switch (pathname) {
		case Routes.COMMUNITY:
			return Tabs.COMMUNITY;
		case Routes.LEAGUES:
			return Tabs.LEAGUES;
		case Routes.TROPHYROOM:
			return Tabs.TROPHYROOM;
		case Routes.RLAWARDS:
			return Tabs.RLAWARDS;
		default:
			return Tabs.PICKEMS;
	}
};

export default function useTabSelect() {
	const location = useLocation();
	const currentTab = getCurrentTabFromRoute(location);
	const [, setTab] = useState(currentTab);

	const updateTab = (value) => {
		const route = GetCurrentGamePrefix();
		switch (value) {
			case Tabs.PICKEMS:
				setTab(Tabs.PICKEMS);
				changeUrl(route);
				break;
			case Tabs.LEAGUES:
				setTab(Tabs.LEAGUES);
				changeUrl(route + Routes.LEAGUES);
				break;
			case Tabs.TROPHYROOM:
				setTab(Tabs.TROPHYROOM);
				changeUrl(route + Routes.TROPHYROOM);
				break;
			case Tabs.COMMUNITY:
				setTab(Tabs.COMMUNITY);
				changeUrl(route + Routes.COMMUNITY);
				break;
			case Tabs.RLAWARDS:
				setTab(Tabs.RLAWARDS);
				changeUrl(route + Routes.RLAWARDS);
				break;
			default:
				setTab(route + Tabs.PICKEMS);
				break;
		}
	};

	return [currentTab, updateTab];
}
