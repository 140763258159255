import axiosWrapper from "common/axiosWrapper";

const baseUrl = "/api/user";

/** The User service which calls the API endpoints for the User Controller */
export default class UserService {
	static async getUserProfile(userId) {
		return axiosWrapper
			.getCached(`${baseUrl}/profile?userId=${userId}`)
			.then((data) => {
				data.created = new Date(data.created);
				return data;
			});
	}

	static async getUserCardInfo(userId) {
		if (!userId) {
			return null;
		}

		return await axiosWrapper.get(`/api/user/usercardinfo/${userId}`);
	}

	/**
	 * Searches users based on the provided search value.
	 *
	 * @param {string} searchValue The username search value.
	 * */
	static async searchUsers(searchValue) {
		return await axiosWrapper.get(`api/user/search?searchValue=${searchValue}`);
	}

	/** Gets the available roles. */
	static async getRoles() {
		return await axiosWrapper.getCached(`api/user/roles`);
	}

	/**
	 * Gets the specified users roles
	 *
	 * @param {number} userId The user's id.
	 * @returns {number[]} The list of role ids.
	 */
	static async getUserRoles(userId) {
		return await axiosWrapper.get(`api/user/userroles?userId=${userId}`);
	}

	/**
	 * Gets the user's game they are an analyst for.
	 *
	 * @param {number} userId The user's id.
	 * @returns {number} The analyst game id.
	 */
	static async getParticipatingAnalystGame(userId) {
		return await axiosWrapper.get(
			`api/user/analystParticipation?userId=${userId}`
		);
	}

	/**
	 * Updates the user with their new roles.
	 *
	 * @param {number} userId The user id of the user to update.
	 * @param {string} username The username to be set.
	 * @param {Array<number>} roleIds The list of role ids.
	 * @param {boolean} flagUsernameChange If we should prompt for username change.
	 */
	static async updateUser(
		userId,
		username,
		roleIds,
		flagUsernameChange,
		analystParticipationGameId = null,
		createAnalystInfluencerLeague = null
	) {
		return await axiosWrapper.post(`api/user/update`, {
			userId,
			username,
			roleIds,
			flagUsernameChange,
			analystParticipationGameId,
			createAnalystInfluencerLeague,
		});
	}

	/**
	 * Triggers the user for a password reset and returns the link to provide the user.
	 *
	 * @param {number} userId The user id to reset the password of.
	 * @returns {Promise<string>} The async result with the password link.
	 */
	static async resetPassword(userId) {
		return await axiosWrapper.get(`api/user/resetpassword?userId=${userId}`);
	}
}
