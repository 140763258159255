import * as React from "react";
import "./GroupStageTeam.scss";
import { targetArrayHasMatchingRecord } from "common/helpers/arrayHelper";
import { PickTypes } from "common/constants/PickTypes";

export class GroupStageTeam extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showPlayers: false,
			showOfficial: false,
			disabled: false,
		};

		this.selector = React.createRef();
	}

	componentDidUpdate(prevProps) {
		if (this.props.showPlayers !== this.state.showPlayers) {
			this.setState({
				showPlayers: this.props.showPlayers,
			});
		}
		if (this.props.showOfficial !== this.state.showOfficial) {
			this.setState({
				showOfficial: this.props.showOfficial,
			});
		}
	}

	togglePlayerList(e) {
		this.setState({
			showPlayers: !this.state.showPlayers,
		});
		e.stopPropagation();
	}

	isPlayerOrCaptain(member) {
		return member.position === "Player" || member.position === "Captain";
	}

	getCardStatus() {
		if (this.props.step === 0) {
			if (this.props.isAdvancing) {
				return "advancing";
			}
		} else if (this.props.step === 1) {
			if (!this.props.isAdvancing) {
				return "disabled";
			}

			return "advancing";
		} else if (this.props.step === 2) {
			if (this.props.toggleSecondTeam) {
				if (!this.props.isAdvancing) {
					return "disabled";
				}

				return "advancing";
			}

			if (this.props.toggleEliminated) {
				if (this.props.isAdvancing) {
					return "disabled";
				}

				return "advancing";
			}
		}

		return "";
	}
	render() {
		const correct =
			!this.state.showOfficial &&
			targetArrayHasMatchingRecord(
				this.props.officialGroupPicks,
				{
					teamId: this.props.team.id,
					pickTypeId: this.props.isAdvancing
						? PickTypes.Advancing
						: PickTypes.Eliminated,
				},
				["teamId", "pickTypeId"]
			);
		const cardStatus =
			this.getCardStatus() +
			(this.props.locked ? " locked" : "") +
			(correct ? " correct" : "");
		const team = this.props.team;
		const players = team.members.filter((player) =>
			this.isPlayerOrCaptain(player)
		);
		const coach = team.members.filter((player) => player.position === "Coach");
		const other = team.members.filter(
			(player) =>
				player.position !== "Player" &&
				player.position !== "Coach" &&
				player.position !== "Captain"
		);
		const playerOrder = [...players, ...coach, ...other];

		return (
			<div className="group-stage-team-container">
				{!cardStatus.includes("disabled") && this.state.showPlayers && (
					<div className={`group-stage-team-players ${cardStatus}`}>
						<table>
							<tbody>
								{playerOrder?.map((member, index) => (
									<tr key={index} className="group-stage-player">
										{/* <td>
											<img src={member.flag.imageUrl} alt="Player Flag" />
										</td> */}
										<td title={member.username}>
											<label>
												{member.position === "Coach" ? "(C) " : ""}{" "}
												{member.position === "Substitute" ? "(S) " : ""}
												{member.username}{" "}
											</label>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
				{!cardStatus.includes("disabled") && !this.state.showPlayers && (
					<div
						className={`group-stage-team ${cardStatus}`}
						onClick={() => {
							if (!cardStatus.includes("disabled")) {
								if (
									(this.props.step === 0 && this.props.toggleAdvancing) ||
									this.props.enableAdvancingStepOne
								) {
									this.props.toggleAdvancing(this.props.team.id);
								} else if (
									this.props.step === 1 &&
									this.props.isAdvancing &&
									this.props.toggleTopTeam
								) {
									this.props.toggleTopTeam(this.props.team.id);
								} else if (this.props.step === 2) {
									if (this.props.toggleSecondTeam && this.props.isAdvancing &&
										!this.props.isTopTeam) {
											this.props.toggleSecondTeam(this.props.team.id);
									}

									if (this.props.toggleEliminated && !this.props.isAdvancing) {
										this.props.toggleEliminated(this.props.team.id);
									}
								}
							}
						}}
					>
						{this.props.topLeftEl}
						<div
							className={
								"team-wrapper " +
								(!this.props.isEditing && !this.props.isAdvancing
									? "faded"
									: "")
							}
						>
							{this.props.team.logoUrl && (
								<div className="logo-wrapper">
									<img src={this.props.team.logoUrl} alt="Team Logo" />
								</div>
							)}
							{this.props.team && <label>{this.props.team.name}</label>}
						</div>
					</div>
				)}
				{cardStatus.includes("disabled") && (
					<div
						className={`group-stage-team disabled ${this.props.locked ?? ""}`}
						onClick={() => {
							if (!cardStatus.includes("disabled")) {
								if (this.props.step === 0 && this.props.toggleAdvancing) {
									this.props.toggleAdvancing(
										this.props.team.id,
										!this.props.isAdvancing
									);
								}
							}
						}}
					>
						<div className="logo-name-container">
							{this.props.team.logoUrl && (
								<img src={this.props.team.logoUrl} alt="Team Logo" />
							)}
							{this.props.team && <label>{this.props.team.name}</label>}
						</div>
					</div>
				)}
			</div>
		);
	}
}
