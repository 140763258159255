// eslint-disable-next-line no-unused-vars
import { SurveyPromptAnswerDto } from "./SurveyPromptAnswerDto";

export class SurveyPromptDto {
  /** @type {number} */
  promptId;
  /** @type {string} */
  text;
  /** @type {boolean} */
  isMultiSelect;
  /** @type {number} */
  numberOfAnswers;
  /** @type {SurveyPromptAnswerDto[]} */
  answers;

  constructor() {
    this.text = "New Prompt";
    this.answers = [];
  }
}
