import { SetLoading } from "common/components/Loader/Loader";
import EventHelper from "common/helpers/eventHelper/eventHelper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import GamePickemScreen from "./GamePickemScreen";
import { PickemContent } from "./PickemLeftPane/Content/PickemContent";
import PickemLeftPane from "./PickemLeftPane/LeftPane/PickemLeftPane";

export default class PickemScreen extends React.Component {
	eventId = 0;
	userId = 0;

	componentDidMount() {
		this.load(true);
	}

	componentDidUpdate() {
		const eventId = this.props.match.params.eventId;
		const userId = this.props.match.params.userId;

		if (this.eventId !== eventId || this.userId !== userId) {
			this.load();
		}
	}

	async load(onMount = false) {
		SetLoading(true, "Loading");

		/** @type {Record<string, any>} */
		const params = this.props.match.params;
		const eventId = params.eventId;
		const userId = params.userId;

		if (eventId === this.eventId && userId === this.userId) {
			return;
		}

		this.eventId = eventId;
		this.userId = userId;

		if (this.eventId) {
			const liveId = await EventHelper.getLiveEventId();
			if (onMount && eventId.toString() !== liveId.toString()) {
				const pastEventIds = await EventHelper.getListOfPastEventIds();
				const index = pastEventIds.indexOf(eventId);

				Store.cacheChanges("event");
				Store.set(StoreKeys.LeftPane.PastEventIds, pastEventIds);
				Store.set(StoreKeys.LeftPane.PastEventIndex, index);
				Store.set(StoreKeys.LeftPane.ViewingPastEvents, true);
				Store.applyChanges("event");
			}
			await EventHelper.fullLoadEvent(Number(eventId), userId);
		} else {
			await EventHelper.getLiveEvent();
		}

		SetLoading(false);
	}

	render() {
		return (
			<GamePickemScreen>
				<PickemLeftPane />
				<PickemContent />
			</GamePickemScreen>
		);
	}
}
