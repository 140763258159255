import axiosWrapper from "common/axiosWrapper";
import { updateUserCardsWithNameplateId } from "common/components/UserCard";
import StyleHelper from "common/helpers/styleHelper";
// eslint-disable-next-line no-unused-vars
import { NameplateStyle } from "../common/types/NameplateStyle";

const baseUrl = "/api/nameplate";

export default class NameplateService {
  /** @type {Array<NameplateStyle>} */
  static nameplates;

  /** Loads the nameplates for the client to render, and injects the styles. */
  static async init() {
    NameplateService.nameplates = await NameplateService.loadCachedNameplates();
    NameplateService.injectStyles();
  }

  /** Gets all the nameplates in the system. */
  static async loadNameplates() {
    return await axiosWrapper.getCached(`${baseUrl}/all`);
  }

  /** Gets all the nameplates in the system. */
  static async loadCachedNameplates() {
    return await axiosWrapper.getCached(`${baseUrl}/all-cached`);
  }

  static async getTypes() {
    return await axiosWrapper.getCached(`${baseUrl}/types`);
  }

  /**
   * Gets nameplates the user has access to.
   */
  static async getUserNameplates() {
    return await axiosWrapper.get(`${baseUrl}/user`);
  }

  /**
   * Gets nameplates the user has the option to choose.
   */
  static async getUserNameplateOptions() {
    return await axiosWrapper.get(`${baseUrl}/options`);
  }

  /**
   * Sets the users nameplate to the provided id. Or sets to default if null is provided.
   *
   * @param {number | null} nameplateId
   */
  static async setNameplate(nameplateId) {
    if (!nameplateId) {
      return await axiosWrapper.get(`${baseUrl}/set`);
    }
    return await axiosWrapper
      .get(`${baseUrl}/set?nameplateId=${nameplateId}`)
      .then(() => {
        updateUserCardsWithNameplateId(nameplateId);
      });
  }

  static async selectNameplate(nameplateId) {
    if (!nameplateId) {
      return;
    }

    return await axiosWrapper.get(
      `${baseUrl}/select?nameplateId=${nameplateId}`
    );
  }

  /**
   * Saves the nameplate to the API.
   *
   * @param {NameplateStyle} nameplate
   * @returns {Promise<NameplateStyle>}
   */
  static async saveNameplate(nameplate) {
    return await axiosWrapper.post(`${baseUrl}/save`, nameplate);
  }

  static async deleteNameplate(id) {
    return await axiosWrapper.delete(`${baseUrl}/delete/${id}`);
  }

  static injectStyles() {
    for (const nameplate of NameplateService.nameplates) {
      // @ts-ignore
      let styleText = nameplate.style.replaceAll(
        "{id}",
        `.nameplate-${nameplate.id}`
      );
      StyleHelper.addStyle(styleText);
    }
  }
}
