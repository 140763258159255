import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalHeader,
	ModalBody,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import TrophyList from "common/components/TrophyList/TrophyList";

export default function TrophySelectorModal(props) {
	/** @type {Array<any>} */
	const exceptThese = props.except;
	const awards = props.awards.filter((a) => {
		return !exceptThese || !exceptThese.find((e) => e.eventId === a.eventId);
	});
	const onSelect = props.onSelect;
	if (!onSelect) {
		console.error(
			"You are using a Trophy Selector Modal without an onSelect callback."
		);
	}

	return (
		<CustomModal>
			<ModalPanel>
				<ModalHeader>{props.header ?? "Select a Trophy"}</ModalHeader>
				<ModalBody>
					<TrophyList awards={awards} onClick={onSelect} />
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
