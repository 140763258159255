const signalR = require("@microsoft/signalr");

export default class RealtimeHelper {
	/** @type {Record<string, signalR.HubConnection>} */
	static connections = {};

	static async connect(url, onStart) {
		let connection;
		if (!RealtimeHelper.connections[url]) {
			connection = new signalR.HubConnectionBuilder().withUrl(url).build();
			RealtimeHelper.connections[url] = connection;
			await connection.start();
		}

		if (onStart) {
			onStart(connection);
		}

		return RealtimeHelper.connections[url];
	}

	static getConnection(url) {
		return RealtimeHelper.connections[url];
	}

	static async on(url, eventName, callback) {
		const connection = await RealtimeHelper.connect(url, null);
		connection.on(eventName, callback);
	}

	static async off(url, eventName, callback) {
		const connection = await RealtimeHelper.getConnection(url);
		if (!connection) {
			return;
		}

		connection.off(eventName, callback);
	}
}
