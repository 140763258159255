import Store from "common/store";
import StoreKeys from "common/storeKeys";
import { SetLoading } from "common/components/Loader/Loader";
// eslint-disable-next-line no-unused-vars
import EventPicksDto from "common/types/EventPicksDto";
import BaseEvent from "./BaseEvent";
import { PickTypes } from "common/constants/PickTypes";
import PickService from "services/PickService";
// eslint-disable-next-line no-unused-vars
import { GroupStagePick } from "common/types/GroupStagePick";
import _ from "lodash";
import {
	EventFormatData,
	stageUsesBracket,
} from "common/helpers/eventFormatHelper";
import PicksHelper from "common/picksHelper";
import UserManager from "common/userManager";

export default class GenericEvent extends BaseEvent {
	async fullLoad(userId) {
		SetLoading(true);
		await Promise.all([
			this.userHasBracket(),
			this.loadUserScore(userId),
			this.loadLeaderboard(),
			this.loadOfficialPicks(),
			this.loadBracketUser(userId),
		]);

		await this.loadEventPicks(userId);
		this.checkLoadFirstPlace(userId, this.fullLoad.bind(this));
	}

	async userHasBracket() {
		const eventId = this.eventData.id;
		await PickService.getUserPicks(eventId).then(
			/** @param {EventPicksDto} picks */
			(picks) => {
				const groupStagePicks = picks.groupStagePicks ?? [];
				const bracketPicks = picks.matchPicks ?? [];

				/** @type {EventFormatData} */
				const format = Store.get(StoreKeys.EVENT.FORMAT);
				const eventStage = this.eventData.eventStages[this.eventData.stage];
				const currentStage = format.stages[this.eventData.stage];

				if (stageUsesBracket(currentStage)) {
					this.hasBracket = bracketPicks.some(
						(p) => p.eventStageId === eventStage.id
					);
				} else {
					this.hasBracket = groupStagePicks.some(
						(p) => p.eventStageId === eventStage.id
					);
				}

				const picksHelper = new PicksHelper(this.eventData);
				const isLocked = picksHelper.isLocked();

				Store.set(StoreKeys.EVENT.HAS_BRACKET, this.hasBracket);

				if (!isLocked && !this.hasBracket) {
					Store.set(StoreKeys.EVENT.IS_EDITING, true);
					Store.set(StoreKeys.EVENT.STEP, 0);
				}
			}
		);
	}

	async loadEventPicks(userId) {
		const eventId = this.eventData.id;
		await PickService.getUserPicks(eventId, userId).then(
			/** @param {EventPicksDto} picks */
			(picks) => {
				const groupStagePicks = picks.groupStagePicks ?? [];
				Store.set(StoreKeys.EVENT.USER.GROUP_PICKS, groupStagePicks);

				let bracketPicks = picks.matchPicks ?? [];
				Store.set(StoreKeys.EVENT.USER.BRACKET_PICKS, bracketPicks);

				Store.set(StoreKeys.EVENT.STAGE_INDEX, this.eventData.stage);
				Store.set(StoreKeys.EVENT.STEP, 0);
				Store.set(StoreKeys.EVENT.SUBSTAGE_INDEX, 0);
			}
		);
	}

	async submitPicks() {
		const groupStagePicks = Store.get(StoreKeys.EVENT.USER.GROUP_PICKS);
		const matchPicks = Store.get(StoreKeys.EVENT.USER.BRACKET_PICKS);

		// Don't transfer data we don't need to.
		for (const pick of matchPicks) {
			pick.bottomTeam = null;
			pick.topTeam = null;
		}

		const eventId = this.eventData.id;
		await PickService.saveUserPicks(eventId, {
			groupStagePicks,
			matchPicks,
		});
	}
}
