import * as React from "react";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import EventService from "services/EventService";
import Store from "common/store";
import {
	FormControlLabel,
	makeStyles,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from "@material-ui/core";
import { Colors } from "styles/Colors";
import { SetLoading } from "common/components/Loader/Loader";
import FatButton from "common/components/FatButton/FatButton";
import TrophyService from "services/TrophyService";
import { toast } from "react-toastify";

const propKeys = {
	events: StoreKeys.ADMIN.GIVE_TROPHY.EVENTS,
};

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(),
		minWidth: 120,
		marginBottom: theme.spacing(2),
	},
	fullWidth: {
		width: "100%",
	},
	selectInGrid: {
		minWidth: 200,
	},
	padding: {
		padding: theme.spacing(2),
	},
	paper: {
		color: Colors.primary,
		backgroundColor: Colors.panel,
	},
	marginBottom: {
		marginBottom: theme.spacing(2),
	},
}));

function GiveTrophyC({ events }) {
	const [selectedEventId, setEventId] = React.useState(null);
	const classes = useStyles();
	React.useEffect(() => {
		if (!events) {
			SetLoading(true, "Loading Events");
			EventService.getEventsNotRewarded().then((events) => {
				Store.set(StoreKeys.ADMIN.GIVE_TROPHY.EVENTS, events);
				SetLoading(false);
			});
		}
	}, [events]);

	const onEventChange = (event) => {
		const eventId = event.target.value;
		setEventId(eventId);
	};

	const rewardTrophies = async () => {
		if (!selectedEventId) {
			return;
		}

		try {
			SetLoading(true);
			await TrophyService.giveTrophies(selectedEventId);
			const events = await EventService.getEventsNotRewarded();
			Store.set(StoreKeys.ADMIN.GIVE_TROPHY.EVENTS, events);
			SetLoading(false);
			toast("Trophies Rewarded!", { type: "success" });
		} catch (error) {
			SetLoading(false);
			toast("Contact Lor!", { type: "error" });
		}
	};

	if (!events?.length) {
		return <Typography>There are no events to reward trophies for.</Typography>;
	}

	return (
		<Paper
			className={
				classes.paper + " " + classes.marginBottom + " " + classes.padding
			}
			elevation={3}
		>
			<Typography>Events to Reward Trophies For</Typography>
			<div style={{ overflowY: "scroll", maxHeight: 400 }}>
				<RadioGroup aria-label="series" name="series" onChange={onEventChange}>
					{events.map((e, i) => {
						return (
							<FormControlLabel
								key={i}
								value={e.id.toString()}
								control={<Radio />}
								label={e.nameOverride}
							/>
						);
					})}
				</RadioGroup>
			</div>
			<FatButton
				primary
				text="Give Trophies"
				onClick={() => {
					rewardTrophies();
				}}
				disabled={!selectedEventId}
			/>
		</Paper>
	);
}

export default function GiveTrophy(props) {
	return connect(<GiveTrophyC />, propKeys, props);
}
