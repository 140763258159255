import { useContext } from "react";
import { matchContext } from "bracket-system/core/match-context";

const useMatchHighlightContext = ({ bracketSnippet = null }) => {
	const {
		state: { hoveredPartyId, highlightedMatchIds },
	} = useContext(matchContext);
	const previousTopMatch = bracketSnippet?.previousTopMatch;
	const previousBottomMatch = bracketSnippet?.previousBottomMatch;
	const currentMatch = bracketSnippet?.currentMatch;

	const topHighlighted =
		hoveredPartyId != null &&
		currentMatch?.participants?.some((p) => p.id === hoveredPartyId) &&
		previousTopMatch?.participants?.some((p) => p.id === hoveredPartyId);

	const bottomHighlighted =
		hoveredPartyId != null &&
		currentMatch?.participants?.some((p) => p.id === hoveredPartyId) &&
		previousBottomMatch?.participants?.some((p) => p.id === hoveredPartyId);

	const matchHighlighted = highlightedMatchIds?.some(
		(id) => id === currentMatch?.id
	);
	return { topHighlighted, bottomHighlighted, matchHighlighted };
};

export default useMatchHighlightContext;
