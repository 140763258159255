import axiosWrapper from "common/axiosWrapper";
import League from "common/types/League";
import JoinedLeagueInfo from "common/types/JoinedLeagueInfo";

const baseUrl = "/api/leagues";

/** The League service which calls the API endpoints for the League Controller */
export default class LeagueService {
	/**
	 * Gets the user's league limit.
	 * @returns {Promise<number>} An async result.
	 */
	static async getUserLeagueLimit() {
		return await axiosWrapper.getCached(`${baseUrl}/userLimit`);
	}

	/**
	 * Gets the league.
	 * @param {number} leagueId
	 * @returns {Promise<League>} An async result containing the league.
	 */
	static async getLeague(leagueId) {
		if (!leagueId) {
			return null;
		}

		return await axiosWrapper.get(`${baseUrl}/get?leagueId=${leagueId}`);
	}

	/**
	 * Gets leagues.
	 * @returns {Promise<Array<League>>} An async result containing all leagues.
	 */
	static async getLeagues() {
		return await axiosWrapper.get(`${baseUrl}/all`);
	}

	/**
	 * Gets the 10 newest leagues.
	 * @returns {Promise<Array<League>>} An async result containing all leagues.
	 */
	static async getNewLeagues() {
		return await axiosWrapper.get(`${baseUrl}/new`);
	}

	/**
	 * Gets the top 10 popular leagues.
	 * @returns {Promise<Array<League>>} An async result containing all leagues.
	 */
	static async getPopularLeagues() {
		return await axiosWrapper.get(`${baseUrl}/popular`);
	}

	/**
	 * Gets the top 10 popular leagues.
	 * @returns {Promise<Array<League>>} An async result containing all leagues.
	 */
	static async searchLeagues(term) {
		if (!term) {
			return [];
		}

		return await axiosWrapper.getCached(`${baseUrl}/search?term=${term}`);
	}

	/**
	 * Gets joined leagues.
	 * @returns {Promise<Array<JoinedLeagueInfo>>} An async result containing all leagues that the current user has joined.
	 */
	static async getJoinedLeagues() {
		return await axiosWrapper.get(`${baseUrl}/joined`);
	}

	/**
	 * Gets analyst league rankings for an event.
	 * @returns {Promise<Array>} An async result containing all rankings for the analyst league and the specified event.
	 */
	static async getAnalystLeague() {
		return await axiosWrapper.getCached(`${baseUrl}/analyst`);
	}

	/**
	 * Gets analyst league rankings for an event.
	 * @returns {Promise<Array>} An async result containing all rankings for the analyst league and the specified event.
	 */
	static async getAnalystLeagueRankings(eventId) {
		return await axiosWrapper.getCached(
			`${baseUrl}/analystRankings?eventId=${eventId}`
		);
	}

	/**
	 * Gets league rankings for an event.
	 * @returns {Promise<Array>} An async result containing all rankings for the specified league and the specified event.
	 */
	static async getLeagueRankings(leagueId, eventId) {
		if (!leagueId) {
			return [];
		}

		return await axiosWrapper.getCached(
			`${baseUrl}/rankings?leagueId=${leagueId}&eventId=${eventId}`
		);
	}

	/**
	 * Updates a league if it exists, otherwise creates a new league.
	 * @param {number} leagueId League id if it exists
	 * @param {string} name
	 * @param {string} password
	 * @param {string} confirmPassword
	 * @param {boolean} requireDiscord
	 * @param {boolean} requireReddit
	 * @returns {Promise<void>} An async result.
	 */
	static async createOrUpdateLeague(
		leagueId,
		name,
		password,
		confirmPassword,
		nameplateId,
		requireDiscord,
		requireReddit
	) {
		return await axiosWrapper.post(`${baseUrl}/save`, {
			id: leagueId,
			name,
			password,
			confirmPassword,
			nameplateId,
			requireDiscord,
			requireReddit,
		});
	}
	/**
	 * Deletes a league.
	 * @param {number} leagueId
	 * @returns {Promise<void>} An async result.
	 */
	static async deleteLeague(leagueId) {
		if (!leagueId) {
			return;
		}

		return await axiosWrapper.delete(`${baseUrl}/delete?leagueId=${leagueId}`);
	}

	/**
	 * Gets all members of a given league
	 * @param {number} leagueId
	 * @returns {Promise<Array<LeagueMember>>} An async result containing league members.
	 */
	static async getLeagueMembers(leagueId) {
		if (!leagueId) {
			return [];
		}

		return await axiosWrapper.getCached(
			`${baseUrl}/members?leagueId=${leagueId}`
		);
	}

	/**
	 * Gets all events during a given league.
	 * @param {number} leagueId
	 * @returns
	 */
	static async getLeagueEvents(leagueId) {
		if (!leagueId) {
			return [];
		}

		return await axiosWrapper.getCached(
			`${baseUrl}/events?leagueId=${leagueId}`
		);
	}

	/**
	 * Joins a league.
	 * @param {number} leagueId
	 * @param {string} password
	 * @returns {Promise<void>} An async result.
	 */
	static async joinLeague(leagueId, password) {
		if (!leagueId) {
			return;
		}

		return await axiosWrapper.post(`${baseUrl}/join`, { leagueId, password });
	}

	/**
	 * Leaves a league.
	 * @param {number} leagueId
	 * @returns {Promise<void>} An async result.
	 */
	static async leaveLeague(leagueId) {
		if (!leagueId) {
			return;
		}

		return await axiosWrapper.delete(`${baseUrl}/leave?leagueId=${leagueId}`);
	}

	/**
	 * Removes a user from a league.
	 * @param {number} leagueId
	 * @param {number} userId The id of the user to be removed.
	 * @returns {Promise<void>} An async result.
	 */
	static async removeFromLeague(leagueId, userId) {
		if (!leagueId || !userId) {
			return;
		}

		return await axiosWrapper.delete(
			`${baseUrl}/removeMember?leagueId=${leagueId}&userId=${userId}`
		);
	}
}
