import * as React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import StoreKeys from "common/storeKeys";
import { connect } from "common/connect";
import { EventStageTypes } from "common/constants/EventStageTypes";
import PicksHelper from "common/picksHelper";
import EventHeader from "common/components/EventHeader/EventHeader";
import EventFooter from "common/components/EventFooter/EventFooter";
import Store from "common/store";
import { useForceUpdate } from "common/helpers/componentHelper";
import BracketRenderer from "./Brackets/BracketRenderer";
import SwissStageRenderer from "./SwissStage/SwissStageRenderer";
import GroupStageRenderer from "./GroupStage/GroupStageRenderer";
// eslint-disable-next-line no-unused-vars
import { EventFormatData } from "common/helpers/eventFormatHelper";
import StageSelector from "./StageSelector";

const propKeys = {
	eventData: StoreKeys.EVENT.EVENTDATA,
	stageIndex: StoreKeys.EVENT.STAGE_INDEX,
	format: StoreKeys.EVENT.FORMAT,
	isEditing: StoreKeys.EVENT.IS_EDITING,
	showingOfficial: StoreKeys.EVENT.SHOW_OFFICIAL,
};

/**
 * @typedef EventRendererCProps
 * @type {object}
 * @property {any} eventData
 * @property {number} stageIndex
 * @property {EventFormatData} format
 * @property {boolean} isEditing
 * @property {boolean} isAdmin
 * @property {any} adminEventData
 */

/**
 * @param {EventRendererCProps} props
 */
function EventRendererC({
	eventData,
	stageIndex,
	format,
	isEditing,
	showingOfficial,
	isAdmin,
}) {
	const forceUpdate = useForceUpdate();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	React.useEffect(() => {
		const callback = () => {
			forceUpdate();
		};
		window.addEventListener("resize", callback);

		return () => {
			window.removeEventListener("resize", callback);
		};
	});

	if (!eventData || !format) {
		return <></>;
	}

	const picksHelper = new PicksHelper(eventData);
	const isCurrentStage = stageIndex === eventData?.stage;
	const isLocked = !isAdmin && (!isCurrentStage || picksHelper.isLocked());

	const onEdit = () => {
		if (isLocked) {
			return;
		}

		const updates = {};
		updates[StoreKeys.EVENT.IS_EDITING] = true;
		updates[StoreKeys.EVENT.STEP] = 0;
		Store.setMany(updates);
	};

	const onShowOfficialResults = () => {
		Store.set(
			StoreKeys.EVENT.SHOW_OFFICIAL,
			!Store.get(StoreKeys.EVENT.SHOW_OFFICIAL)
		);
	};

	const currentStage = format.stages[stageIndex];
	const renderWidth = !isSmall
		? window.innerWidth - 382
		: window.innerWidth - 24;

	const isBracketStage =
		currentStage?.type === EventStageTypes.Playoffs ||
		(currentStage?.type === EventStageTypes.Group &&
			currentStage?.groupStageDetails?.hasGroupBrackets);

	const isSwissStage = currentStage?.type === EventStageTypes.Swiss;

	const isGroupStage =
		currentStage?.type === EventStageTypes.Group &&
		!currentStage?.groupStageDetails?.hasGroupBrackets;

	const hasMultipleStages = format.stages.length > 0;

	return (
		<>
			{(isLocked || !isEditing) && <EventHeader width={renderWidth} />}
			{hasMultipleStages && !isEditing && <StageSelector />}
			{isBracketStage && <BracketRenderer width={renderWidth} />}
			{isSwissStage && <SwissStageRenderer width={renderWidth} />}
			{isGroupStage && <GroupStageRenderer width={renderWidth} />}
			{(isLocked || !isEditing) && (
				<EventFooter
					width={renderWidth}
					onEdit={onEdit}
					showingOfficialResults={showingOfficial}
					showOfficialResults={onShowOfficialResults}
					disableShowPlayers={!isSwissStage && !isGroupStage}
					picksLocked={isLocked}
				/>
			)}
		</>
	);
}

export default function EventRenderer(props) {
	return connect(<EventRendererC />, propKeys, props);
}
