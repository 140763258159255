import { Container } from "@material-ui/core";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import { Helmet } from "react-helmet";
// eslint-disable-next-line no-unused-vars
import { SurveyDto } from "common/types/Survey/SurveyDto";
import SurveySelector from "./SurveySelector";
import SurveyEditor from "./SurveyEditor";

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {SurveyDto} current
 */

const propKeys = {
	current: StoreKeys.ADMIN.SURVEYS.CURRENT,
};

/**
 * @param {ComponentProps} props
 */
function SurveyBuilderC(props) {
	const { current } = props;

	return (
		<Container maxWidth={false}>
			<Helmet>
				<title>Pickstop - Survey Builder</title>
			</Helmet>
			{!current && (
				<>
					<h1>Survey Editor</h1>
					<SurveySelector />
				</>
			)}
			{current && <SurveyEditor />}
		</Container>
	);
}

function SurveyBuilder(props) {
	return connect(<SurveyBuilderC />, propKeys, props);
}

export default SurveyBuilder;
