import { Box, makeStyles } from "@material-ui/core";
import * as React from "react";
import * as $ from "jquery";
import { Colors } from "styles/Colors";
import Leaderboard from "common/components/Leaderboard/Leaderboard";
import UserManager from "common/userManager";
import TrophyService from "services/TrophyService";
import UserCard from "common/components/UserCard";
import ContentHeader from "common/components/ContentHeader/ContentHeader";

const useStyles = makeStyles({
	root: {
		backgroundColor: Colors.panel,
		borderRadius: 12,
		padding: 10,
		marginBottom: 5,
	},
	heading: {
		color: Colors.pickstop,
		marginTop: 5,
		marginBottom: 5,
		marginleft: 10,
	},
	leaderboardDetails: {
		textAlign: "center",
		margin: "0 10px",
		position: "relative",
	},
	fullSvg: {
		display: "inline-block",
		position: "relative",
		left: -5,
		top: 3,
		stroke: Colors.primary,
		fill: Colors.primary,
	},
	yourSvg: {
		display: "inline-block",
		position: "relative",
		left: -3,
		top: 1,
		stroke: Colors.primary,
		fill: Colors.primary,
	},
});

export default function AwardLeaders(props) {
	const styles = useStyles();

	const [awardLeaderState, setAwardLeaderState] = React.useState({
		items: [],
		totalRecords: 0,
		rowNumberToGoTo: 1,
		userIdToGoTo: null,
	});
	const [currentUserRow, setCurrentUserRow] = React.useState(null);

	React.useEffect(() => {
		TrophyService.getTrophyLeaderboard().then((leaderboardData) => {
			let copy = { ...awardLeaderState };
			copy.items = leaderboardData;
			setAwardLeaderState(copy);
		});
		TrophyService.getTrophyLeaderboardUserRow(UserManager.getUserId()).then(
			(data) => {
				setCurrentUserRow(data);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getLeaderboardData = (startRow, overwriteData, isUp) => {
		const def = $.Deferred();
		TrophyService.getTrophyLeaderboard(startRow).then((leaderboard) => {
			let items = [...awardLeaderState.items];
			let newItems = null;
			if (overwriteData) {
				newItems = leaderboard;
			} else {
				if (isUp) {
					newItems = leaderboard.concat(items);
				} else {
					newItems = items.concat(leaderboard);
				}
			}
			let copy = { ...awardLeaderState };
			copy.items = newItems;
			copy.rowNumberToGoTo = 1;
			copy.userIdToGoTo = null;
			setAwardLeaderState(copy);
			def.resolve(newItems.length > 0);
		});
		return def.promise();
	};

	const getLeaderboardAdditionalData = (fromTop) => {
		return TrophyService.getTrophyLeaderboardAdditional(fromTop).then(
			(info) => {
				let itemIdx = getItemIdx(UserManager.getUserId(), info.records);
				let copy = { ...awardLeaderState };
				copy.items = info.records;
				copy.totalRecords = info.totalRecords;
				copy.rowNumberToGoTo = fromTop || !itemIdx ? 1 : itemIdx;
				copy.userIdToGoTo = fromTop ? null : UserManager.getUserId();
				setAwardLeaderState(copy);
			}
		);
	};

	const searchLeaderboardData = (term) => {
		return TrophyService.searchTrophyLeaderboard(term).then((data) => {
			let copy = { ...awardLeaderState };
			copy.items = data;
			copy.totalRecords = data.length;
			copy.rowNumberToGoTo = 1;
			copy.userIdToGoTo = null;
			setAwardLeaderState(copy);
		});
	};

	const getItemIdx = (id, items) => {
		if (!id || !items) {
			return null;
		}
		return items.findIndex((r) => {
			return r.userId === id;
		});
	};

	const leaderboardRowJsx = (record) => {
		return (
			<>
				<span className="position">{record.rank}</span>
				<span className="name">
					<UserCard
						userId={record.userId}
						dontLoadData={true}
						userName={record.username}
						pinnedEventName={record.pinnedEventName}
						pinnedTrophyImagePath={record.pinnedTrophyImagePath}
						nameplateId={record.nameplateId}
					/>
				</span>
				<span className={`topTrophies`}>
					{!!record.event1Name && (
						<img
							className={"awardImage"}
							alt={record.event1Name}
							src={record.trophy1ImagePath}
						/>
					)}
					{!!record.event2Name && (
						<img
							className={"awardImage"}
							alt={record.event2Name}
							src={record.trophy2ImagePath}
						/>
					)}
					{!!record.event3Name && (
						<img
							className={"awardImage"}
							alt={record.event3Name}
							src={record.trophy3ImagePath}
						/>
					)}
					{!!record.event4Name && (
						<img
							className={"awardImage"}
							alt={record.event4Name}
							src={record.trophy4ImagePath}
						/>
					)}
					{!!record.event5Name && (
						<img
							className={"awardImage"}
							alt={record.event5Name}
							src={record.trophy5ImagePath}
						/>
					)}
				</span>
			</>
		);
	};

	const currentUserRowJsx = !!currentUserRow
		? leaderboardRowJsx(currentUserRow)
		: null;
	const headerJsx = <ContentHeader text="Award Leaders" />;
	const tableDataAry = !awardLeaderState.items
		? []
		: awardLeaderState.items.map((record) => {
				return leaderboardRowJsx(record);
		  });

	const modalDataAry = awardLeaderState.items.map((record, index) => {
		return (
			<div data-id={record.userId} key={index} className={"leaderboard-row"}>
				<span className="position">{record.rank}</span>

				<span className="name">
					<UserCard
						userId={record.userId}
						dontLoadData={true}
						userName={record.username}
						pinnedEventName={record.pinnedEventName}
						pinnedTrophyImagePath={record.pinnedTrophyImagePath}
						nameplateId={record.nameplateId}
					/>
				</span>
				<span className={`topTrophies`}>
					{!!record.event1Name && (
						<img
							className={"awardImage"}
							alt={record.event1Name}
							src={record.trophy1ImagePath}
						/>
					)}
					{!!record.event2Name && (
						<img
							className={"awardImage"}
							alt={record.event2Name}
							src={record.trophy2ImagePath}
						/>
					)}
					{!!record.event3Name && (
						<img
							className={"awardImage"}
							alt={record.event3Name}
							src={record.trophy3ImagePath}
						/>
					)}
					{!!record.event4Name && (
						<img
							className={"awardImage"}
							alt={record.event4Name}
							src={record.trophy4ImagePath}
						/>
					)}
					{!!record.event5Name && (
						<img
							className={"awardImage"}
							alt={record.event5Name}
							src={record.trophy5ImagePath}
						/>
					)}
				</span>
			</div>
		);
	});

	if (!awardLeaderState.items || awardLeaderState.items.length < 1) {
		return (
			<Box className={styles.root}>
				{headerJsx}
				No leaderboard data to show.
			</Box>
		);
	}
	const hasItems =
		!!awardLeaderState &&
		awardLeaderState.items &&
		awardLeaderState.items.length > 0;
	return (
		<>
			<Box className={styles.root}>
				{headerJsx}
				<Leaderboard
					leaderboardClass={`trophy`}
					loadData={getLeaderboardData}
					loadDataAddtl={getLeaderboardAdditionalData}
					totalRecords={awardLeaderState.totalRecords}
					leaderboardHeaders={[``, `Name`, `Top Trophies`]}
					tableData={tableDataAry}
					modalTableData={modalDataAry}
					eventData={null}
					hasBracket={true}
					noDataEl={null}
					firstRow={hasItems ? awardLeaderState.items[0].rank : 0}
					lastRow={
						hasItems
							? awardLeaderState.items[awardLeaderState.items.length - 1].rank
							: 0
					}
					rowNumberToGoTo={awardLeaderState.rowNumberToGoTo}
					userIdToGoTo={awardLeaderState.userIdToGoTo}
					searchLeaderboard={searchLeaderboardData}
					totalEntrants={hasItems ? awardLeaderState.items[0].totalRecords : 0}
					currentUserRow={currentUserRowJsx}
				/>
			</Box>
		</>
	);
}
