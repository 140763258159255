import axiosWrapper from "common/axiosWrapper";
import { toMMDDYYYY } from "common/helpers/dateHelper";
// eslint-disable-next-line no-unused-vars
import { AbiosTournament } from "../common/types/Abios/AbiosTournament";
// eslint-disable-next-line no-unused-vars
import { EventCreateRequestDto } from "../common/types/EventCreateRequestDto";

const baseUrl = "/api/eventBuilder";

/** The Event service which calls the API endpoints for the Event Builder Controller */
export default class EventBuilderService {
	/**
	 * Gets a list of upcoming events.
	 * @returns {Promise<Array<AbiosTournament>>} the upcoming Abios Tournaments within 30 days from current time.
	 */
	static async getUpcomingEvents(gameId, from, to) {
		return await axiosWrapper.get(
			`${baseUrl}/upcoming?gameId=${gameId}&from=${toMMDDYYYY(
				from
			)}&to=${toMMDDYYYY(to)}`
		);
	}

	/**
	 * Gets a list of previous events.
	 * @returns {Promise<Array<AbiosTournament>>} the past Abios Tournaments within the last 30 days.
	 */
	static async getRecentEvents() {
		// not currently called anywhere
		return await axiosWrapper.get(`${baseUrl}/recent`);
	}

	/**
	 * Create an event with the provided data.
	 *
	 * @param {EventCreateRequestDto} createRequest
	 * @returns {Promise<any>} an EventDto object.
	 */
	static async createEvent(createRequest) {
		return await axiosWrapper.post(`${baseUrl}/create`, createRequest);
	}
}
