import React from "react";
import Connectors from "bracket-system/components/connector";

import { getCalculatedStyles } from "../settings";
import {
	calculatePositionOfMatchUpperBracket,
	calculatePositionOfMatchLowerBracket,
	getColumnSizeArray,
} from "./calculate-match-position";

const ConnectorsUpper = ({
	columns,
	bracketSnippet,
	rowIndex,
	columnIndex,
	style,
	offsetY = 0,
	isLowerBracket = false,
	lowerColumns,
	isQualifierRound,
}) => {
	const { columnWidth, rowHeight, canvasPadding } = getCalculatedStyles(style);

	const isUpperSeedingRound = isLowerBracket && columnIndex % 2 !== 0;
	const positioningFunction = isLowerBracket
		? calculatePositionOfMatchLowerBracket
		: calculatePositionOfMatchUpperBracket;

	const isDirectFeed =
		columns[columnIndex].length === columns[columnIndex - 1].length;

	// If the previous column has the same number of matches then some teams prequalified.
	// Offset the connector column by some value
	const columnArray = getColumnSizeArray(columns);
	const isStraightConnector =
		columnArray.indexOf(columns[columnIndex].length) ===
		columnArray.indexOf(columns[columnIndex - 1].length);

	// If its not a direct feed, treat normally.
	if (!isDirectFeed) {
		const currentMatchPosition = positioningFunction(
			columns,
			rowIndex,
			columnIndex,
			{
				canvasPadding,
				rowHeight,
				columnWidth,
				offsetY,
			},
			lowerColumns,
			isQualifierRound
		);
		const previousBottomPosition = isUpperSeedingRound
			? rowIndex
			: (rowIndex + 1) * 2 - 1;
		const previousTopMatchPosition =
			!isUpperSeedingRound &&
			positioningFunction(
				columns,
				previousBottomPosition - 1,
				columnIndex - 1,
				{
					canvasPadding,
					rowHeight,
					columnWidth,
					offsetY,
				},
				lowerColumns,
				isQualifierRound
			);
		const previousBottomMatchPosition = positioningFunction(
			columns,
			previousBottomPosition,
			columnIndex - 1,
			{
				canvasPadding,
				rowHeight,
				columnWidth,
				offsetY,
			},
			lowerColumns,
			isQualifierRound
		);

		return (
			<Connectors
				bracketSnippet={bracketSnippet}
				previousBottomMatchPosition={previousBottomMatchPosition}
				previousTopMatchPosition={previousTopMatchPosition}
				currentMatchPosition={currentMatchPosition}
				style={style}
			/>
		);
	}

	if (isDirectFeed) {
		const currentMatchPosition = positioningFunction(
			columns,
			rowIndex,
			columnIndex,
			{
				canvasPadding,
				rowHeight,
				columnWidth,
				offsetY,
			},
			lowerColumns,
			isQualifierRound
		);

		const previousTopMatchPosition =
			!isUpperSeedingRound &&
			positioningFunction(
				columns,
				rowIndex,
				columnIndex - 1,
				{
					canvasPadding,
					rowHeight,
					columnWidth,
					offsetY,
				},
				lowerColumns,
				isQualifierRound
			);

		if (isStraightConnector) {
			currentMatchPosition.y = previousTopMatchPosition.y;
		}

		const straightConnector = (
			<Connectors
				bracketSnippet={bracketSnippet}
				previousTopMatchPosition={previousTopMatchPosition}
				currentMatchPosition={currentMatchPosition}
				style={style}
			/>
		);

		const column = columns[columnIndex];
		const bothTeamsAdvance = column[0].bothTeamsAdvance;

		if (bothTeamsAdvance) {
			const lowerMatchPosition = {
				x: currentMatchPosition.x,
				y: currentMatchPosition.y,
			};

			lowerMatchPosition.y += 48;

			const lowerAdvancingConnector = (
				<Connectors
					bracketSnippet={bracketSnippet}
					previousTopMatchPosition={previousTopMatchPosition}
					currentMatchPosition={lowerMatchPosition}
					style={style}
				/>
			);

			return (
				<>
					{straightConnector}
					{lowerAdvancingConnector}
				</>
			);
		}

		return straightConnector;
	}
};

export default ConnectorsUpper;
