/**
 *
 * @param {Array<Array<any>>} arrayList
 * @returns {Array<any>}
 */
export function flattenArrayList(arrayList) {
	const parentArray = [];
	if (!arrayList) {
		return parentArray;
	}

	for (const array of arrayList) {
		for (const item of array) {
			parentArray.push(item);
		}
	}
	return parentArray;
}

/**
 * Determines if an item has a matching record that exists in the provided array
 * based on the provided props.
 *
 * @param {Array<any>} otherArray
 * @param {any} item
 * @param {Array<string>} props
 * @returns {boolean}
 */
export function targetArrayHasMatchingRecord(otherArray, item, props) {
	if (!otherArray) {
		return false;
	}

	return (
		otherArray.filter((other) => {
			let matching = true;
			for (let prop of props) {
				matching = item[prop] === other[prop];
				if (!matching) {
					break;
				}
			}

			return matching;
		}).length > 0
	);
}
