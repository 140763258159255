import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalHeader,
	ModalBody,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import {
	GetCurrentGamePrefix
} from "common/constants/Games";
import { changeUrl } from "../../../history";
import ModalHelper from "../../../helpers/modalHelper";

export default function SurveyForPicksModal(props) {
	const targetUrl = `${GetCurrentGamePrefix()}/survey`;
	const onClick = (e) => {
		e.preventDefault();

		ModalHelper.closeModal();
		changeUrl(targetUrl);
	};

	return (
		<CustomModal>
			<ModalPanel>
				<ModalHeader>{props.header ?? "Survey Based Picks"}</ModalHeader>
				<ModalBody>
					<div>
						A Survey will be used for this stage.
						To make your picks <a onClick={onClick} style={{ cursor: "pointer" }}>Click Here</a>
					</div>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
