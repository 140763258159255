import { Button, makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { SetLoading } from "common/components/Loader/Loader";
import DeleteConfirmModal from "common/components/Modals/DeleteConfirmModal/DeleteConfirmModal";
import RecordInUseModal from "common/components/Modals/RecordInUseModal/RecordInUseModal";
import { connect } from "common/connect";
import EventFormatHelper from "common/helpers/eventFormatHelper";
import ModalHelper from "common/helpers/modalHelper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
// eslint-disable-next-line no-unused-vars
import { EventFormat } from "common/types/EventFormat";
import * as React from "react";
import { useEffect } from "react";
import EventFormatService from "services/EventFormatService";
import { Colors } from "styles/Colors";
import { Add, Edit, FileCopy, Delete } from "@material-ui/icons";
import { PanZoomHelper } from "common/helpers/panZoomHelper";

const propKeys = {
	eventFormats: StoreKeys.EVENTFORMATBUILDER.EVENT_FORMATS,
};

const classStyles = makeStyles(() => {
	return {
		dataGrid: {
			backgroundColor: Colors.panel,
			"& .MuiTablePagination-displayedRows": {
				color: Colors.primary,
			},
			"& .MuiTablePagination-actions button": {
				color: Colors.primary,
				"&:disabled": {
					color: Colors.panelLighterColor,
				},
			},
			"& .MuiDataGrid-columnHeaderTitle": {
				color: Colors.primary,
			},
		},
		dataGridRow: {
			cursor: "pointer",
			color: Colors.primary,
			"&:hover": {
				backgroundColor: `${Colors.panelLight}!important`,
			},
			"&:focus-within": {
				backgroundColor: `${Colors.panelLighterColor}!important`,
			},
		},
		button: {
			color: Colors.primary,
			backgroundColor: Colors.panelLight,
			margin: "0 10px",
			"&:disabled": {
				color: Colors.panelLighterColor,
				border: 0,
			},
		},
	};
});

const dateFormatter = (object) => object.value.split("T")[0];

const columns = [
	{ field: "id", headerName: "ID", flex: 0.2 },
	{ field: "name", headerName: "Name", flex: 1 },
	{
		field: "created",
		headerName: "Date Created",
		flex: 1,
		valueFormatter: dateFormatter,
	},
	{
		field: "modified",
		headerName: "Date Modified",
		flex: 1,
		valueFormatter: dateFormatter,
	},
];

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {EventFormat} currentFormat
 * @property {Array<EventFormat>} eventFormats
 */

/**
 * @param {ComponentProps} props
 */
export function EventFormatSelectorC(props) {
	const { eventFormats } = props;
	const [rowsSelected, setRowsSelected] = React.useState([]);
	const classNames = classStyles();

	useEffect(() => {
		if (!eventFormats) {
			EventFormatService.getAllFormats().then((data) => {
				Store.set(StoreKeys.EVENTFORMATBUILDER.EVENT_FORMATS, data);
			});
		}
	});

	/**
	 * @param {number} id
	 * @returns
	 */
	function getFormatById(id) {
		return eventFormats.find((ef) => ef.id === id);
	}

	function addEventFormat() {
		PanZoomHelper.resetView(0, 0, 0.75);
		const formatBuilder = EventFormatHelper.getEventFormatBuilder();
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, formatBuilder);
	}

	function editEventFormat() {
		if (rowsSelected.length) {
			PanZoomHelper.resetView(0, 0, 0.75);
			const format = getFormatById(rowsSelected[0]);
			const formatBuilder = EventFormatHelper.getEventFormatBuilder(format);
			Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, formatBuilder);
		}
	}

	function cloneEventFormat() {
		if (rowsSelected.length) {
			PanZoomHelper.resetView(0, 0, 0.75);
			const format = getFormatById(rowsSelected[0]);

			/** @type {EventFormat} */
			const newFormat = JSON.parse(JSON.stringify(format));
			// Reset id so it's handled as a new format when saved.
			newFormat.id = 0;

			const formatBuilder = EventFormatHelper.getEventFormatBuilder(newFormat);
			Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, formatBuilder);
		}
	}

	function deleteEventFormat() {
		if (rowsSelected.length) {
			// TODO: Prompt a confirmation modal
			// If any events use this format, block the delete of the format.
			const formatId = rowsSelected[0];

			ModalHelper.openModal(
				<DeleteConfirmModal
					objectName="Event Format"
					onConfirm={async () => {
						SetLoading(true, "Deleting Format");
						try {
							await EventFormatService.deleteFormat(formatId);
							const formats = Store.get(
								StoreKeys.EVENTFORMATBUILDER.EVENT_FORMATS
							);
							Store.set(
								StoreKeys.EVENTFORMATBUILDER.EVENT_FORMATS,
								formats.filter((ef) => ef.id !== formatId)
							);
							SetLoading(false);
						} catch (error) {
							SetLoading(false);
							ModalHelper.openModal(
								<RecordInUseModal objectName="Event Format" />
							);
						}
					}}
				/>
			);
		}
	}

	return (
		<div style={{ width: 720 }}>
			<DataGrid
				rows={eventFormats}
				columns={columns}
				pageSize={10}
				rowsPerPageOptions={[10]}
				selectionModel={rowsSelected}
				autoHeight
				hideFooterSelectedRowCount
				className={classNames.dataGrid}
				getRowClassName={() => classNames.dataGridRow}
				onSelectionModelChange={(newRowSelected) => {
					setRowsSelected(newRowSelected);
				}}
			/>
			<br />
			<Button onClick={addEventFormat} className={classNames.button}>
				<Add color="primary" />
				&nbsp; Add
			</Button>
			<Button
				onClick={editEventFormat}
				disabled={!rowsSelected.length}
				className={classNames.button}
			>
				<Edit color="primary" />
				&nbsp; Edit
			</Button>
			<Button
				onClick={cloneEventFormat}
				disabled={!rowsSelected.length}
				className={classNames.button}
			>
				<FileCopy color="primary" />
				&nbsp; Clone
			</Button>
			<Button
				onClick={deleteEventFormat}
				disabled={!rowsSelected.length}
				className={classNames.button}
			>
				<Delete color="primary" />
				&nbsp; Delete
			</Button>
		</div>
	);
}

function EventFormatSelector(props) {
	return connect(<EventFormatSelectorC />, propKeys, props);
}

export default EventFormatSelector;
