import * as React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";
import ModalHelper from "common/helpers/modalHelper";
import StoreKeys from "common/storeKeys";
import Store from "common/store";
import LeagueService from "services/LeagueService";
import { SetLoading } from "../Loader/Loader";
import LeagueDetailsModal from "../Modals/Leagues/LeagueDetailsModal";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: Colors.panelLight,
		borderRadius: 12,
		border: "1px solid " + theme.palette.primary.main,
		color: theme.palette.primary.main,
		textAlign: "left",
		display: "inline-flex",
		alignItems: "center",
		width: 200,
		height: 34,
		whiteSpace: "nowrap",
	},
	name: {
		margin: 8,
		flex: "0 0 184px",
		font: "normal normal bold 14px/24px Arial",
		maxWidth: "100%",
		textAlign: "center",
		textOverflow: "ellipsis",
		overflow: "hidden",
	},
	clickable: {
		cursor: "pointer",
	},
}));

export default function LeagueNameplate({
	id,
	name,
	nameplateId,
	disableClick,
}) {
	const styles = useStyles();
	const nameplateIdClassName = `nameplate-${nameplateId}`;
	const showLeagueInfo = async () => {
		SetLoading(true, "Loading League Info");
		const [league, events] = await Promise.all([
			LeagueService.getLeague(id),
			LeagueService.getLeagueEvents(id),
		]);

		let rankings = [];
		if (events.length > 0) {
			const lastEvent = events[events.length - 1];
			rankings = await LeagueService.getLeagueRankings(id, lastEvent.eventId);
		} else {
			// Load and show all members 0 pts
		}

		const updates = {};
		updates[StoreKeys.LEAGUES.VIEWING.LEAGUE] = league;
		updates[StoreKeys.LEAGUES.VIEWING.EVENTS] = events;
		updates[StoreKeys.LEAGUES.VIEWING.RANKINGS] = rankings;
		Store.setMany(updates);
		SetLoading(false);
		ModalHelper.openModal(<LeagueDetailsModal />);
	};

	return (
		<Box
			className={`${styles.root} ${
				nameplateId ? nameplateIdClassName : ""
			} nameplate-container ${!disableClick ? styles.clickable : ""}`}
			onClick={() => {
				if (disableClick) {
					return;
				}

				showLeagueInfo();
			}}
		>
			<span className={`${styles.name} nameplate-name`} title={name}>
				{name}
			</span>
		</Box>
	);
}
