import { makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";

export const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	toolbar: {
		minHeight: 0,
	},
	menu: {
		color: Colors.primary,
		backgroundColor: Colors.panel,
	},
	title: {
		flexGrow: 1,
	},
	navbar: {
		backgroundColor: Colors.panel,
		zIndex: 100,
	},
	bottomBar: {
		backgroundColor: Colors.panel,
		zIndex: 100,
		top: "auto",
		bottom: 0,
	},
	logo: {
		height: 48,
		marginRight: theme.spacing(2),
		cursor: "pointer",
	},
	authButtons: {
		marginLeft: "auto",
		display: "inline-flex",
		alignItems: "center",
	},
	usernameChip: {
		backgroundColor: Colors.primary,
		color: Colors.panel,
	},
	userIcon: {
		color: Colors.primary,
	},
	tabicons: {
		float: "left",
		marginRight: 4,
		width: 24,
		height: 24,
		marginTop: -2,
	},
	smallTabIcons: {
		display: "block",
		margin: "0 auto",
		width: 24,
		height: 24,
		marginTop: 4,
	},
	gameMenu: {
		label: {
			width: 160,
		},
	},
	notification: {
		fontSize: 14,
		"& b": {
			color: Colors.pickstop,
		},
	},
}));

export const gameMenu = makeStyles((theme) => ({
	label: {
		width: 160,
	},
}));

export const bottomTabs = makeStyles((theme) => ({
	indicator: {
		backgroundColor: Colors.primary,
		height: 1,
		top: 0,
	},

	root: {
		width: "100%",
	},
}));

export const tabs = makeStyles((theme) => ({
	indicator: {
		backgroundColor: Colors.body,
		height: 0,
		borderLeft: `1px solid ${theme.palette.secondary.main}`,
		borderRight: `1px solid ${theme.palette.secondary.main}`,
	},
}));

export const tab = makeStyles((theme) => ({
	root: {
		"&$selected": {
			backgroundColor: Colors.body,
			color: theme.palette.secondary.main,
			borderLeft: `1px solid ${theme.palette.secondary.main}`,
			borderRight: `1px solid ${theme.palette.secondary.main}`,
			stroke: theme.palette.secondary.main,
			fill: theme.palette.secondary.main,
			"& span": {
				color: theme.palette.secondary.main,
			},
			"& svg": {
				color: theme.palette.secondary.main,
			},
		},
		"&$disabled": {
			color: Colors.body,
			stroke: Colors.body,
			fill: Colors.body,
		},
		"& svg": {
			color: theme.palette.primary.main,
		},
		backgroundColor: Colors.body,
		color: theme.palette.primary.main,
		stroke: theme.palette.primary.main,
		fill: theme.palette.primary.main,
		display: "block",
		"& span": {
			whiteSpace: "nowrap",
			color: theme.palette.primary.main,
			marginBottom: 2,
		},
	},
	selected: {},
	disabled: {},
}));
