// import { PeopleAlt } from "@material-ui/icons";
import { Tabs } from "common/constants/Routes";
import React from "react";
import { ReactComponent as Pick } from "icons/Pick.svg";
import { ReactComponent as Trophy } from "icons/Trophy.svg";
import { ReactComponent as Crown } from "icons/Crown.svg";
import * as Styles from "./styles";
import { Poll } from "@material-ui/icons";

export default function useTabList(screen = "large", hasSurvey = false) {
  let iconStyles;
  if (screen === "large") {
    iconStyles = Styles.styles().tabicons;
  } else {
    iconStyles = Styles.styles().smallTabIcons;
  }

  const tabs = [
    {
      title: "PICK'EMS",
      value: Tabs.PICKEMS,
      visible: true,
      get icon() {
        return <Pick className={iconStyles} title={this.title} />;
      },
    },
    {
      title: "LEAGUES",
      value: Tabs.LEAGUES,
      visible: true,
      get icon() {
        return <Crown className={iconStyles} title={this.title} />;
      },
    },
    {
      title: "TROPHY ROOM",
      value: Tabs.TROPHYROOM,
      visible: true,
      get icon() {
        return <Trophy className={iconStyles} title={this.title} />;
      },
    },
    {
      title: "2024 Championship",
      value: Tabs.RLAWARDS,
      visible: hasSurvey,
      get icon() {
        return <Poll className={iconStyles} title={this.title} />;
      },
    },
  ];

  /* 	if (hasSurvey) {
		tabs.push({
			title: "RL PRO AWARDS",
			value: Tabs.RLAWARDS,
			get icon() {
				return <Poll className={iconStyles} title={this.title} />;
			},
		});
	} */

  return tabs;
}
