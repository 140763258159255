import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as DiscordImage } from "icons/Discord.svg";

const useStyles = makeStyles((theme) => ({
	button: {
		cursor: "pointer",
		margin: 2,

		"&.disabled": {
			opacity: 0.3,
		},
	},
}));

export default function Discord(props) {
	const classNames = useStyles();

	const clickDiscord = () => {
		window.open("https://discord.gg/SHrtVxChFF", "_blank");
	};

	return <DiscordImage className={classNames.button} onClick={clickDiscord} />;
}
