import * as React from "react";
import { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core";
import { takeUntil, Subject } from "rxjs";
import { Colors } from "styles/Colors";

const useStyles = makeStyles((theme) => {
	return {
		defaultClass: {
			display: "flex",
			flexDirection: "column",
			transition: "background-color 1s",
			borderRadius: theme.shape.borderRadius,
		},
		defaultHeader: {
			margin: 8,
			marginTop: 16,
			cursor: "pointer",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		exactMatch: {
			transition: "background-color 0s",
			backgroundColor: Colors.body,
		},
	};
});

export default function Collapse({
	startOpen,
	children,
	headerElement,
	headerClassName,
	className,
	childClassName,
	focusObservable,
	id,
}) {
	const classNames = useStyles();
	const [open, setOpen] = useState(startOpen);
	const [exactMatch, setExactMatch] = useState(false);

	const onClick = () => {
		setOpen(!open);
	};

	React.useEffect(() => {
		if (focusObservable) {
			const destroy = new Subject();
			focusObservable.pipe(takeUntil(destroy)).subscribe((focusedId) => {
				setOpen(focusedId.startsWith(id));
				setExactMatch(focusedId === id);

				setTimeout(() => {
					setExactMatch(false);
				});
			});

			return () => {
				destroy.next();
			};
		}
	});

	return (
		<div
			id={id ?? ""}
			className={`${classNames.defaultClass} ${className ?? ""} ${
				exactMatch && classNames.exactMatch
			}`}
		>
			<div
				className={`${classNames.defaultHeader} ${headerClassName}`}
				onClick={onClick}
			>
				{headerElement}
				{open && (
					<KeyboardArrowUpIcon color="primary" style={{ float: "right" }} />
				)}
				{!open && (
					<KeyboardArrowDownIcon color="primary" style={{ float: "right" }} />
				)}
			</div>
			<div hidden={!open} className={childClassName}>
				{children}
			</div>
		</div>
	);
}
