import * as React from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { ModalPanelTransparent } from "../../CustomModal/Modal/ModalFrame";
import ScorePillDetails from "../../ScorePillDetails/ScorePillDetails";

export default function ScorePillModal(props) {
	return (
		<CustomModal>
			<ModalPanelTransparent>
				<ScorePillDetails {...props} />
			</ModalPanelTransparent>
		</CustomModal>
	);
}
