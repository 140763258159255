import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import * as React from "react";
import "../SuccessFailModal/SuccessFailModal.scss";
import Discord from "common/components/Discord/Discord";

export default function ErrorModal({ message, displayContactDiscord }) {
	message = typeof message === "object" ? JSON.stringify(message) : message;
	return (
		<>
			<CustomModal>
				<ModalPanel className="success-fail-modal fail">
					<ModalHeader>Error</ModalHeader>
					<ModalBody className="success-fail-section">
						<label>{message}</label>
						<br />
						{(displayContactDiscord ?? true) && (
							<>
								<label>
									If problem persists, please contact us on Discord:
								</label>
								<br />
								<Discord />
							</>
						)}
					</ModalBody>
				</ModalPanel>
			</CustomModal>
		</>
	);
}
