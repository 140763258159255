import * as React from "react";
import {
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { Colors } from "styles/Colors";
import * as Styles from "components/Layout/styles";

const useStyles = makeStyles({
	heading: {
		color: Colors.pickstop,
		marginTop: 0,
		marginBottom: 5,
		textTransform: "uppercase",
		font: "normal normal normal 16px/27px Arial",
		letterSpacing: "1.2px",
	},
	icon: {
		height: 18,
		width: 18,
		display: "inline-block",
		position: "relative",
		top: 2,
		marginRight: 8,
	},
	rightIcon: {
		display: "flex",
		float: "right",
		padding: 0,
		color: Colors.pickstop,
	},
	menu: {
		zIndex: "1600 !important",
	},
});

export default function ContentHeader({ icon, text, menuItems }) {
	const styles = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	if (icon) {
		icon = React.cloneElement(icon, { className: styles.icon });
	}

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<h4 className={styles.heading}>
			{icon ? icon : <></>}
			{text}
			{menuItems &&
				<>
					<IconButton
						aria-label="header-menu"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						className={`${styles.icon} ${styles.rightIcon}`}
						onClick={handleMenuClick}
					>
						<MoreHoriz />
					</IconButton>
					<Menu
						classes={{ paper: Styles.styles().menu }}
						className={styles.menu}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
					>
						{menuItems.map((menuItem, index) =>
							<MenuItem onClick={() => { handleMenuClose(); menuItem.onClick(); }} key={index}>{menuItem.text}</MenuItem>
						)}
					</Menu>
				</>
			}
		</h4>
	);
}
