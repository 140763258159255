// eslint-disable-next-line no-unused-vars
import { SurveyPromptDto } from "./SurveyPromptDto";

export class SurveyDto {
	/** @type {number} */
	surveyId;
	/** @type {string} */
	title;
	/** @type {string} */
	description;
	/** @type {Date} */
	openTime;
	/** @type {Date} */
	closeTime;
	/** @type {SurveyPromptDto[]} */
	prompts;

	constructor() {
		this.openTime = new Date();
		this.closeTime = new Date();
		this.prompts = [];
	}
}
