import * as React from "react";
import axiosWrapper from "common/axiosWrapper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import ModalHelper from "common/helpers/modalHelper";
import EmailUsernameChangePrompt from "common/components/Modals/EmailUsernameChangePrompt/EmailUsernameChangePrompt";
import UsernameChangePrompt from "common/components/Modals/UsernameChangePrompt/UsernameChangePrompt";
import LoginModal from "common/components/Modals/LoginModal/LoginModal";
import RegisterModal from "common/components/Modals/RegisterModal/RegisterModal";
// eslint-disable-next-line no-unused-vars
import AuthenticationResultDto from "common/types/AuthenticationResultDto";
import AuthService from "services/AuthService";

export default class UserManager {
	static loginCallbacks = [];

	/**
	 * @returns {number | null} the user id if set in session storage.
	 */
	static getUserId() {
		return Store.get(StoreKeys.USER.ID);
	}

	/**
	 * @returns {string | null} the username if set in session storage.
	 */
	static getUsername() {
		return Store.get(StoreKeys.USER.USERNAME);
	}

	static setUsername(username) {
		Store.set(StoreKeys.USER.USERNAME, username);
	}

	/**
	 * @returns {boolean} true if the user has a token in storage.
	 */
	static isLoggedIn() {
		return Store.get(StoreKeys.USER.LOGGED_IN);
	}

	/**
	 * Login the user with the provided username and password.
	 *
	 * @param {string} username
	 * @param {string} password
	 * @returns {Promise<void>} The response containing a token if successful.
	 */
	static login(username, password) {
		return axiosWrapper
			.post("/api/auth/login", {
				username,
				password,
			})
			.then(UserManager.handlePostLogin);
	}

	/**
	 * Logout the user.
	 */
	static async logout() {
		await AuthService.logout();
	}

	/**
	 * Register the user with the provided username and password.
	 *
	 * @param {string} username
	 * @param {string} email
	 * @param {string} password
	 * @param {string} confirmPassword
	 */
	static register(username, email, password, confirmPassword) {
		return axiosWrapper.post("/api/account/register", {
			username,
			email,
			password,
			confirmPassword,
		});
	}

	/**
	 * Attempts to change the user's username.
	 *
	 * @param {string} username The username to change to.
	 */
	static changeUsername(username) {
		return axiosWrapper
			.post(`api/account/username`, { username })
			.then((result) => {
				if (!result.error) {
					sessionStorage.setItem(UserManager.pickstopUser, username);
				}

				return result;
			});
	}

	/**
	 * Attempts to change the user's password based on reset id.
	 *
	 * @param {string} resetId
	 * @param {string} username
	 * @param {string} password
	 * @param {string} confirmPassword
	 */
	static changePassword(resetId, username, password, confirmPassword) {
		return axiosWrapper.post(`api/account/changepassword`, {
			resetId,
			username,
			password,
			confirmPassword,
		});
	}

	/**
	 *
	 * @param {AuthenticationResultDto} authenticationResult
	 */
	static async handlePostLogin(authenticationResult) {
		Store.set(StoreKeys.USER.LOADED, false);
		await UserManager.getUserData();
		if (authenticationResult.usernameIsEmail) {
			ModalHelper.openModal(<EmailUsernameChangePrompt />, null);
		} else if (authenticationResult.usernameChangeAvailable) {
			ModalHelper.openModal(<UsernameChangePrompt />, null);
		}

		UserManager.loginCallbacks.forEach((callback) => {
			callback();
		});
	}

	static openLoginModal() {
		ModalHelper.openModal(<LoginModal />);
	}

	static openRegisterModal() {
		ModalHelper.openModal(<RegisterModal />);
	}

	static async getUserData() {
		if (Store.get(StoreKeys.USER.LOGGED_IN)) {
			return;
		}

		const user = await UserManager.getCurrentUser();
		const updates = {};
		updates[StoreKeys.USER.ID] = user?.id;
		updates[StoreKeys.USER.USERNAME] = user?.username;
		updates[StoreKeys.ROLES] = user?.roles;
		updates[StoreKeys.USER.LOADED] = true;
		updates[StoreKeys.USER.LOGGED_IN] = !!user;
		Store.setMany(updates);
	}

	static getCurrentUser = async () => {
		return await axiosWrapper.get(`api/user/currentUser`);
	};

	static onLogin(callback) {
		UserManager.loginCallbacks.push(callback);
	}
}

export class AuthenticationResult {
	token;
	userId;
	username;
	created;
}
