import axiosWrapper from "common/axiosWrapper";
// eslint-disable-next-line no-unused-vars
import AuthenticationResultDto from "common/types/AuthenticationResultDto";

/**
 * Attempts discord sign in with the provided code.
 *
 * @param {string} code The discord access code.
 * @returns {Promise<AuthenticationResultDto>}
 * */
export const linkDiscord = async (code) => {
	return await axiosWrapper.get(`api/auth/link-discord?code=${code}`);
};

/**
 * Attempts google sign in with the provided code.
 *
 * @param {string} code The google access code.
 * @returns {Promise<AuthenticationResultDto>}
 * */
export const linkGoogle = async (code) => {
	return await axiosWrapper.get(`api/auth/link-google?code=${code}`);
};

/**
 * Attempts reddit sign in with the provided code.
 *
 * @param {string} code The reddit access code.
 * @returns {Promise<AuthenticationResultDto>}
 * */
export const linkReddit = async (code) => {
	return await axiosWrapper.get(`api/auth/link-reddit?code=${code}`);
};
