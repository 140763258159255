import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalHeader,
	ModalBody,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import {
	TextField,
	Table,
	TableBody,
	TableCell,
	TableRow,
	InputAdornment,
	Grid,
} from "@material-ui/core";
import { LockOpen } from "@material-ui/icons";
import FatButton from "common/components/FatButton/FatButton";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import ModalHelper from "common/helpers/modalHelper";
import { ReactComponent as Crown } from "icons/Crown.svg";

export default function JoinLeagueModal({ onConfirm }) {
	const [password, setPassword] = React.useState("");

	const confirm = () => {
		onConfirm(password);
	};

	const cancel = () => {
		ModalHelper.closeModal();
	};

	return (
		<CustomModal>
			<ModalPanel>
				<ModalBody>
					<ContentHeader icon={<Crown />} text="Join Private League" />
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>Password:</TableCell>
								<TableCell>
									<TextField
										id="password"
										onChange={(e) => setPassword(e.target.value)}
										defaultValue={""}
										variant="outlined"
										autoComplete="off"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<LockOpen />
												</InputAdornment>
											),
										}}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Grid
						container
						justifyContent="space-evenly"
						style={{ marginTop: 8 }}
					>
						<FatButton text="Cancel" onClick={cancel} />
						<FatButton text="Confirm" onClick={confirm} primary />
					</Grid>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
