import React from "react";
import MatchWrapper from "bracket-system/core/match-wrapper";
import { getPreviousMatches } from "bracket-system/core/match-functions";
import { calculatePositionOfMatchUpperBracket } from "./calculate-match-position";
import ConnectorsUpper from "./upper-connectors";
import UpperRoundHeader from "bracket-system/components/upper-round-header";
import { Match, Participant } from "bracket-system/types";

const UpperBracket = ({
	columns,
	calculatedStyles,
	gameHeight,
	gameWidth,
	onMatchClick,
	onPartyClick,
	matchComponent,
	lowerColumns,
	onUpperClick,
	onColumnClick,
	customHeaderNames,
	lockedColumns,
	hideSeriesScore,
	isQualifierRound,
}) => {
	const { canvasPadding, columnWidth, rowHeight, roundHeader } =
		calculatedStyles;

	if (isQualifierRound && columns?.length) {
		const lastColumn = columns[columns.length - 1];
		customHeaderNames.push("Qualified");
		columns = [...columns];
		columns.push([...lastColumn]);
	}

	return (
		// @ts-ignore
		<g style={{ pointerEvents: "bounding-box" }} onClick={onUpperClick}>
			{columns.map((matchesColumn, columnIndex) => {
				const isQualifierColumn =
					isQualifierRound && columnIndex === columns.length - 1;

				const colPos = calculatePositionOfMatchUpperBracket(
					columns,
					0,
					columnIndex,
					{
						canvasPadding,
						columnWidth,
						rowHeight,
					},
					lowerColumns,
					isQualifierRound
				);

				return (
					<>
						{roundHeader.isShown && (
							<UpperRoundHeader
								x={colPos.x}
								roundHeader={roundHeader}
								canvasPadding={canvasPadding}
								width={calculatedStyles.width}
								numOfRounds={columns.length}
								columnIndex={columnIndex}
								onClick={(event) => {
									if (onColumnClick) {
										onColumnClick("UB", columnIndex);
										event.stopPropagation();
									}
								}}
								customHeaderText={
									customHeaderNames && customHeaderNames[columnIndex]
								}
								isQualifierRound={isQualifierRound}
							/>
						)}
						{matchesColumn.map((match, rowIndex) => {
							const { x, y } = calculatePositionOfMatchUpperBracket(
								columns,
								rowIndex,
								columnIndex,
								{
									canvasPadding,
									columnWidth,
									rowHeight,
								},
								lowerColumns,
								isQualifierRound
							);
							const previousBottomPosition = (rowIndex + 1) * 2 - 1;
							const { previousTopMatch, previousBottomMatch } =
								getPreviousMatches(
									columnIndex,
									columns,
									previousBottomPosition
								);
							return (
								<>
									{columnIndex !== 0 && (
										<ConnectorsUpper
											{...{
												columns,
												bracketSnippet: {
													currentMatch: match,
													previousTopMatch,
													previousBottomMatch,
												},
												rowIndex,
												columnIndex,
												gameHeight,
												gameWidth,
												style: calculatedStyles,
												lowerColumns,
												isQualifierRound,
												isQualifierColumn,
											}}
										/>
									)}
									<g>
										<MatchWrapper
											x={x}
											y={
												y +
												(roundHeader.isShown
													? roundHeader.height + roundHeader.marginBottom
													: 0) +
												(isQualifierColumn ? 17 : 0)
											}
											rowIndex={rowIndex}
											columnIndex={columnIndex}
											match={match}
											previousBottomMatch={previousBottomMatch}
											topText={match.startTime}
											bottomText={match.name}
											teams={match.participants}
											onMatchClick={onMatchClick}
											onPartyClick={(e, match, isTop, party, partyWon) => {
												if (
													onPartyClick &&
													!lockedColumns.includes(columnIndex)
												) {
													onPartyClick(e, match, isTop, party, partyWon);
												}
											}}
											locked={lockedColumns.includes(columnIndex)}
											style={calculatedStyles}
											matchComponent={matchComponent}
											hideSeriesScore={hideSeriesScore}
											isQualifierColumn={isQualifierColumn}
										/>
									</g>
								</>
							);
						})}
					</>
				);
			})}
		</g>
	);
};
export default UpperBracket;
