import { Box, Grid } from "@material-ui/core";
import * as React from "react";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import "./GamePickemScreen.scss";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

const drawerWidth = 358;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	drawer: {
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
		top: "initial",
		borderRight: "none",
		zIndex: 100
	},
	drawerHeader: {
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		justifyContent: "flex-end",
	},
	content: {
		marginLeft: drawerWidth,
		overflow: "hidden",
	},
	contentBox: {
		height: "100%",
		overflow: "auto",
	},
}));

const propKeys = {
	open: StoreKeys.LeftPane.Open,
};

function GamePickemScreenC(props) {
	const classes = useStyles();
	const isSm = props.width === "xs" || props.width === "sm";

	return (
		<Grid container className="rocket-league" style={{ height: "100%" }}>
			{isSm && (
				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="left"
					open={props.open}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<div className="left-pane h-100">{props.children[0]}</div>
				</Drawer>
			)}
			{!isSm && (
				<Drawer
					classes={{
						paper: classes.drawerPaper,
					}}
					variant="permanent"
					open
				>
					<Box px={3}></Box>
					<div className="left-pane">{props.children[0]}</div>
				</Drawer>
			)}

			<Grid
				item
				className={"rocket-league " + (!isSm ? classes.content : "")}
				xs
			>
				<Box px={3} className={classes.contentBox}>
					{props.children[1]}
				</Box>
			</Grid>
		</Grid>
	);
}

const GamePickemScreenWidthC = withWidth()(GamePickemScreenC);

export default function GamePickemScreen(props) {
	return connect(<GamePickemScreenWidthC />, propKeys, props);
}
