import axiosWrapper from "common/axiosWrapper";
// eslint-disable-next-line no-unused-vars
import { SurveyDto } from "common/types/Survey/SurveyDto";

const baseUrl = "/api/survey";

export default class SurveyService {
	/**
	 * Gets the currently active survey for the user.
	 *
	 * @returns {Promise<SurveyDto>}
	 */
	static async getCurrentSurvey() {
		return await axiosWrapper.getCached(`${baseUrl}/current`);
	}

	/**
	 * Gets all surveys in the system.
	 * Note: These dto's only contain the top level data, no child data is returned.
	 *
	 * @returns {Promise<SurveyDto[]>}
	 */
	static async getAllSurveys() {
		return await axiosWrapper.get(`${baseUrl}/list`);
	}

	/**
	 * Loads the full survey details with prompts and answers.
	 * Note: This should be used to prompt users and for admins editing the survey prior to opening.
	 *
	 * @param {number} surveyId The id of the survey to fetch.
	 * @returns {Promise<SurveyDto>}
	 */
	static async getSpecificSurvey(surveyId) {
		return await axiosWrapper.get(`${baseUrl}/${surveyId}`);
	}

	/**
	 * Saves the survey to the backend.
	 *
	 * @param {SurveyDto} surveyDto The survey to save.
	 * @returns {Promise<number>}
	 */
	static async saveSurvey(surveyDto) {
		return await axiosWrapper.post(`${baseUrl}/save`, surveyDto);
	}

	/**
	 * Gets the users answers from the backend.
	 *
	 * @param {number} surveyId The id of the survey to get answers for.
	 * @returns {Promise<number[]>}
	 */
	static async getAnswers(surveyId) {
		return await axiosWrapper.get(`${baseUrl}/answers?surveyId=${surveyId}`);
	}

	/**
	 * Saves the users answers to the backend.
	 *
	 * @param {number[]} userAnswerIds The answer ids of the user.
	 * @returns {Promise<void>}
	 */
	static async saveAnswers(userAnswerIds) {
		return await axiosWrapper.post(`${baseUrl}/answers`, userAnswerIds);
	}
}
