import { Button, TextField } from "@material-ui/core";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import RedeemCodeModal from "common/components/Modals/RedeemCodeModal/RedeemCodeModal";
import { connect } from "common/connect";
import { LocalStorageKeys } from "common/constants/StorageKeys";
import { useForceUpdate } from "common/helpers/componentHelper";
import ModalHelper from "common/helpers/modalHelper";
import StoreKeys from "common/storeKeys";
import UserManager from "common/userManager";
import * as React from "react";
import PromoService from "services/PromoService";

const propKeys = {
	loadedUser: StoreKeys.USER.LOADED,
	loggedIn: StoreKeys.USER.LOGGED_IN,
};

function RedeemPromoCodeScreenC({ loadedUser, loggedIn }) {
	const [code, setCode] = React.useState("");
	const forceUpdate = useForceUpdate();
	const refresh = () => {
		forceUpdate();
	};

	React.useEffect(() => {
		UserManager.onLogin(() => refresh());
	});

	if (loadedUser && !loggedIn) {
		localStorage.setItem(LocalStorageKeys.POSTLOGINURL, "/redeem");
		UserManager.openLoginModal();

		return <></>;
	}

	const redeem = () => {
		PromoService.redeemCode(code).then((result) => {
			if (result) {
				ModalHelper.openModal(<RedeemCodeModal promo={result} />);
			} else {
				ModalHelper.openModal(
					<CustomModal>
						<ModalPanel className="success-fail-modal fail">
							<ModalHeader>Invalid Promo Code</ModalHeader>
							<ModalBody className="success-fail-section">
								<label>
									That code has either been claimed, expired or is invalid.
								</label>
							</ModalBody>
						</ModalPanel>
					</CustomModal>
				);
			}
		});
	};

	return (
		<div
			style={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<div>
				<label stlye={{ fontWeight: 20 }}>Enter Code</label>
			</div>
			<div>
				<TextField onChange={(e) => setCode(e.target.value)}></TextField>
			</div>
			<div>
				<Button onClick={redeem}>Redeem</Button>
			</div>
		</div>
	);
}

export default function RedeemPromoCodeScreen(props) {
	return connect(<RedeemPromoCodeScreenC />, propKeys, props);
}
