export const EventTypes = {
	DoubleElimination: 1,
	SwissStage: 2,
	FullDoubleElimination: 3,
	SpringSplit: 4,
	SpringSplitMajor: 5,
	SeasonXChampionships: 6,
	SwissStagePlayoffs: 7,
	WinterSplit2021: 8,
};
