import { Grid, makeStyles } from "@material-ui/core";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import ContentPanel from "common/components/ContentPanel/ContentPanel";
import {
	games,
	GamesEnum,
	GetCurrentGameId,
	GetCurrentGameTrophyPath,
} from "common/constants/Games";
import * as React from "react";
import { Colors } from "styles/Colors";

const useStyles = makeStyles({
	container: {
		display: "inline-block",
		width: "50%",
	},
	heading: {
		color: Colors.pickstop,
		marginTop: 5,
		marginBottom: 5,
	},
	tableContainer: {
		backgroundColor: Colors.body,
		borderRadius: 12,
		margin: 4,
		marginLeft: 0,
	},
	table: {
		textAlign: "center",
		width: "100%",
		borderSpacing: 0,
	},
	tableHead: {
		color: Colors.pickstop,
	},
	tableCell: {
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: Colors.panel,
		padding: 5,
		fontSize: 12,
		lineHeight: "20px",
	},
	diamond: {
		color: Colors.diamond_light,
	},
	platinum: {
		color: Colors.platinum_light,
	},
	gold: {
		color: Colors.gold_light,
	},
	silver: {
		color: Colors.silver_light,
	},
	bronze: {
		color: Colors.bronze_light,
	},
	wood: {
		color: Colors.wood_light,
	},
	globeIcon: {
		maxHeight: 30,
		position: "relative",
	},
	trophyIcon: {
		maxHeight: 30,
		position: "relative",
	},
	medalIcon: {
		maxHeight: 30,
		position: "relative",
	},
	prestigeIcon: {
		maxHeight: 30,
		position: "relative",
		left: -2,
	},
	rewardText: {
		width: 84,
	},
	miniAward: {
		display: "inline-block",
		float: "left",
		borderRadius: "50%",
		overflow: "hidden",
		height: 20,
		marginLeft: 0,
		marginRight: 0,
	},
	materialBlock: {
		height: 20,
	},
	awardCellWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-around",
	},
	awardCellInnerWrapper: {
		width: 40,
		display: "flex",
		justifyContent: "space-around",

		"& img": {
			width: 20,
			marginLeft: 2,
			marginRight: 2,
		},
	},
});

export default function AwardInfo(props) {
	const styles = useStyles();
	const trophyImagePath = GetCurrentGameTrophyPath();
	const isRocketLeague = GetCurrentGameId() === GamesEnum.RocketLeague;
	const isCounterStrike = GetCurrentGameId() === GamesEnum.CounterStrike;

	return (
		<ContentPanel>
			<Grid container style={{ height: 232 }}>
				<Grid item xs={6}>
					<ContentHeader text="Award Info" />
					<div className={styles.tableContainer}>
						<table className={styles.table}>
							<thead>
								<tr className={styles.tableHead}>
									<th className={styles.tableCell}>Event</th>
									<th className={styles.tableCell}>Award</th>
								</tr>
							</thead>
							<tbody>
								{isRocketLeague && (
									<>
										<tr>
											<td className={styles.tableCell}>Worlds</td>
											<td className={styles.tableCell}>
												<div className={styles.awardCellWrapper}>
													<img
														className={styles.globeIcon}
														alt=""
														src="/images/rewards/rl/DiamondGlobe.svg"
													/>
													<span className={styles.rewardText}>Globe</span>
												</div>
											</td>
										</tr>
										<tr>
											<td className={styles.tableCell}>Major</td>
											<td className={styles.tableCell}>
												<div className={styles.awardCellWrapper}>
													<img
														className={styles.trophyIcon}
														alt=""
														src="/images/rewards/rl/DiamondTrophy.svg"
													/>
													<span className={styles.rewardText}>Trophy</span>
												</div>
											</td>
										</tr>
										<tr>
											<td className={styles.tableCell}>
												{isCounterStrike ? "Tier 1" : "Regional"}
											</td>
											<td className={styles.tableCell}>
												<div className={styles.awardCellWrapper}>
													<img
														className={styles.medalIcon}
														alt=""
														src="/images/rewards/rl/DiamondMedal.svg"
													/>
													<span className={styles.rewardText}>Medal</span>
												</div>
											</td>
										</tr>
									</>
								)}
								{isCounterStrike && (
									<>
										<tr>
											<td className={styles.tableCell}>Major</td>
											<td className={styles.tableCell}>
												<div className={styles.awardCellWrapper}>
													<div className={styles.awardCellInnerWrapper}>
														<img
															className={styles.trophyIcon}
															alt=""
															src="/images/rewards/cs/DiamondTrophy.svg"
														/>
													</div>
													<span className={styles.rewardText}>
														Major Trophy
													</span>
												</div>
											</td>
										</tr>
										<tr>
											<td className={styles.tableCell}>
												Prestige Event <br /> (Cologne & Katowice)
											</td>
											<td className={styles.tableCell}>
												<div className={styles.awardCellWrapper}>
													<div className={styles.awardCellInnerWrapper}>
														<img
															className={styles.prestigeIcon}
															alt=""
															src="/images/rewards/cs/CologneDiamond.svg"
														/>
														<img
															className={styles.prestigeIcon}
															alt=""
															src="/images/rewards/cs/KatowiceDiamond.svg"
														/>
													</div>
													<span className={styles.rewardText}>
														Prestige Trophy
													</span>
												</div>
											</td>
										</tr>
										<tr>
											<td className={styles.tableCell}>S-Tier LAN</td>
											<td className={styles.tableCell}>
												<div className={styles.awardCellWrapper}>
													<div className={styles.awardCellInnerWrapper}>
														<img
															className={styles.medalIcon}
															alt=""
															src="/images/rewards/cs/DiamondMedal.svg"
														/>
													</div>
													<span className={styles.rewardText}>Medal</span>
												</div>
											</td>
										</tr>
									</>
								)}
							</tbody>
						</table>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={styles.tableContainer}>
						<table className={styles.table}>
							<thead>
								<tr className={styles.tableHead}>
									<th className={styles.tableCell}>Rank</th>
									<th className={styles.tableCell}>Tier</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className={styles.tableCell}>Winner</td>
									<td className={`${styles.diamond} ${styles.tableCell}`}>
										<span className={styles.miniAward}>
											<img
												className={styles.materialBlock}
												title="Diamond Award Count"
												alt=""
												src="/images/DiamondBlock.svg"
											/>
										</span>
										Diamond
									</td>
								</tr>
								<tr>
									<td className={styles.tableCell}>Top 10</td>
									<td className={`${styles.platinum} ${styles.tableCell}`}>
										<span className={styles.miniAward}>
											<img
												className={styles.materialBlock}
												title="Platinum Award Count"
												alt=""
												src="/images/PlatinumBlock.svg"
											/>
										</span>
										Platinum
									</td>
								</tr>
								<tr>
									<td className={styles.tableCell}>Top 10%</td>
									<td className={`${styles.gold} ${styles.tableCell}`}>
										<span className={styles.miniAward}>
											<img
												className={styles.materialBlock}
												title="Gold Award Count"
												alt=""
												src="/images/GoldBlock.svg"
											/>
										</span>
										Gold
									</td>
								</tr>
								<tr>
									<td className={styles.tableCell}>Top 30%</td>
									<td className={`${styles.silver} ${styles.tableCell}`}>
										<span className={styles.miniAward}>
											<img
												className={styles.materialBlock}
												title="Silver Award Count"
												alt=""
												src="/images/SilverBlock.svg"
											/>
										</span>
										Silver
									</td>
								</tr>
								<tr>
									<td className={styles.tableCell}>Top 50%</td>
									<td className={`${styles.bronze} ${styles.tableCell}`}>
										<span className={styles.miniAward}>
											<img
												className={styles.materialBlock}
												title="Bronze Award Count"
												alt=""
												src="/images/BronzeBlock.svg"
											/>
										</span>
										Bronze
									</td>
								</tr>
								<tr>
									<td className={styles.tableCell}>Participant</td>
									<td className={`${styles.wood} ${styles.tableCell}`}>
										<span className={styles.miniAward}>
											<img
												className={styles.materialBlock}
												title="Wood Award Count"
												alt=""
												src="/images/WoodBlock.svg"
											/>
										</span>
										Wood
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Grid>
			</Grid>
		</ContentPanel>
	);
}
