import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "common/connect";
import { EventStageTypes } from "common/constants/EventStageTypes";
// eslint-disable-next-line no-unused-vars
import {
	EventFormatStage,
	buildScoringDetails,
} from "common/helpers/eventFormatHelper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import * as React from "react";
import { Colors } from "styles/Colors";

const useStyles = makeStyles((theme) => {
	return {
		dialog: {
			"& .MuiPaper-root": {
				backgroundColor: `${Colors.panel}`,
			},
		},
		form: {
			maxWidth: 400,
			"& .MuiInputBase-root": {
				overflow: "hidden",
			},
		},
		list: {
			marginTop: theme.spacing() * 2,
			marginBottom: theme.spacing() * 2,
			borderRadius: theme.spacing() * 2,
			border: `1px solid ${Colors.panel}`,
			"& .MuiListItem-container": {
				"&:not(:last-child)": {
					borderBottom: `1px solid ${Colors.panel}`,
				},
			},
		},
		paper: {
			color: Colors.primary,
			backgroundColor: Colors.panel,
		},
		select: {
			margin: "20px 0",
		},
		textField: {
			display: "block",
			margin: "20px 0",
		},
	};
});

/**
 * @typedef ComponentProps
 * @type {object}
 * @property {EventFormatBuilder} currentFormat
 */

const propKeys = {
	currentFormat: StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT,
};

/**
 * @param {ComponentProps} props
 */
function EventFormatDetailsC({ currentFormat }) {
	const classNames = useStyles();
	const [currentStage, setCurrentStage] = React.useState(
		new EventFormatStage()
	);
	const [currentStageIndex, setCurrentStageIndex] = React.useState(-1);
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);

	const onChangeName = (event) => {
		const name = event.target.value;
		currentFormat.eventFormat.name = name;
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	const addStage = () => {
		setIsDialogOpen(true);
		setCurrentStage(new EventFormatStage());
		setCurrentStageIndex(-1);
	};

	const editStage = (stage, index) => {
		setIsDialogOpen(true);
		setCurrentStage(stage);
		setCurrentStageIndex(index);
	};

	const deleteStage = (index) => {
		// TODO: confirmation popup: 'Are you sure'
		currentFormat.data.stages.splice(index, 1);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
	};

	const onDialogClose = () => {
		setIsDialogOpen(false);
	};

	const eventStageSave = () => {
		if (currentStageIndex === -1) {
			currentFormat.data.stages.push(currentStage);
		} else {
			currentFormat.data.stages[currentStageIndex] = currentStage;
		}

		buildScoringDetails(currentFormat);
		Store.set(StoreKeys.EVENTFORMATBUILDER.CURRENT_FORMAT, currentFormat);
		setIsDialogOpen(false);
	};

	return (
		<>
			<div className={classNames.form}>
				<TextField
					id="name"
					label="Event Format Name"
					value={currentFormat.eventFormat.name}
					fullWidth={400}
					onChange={onChangeName}
					variant="outlined"
					required
				/>

				<h2>Stages</h2>

				<List disablePadding={true} className={classNames.list}>
					{currentFormat.data.stages.map((stage, index) => {
						return (
							<ListItem>
								<ListItemText primary={stage.name} />
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="edit"
										onClick={() => editStage(stage, index)}
									>
										<EditOutlined color="primary" />
									</IconButton>
									<IconButton
										edge="end"
										aria-label="delete"
										onClick={() => deleteStage(index)}
									>
										<DeleteIcon color="primary" />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>

				<Button onClick={addStage}>
					<AddIcon />
					Add
				</Button>
			</div>
			<Dialog
				className={classNames.dialog}
				open={isDialogOpen}
				onClose={onDialogClose}
			>
				<DialogTitle>
					{currentStageIndex === -1 ? `Add Event Stage` : `Edit Event Stage`}
				</DialogTitle>
				<DialogContent>
					<div>
						<TextField
							id="stageName"
							label="Stage Name"
							value={currentStage.name}
							onChange={(event) =>
								setCurrentStage({ ...currentStage, name: event.target.value })
							}
							variant="outlined"
							required
						/>
					</div>
					<div>
						<Select
							className={classNames.select}
							value={currentStage?.type}
							onChange={(event) =>
								setCurrentStage({ ...currentStage, type: event.target.value })
							}
							MenuProps={{ PaperProps: { className: classNames.paper } }}
						>
							<MenuItem value={EventStageTypes.Group}>Group Stage</MenuItem>
							<MenuItem value={EventStageTypes.Swiss}>Swiss Stage</MenuItem>
							<MenuItem value={EventStageTypes.Playoffs}>
								Playoffs Stage
							</MenuItem>
						</Select>
					</div>
					{currentStage.type === EventStageTypes.Group && (
						<>
							<div>
								<FormControlLabel
									label="Has group brackets?"
									control={
										<Checkbox
											checked={
												currentStage.groupStageDetails?.hasGroupBrackets ??
												false
											}
											onChange={(event) =>
												setCurrentStage({
													...currentStage,
													groupStageDetails: {
														...currentStage.groupStageDetails,
														hasGroupBrackets: event.target.checked,
														useSeriesScore: event.target.checked
															? currentStage.groupStageDetails?.useSeriesScore
															: false,
													},
												})
											}
										/>
									}
								/>
							</div>
							<div>
								{currentStage.groupStageDetails?.hasGroupBrackets && (
									<FormControlLabel
										label="Use Series Score?"
										control={
											<Checkbox
												checked={
													currentStage.groupStageDetails?.useSeriesScore ??
													false
												}
												onChange={(event) =>
													setCurrentStage({
														...currentStage,
														groupStageDetails: {
															...currentStage.groupStageDetails,
															useSeriesScore: event.target.checked,
														},
													})
												}
											/>
										}
									/>
								)}
							</div>
							<TextField
								id="groupCount"
								className={classNames.textField}
								label="Group Count"
								value={currentStage.groupStageDetails?.groupCount ?? 0}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										groupStageDetails: {
											...currentStage.groupStageDetails,
											groupCount: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
							<TextField
								id="totalAdvancing"
								className={classNames.textField}
								label="Total Advancing"
								value={currentStage.groupStageDetails?.totalAdvancing ?? 0}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										groupStageDetails: {
											...currentStage.groupStageDetails,
											totalAdvancing: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
							<TextField
								id="pointsPerCorrectAdvancing"
								className={classNames.textField}
								label="Points Per Correct Advancing"
								value={currentStage.groupStageDetails?.pointsPerAdvancing ?? 0}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										groupStageDetails: {
											...currentStage.groupStageDetails,
											pointsPerAdvancing: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={currentStage.groupStageDetails?.scoreFirstPlace}
										onChange={(event) =>
											setCurrentStage({
												...currentStage,
												groupStageDetails: {
													...currentStage.groupStageDetails,
													scoreFirstPlace: event.target.checked,
													scoreSecondPlace:
														event.target.checked &&
														currentStage.groupStageDetails.scoreSecondPlace,
												},
											})
										}
									/>
								}
								label="Score First Place"
							/>
							{currentStage.groupStageDetails?.scoreFirstPlace && (
								<>
									<TextField
										id="pointsPerCorrectFirstPlace"
										className={classNames.textField}
										label="Points Per Correct First Place"
										value={
											currentStage.groupStageDetails?.pointsPerFirstPlace ?? 0
										}
										onChange={(event) =>
											setCurrentStage({
												...currentStage,
												groupStageDetails: {
													...currentStage.groupStageDetails,
													pointsPerFirstPlace: event.target.value,
												},
											})
										}
										variant="outlined"
										type="number"
										required
									/>

									<FormControlLabel
										control={
											<Checkbox
												checked={
													currentStage.groupStageDetails?.scoreSecondPlace
												}
												onChange={(event) =>
													setCurrentStage({
														...currentStage,
														groupStageDetails: {
															...currentStage.groupStageDetails,
															scoreSecondPlace: event.target.checked,
														},
													})
												}
											/>
										}
										label="Score Second Place"
									/>
									{currentStage.groupStageDetails?.scoreSecondPlace && (
										<TextField
											id="pointsPerCorrectSecondPlace"
											className={classNames.textField}
											label="Points Per Correct Second Place"
											value={
												currentStage.groupStageDetails?.pointsPerSecondPlace ??
												0
											}
											onChange={(event) =>
												setCurrentStage({
													...currentStage,
													groupStageDetails: {
														...currentStage.groupStageDetails,
														pointsPerSecondPlace: event.target.value,
													},
												})
											}
											variant="outlined"
											type="number"
											required
										/>
									)}
								</>
							)}
						</>
					)}
					{currentStage.type === EventStageTypes.Swiss && (
						<div>
							<TextField
								id="advancingCount"
								className={classNames.textField}
								label="# of Advancing"
								value={currentStage.swissStageDetails?.advancingCount ?? 0}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										swissStageDetails: {
											...currentStage.swissStageDetails,
											advancingCount: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
							<TextField
								id="pointsPerAdvancing"
								className={classNames.textField}
								label="Points Per Correct Advancing"
								value={currentStage.swissStageDetails?.pointsPerAdvancing ?? 0}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										swissStageDetails: {
											...currentStage.swissStageDetails,
											pointsPerAdvancing: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
							<TextField
								id="cleanSweepWinnerCount"
								className={classNames.textField}
								label="Clean Sweep Winners"
								value={
									currentStage.swissStageDetails?.cleanSweepWinnerCount ?? 0
								}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										swissStageDetails: {
											...currentStage.swissStageDetails,
											cleanSweepWinnerCount: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
							<TextField
								id="pointsPerWinner"
								className={classNames.textField}
								label="Points Per Correct 3-0 Winner"
								value={
									currentStage.swissStageDetails?.pointsPerCorrectWinner ?? 0
								}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										swissStageDetails: {
											...currentStage.swissStageDetails,
											pointsPerCorrectWinner: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
							<TextField
								id="cleanSweepLoserCount"
								className={classNames.textField}
								label="Clean Sweep Losers"
								value={
									currentStage.swissStageDetails?.cleanSweepLoserCount ?? 0
								}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										swissStageDetails: {
											...currentStage.swissStageDetails,
											cleanSweepLoserCount: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
							<TextField
								id="pointsPerLoser"
								className={classNames.textField}
								label="Points Per Correct 0-3 Loser"
								value={
									currentStage.swissStageDetails?.pointsPerCorrectLoser ?? 0
								}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										swissStageDetails: {
											...currentStage.swissStageDetails,
											pointsPerCorrectLoser: event.target.value,
										},
									})
								}
								variant="outlined"
								type="number"
								required
							/>
						</div>
					)}
					{currentStage.type === EventStageTypes.Playoffs && (
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checked={currentStage.playoffDetails?.useSeriesScore}
										onChange={(event) =>
											setCurrentStage({
												...currentStage,
												playoffDetails: {
													...currentStage.playoffDetails,
													useSeriesScore: event.target.checked,
												},
											})
										}
									/>
								}
								label="Use Series Score"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={currentStage.playoffDetails?.hasBracketReset}
										onChange={(event) =>
											setCurrentStage({
												...currentStage,
												playoffDetails: {
													...currentStage.playoffDetails,
													hasBracketReset: event.target.checked,
												},
											})
										}
									/>
								}
								label="Has Bracket Reset"
							/>
							{currentStage.playoffDetails?.hasBracketReset && (
								<TextField
									id="pointsForCorrectReset"
									className={classNames.textField}
									label="Points For Correct Bracket Reset"
									value={
										currentStage.playoffDetails?.pointsForCorrectBracketReset ??
										0
									}
									onChange={(event) =>
										setCurrentStage({
											...currentStage,
											playoffDetails: {
												...currentStage.playoffDetails,
												pointsForCorrectBracketReset: event.target.value,
											},
										})
									}
									variant="outlined"
									type="number"
									required
								/>
							)}
						</div>
					)}
					<div>
						{currentStageIndex !== 0 && (
							<TextField
								id="teamsToStartStage"
								label="# of Teams to Open Picks"
								value={currentStage.teamsToOpenPicks}
								onChange={(event) =>
									setCurrentStage({
										...currentStage,
										teamsToOpenPicks: Number(event.target.value),
									})
								}
								variant="outlined"
								type="number"
								required
							/>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDialogClose} color="primary">
						Cancel
					</Button>
					<Button onClick={eventStageSave} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function EventFormatDetails(props) {
	return connect(<EventFormatDetailsC />, propKeys, props);
}

export default EventFormatDetails;
