import React, { useEffect, useState } from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	IconModalHeader,
	ModalBody,
	ModalPanel,
	ModalPanelTransparent,
} from "common/components/CustomModal/Modal/ModalFrame";
import UserManager from "common/userManager";
import { Button, makeStyles } from "@material-ui/core";
import UserCard from "common/components/UserCard";
import PersonIcon from "@material-ui/icons/Person";
import HttpsIcon from "@material-ui/icons/Https";
import { toMonthNameAndYear } from "common/helpers/dateHelper";
import AwardSummary from "components/screens/TrophyRoom/AwardSummary";
import { AiFillPushpin } from "react-icons/ai";
import { EditOutlined } from "@material-ui/icons";
import TrophyService from "services/TrophyService";
import ModalHelper from "common/helpers/modalHelper";
import TrophySelectorModal from "common/components/Modals/TrophySelectorModal/TrophySelectorModal";
import { SetLoading } from "common/components/Loader/Loader";
import UserProfileModalHelper from "common/helpers/userProfileModalHelper";
import HtmlTooltip from "common/components/HtmlTooltip/HtmlTooltip";
import { Colors } from "styles/Colors";
import TrophyCase from "common/components/TrophyCase/TrophyCase";
import UserService from "services/UserService";
import NameplateSelectorModal from "../NameplateSelectorModal/NameplateSelectorModal";
import NameplateService from "services/NameplateService";
import LookupService from "services/LookupService";
import { ReactComponent as Trophy } from "icons/Trophy.svg";
import {
	getDiscordLinkUrl,
	getGoogleLinkUrl,
	getRedditLinkUrl,
	getThirdPartyStatus,
} from "./api";
import { LocalStorageKeys } from "common/constants/StorageKeys";

const useStyles = makeStyles((theme) => ({
	topContainer: {
		width: 340,
		display: "flex",
		justifyContent: "space-between",
	},
	summary: {},
	detailSection: {
		marginBottom: 8,
	},
	detailText: {
		fontSize: 12,
	},
	pinnedTrophyContainer: {
		width: 140,
		position: "relative",
		marginLeft: 8,
	},
	pinnedTrophyWrapper: {
		display: "flex",
		position: "relative",
		flexWrap: "wrap",
		justifyContent: "center",
	},
	pinnedAwardImage: {
		maxHeight: 120,
	},
	pinnedAwardName: {
		fontSize: 12,
		textAlign: "center",
		marginTop: 10,
	},
	pinIcon: {
		transform: "rotate(270deg)",
		position: "absolute",
		left: 0,
		top: 4,
		height: 20,
		width: 20,
		stroke: theme.palette.secondary.main,
		fill: theme.palette.secondary.main,
		zIndex: 1,
	},
	editIcon: {
		position: "absolute",
		right: 0,
		top: 4,
		height: 20,
		width: 20,
		stroke: theme.palette.secondary.main,
		fill: theme.palette.secondary.main,
		zIndex: 1,
		"&:hover": {
			cursor: "pointer",
		},
	},
	editIconDiagonal: {
		position: "absolute",
		right: -4,
		top: -16,
		height: 20,
		width: 20,
		stroke: theme.palette.secondary.main,
		fill: theme.palette.secondary.main,
		zIndex: 1,
		"&:hover": {
			cursor: "pointer",
		},
	},
	editIconInner: {
		height: 20,
		width: 20,
		stroke: theme.palette.secondary.main,
		fill: theme.palette.secondary.main,
	},
	divider: {
		border: "1px solid " + Colors.body,
		display: "block",
		marginLeft: 10,
		marginRight: 10,
	},
	extraItems: {
		display: "flex",
		flexDirection: "column",
		alignContent: "space-around",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 8,
	},
	editNameplate: {
		float: "right",
		height: 20,
		width: 20,
		left: 4,
		top: 4,
		position: "relative",
		"&:hover": {
			cursor: "pointer",
		},
	},
	thirdPartyTable: {
		textAlign: "left",
		padding: 4,
		"& th": {
			padding: 4,
			borderBotton: "1px solid white",
		},
		"& td": {
			padding: 4,
			paddingBottom: 0,
		},
	},
}));

export default function UserProfileModal(props) {
	const styles = useStyles();
	const [profile, setProfile] = useState(null);
	const [awards, setAwards] = React.useState(null);
	const [games, setGames] = React.useState([]);
	const [pinnedAward, setPinnedAward] = React.useState(null);
	const [discordLinkUrl, setDiscordLinkUrl] = React.useState(null);
	const [googleLinkUrl, setGoogleLinkUrl] = React.useState(null);
	const [redditLinkUrl, setRedditLinkUrl] = React.useState(null);
	const [thirdPartyLinks, setThirdPartyLinks] = React.useState(null);
	const [nameplates, setNameplates] = useState([]);
	const [loading, setLoading] = useState(true);
	const hasPinned = !!pinnedAward;
	const isCurrentUser = props.userId === UserManager.getUserId();
	const headerText = isCurrentUser ? "Your Profile" : "Profile";

	useEffect(() => {
		SetLoading(true, "Loading Profile");

		const promises = [
			UserService.getUserProfile(props.userId).then((profile) => {
				setProfile(profile);
			}),
			TrophyService.getYourAwards(props.userId, null).then((awardData) => {
				setAwards(awardData);
			}),
			LookupService.getGames().then((games) => {
				setGames(games);
			}),
		];

		if (isCurrentUser) {
			promises.push(
				NameplateService.getUserNameplates().then((nameplates) => {
					setNameplates(nameplates);
				})
			);
			promises.push(
				getDiscordLinkUrl().then((discordLinkUrl) => {
					setDiscordLinkUrl(discordLinkUrl);
				})
			);
			promises.push(
				getGoogleLinkUrl().then((googleLinkUrl) => {
					setGoogleLinkUrl(googleLinkUrl);
				})
			);
			promises.push(
				getRedditLinkUrl().then((redditLinkUrl) => {
					setRedditLinkUrl(redditLinkUrl);
				})
			);
			promises.push(
				getThirdPartyStatus().then((links) => {
					setThirdPartyLinks(links);
				})
			);
		}

		Promise.all(promises).then(() => {
			SetLoading(false);
			setLoading(false);
		});
	}, [isCurrentUser, props.userId]);

	useEffect(() => {
		if (awards) {
			const pinnedAwards = awards.filter((item) => item.pinned);
			setPinnedAward(pinnedAwards.length > 0 && pinnedAwards[0]);
		} else {
			setPinnedAward(null);
		}
	}, [awards]);

	if (!profile) {
		return <></>;
	}

	if (loading) {
		return <></>;
	}

	const monthAndYear = toMonthNameAndYear(profile.created);

	const selectPinnedTrophy = (trophyId, eventId) => {
		ModalHelper.closeAllModals();
		SetLoading(true, "Pinning Trophy");
		TrophyService.pinTrophy(trophyId, eventId).then(() => {
			SetLoading(false);
			UserProfileModalHelper.openProfile(props.userId);
		});
	};

	const editPinnedTrophy = () => {
		ModalHelper.openModal(<TrophySelectorModal />, () => {}, {
			header: "Select a trophy to pin",
			awards,
			onSelect: selectPinnedTrophy.bind(this),
		});
	};

	const selectNameplate = (nameplate) => {
		ModalHelper.closeAllModals();
		SetLoading(true, "Changing Nameplate");
		NameplateService.setNameplate(nameplate.id).then(() => {
			SetLoading(false);
			UserProfileModalHelper.openProfile(props.userId);
		});
	};

	const editNameplate = () => {
		ModalHelper.openModal(<NameplateSelectorModal />, () => {}, {
			header: "Select a Nameplate",
			userId: props.userId,
			username: profile?.username,
			nameplates,
			pinnedEventName: pinnedAward?.eventName,
			pinnedTrophyImagePath: pinnedAward?.imagePath,
			onSelect: selectNameplate.bind(this),
		});
	};

	const onLinkAccountClick = (provider) => {
		switch (provider) {
			case "Discord":
				storeCurrentRoute();
				window.location.replace(discordLinkUrl);
				break;
			case "Google":
				storeCurrentRoute();
				window.location.replace(googleLinkUrl);
				break;
			case "Reddit":
				storeCurrentRoute();
				window.location.replace(redditLinkUrl);
				break;
			default:
				console.error("Invalid 3rd party provider, " + provider);
		}
	};

	const storeCurrentRoute = () => {
		localStorage.setItem(
			LocalStorageKeys.POSTLINKURL,
			window.location.pathname + window.location.search
		);
	};

	return (
		<CustomModal>
			<ModalPanelTransparent>
				<ModalPanel>
					<IconModalHeader icon={<PersonIcon />} label={headerText} />
					<ModalBody>
						<div className={styles.topContainer}>
							<div className={styles.summary}>
								<div>
									<UserCard userId={props.userId} marginBottom={8} />
									{isCurrentUser && (
										<HtmlTooltip title={<>Edit Nameplate</>}>
											<span
												className={styles.editNameplate}
												onClick={editNameplate}
											>
												<EditOutlined className={styles.editIconInner} />
											</span>
										</HtmlTooltip>
									)}
								</div>
								<div className={styles.detailSection}>
									<div className={styles.detailText}>Joined {monthAndYear}</div>
									{profile?.patreonLevel?.id !== 1 && (
										<div className={styles.detailSection}>
											<label
												className={styles.detailText}
												style={{ color: profile?.patreonLevel?.hexCode }}
											>
												{profile?.patreonLevel?.name}
											</label>
										</div>
									)}
								</div>
								<AwardSummary awards={awards} games={games} />
							</div>
							<div className={styles.pinnedTrophyContainer}>
								{!!hasPinned && (
									<>
										<AiFillPushpin className={styles.pinIcon} />
										{isCurrentUser && (
											<HtmlTooltip title={<>Edit Pinned Trophy</>}>
												<span
													className={styles.editIcon}
													onClick={editPinnedTrophy}
												>
													<EditOutlined className={styles.editIconInner} />
												</span>
											</HtmlTooltip>
										)}
										<div className={styles.pinnedTrophyWrapper}>
											<img
												className={styles.pinnedAwardImage}
												alt="Your pinned trophy"
												src={pinnedAward?.imagePath}
											/>
											<div className={styles.pinnedAwardName}>
												{pinnedAward?.eventName}
											</div>
										</div>
									</>
								)}
								{!hasPinned && (
									<>
										<span>No pinned trophy</span>
										{isCurrentUser && (
											<HtmlTooltip title={<>Edit Pinned Trophy</>}>
												<span
													className={styles.editIconDiagonal}
													onClick={editPinnedTrophy}
												>
													<EditOutlined className={styles.editIconInner} />
												</span>
											</HtmlTooltip>
										)}
									</>
								)}
							</div>
						</div>
						<hr className={styles.divider} />
						<IconModalHeader
							style={{ position: "relative", left: -12 }}
							label="Trophy Case"
							icon={<Trophy width={24} />}
						/>
						<TrophyCase
							userId={props.userId}
							awards={awards}
							slots={3}
							width={352}
						/>
						{isCurrentUser && (
							<>
								<hr className={styles.divider} />
								<IconModalHeader
									style={{ position: "relative", left: -12 }}
									icon={<HttpsIcon />}
									label="Third Party Accounts"
								/>
								<div>
									<table className={styles.thirdPartyTable}>
										<thead>
											<tr>
												<th>Provider</th>
												<th>Status</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Discord</td>
												<td>
													{(thirdPartyLinks ?? []).includes("Discord") ? (
														<span style={{ fontStyle: "italic" }}>Linked</span>
													) : (
														<Button
															color="secondary"
															onClick={() => {
																onLinkAccountClick("Discord");
															}}
														>
															Link Your Account
														</Button>
													)}
												</td>
											</tr>
											<tr>
												<td>Google</td>
												<td>
													{(thirdPartyLinks ?? []).includes("Google") ? (
														<span style={{ fontStyle: "italic" }}>Linked</span>
													) : (
														<Button
															color="secondary"
															onClick={() => {
																onLinkAccountClick("Google");
															}}
														>
															Link Your Account
														</Button>
													)}
												</td>
											</tr>
											<tr>
												<td>Reddit</td>
												<td>
													{(thirdPartyLinks ?? []).includes("Reddit") ? (
														<span style={{ fontStyle: "italic" }}>Linked</span>
													) : (
														<Button
															color="secondary"
															onClick={() => {
																onLinkAccountClick("Reddit");
															}}
														>
															Link Your Account
														</Button>
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</>
						)}
					</ModalBody>
				</ModalPanel>
			</ModalPanelTransparent>
		</CustomModal>
	);
}
