import * as React from "react";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import EventRenderer from "../../EventRenderer/EventRenderer";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
	return {
		container: {
			width: "100%",
		},
	};
});

const propKeys = {
	eventData: StoreKeys.EVENT.EVENTDATA,
};

function PickemContentC(props) {
	const classNames = useStyles();

	return (
		<div className={classNames.container}>
			{props.eventData && <EventRenderer />}
		</div>
	);
}

export const PickemContent = (props) => {
	return connect(<PickemContentC />, propKeys, props);
};
