import { Container, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { SetLoading } from "common/components/Loader/Loader";
import * as React from "react";
import EventService from "services/EventService";
import { EventTypes } from "common/constants/EventTypes";
import LookupService from "services/LookupService";

const classStyles = makeStyles(() => {
	return {
		pickSelect: {
			display: "block",
			marginTop: 4,
			marginBottom: 16,
		},
		pickLabel: {
			display: "block",
		},
		pickTable: {
			border: "solid 1px black",
			borderCollapse: "collapse",
		},
		pickCell: {
			border: "solid 1px black",
		},
	};
});

export default function PickData() {
	const classNames = classStyles();
	const [gameData, setGameData] = React.useState(null);
	const [events, setEvents] = React.useState(null);
	const [selectedGame, setSelectedGame] = React.useState(null);
	const [selectedEventId, setSelectedEventId] = React.useState(null);
	const [pickData, setPickData] = React.useState(null);

	const loadPickData = (event) => {
		/**
		 	@type {
				 Array<{
					name: string,
					data: any,
					headers: Array<any>
				 }>
			 }
		 */
		let formattedPickData;
		switch (event.eventTypeId) {
			case EventTypes.SwissStagePlayoffs:
				EventService.getSwissStagePickData(event.id).then((pd) => {
					formattedPickData = [
						{
							name: "Advancing",
							data: pd.advancePickData,
							headers: ["Group", "Name", "Percent", "Picks", "Users"],
						},
						{
							name: "Eliminated",
							data: pd.elimPickData,
							headers: ["Group", "Name", "Percent", "Picks", "Users"],
						},
						{
							name: "Bracket Winner",
							data: pd.bracketPickData,
							headers: ["Group", "Name", "Percent", "Picks", "Users"],
						},
					];
				});
				break;
			case EventTypes.DoubleElimination:
			case EventTypes.FullDoubleElimination:
			case EventTypes.SeasonXChampionships:
			case EventTypes.SpringSplit:
			case EventTypes.SpringSplitMajor:
			case EventTypes.SwissStage:
				alert("This Event Type hasn't been set up yet. Contact the devs.");
				return;
			default:
				alert("This Event Type hasn't been set up yet. Contact the devs.");
				return;
		}

		setPickData(formattedPickData);
	};

	React.useEffect(() => {
		SetLoading(true);
		LookupService.getGames().then((games) => {
			SetLoading(false);
			setGameData(games);
		});
	}, []);

	React.useEffect(() => {
		if (!selectedGame) {
			return;
		}
		SetLoading(true);
		EventService.getEvents(selectedGame).then((eventTypes) => {
			SetLoading(false);
			setEvents(eventTypes);
		});
	}, [selectedGame]);

	React.useEffect(() => {
		if (!selectedEventId || !events) {
			return;
		}
		let event = null;
		for (var i = 0; i < events.length; i++) {
			if (events[i] && events[i].id === selectedEventId) {
				event = events[i];
				break;
			}
		}
		if (!!event) {
			loadPickData(event);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedEventId]);

	const onEventSelect = (ev) => {
		setSelectedEventId(Number(ev.target.value));
	};

	const onGameSelect = (ev) => {
		setSelectedGame(Number(ev.target.value));
	};

	const gameSelection = !gameData ? null : (
		<>
			<label className={classNames.pickLabel}>Game</label>
			<select className={classNames.pickSelect} onChange={onGameSelect}>
				<option>Please Select</option>
				{gameData &&
					gameData.map((game) => {
						return (
							<option key={game.id} value={game.id}>
								{game.name}
							</option>
						);
					})}
			</select>
		</>
	);

	const eventSelection = !events ? null : (
		<>
			<label className={classNames.pickLabel}>Event</label>
			<select className={classNames.pickSelect} onChange={onEventSelect}>
				<option>Please Select</option>
				{events &&
					events.map((event) => {
						return (
							<option key={event.id} value={event.id}>
								{event.name}
							</option>
						);
					})}
			</select>
		</>
	);

	const pickDataHeaderCell = (index, h) => {
		return (
			<th className={classNames.pickCell} key={index}>
				{h}
			</th>
		);
	};

	const dataCellJsx = (index, c) => {
		return (
			<td className={classNames.pickCell} key={index}>
				{c}
			</td>
		);
	};

	const dataRowJsx = (index, d) => {
		const cellJsx = [];
		const props = Object.getOwnPropertyNames(d);
		const sortedProps = props.sort();
		sortedProps.forEach((p, idx) => {
			cellJsx.push(dataCellJsx(idx, d[p]));
		});
		return <tr key={index}>{cellJsx}</tr>;
	};

	const pickDataRowJsx = (index, item) => {
		item.headers.sort();
		const headerCellsJsx = item.headers.map((h, ix) => {
			return pickDataHeaderCell(ix, h);
		});
		const dataRowsHtml = item.data.map((d, idx) => {
			return dataRowJsx(idx, d);
		});
		return (
			<div key={index}>
				<h3>{item.name}</h3>
				<table className={classNames.pickTable}>
					<thead>
						<tr>{headerCellsJsx}</tr>
					</thead>
					<tbody>{dataRowsHtml}</tbody>
				</table>
			</div>
		);
	};

	const pickDataJsx = !pickData
		? null
		: pickData.map((record, index) => {
				return pickDataRowJsx(index, record);
		  });

	return (
		<Container className="event-pick-data" maxWidth={false}>
			<Helmet>
				<title>Pickstop - Pick Data</title>
			</Helmet>
			<h1>Event Pick Data</h1>
			{gameSelection}
			{selectedGame && (
				<>
					{eventSelection}
					{selectedEventId && <>{pickDataJsx}</>}
				</>
			)}
		</Container>
	);
}
