import React from "react";
import Connectors from "bracket-system/components/connector";

import { getCalculatedStyles } from "../settings";
import {
	calculatePositionOfMatchLowerBracket,
	getColumnSizeArray,
} from "./calculate-match-position";

const ConnectorsLower = ({
	columns,
	bracketSnippet,
	rowIndex,
	columnIndex,
	style,
	offsetY = 0,
	isQualifierRound,
	isQualifierColumn,
}) => {
	const { columnWidth, rowHeight, canvasPadding } = getCalculatedStyles(style);
	const isFirstColumn = columnIndex === 0;
	const isUpperSeedingRound =
		isFirstColumn ||
		columns[columnIndex].length === columns[columnIndex - 1].length;

	// If the previous column has the same number of matches then some teams prequalified.
	// Offset the connector column by some value
	const columnArray = getColumnSizeArray(columns);
	const straightConnector =
		columnArray.indexOf(columns[columnIndex].length) ===
		columnArray.indexOf(columns[columnIndex - 1].length);

	if (!isQualifierColumn) {
		const currentMatchPosition = calculatePositionOfMatchLowerBracket(
			columns,
			rowIndex,
			columnIndex,
			{
				canvasPadding,
				rowHeight,
				columnWidth,
				offsetY,
			},
			isQualifierRound
		);
		const previousBottomPosition = isUpperSeedingRound
			? rowIndex
			: (rowIndex + 1) * 2 - 1;
		const previousTopMatchPosition =
			!isUpperSeedingRound &&
			calculatePositionOfMatchLowerBracket(
				columns,
				previousBottomPosition - 1,
				columnIndex - 1,
				{
					canvasPadding,
					rowHeight,
					columnWidth,
					offsetY,
				},
				isQualifierRound
			);
		const previousBottomMatchPosition = calculatePositionOfMatchLowerBracket(
			columns,
			previousBottomPosition,
			columnIndex - 1,
			{
				canvasPadding,
				rowHeight,
				columnWidth,
				offsetY,
			},
			isQualifierRound
		);

		if (straightConnector) {
			currentMatchPosition.y = previousTopMatchPosition.y;
			currentMatchPosition.y = previousBottomMatchPosition.y;
		}

		return (
			<Connectors
				bracketSnippet={bracketSnippet}
				previousBottomMatchPosition={previousBottomMatchPosition}
				previousTopMatchPosition={previousTopMatchPosition}
				currentMatchPosition={currentMatchPosition}
				style={style}
			/>
		);
	} else {
		const currentMatchPosition = calculatePositionOfMatchLowerBracket(
			columns,
			rowIndex,
			columnIndex,
			{
				canvasPadding,
				rowHeight,
				columnWidth,
				offsetY,
			},
			isQualifierRound
		);

		const previousTopMatchPosition = calculatePositionOfMatchLowerBracket(
			columns,
			rowIndex,
			columnIndex - 1,
			{
				canvasPadding,
				rowHeight,
				columnWidth,
				offsetY,
			},
			isQualifierRound
		);

		if (straightConnector) {
			currentMatchPosition.y = previousTopMatchPosition.y;
		}

		return (
			<Connectors
				bracketSnippet={bracketSnippet}
				previousTopMatchPosition={previousTopMatchPosition}
				currentMatchPosition={currentMatchPosition}
				style={style}
			/>
		);
	}
};

export default ConnectorsLower;
