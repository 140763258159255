import * as React from "react";
import { Box, Container, Divider, Paper } from "@material-ui/core";

export class ModalPanelTransparent extends React.Component {
	render() {
		return (
			<div className={"modal-content " + (this.props.className ?? "")}>
				{this.props.children}
			</div>
		);
	}
}

export class ModalPanel extends React.Component {
	render() {
		return (
			<Paper
				className={"modal-panel " + (this.props.className ?? "")}
				variant="outlined"
				elevation={3}
			>
				{this.props.children}
			</Paper>
		);
	}
}

export class ModalHeader extends React.Component {
	render() {
		return (
			<Container className={"modal-header " + (this.props.className ?? "")}>
				<Box ml={2} mr={2}>
					<h3>{this.props.children}</h3>
				</Box>
			</Container>
		);
	}
}

export class IconModalHeader extends React.Component {
	render() {
		return (
			<Container
				className={"modal-icon-header " + (this.props.className ?? "")}
				style={this.props.style ?? {}}
			>
				<Box>
					<label>
						{this.props.icon}
						{this.props.label?.toUpperCase()}
					</label>
				</Box>
			</Container>
		);
	}
}

export class ModalBody extends React.Component {
	render() {
		return (
			<Container className={"modal-body " + (this.props.className ?? "")}>
				{this.props.children}
			</Container>
		);
	}
}

export class ModalFooter extends React.Component {
	render() {
		return (
			<Container className="modal-footer">{this.props.children}</Container>
		);
	}
}

export class ModalDivider extends React.Component {
	render() {
		return (
			<Box my={2}>
				<Divider />
			</Box>
		);
	}
}
