import React from "react";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import { Grid } from "@material-ui/core";
import FatButton from "common/components/FatButton/FatButton";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import ModalHelper from "common/helpers/modalHelper";
import { ReactComponent as Crown } from "icons/Crown.svg";

export default function LeaveDeleteLeagueModal({ isDelete, onConfirm }) {
	const cancel = () => {
		ModalHelper.closeModal();
	};

	return (
		<CustomModal>
			<ModalPanel>
				<ModalBody>
					<ContentHeader
						icon={<Crown />}
						text={isDelete ? "Delete League" : "Leave League"}
					/>
					<p>
						Are you sure you want to {isDelete ? "delete" : "leave"} this
						league?
					</p>
					<Grid
						container
						justifyContent="space-evenly"
						style={{ marginTop: 8 }}
					>
						<FatButton text="Cancel" onClick={cancel} />
						<FatButton text="Confirm" onClick={onConfirm} primary />
					</Grid>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);
}
