import axiosWrapper from "common/axiosWrapper";

/**
 * Fetches the user profile for the provided user id.
 *
 * @param {string} userId The user id of the profile being viewed.
 */
export const getUserProfile = async (userId) => {
	return await axiosWrapper.get(`api/user/profile?userId=${userId}`);
};

/** Gets the discord login url. */
export const getDiscordLinkUrl = async () => {
	return await axiosWrapper.getCached(`api/auth/discord-link-url`);
};

/** Gets the google url. */
export const getGoogleLinkUrl = async () => {
	return await axiosWrapper.getCached(`api/auth/google-link-url`);
};

/** Gets the Reddit url. */
export const getRedditLinkUrl = async () => {
	return await axiosWrapper.getCached(`api/auth/reddit-link-url`);
};

/** Gets the 3rd party services this account is registered for. */
export const getThirdPartyStatus = async () => {
	return await axiosWrapper.getCached(`api/auth/third-party-status`);
};
