import React from "react";

export default function UpperRoundHeader({
	x,
	y = 0,
	width,
	roundHeader,
	canvasPadding,
	numOfRounds,
	columnIndex,
	onClick = null,
	customHeaderText = null,
	isQualifierRound,
}) {
	customHeaderText = customHeaderText ? customHeaderText : null;
	return (
		// @ts-ignore
		<g style={{ pointerEvents: "bounding-box" }} onClick={onClick}>
			<rect
				x={x}
				y={y + canvasPadding}
				width={width}
				height={roundHeader.height}
				fill={roundHeader.backgroundColor}
				rx="3"
				ry="3"
			/>
			<text
				fontFamily={roundHeader.fontFamily}
				x={x + width / 2}
				y={y + canvasPadding + roundHeader.height / 2}
				style={{
					fontSize: `${roundHeader.fontSize}px`,
					color: roundHeader.fontColor,
				}}
				fill="currentColor"
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{customHeaderText ??
					GetUpperBracketHeaderName(
						columnIndex,
						isQualifierRound ? numOfRounds - 1 : numOfRounds
					)}
			</text>
		</g>
	);
}

export function GetUpperBracketHeaderName(
	columnIndex: number,
	numOfRounds: number
) {
	if (columnIndex + 1 === numOfRounds) {
		return "Upper Final";
	} else if (columnIndex + 1 === numOfRounds - 1) {
		return "Upper Semi-Finals";
	} else if (columnIndex + 1 === numOfRounds - 2) {
		return "Upper Quarter-Finals";
	} else if (columnIndex + 1 < numOfRounds - 2) {
		return `Upper Round ${columnIndex + 1}`;
	}
}
