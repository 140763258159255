import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import UserManager from "common/userManager";
import * as React from "react";
import { Colors } from "styles/Colors";
import ScorePill from "../ScorePill/ScorePill";
import UserCard from "../UserCard";

const classStyles = makeStyles((theme) => {
	return {
		eventHeader: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			alignItems: "center",
		},
		eventName: {
			textTransform: "uppercase",
			display: "block",
			font: "normal normal bold 20px/23px Roboto",
			letterSpacing: 4,
			marginTop: 8,
			marginBottom: 8,
			textAlign: "center",
		},
		eventNameSmall: {
			textTransform: "uppercase",
			display: "block",
			font: "normal normal bold 15px/18px Roboto",
			letterSpacing: 4,
			marginTop: 8,
			marginBottom: 8,
			textAlign: "center",
		},
		flexRow: {
			display: "flex",
			alignItems: "center",
		},
		userCardContainer: {
			display: "inline-block",
		},
		scorePillContainer: {
			display: "inline-block",
			width: 145,
			marginLeft: 8,
		},
		divider: {
			width: 350,
			color: Colors.panelLighterColor,
			marginTop: 8,
			marginBottom: 10,
			opacity: 0.4,
		},
	};
});

const propKeys = {
	eventData: StoreKeys.EVENT.EVENTDATA,
	bracketUser: StoreKeys.EVENT.BRACKET_USER,
	score: StoreKeys.EVENT.SCORE,
};

function EventHeaderC(props) {
	const { eventData, bracketUser, score, width } = props;
	const styles = classStyles();

	const userId = bracketUser?.userId ?? UserManager.getUserId();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<div style={{ width: width ?? 400 }} className={styles.eventHeader}>
			<label className={isSmall ? styles.eventNameSmall : styles.eventName}>
				{eventData.nameOverride ?? eventData.name}
			</label>
			<div className={styles.flexRow}>
				<div className={styles.userCardContainer}>
					<UserCard userId={userId} />
				</div>
				<div className={styles.scorePillContainer}>
					<ScorePill score={score} eventType={eventData?.eventType} />
				</div>
			</div>
			<hr className={styles.divider} />
		</div>
	);
}

export default function EventHeader(props) {
	return connect(<EventHeaderC />, propKeys, props);
}
