import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import { toFriendlyDateTime } from "common/helpers/dateHelper";
import { SurveyDto } from "common/types/Survey/SurveyDto";
import * as React from "react";

export default function SurveyCompleteModal(props) {
	/** @type {SurveyDto} */
	const survey = props.survey;
	const dateTime = toFriendlyDateTime(survey.closeTime);

	return (
		<CustomModal>
			<ModalPanel>
				<ModalHeader>
					Your playoffs picks for RLCS Worlds 2024 have been submitted!
				</ModalHeader>
				<ModalBody>
					<p>
						You can revisit this page at any time to modify your selections
						until {dateTime.toString()}
					</p>
				</ModalBody>
			</ModalPanel>
		</CustomModal>
	);

	//return (
	//	<CustomModal>
	//		<ModalPanel>
	//			<ModalHeader>
	//				Thank you for voting in this year's RL Pro Awards!
	//			</ModalHeader>
	//			<ModalBody>
	//				<p>
	//					You can revisit this page at any time to modify your selections
	//					until {dateTime.toString()}
	//				</p>
	//			</ModalBody>
	//		</ModalPanel>
	//	</CustomModal>
	//);
}
