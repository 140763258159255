import axiosWrapper from "common/axiosWrapper";
import PicksHelper from "common/picksHelper";
import Store from "common/store";
import StoreKeys from "common/storeKeys";
import UserService from "services/UserService";
import { SetLoading } from "common/components/Loader/Loader";
// eslint-disable-next-line no-unused-vars
import { MatchPick } from "common/types/MatchPick";

export default class BaseEvent {
	/** @type {boolean} */
	hasBracket = false;
	/** @type {string} */
	baseUrl = "/api/picks";
	/** @type {any} */
	eventData;
	/** @type {any} */
	leaderboard;
	/** @type {PicksHelper} */
	picksHelper;
	/** @type {Array<any>} */
	officialBracketPicks;
	/** @type {Array<any>} */
	officialGroupPicks;

	constructor(eventData) {
		this.eventData = eventData;
		this.picksHelper = new PicksHelper(eventData);
	}

	async loadLeaderboard(startRow) {
		const eventId = this.eventData.id;
		let route = `/get/leaderboard/${eventId}`;
		if (startRow) {
			route += "/" + startRow;
		}

		await axiosWrapper.get(`${this.baseUrl}${route}`).then((leaderboard) => {
			this.leaderboard = leaderboard;
			Store.set(StoreKeys.EVENT.LEADERBOARD, leaderboard);
		});
	}

	async loadOfficialPicks() {
		const eventId = this.eventData.id;
		let route = `/get/official/${eventId}`;

		await axiosWrapper.get(`${this.baseUrl}${route}`).then((official) => {
			this.officialGroupPicks = official.groupStagePicks;
			Store.set(StoreKeys.EVENT.OFFICIAL.GROUP_PICKS, official.groupStagePicks);

			this.officialBracketPicks = official.matchPicks;
			Store.set(
				StoreKeys.EVENT.OFFICIAL.BRACKET_PICKS,
				this.buildPickedBracketMatchesFromTeams(official.matchPicks)
			);
		});
	}

	async loadBracketUser(userId) {
		if (userId) {
			await UserService.getUserProfile(userId).then((user) => {
				Store.set(StoreKeys.EVENT.BRACKET_USER, user);
			});
		} else {
			Store.set(StoreKeys.EVENT.BRACKET_USER, undefined);
		}
	}

	async loadUserScore(userId) {
		const eventId = this.eventData.id;
		const userIdQuery = userId ? `?userId=${userId}` : "";
		await axiosWrapper
			.get(`${this.baseUrl}/get/score/${eventId}${userIdQuery}`)
			.then((bracketScore) => {
				Store.set(StoreKeys.EVENT.SCORE, bracketScore);
			});
	}

	async checkLoadFirstPlace(targetUserId, fullLoad) {
		if (targetUserId || this.hasBracket || !this.picksHelper.isLocked()) {
			SetLoading(false);
			return;
		}

		// If user doesn't have a bracket we load first user on leaderboard.
		// Note: We mark the user as having a bracket while an event is unlocked event if they don't have one saved.
		const first = this.leaderboard[0];
		const userId = first?.userId;

		if (userId && targetUserId !== userId) {
			await fullLoad(userId);
		}
	}

	buildPickedBracketMatchesFromTeams(picks) {
		const teams = this.eventData.teams;
		for (const pick of picks) {
			pick.index = pick.matchIndex;
			pick.topTeam = teams.filter((team) => team.id === pick.topTeamId)[0];
			pick.bottomTeam = teams.filter(
				(team) => team.id === pick.bottomTeamId
			)[0];
			pick.winner = teams.filter((team) => team.id === pick.winnerTeamId)[0];
			pick.loser = teams.filter((team) => team.id === pick.loserTeamId)[0];
		}

		return picks;
	}
}
