import * as React from "react";
import { changeUrl } from "common/history";
import { makeStyles } from "@material-ui/core";
import { Box, Grid } from "@material-ui/core";
import { Colors } from "styles/Colors";

const useStyles = makeStyles((theme) => ({
	navCard: {
		border: `solid 1px ${theme.palette.primary.main}`,
		boxShadow: `0px 0px 4px 4px ${Colors.panelDark}`,
		backgroundColor: Colors.panel,
		textAlign: "center",
		padding: 16,
		"& hr": {
			border: `1px double ${Colors.error}`,
		},
		"& button": {
			marginTop: 16,
			width: "80%",
			borderRadius: 0,
		},
	},
}));

export default function NavCard(props) {
	const classNames = useStyles();
	const click = () => {
		changeUrl(props.path);
	};

	return (
		<>
			<Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg}>
				<Box className={classNames.navCard} mx={1} my={1}>
					<label>{props.title}</label>
					<hr />
					<span>{props.description}</span>
					<br />
					<button onClick={click}>{props.buttonLabel}</button>
				</Box>
			</Grid>
		</>
	);
}
