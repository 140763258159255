import * as React from "react";
import { ReactComponent as ShowResults } from "icons/ShowResults.svg";
import { ReactComponent as HideResults } from "icons/HideResults.svg";
import { ReactComponent as SubmittedAndCheck } from "icons/SubmittedAndCheck.svg";
import { ReactComponent as EditYourPicks } from "icons/EditYourPicks.svg";
import { ReactComponent as BackToMyPicks } from "icons/BackToMyPicks.svg";
import { Button, makeStyles } from "@material-ui/core";
import { Colors } from "styles/Colors";
import Clipboard from "react-clipboard.js";
import EventHelper from "common/helpers/eventHelper/eventHelper";
import UserManager from "common/userManager";
import { toast } from "react-toastify";
import StoreKeys from "common/storeKeys";
import { connect } from "common/connect";
import { changeUrl } from "common/history";
import { GetCurrentGameId, GetCurrentGamePrefix } from "common/constants/Games";
import { ReactComponent as DiscordIcon } from "icons/DiscordIcon.svg";
import { ReactComponent as InstagramIcon } from "icons/InstagramIcon.svg";
import { ReactComponent as RedditIcon } from "icons/RedditIcon.svg";
import { ReactComponent as TwitterIcon } from "icons/TwitterIcon.svg";
import { ReactComponent as ShareIcon } from "icons/ShareIcon.svg";
import Store from "common/store";
import LookupService from "services/LookupService";

const propKeys = {
	bracketUser: StoreKeys.EVENT.BRACKET_USER,
	hasBracket: StoreKeys.EVENT.HAS_BRACKET,
	games: StoreKeys.GAMES,
};

const useStyles = makeStyles((theme) => {
	return {
		eventFooter: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			alignItems: "center",
			marginTop: 8,
		},
		panel: {
			backgroundColor: Colors.panel,
			width: "fit-content",
			padding: 5,
			marginTop: 8,
			borderRadius: 12,
			position: "relative",
			height: 36,
			display: "flex",
			alignContent: "center",
		},
		icon: {
			cursor: "pointer",
			margin: "2px 4px",
			width: 32,
			height: 32
		},
		button: {
			cursor: "pointer",
			margin: 2,

			"&.disabled": {
				opacity: 0.3,
				cursor: "default",
				pointerEvents: "none",
			},
		},
		inline: {
			display: "inline-block",
			width: "fit-content",
		},
		flexCentered: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			width: "100%",
			justifyContent: "center",
		},
	};
});

export function EventFooterC(props) {
	const {
		bracketUser,
		hasBracket,
		games,
		width,
		onEdit,
		showingOfficialResults,
		showOfficialResults,
		picksLocked,
	} = props;
	const styles = useStyles();
	const isCurrentUser =
		bracketUser == null || bracketUser.userId === UserManager.getUserId();

	const clickShare = () => {
		toast("Link Copied!", { type: "success", toastId: "link-copied" });
	};

	React.useEffect(() => {
		if (!games) {
			LookupService.getGames().then((games) => {
				Store.set(StoreKeys.GAMES, games);
			});
		}
	});

	const gameId = GetCurrentGameId();
	const gamePrefix = GetCurrentGamePrefix();
	const currentGame = games.find((g) => g.id === gameId);

	const shareLink =
		"https://" +
		window.location.host +
		gamePrefix +
		"/event/" +
		EventHelper.currentEventId +
		"/" +
		(EventHelper.currentUserId ?? UserManager.getUserId());

	return (
		<div style={{ width: width ?? 400 }} className={styles.eventFooter}>
			<div className={styles.flexCentered}>
				{isCurrentUser && (
					<>
						{!showingOfficialResults && (
							<ShowResults
								className={styles.button}
								onClick={() => {
									showOfficialResults();
								}}
							/>
						)}
						{showingOfficialResults && (
							<HideResults
								className={styles.button}
								onClick={() => {
									showOfficialResults();
								}}
							/>
						)}

						{/* <ShowPlayers
							className={styles.button + (true ? " disabled" : "")}
							onClick={() => {
								if (onShowPlayers) {
									onShowPlayers();
								} else {
									console.error(
										"onShowPlayers callback was not provided to EventFooter Component!"
									);
								}
							}}
						/> */}
						<SubmittedAndCheck />
						<EditYourPicks
							className={styles.button + (picksLocked ? " disabled" : "")}
							onClick={() => {
								if (onEdit) {
									onEdit();
								} else {
									console.error(
										"onEdit callback was not provided to EventFooter Component!"
									);
								}
							}}
						/>
					</>
				)}
				{!isCurrentUser && picksLocked && (
					<>
						{!showingOfficialResults && (
							<ShowResults
								className={styles.button}
								onClick={() => {
									showOfficialResults();
								}}
							/>
						)}
						{showingOfficialResults && (
							<HideResults
								className={styles.button}
								onClick={() => {
									showOfficialResults();
								}}
							/>
						)}
					</>
				)}
				{!isCurrentUser && (!picksLocked || hasBracket) && (
					<BackToMyPicks
						className={styles.button}
						onClick={() => {
							const route = GetCurrentGamePrefix();
							changeUrl(route + "/event/" + EventHelper.currentEventId);
						}}
					/>
				)}
			</div>
			<div className={styles.panel}>
				<Button
					onClick={() => {
						changeUrl("/about-us");
					}}
					color="secondary"
					style={{ backgroundColor: Colors.body }}
				>
					<img src="/images/LogoOnly.png" width="36" height="36" />
					About Us
				</Button>
				<Clipboard
					component="div"
					style={{ display: "contents" }}
					data-clipboard-text={shareLink}
					onClick={() => clickShare()}
				>
					<ShareIcon title="Share" className={styles.icon} />
				</Clipboard>
				<DiscordIcon
					title="Discord"
					onClick={() => {
						window.open("https://discord.gg/r8ujzkRYeF", "_blank");
					}}
					className={styles.icon}
				/>
				<InstagramIcon
					title="Instagram"
					onClick={() => {
						window.open(currentGame.instagramUrl, "_blank");
					}}
					className={styles.icon}
				/>
				<TwitterIcon
					title="Twitter"
					onClick={() => {
						window.open(currentGame.twitterUrl, "_blank");
					}}
					className={styles.icon}
				/>
				<RedditIcon
					title="Reddit"
					onClick={() => {
						window.open("https://www.reddit.com/user/pickstopgg/", "_blank");
					}}
					className={styles.icon}
				/>
			</div>
		</div>
	);
}

export default function EventFooter(props) {
	return connect(<EventFooterC />, propKeys, props);
}
