import { Button } from "@material-ui/core";
import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import ModalHelper from "common/helpers/modalHelper";
import * as React from "react";
import "../SuccessFailModal/SuccessFailModal.scss";

export default function DeleteConfirmModal(props) {
	const onConfirm = () => {
		ModalHelper.closeModal();
		if (props.onConfirm) {
			props.onConfirm();
		}
	};
	const onCancel = () => {
		ModalHelper.closeModal();
	};
	return (
		<>
			<CustomModal>
				<ModalPanel className="success-fail-modal fail">
					<ModalHeader>Confirmation</ModalHeader>
					<ModalBody className="success-fail-section">
						<label>
							{`Are you sure you want to delete this '${props.objectName}'?`}
						</label>
						<br />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={onConfirm}>
							Confirm
						</Button>
						<Button color="secondary" onClick={onCancel}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalPanel>
			</CustomModal>
		</>
	);
}
