import * as React from "react";
import { connect } from "common/connect";
import StoreKeys from "common/storeKeys";
import { Button, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Colors } from "styles/Colors";
import DoubleEliminationBracket from "bracket-system/bracket-double/double-elim-bracket";
import Match from "bracket-system/components/match";
import SvgViewer from "bracket-system/svg-viewer";
import {
	getHeaderNamesFromBracket,
	getLockedColumns,
} from "common/helpers/eventFormatHelper";
import { EventStageTypes } from "common/constants/EventStageTypes";
import { parsePicksIntoBracketMatches } from "common/helpers/bracketHelper";
import Store from "common/store";
import ModalHelper from "common/helpers/modalHelper";
import TeamSelection from "common/components/TeamSelection/TeamSelection";
import EventHelper from "common/helpers/eventHelper/eventHelper";
// eslint-disable-next-line no-unused-vars
import { EventFormatData } from "common/helpers/eventFormatHelper";
// eslint-disable-next-line no-unused-vars
import { MatchPick } from "common/types/MatchPick";
// eslint-disable-next-line no-unused-vars
import { EventStage } from "common/types/EventStage";
import UserManager from "common/userManager";
import AccountRequired from "common/components/Modals/AccountRequired/AccountRequired";
import NameplateButton from "common/components/NameplateButton";
import { GetCurrentGameId } from "common/constants/Games";

const useStyles = makeStyles((theme) => {
	return {
		bracketWrapper: {
			border: `2px solid ${Colors.panel}`,
			borderRadius: theme.shape.borderRadius,
			overflow: "hidden",
			marginTop: theme.spacing() * 3,
			marginBottom: theme.spacing() * 3,
		},
	};
});

const propKeys = {
	eventData: StoreKeys.EVENT.EVENTDATA,
	userBracketPicks: StoreKeys.EVENT.USER.BRACKET_PICKS,
	officialBracketPicks: StoreKeys.EVENT.OFFICIAL.BRACKET_PICKS,
	format: StoreKeys.EVENT.FORMAT,
	stageIndex: StoreKeys.EVENT.STAGE_INDEX,
	substageIndex: StoreKeys.EVENT.SUBSTAGE_INDEX,
	games: StoreKeys.GAMES,
};

/**
 * @typedef BracketEditorCProps
 * @type {object}
 * @property {any} eventData
 * @property {MatchPick[]} userBracketPicks
 * @property {MatchPick[]} officialBracketPicks
 * @property {EventFormatData} format
 * @property {number} stageIndex
 * @property {number} substageIndex
 * @property {any[]} games
 */

/**
 * @param {BracketEditorCProps} props
 */
function BracketEditorC({
	eventData,
	userBracketPicks,
	officialBracketPicks,
	format,
	stageIndex,
	substageIndex,
	games,
}) {
	const ref = React.useRef();
	const styles = useStyles();
	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	const currentStage = format.stages[stageIndex];
	/** @type {EventStage} */
	const currentEventStage = eventData.eventStages[stageIndex];
	const currentSubstage = currentEventStage.eventSubstage[substageIndex];
	const totalSubstages = currentEventStage.eventSubstage.length;
	const bracketWidth = !isSmall
		? window.innerWidth - 382
		: window.innerWidth - 24;
	const bracketHeight = !isSmall
		? window.innerHeight * 0.75
		: window.innerHeight * 0.7;

	const useSeriesScore =
		currentStage?.type === EventStageTypes.Playoffs
			? currentStage?.playoffDetails?.useSeriesScore
			: currentStage?.groupStageDetails?.useSeriesScore;

	const officialMatches = parsePicksIntoBracketMatches(
		eventData,
		currentEventStage,
		currentSubstage,
		currentStage,
		currentStage.bracket,
		[],
		officialBracketPicks
	);

	const matches = parsePicksIntoBracketMatches(
		eventData,
		currentEventStage,
		currentSubstage,
		currentStage,
		currentStage.bracket,
		userBracketPicks ?? [],
		officialBracketPicks,
		true
	);

	const gameId = GetCurrentGameId();
	const currentGame = games.find((g) => g.id === gameId);

	const headerNames = getHeaderNamesFromBracket(currentStage.bracket);
	const lockedColumns = getLockedColumns(currentStage.bracket);

	const clickParty = (e, match, selectedTop, team) => {
		if (!UserManager.isLoggedIn()) {
			ModalHelper.openModal(<AccountRequired />);
			return;
		}

		// Check if match ready to pick
		if (match.participants.filter((p) => p.id !== "").length < 2) {
			return;
		}

		ModalHelper.openModal(<TeamSelection />, null, {
			match,
			eventData,
			bracket: currentStage.bracket,
			userBracketPicks,
			selectedTop,
			eventId: eventData.id,
			eventStageId: eventData.eventStages[stageIndex].id,
			eventSubstageId:
				eventData.eventStages[stageIndex].eventSubstage[substageIndex].id,
			useSeriesScore,
		});
	};

	const onBack = () => {
		Store.set(StoreKeys.EVENT.SUBSTAGE_INDEX, Math.max(substageIndex - 1, 0));
	};

	const onNext = () => {
		Store.set(
			StoreKeys.EVENT.SUBSTAGE_INDEX,
			Math.min(substageIndex + 1, totalSubstages - 1)
		);
	};

	const onSubmit = () => {
		EventHelper.submitPicks(currentGame).then(() => {
			Store.set(StoreKeys.EVENT.IS_EDITING, false);
		});
	};

	const checkNextDisabled = () => {
		return (
			currentStage.bracket.parts
				.flatMap((p) => p.columns.flatMap((c) => c.matches))
				.some((m) => {
					const matchingPick = userBracketPicks?.find(
						(p) =>
							p.bracketPart === m.part &&
							p.bracketCol === m.col &&
							p.bracketColIndex === m.colIndex &&
							p.eventSubstageId === currentSubstage.id
					);

					return matchingPick?.winnerTeamId == null;
				}) ?? true
		);
	};

	const nextDisabled = checkNextDisabled();
	const isSubmit = substageIndex + 1 === totalSubstages;

	return (
		<>
			<div className={styles.bracketWrapper} ref={ref}>
				<DoubleEliminationBracket
					matches={matches}
					officialMatches={officialMatches}
					matchComponent={Match}
					options={{
						style: {
							roundHeader: {
								fontSize: 20,
								backgroundColor: Colors.panel,
								fontColor: Colors.primary,
							},
							connectorColor: Colors.panel,
							connectorColorHighlight: Colors.pickstop,
						},
					}}
					svgWrapper={({ children, ...props }) => (
						<SvgViewer
							width={ref.current?.offsetWidth ?? bracketWidth}
							height={bracketHeight}
							{...props}
						>
							{children}
						</SvgViewer>
					)}
					customHeaderNames={headerNames}
					lockedColumns={lockedColumns}
					hideSeriesScore={!useSeriesScore}
					onPartyClick={clickParty}
				/>
			</div>
			<div
				style={{
					width: bracketWidth,
					display: "flex",
					justifyContent: "center",
				}}
			>
				{substageIndex > 0 && (
					<NameplateButton onClick={onBack} buttonText={"BACK"} />
				)}
				<NameplateButton
					onClick={() => {
						isSubmit ? onSubmit() : onNext();
					}}
					disabled={nextDisabled}
					buttonText={isSubmit ? "SUBMIT" : "NEXT"}
					nameplateId={isSubmit ? 32 : null}
				/>
			</div>
		</>
	);
}

export default function BracketEditor(props) {
	return connect(<BracketEditorC />, propKeys, props);
}
