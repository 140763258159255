import CustomModal from "common/components/CustomModal/CustomModal";
import {
	ModalBody,
	ModalHeader,
	ModalPanel,
} from "common/components/CustomModal/Modal/ModalFrame";
import * as React from "react";
import "../SuccessFailModal/SuccessFailModal.scss";

export default function RecordInUseModal(props) {
	return (
		<>
			<CustomModal>
				<ModalPanel className="success-fail-modal fail">
					<ModalHeader>Record Is In Use</ModalHeader>
					<ModalBody className="success-fail-section">
						<label>
							{`That '${props.objectName}' is in use and would ` +
								"cause data corruption by deleting."}
						</label>
					</ModalBody>
				</ModalPanel>
			</CustomModal>
		</>
	);
}
