import axiosWrapper from "common/axiosWrapper";
import EventHelper from "common/helpers/eventHelper/eventHelper";
// eslint-disable-next-line no-unused-vars
import EventPicksDto from "common/types/EventPicksDto";
import UserManager from "common/userManager";

const baseUrl = "/api/picks";

/** The Pick service which calls the API endpoints for the Pick Controller */
export default class PickService {
	/**
	 * Get the current user's picks for the provided event id.
	 *
	 * @param {number} eventId The event to view picks for.
	 * @param {number} userId The user to view picks for.
	 * @returns {Promise<EventPicksDto>}
	 */
	static async getUserPicks(eventId, userId = null) {
		const userIdQuery = userId ? `?userId=${userId}` : "";
		return await axiosWrapper.get(
			`${baseUrl}/get/user/${eventId}${userIdQuery}`
		);
	}

	/**
	 * Saves the current users picks.
	 *
	 * @param {number} eventId
	 * @param {EventPicksDto} picks
	 */
	static async saveUserPicks(eventId, picks) {
		for (const groupPick of picks.groupStagePicks) {
			groupPick.eventId = EventHelper.getCurrentEventId();
			groupPick.userId = UserManager.getUserId();
		}
		for (const groupPick of picks.matchPicks) {
			groupPick.eventId = EventHelper.getCurrentEventId();
			groupPick.userId = UserManager.getUserId();
		}

		return await axiosWrapper.post(`${baseUrl}/save/user/${eventId}`, picks);
	}
}
