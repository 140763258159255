import axiosWrapper from "common/axiosWrapper";

const baseUrl = "/api/auth";

/** The auth service which calls the API endpoints for the Auth Controller */
export default class AuthService {
	/**
	 * Gets the patreon url to link your account with.
	 *
	 * @returns {Promise<string>} The url.
	 */
	// static async getPatreonUrl() {
	// 	const route = `/patreon-url`;
	// 	return await axiosWrapper.get(`${baseUrl}${route}`);
	// }

	static async logout() {
		return await axiosWrapper.post(`${baseUrl}/logout`, {});
	}
}
