import { makeStyles } from "@material-ui/core";
import * as React from "react";
import UserManager from "common/userManager";
import { Colors } from "styles/Colors";
import UserCard from "common/components/UserCard";
import StoreKeys from "common/storeKeys";
import AwardSummary from "./AwardSummary";
import TrophyService from "services/TrophyService";
import TrophyList from "common/components/TrophyList/TrophyList";
import ContentPanel from "common/components/ContentPanel/ContentPanel";
import ContentHeader from "common/components/ContentHeader/ContentHeader";
import LookupService from "services/LookupService";
import { GetCurrentGameId } from "common/constants/Games";
import { connect } from "common/connect";

const useStyles = makeStyles({
	topContainer: {
		display: "flex",
	},
	summary: {
		width: "100%",
		display: "flex",
	},
	summaryContent: {
		width: "100%",
	},
	userInfoContainer: {
		display: "flex",
		flexDirection: "column",
		width: "80%",
		maxWidth: 400,
		margin: "auto",
		height: "95%",
		alignItems: "center",
		cursor: "pointer",
	},
	divider: {
		border: "1px solid " + Colors.body,
		display: "block",
		marginLeft: 10,
		marginRight: 10,
	},
});

const propKeys = {
	userId: StoreKeys.USER.ID,
};

function YourAwardsC({ userId }) {
	const styles = useStyles();
	const [state, setState] = React.useState({
		awards: [],
		games: [],
	});

	React.useEffect(() => {
		if (userId) {
			const gameId = GetCurrentGameId();
			Promise.all([
				TrophyService.getYourAwards(userId, gameId),
				LookupService.getGames(),
			]).then(([awardData, games]) => {
				awardData = awardData.sort((a, b) => {
					return b.eventId - a.eventId;
				});
				games = games.filter((g) => g.id === gameId);

				setState({
					awards: awardData,
					games,
				});
			});
		}
	}, [userId]);

	const headerJsx = <ContentHeader text="Your Awards" />;

	if (!state.awards) {
		return (
			<ContentPanel>
				<div className={styles.topContainer}>
					<div className={styles.summary}>
						<div className={styles.summaryContent}>
							{headerJsx}
							No awards to show.
						</div>
					</div>
				</div>
			</ContentPanel>
		);
	}

	return (
		<ContentPanel>
			<div className={styles.topContainer}>
				<div className={styles.summary}>
					<div className={styles.summaryContent}>
						{headerJsx}
						<div className={styles.userInfoContainer}>
							<UserCard userId={UserManager.getUserId()} marginBottom={16} />
							<AwardSummary
								awards={state.awards}
								games={state.games}
								showEmptyRows={true}
								hideIcons={true}
							/>
						</div>
					</div>
				</div>
			</div>
			<hr className={styles.divider} />
			<div style={{ padding: 10 }}>
				<TrophyList awards={state.awards} />
			</div>
		</ContentPanel>
	);
}

export default function YourAwards(props) {
	return connect(<YourAwardsC />, propKeys, props);
}
